
import { child, get, getDatabase, onValue, ref, set, update } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { formatDateToDateTimeStr } from "../../Utils/functions";

const db = getDatabase()
export const saveBusinessInfo = (data, firebaseUserID, companyId = null)=>new Promise((successCallBack, errorCallBack)=>{

    // const fId = push(child(ref(db), 'UsersCompany/' + firebaseUserID)).key
    const userRef = companyId?ref(db, 'CompanyInvoiceDetails/' + companyId) : ref(db, 'UserInvoiceDetails/' + firebaseUserID)
    data['userFId'] = firebaseUserID
    set(userRef, data).then(()=>{
        //goOffline(db)
        successCallBack()
    }).catch((err)=>{
        //goOffline(db)
        errorCallBack(err)
    });
})

export const updateBusinessInfo = (data, firebaseUserID, companyId = null)=>new Promise((successCallBack, errorCallBack)=>{

    // const fId = push(child(ref(db), 'UsersCompany/' + firebaseUserID)).key
    const userRef = companyId?ref(db, 'CompanyInvoiceDetails/' + companyId) : ref(db, 'UserInvoiceDetails/' + firebaseUserID)
    
    update(userRef, data).then(()=>{
        //goOffline(db)
        successCallBack()
    }).catch((err)=>{
        //goOffline(db)
        errorCallBack(err)
    });
})


export const getBusinessDetailsFromDb = (firebaseUserID, companyId = null)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    
    const userRef = companyId?ref(db, 'CompanyInvoiceDetails/' + companyId) : ref(db, 'UserInvoiceDetails/' + firebaseUserID)
    onValue(userRef, (snapshot) => {
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
            //goOffline(db)
        }else {
            successCallback({})
            //goOffline(db)
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});


export const updateBusinessLogo = (file, firebaseUserId, firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
     
    
    const storage = getStorage();
    
    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        const imgRef = storageRef(storage, firebaseUrl);
        deleteObject(imgRef).then(()=>{
            
        })
    }

    const imgRef = storageRef(storage, "BusinessDetails/company/" + firebaseUserId + "/" +(new Date().valueOf()) + '.jpg');
    const uploadTask = uploadBytes(imgRef, file)
    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then(successCallBack)
            .catch(errorCallback);
    }).catch(errorCallback)

})

// general settings


export const saveSettings = (data, firebaseUserID, companyID = null)=>new Promise((successCallBack, errorCallBack)=>{

    //const db = getDatabase()
    // const fId = push(child(ref(db), 'UsersCompany/' + firebaseUserID)).key
    const userRef = companyID?ref(db, 'CompanySettings/' + companyID + "/GeneralSettings/" + firebaseUserID)
        :ref(db, 'UsersSettings/' + firebaseUserID)
    data['userId'] = firebaseUserID
    data['updatedAt'] = formatDateToDateTimeStr(new Date())
    set(userRef, data).then((_data)=>{
        //goOffline(db)
        successCallBack(_data)
    }).catch((err)=>{
        //goOffline(db)
        errorCallBack(err)
    });
})


export const getSetingsData = (firebaseUserID, companyID)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    
    const link = companyID?(db, 'CompanySettings/' + companyID + "/GeneralSettings/" + firebaseUserID)
        :(db, 'UsersSettings/' + firebaseUserID)
    const userRef = ref(db)
    get(child(userRef, link))
    // onValue(userRef, (snapshot) => {
        .then((snapshot) => {
        
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
            //goOffline(db)
        }else {
            successCallback({})
            //goOffline(db)
        }
    }).catch(errorCallback)
});

///company settings controller




export const saveCompanySettings = (data, companyId, memberId)=>new Promise((successCallBack, errorCallBack)=>{

    //const db = getDatabase()
    // const fId = push(child(ref(db), 'UsersCompany/' + firebaseUserID)).key
    const companyRef = ref(db, 'CompanySettings/' + companyId + "/member/"+memberId)
    data['companyId'] = companyId
    data['updatedAt'] = formatDateToDateTimeStr(new Date())
    set(companyRef, data).then(successCallBack).catch(errorCallBack);
})


export const getCompanySetingsData = (companyId, memberId)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    
    const companyRef = ref(db, 'CompanySettings/' + companyId + "/member/"+memberId)
    onValue(companyRef, (snapshot) => {
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
            //goOffline(db)
        }else {
            successCallback({})
            //goOffline(db)
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});


export const saveCompanyProfilePageSettings = (data, companyId)=>new Promise((successCallBack, errorCallBack)=>{

    getCompanypProfileSetingsData(companyId).then((settingsData)=>{


        const companyRef = ref(db, 'CompanySettings/' + companyId + "/profile/")
        data['updatedAt'] = formatDateToDateTimeStr(new Date())
        if(Object.keys(settingsData).length > 0) {
            update(companyRef, data).then(successCallBack).catch(errorCallBack);            
        }else {
            set(companyRef, data).then(successCallBack).catch(errorCallBack);
        }

    }).catch(errorCallBack)
})


export const getCompanypProfileSetingsData = (companyId)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    
    const companyRef = ref(db, 'CompanySettings/' + companyId + "/profile/")
    onValue(companyRef, (snapshot) => {
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
            //goOffline(db)
        }else {
            successCallback({})
            //goOffline(db)
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});


export const saveProviderProfilePageSettings = (data, providerId)=>new Promise((successCallBack, errorCallBack)=>{

    getProviderProfileSetingsData(providerId).then((settingsData)=>{


        const companyRef = ref(db, 'UserProviderSettings/' + providerId + "/profile/")
        data['updatedAt'] = formatDateToDateTimeStr(new Date())
        if(Object.keys(settingsData).length > 0) {
            update(companyRef, data).then(successCallBack).catch(errorCallBack);            
        }else {
            set(companyRef, data).then(successCallBack).catch(errorCallBack);
        }

    }).catch(errorCallBack)
})


export const getProviderProfileSetingsData = (providerId)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    
    const companyRef = ref(db, 'UserProviderSettings/' + providerId + "/profile/")
    onValue(companyRef, (snapshot) => {
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
            //goOffline(db)
        }else {
            successCallback({})
            //goOffline(db)
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});


export const getCompanyRoundRobinSettings = (companyId)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    
    const companyRef = ref(db, 'CompanySettings/' + companyId + "/roundrobin/")
    onValue(companyRef, (snapshot) => {
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
            //goOffline(db)
        }else {
            successCallback({})
            //goOffline(db)
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});

export const saveCompanyRoundRobinSettings = (data, companyId)=>new Promise((successCallBack, errorCallBack)=>{

    getCompanyRoundRobinSettings(companyId).then((settingsData)=>{


        const companyRef = ref(db, 'CompanySettings/' + companyId + "/roundrobin/")
        data['updatedAt'] = new Date().valueOf()
        if(Object.keys(settingsData).length > 0) {
            update(companyRef, data).then(successCallBack).catch(errorCallBack);            
        }else {
            set(companyRef, data).then(successCallBack).catch(errorCallBack);
        }

    }).catch(errorCallBack)
})


export const deleteImage = (firebaseUrl = "") =>new Promise(function(successCallBack, errorCallback){
    if(firebaseUrl !== "" && firebaseUrl) {
        const storage = getStorage();
        try{ 
            const imgRef = storageRef(storage, firebaseUrl);
            deleteObject(imgRef).then(()=>{
                successCallBack()
                
            })
        }catch(err) {
            errorCallback(err)
        }
    }
})


export const updateRoundRobinBgImg = (file, companyId, firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    
    const storage = getStorage();

    //delete old file from firebase
    deleteImage(firebaseUrl)

    const imgRef = storageRef(storage, "Companies/RoundRobin/bgImage/"+companyId+"/"+(new Date().valueOf()) + '.jpg');
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                
                saveCompanyRoundRobinSettings({bgImage: url.toString()}, companyId).then(()=>{
                    successCallBack(url)
                }).catch(errorCallback)

            })
            .catch((err)=>{
                errorCallback(err)
            });
    }).catch((err)=>{
        errorCallback(err)
    })

})


export const getProviderPaymentSetingsData = (providerId, isCompany = false)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    
    const companyRef = isCompany?ref(db, 'CompanySettings/' + providerId + "/payments/")
    :ref(db, 'UserProviderSettings/' + providerId + "/payments/")
    onValue(companyRef, (snapshot) => {
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
            //goOffline(db)
        }else {
            successCallback({})
            //goOffline(db)
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});


export const saveProviderPaymentPageSettings = (data, providerId, isCompany = false)=>new Promise((successCallBack, errorCallBack)=>{

    
    const companyRef = isCompany?ref(db, 'CompanySettings/' + providerId + "/payments/")
    :ref(db, 'UserProviderSettings/' + providerId + "/payments/")
    data['updatedAt'] = parseFloat(new Date().valueOf())

    update(companyRef, data).then(successCallBack).catch(errorCallBack);  
})


export const getSubscriptionSettings = (providerId)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    
    const companyRef = ref(db, 'Subscriptions/Users/' + providerId)
    onValue(companyRef, (snapshot) => {
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
            //goOffline(db)
        }else {
            successCallback({})
            //goOffline(db)
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});




export const getStaffSubscriptionSettings = (companyId, providerId)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    
    const companyRef = ref(db, 'Subscriptions/companies/' + companyId + "/"+providerId)
    onValue(companyRef, (snapshot) => {
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
            //goOffline(db)
        }else {
            successCallback([])
            //goOffline(db)
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});

export const saveSubscriptionSettings = (data, providerId)=>new Promise((successCallBack, errorCallBack)=>{

    
    const companyRef = ref(db, 'Subscriptions/Users/' + providerId)
    data['updatedAt'] = parseFloat(new Date().valueOf())
    
    update(companyRef, data).then(successCallBack).catch(errorCallBack);  
})