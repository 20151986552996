import React, { Fragment, useEffect, useState } from 'react';

// reactstrap components
import { Add, CameraAlt, Close, Delete, Done, MiscellaneousServices, Remove } from '@mui/icons-material';
import { Autocomplete, Avatar, Badge, Grid, IconButton, ListItem, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/system';
import DivCenterContents from 'components/DivCenterContents';
import AddServiceProvider from 'components/Modal/AddServiceProvider';
import { getBusinessDetailsFromDb } from 'FirebaseController/Settings';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import Switch from "react-switch";
import {
    Button, Form
} from "reactstrap";
import { getCompanyStaffMembers } from '../../FirebaseController/Company';
import { getCustomLocationsFromDb, getOfflineLocationsFromDb } from '../../FirebaseController/Locations';
import { deleteServiceProfile, getCategoriesDataFromDb, getIndustries, getServiceTags, saveService, updateService, updateServiceProfile } from '../../FirebaseController/Services/services';
import { searchLocation } from '../../LocationController';
import { refreshServices } from '../../Store/Reducers/Services/services.actions';
import { APP_MANAGER_TYPES, MAX_LOGO_FILE_SIZE, SERVICE_STATUS, STAFF_TYPE } from '../../Utils/constants';
import { inputStyles } from '../../Utils/style';

export default function ServicesForm(props) {

    const { editService, isEdit, configs } = props
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [serviceData, setServiceData] = useState({serviceType: "Online", "duration": 10,})
    const [isLoading, setIsLoading] = React.useState(false)
    const [isTagLoading, setIsTagLoading] = React.useState(false)
    const [isIndLoading, setIsIndLoading] = React.useState(false)
    const [isLocLoading, setIsLocLoading] = React.useState(false)
    const [isCatLoading, setIsCatLoading] = React.useState(false)
    const [categories, setCategories] = React.useState([]); 
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [specialization, setSpecialization] = React.useState([]);
    const [Industry, setIndustry] = React.useState([]); 
    const [locations, setLocations] = useState([])
    const [staffMembers, setStaffMembers] = useState([])
    const [inputLogoFile, setInputLogoFile] = React.useState('')
    const [servType, setServType] = useState("Online")
    const [businessDet, setBusinessDet] = useState({})
    const [isBusinessDetLoading, setIsBusinessDetLoading] = React.useState(false)
    const serviceTypes = [
        {
            val: "Online",
            holder: "Online",
        },
        {
            val: "Offline",
            holder: "In-person (Offline)",
        },
        {
            val: "Onsite",
            holder: "On-site (User defined)",
        },
    ]
    //const appConfigReducer = useSelector((state) => state.appConfigReducer)
    // const [appConfigReducer, setAppConfigReducer] = useOutletContext()
    const appConfigReducer = configs
    const { companyName } = useParams()
    //set manager id to user or company id
    const managerId = (appConfigReducer.type === APP_MANAGER_TYPES[0])?user.uid:appConfigReducer.data.companyID
    const [isCityLoading, setIsCityLoading] = React.useState(false)
    const [isKeyboardChange, setIsKeyBoardChange] = React.useState(false)
    const [locOptions, setLocOptions] = React.useState([]);
    const [locValue, setLocValue] = React.useState(locOptions.length > 0?locOptions[0]:'');
    const [locInputValue, setLocInputValue] = React.useState('');
    const [locData, setLocData] = React.useState([]);
    const [teamMember, setTeamMember] = React.useState({});

    
    
    const handleLocValueChange = (value)=>{
        const locVal = locValue
        setLocValue(value)
        setLocData(locData.filter(item=>(item.title === locVal || ("address" in item && item.address.label === locVal))))
    }
  
    const handleLocInputChange = (event, value) => {
        setLocInputValue(value)
        setLocValue(value)
        if(!isLocLoading){// reduce unnecessary calls to api
            if(isKeyboardChange) { // check if is user input
                if(value.length > 2) { //start searching after 3 characters
                    setIsCityLoading(true)
                    
                    if (window.navigator.geolocation) {
                        window.navigator.geolocation.getCurrentPosition((position)=>{
                            
                            const lat = position.coords.latitude
                            const long = position.coords.longitude
                            searchLocation(value, lat, long).then((data)=>{
                                //
                                // 
                                setIsCityLoading(false)
                                setLocOptions(
                                    data.data.items.map(item=>("address" in item?item.address.label:item.title))
                                )
                                setLocData(data.data.items)
                            }).catch((err)=>{
                                setIsCityLoading(false)
                                
                                
                            })
                        })
                    } else {
                        showSnackBar("Geolocation is not supported by this browser.", "error")
                    }
                }
            }
        }
    }

    //
    const handleServTypeChange = (e) => {
        setServiceData({
            ...serviceData,
            'serviceType': e.target.value
        })
        setServType(e.target.value)
    }

    //

    
    
    
    const handleLocationChange = (e) => {
        
        setServiceData({
            ...serviceData,
            'serviceLocation': e.target.value
        })
    }

    const handleCategoryChange = (e) => {
        
        setServiceData({
            ...serviceData,
            'serviceCategory': e.target.value
        })
    }

    const handleCouponChange = (__checked, id) => {
        setServiceData({
            ...serviceData,
            [id]: __checked
        })
    }
    
    
    const handleCityChange = (e) => {
        setServiceData({
            ...serviceData,
            'serviceCity': e.target.value
        })
    }
    
    const handleCountryChange = (e) => {
        setServiceData({
            ...serviceData,
            'serviceCountry': e.target.value
        })
    }
    const handleSpecializationChange = (value) => {
        setServiceData({
            ...serviceData,
            'serviceTag': getServiceId(value)
        })
    };
    const handleIndustryChange = (value) => {
        setServiceData({
            ...serviceData,
            'serviceIndustry': getServiceIndId(value)
        })
    };
    
    const handleSaveService = ()=> {

        const userObj = JSON.parse(localStorage.getItem("user"))
        const user = userObj.user
        let selectedProviders = localStorage.getItem("serviceProviderId")
        selectedProviders = selectedProviders?JSON.parse(selectedProviders):(editService)?serviceData['serviceProviderId']:null        


        //add location
        // if(serviceData.serviceType.toLowerCase() === "custom") {
        //     serviceData["serviceCity"] = locValue
        //     serviceData['lat'] = locData.length > 0?locData[0].position.lat:editService?editService.lat:null
        //     serviceData["long"] = locData.length > 0?locData[0].position.lng:editService?editService.long:null
        // }


        if(!("serviceTitle" in serviceData) || !(serviceData.serviceTitle)) {
            
            showSnackBar("Service title is required", "error")
            return
        }
        if(!("serviceDesc" in serviceData) || !(serviceData.serviceDesc)) {
            
            showSnackBar("Service description is required", "error")
            return
        }
        if(!("duration" in serviceData) || parseInt(serviceData.duration) < 10) {
            
            showSnackBar("Invalid service duration", "error")
            return
        }
        if(serviceData.serviceType.toLowerCase() === "offline") {
            if(!("serviceLocation" in serviceData) || !serviceData.serviceLocation) {
                
                showSnackBar("Service location is required", "error")
                return
            }
        }
        
        if(serviceData.serviceType.toLowerCase() === "custom") {
            if(//(!("serviceCity" in serviceData) || !serviceData.serviceCity) &&
             (!("serviceLocation" in serviceData) || !serviceData.serviceLocation)) {
                
                showSnackBar("Service location is required", "error")
                return
            }
        }
        
        if((!selectedProviders || Object.keys(selectedProviders).length < 1) && appConfigReducer.type === APP_MANAGER_TYPES[1]) {
            
            
            showSnackBar("Service provider is required", "error")
            return
        }
        setIsLoading(true)
        serviceData['serviceProviderId'] = selectedProviders?selectedProviders:(editService)?serviceData['serviceProviderId']:(appConfigReducer.type === APP_MANAGER_TYPES[0])?{[managerId]: true}:null
        serviceData['companyID'] = (editService)?serviceData['companyID']:(appConfigReducer.type === APP_MANAGER_TYPES[0])?null:managerId
        serviceData['createdAt'] = serviceData['createdAt'] || parseFloat(new Date().valueOf())
        if(serviceData['price']) {
            serviceData['price'] = parseFloat(serviceData['price'])
        }
        if(user){

            if(appConfigReducer.type === APP_MANAGER_TYPES[1]){
                
                // getStaffMember(user.uid, managerId)
                
                getCompanyStaffMembers(parseFloat(appConfigReducer.data.companyID), true)
                    .then((allData)=>{

                        const data = allData.filter(item=>(item.userId || item.uid) === user.uid)

                        if(data.length < 1 || (data.length > 0 && data[0]["memberRole"] === STAFF_TYPE[0])) {
                            showSnackBar("You are not authorised to add service, contact admin/manager for approval", "error")
                            setIsLoading(false)
                            return false
                        }

                        
                        const admin = allData.filter((item)=>item["memberRole"] === STAFF_TYPE[2])[0]
                        
                        if(isEdit === 1){
                            //save data
                            updateService(serviceData, managerId.toString()).then(()=>{
                                showSnackBar("Service updated successfully", "success")
                                setIsLoading(false)
                                navigate(appConfigReducer.type === APP_MANAGER_TYPES[0]?"/services":"/co/"+companyName+"/services")
                                dispatch(refreshServices())
                                // toggleModal()
                                // handleRefreshServices()

                            }).catch((err)=>{
                                showSnackBar("Could not update service, try again later", "error")
                                setIsLoading(false)
                                
                            })
                        }else {
                            //save data
                            console.log(serviceData)
                            saveService(serviceData, managerId.toString(), admin.userId).then(()=>{ 
                                showSnackBar("Service added successfully", "success")
                                setIsLoading(false)
                                // navigate("/services")
                                navigate(appConfigReducer.type === APP_MANAGER_TYPES[0]?"/services":"/co/"+companyName+"/services")
                                dispatch(refreshServices())
                                // toggleModal()    
                                // handleRefreshServices()

                            }).catch((err)=>{

                                const msg = (typeof err === 'string' || err instanceof String)?err:"Could not add service, try again later"
                                showSnackBar(msg, "error")
                                setIsLoading(false)
                                
                            })
                        }
                        
                    }).catch((err)=>{
                        console.log(err)
                        showSnackBar("Could not add service, try again later", "error")
                        setIsLoading(false)
                        
                    })
            } else {

                if(editService){
                    //save data
                    if("companyID" in serviceData && !serviceData.companyID) delete serviceData.companyID
                    updateService(serviceData, managerId.toString()).then(()=>{
                        showSnackBar("Service updated successfully", "success")
                        setIsLoading(false)
                        // navigate("/services")
                        navigate(appConfigReducer.type === APP_MANAGER_TYPES[0]?"/services":"/co/"+companyName+"/services")
                        dispatch(refreshServices())
                        // toggleModal()
                        // handleRefreshServices()

                    }).catch((err)=>{
                        showSnackBar("Could not update service, try again later", "error")
                        setIsLoading(false)
                        
                    })
                }else {
                    
                    //save data
                    saveService(serviceData, managerId.toString(), user.uid).then(()=>{
                        showSnackBar("Service added successfully", "success")
                        setIsLoading(false)
                        // navigate("/services")
                        navigate(appConfigReducer.type === APP_MANAGER_TYPES[0]?"/services":"/co/"+companyName+"/services")
                        dispatch(refreshServices())
                        // toggleModal()    
                        // handleRefreshServices()

                    }).catch((err)=>{
                        showSnackBar("Could not add service, try again later", "danger")
                        setIsLoading(false)
                        
                    })
                }
            }
        }
    }


    const handleFormInputChange = (key, e) => {

        const val = e.target.value
        if(key === 'serviceDesc' && val.length > 300) {

            showSnackBar("Service description can only have a maximum of 300 characters", "error")

        }else {
            setServiceData({
                ...serviceData,
                [key]: val
            })

        }
    }

    
    const handleFileChange = (e) => {

        
        
        if(e.target.files.length > 0){
            if(e.target.files[0].size/ (1024) > MAX_LOGO_FILE_SIZE){//greater than 300 kb
                showSnackBar("File is too big, only 300kb are allowed.", "error")
            }else {
                setInputLogoFile(e.target.files[0])
            }
        }
    }
    
    const saveServiceLogo = () => {
        

        showSnackBar("Uploading logo, please wait..", "success")

        updateServiceProfile(
            inputLogoFile,
            appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
            serviceData.serviceImg || "",
            appConfigReducer.type === APP_MANAGER_TYPES[1]
        )
        .then((url) => {//success callback function
                
                
                setServiceData({
                    ...serviceData,
                    "serviceImg": url,
                })

                if(isEdit === 1) {
                    updateService({"serviceImg": url, serviceId: serviceData.serviceId}, managerId.toString()).then(()=>{
                        showSnackBar("Service updated successfully. ", "success")
                    })
                    .catch((err)=>{
                        
                        showSnackBar("Upload successful. click save to update the service", "success")
                    })
                }else {
                    showSnackBar("Upload successful. click save to update the service", "success")
                }
            }           

        ).catch(
            (err) => {
                showSnackBar("Could not upload image, please try again later.", "error")                
                
            }
        )
    }

    const handleAddDuration = ()=> {

        const dur = serviceData.duration?serviceData.duration:0
        setServiceData({
            ...serviceData,
            "duration": (parseInt(dur) + 10).toString()
        })
    }

    
    const handleSubtractDuration = ()=> {

        let dur = parseInt(serviceData.duration) - 10
        dur = (dur >= 10)? dur:10
        setServiceData({
            ...serviceData,
            "duration": (dur).toString()
        })
    }

    const handleArchive = () =>{
        if(window.confirm("Are you sure you want to archive this service?")){
                
            if(managerId){
                const archiveData = {
                    serviceId: serviceData.serviceId,
                    status: SERVICE_STATUS[1]
                }
                updateService( archiveData, managerId.toString()).then(()=>{
                    showSnackBar("Service was archived successfully", "success")
                    dispatch(refreshServices())
                }).catch((err)=>{
                    
                    showSnackBar("Could not archive service, try again later", "error")
                })
                // deleteService(managerId.toString(), serviceData.serviceId).then(()=>{
                //     showSnackBar("Service was deleted successfully", "success")
                //     dispatch(refreshServices())
                // }).catch((err)=>{
                //     
                //     showSnackBar("Could not delete service, try again later", "error")
                // })
            }else {
                showSnackBar("Invalid user", "error")
            }
        }
    }

    useEffect(() => {
      if(editService){
          setServiceData(editService)
          setServType(editService.serviceType)
      }
    
      return () => {
        setServiceData({serviceType: "Online"})
        localStorage.removeItem("serviceProviderId")
      }
    }, [editService])

    useEffect(() => {
        if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
            getCompanyStaffMembers(managerId, true).then((data)=>{
                setStaffMembers(data)                
                setTeamMember(data.filter(item=>item.userId === user.uid)[0])
            }).catch((err)=>{
                
            })
        }
    }, [])
    

    useEffect(() => {
        setIsIndLoading(true)
        setIsTagLoading(true)
        getIndustries().then((data)=>{
            setIsIndLoading(false)
            setIndustry(data)
        }).catch((err)=>{
            
            setIsIndLoading(false)
        })
        getServiceTags().then((data)=>{
            setIsTagLoading(false)
            
            setSpecialization(data)
            if(!editService){
                setServiceData({
                    ...serviceData,
                    "serviceTag": getServiceId("Executive Coaching"),
                    "serviceIndustry" : getServiceIndId("Professional Training & Coaching")
                })
            }
        }).catch((err)=>{
            
            setIsTagLoading(false)
        })
    
      return () => {
        setServiceData({serviceType: "Online",})
      }
    }, [])

    useEffect(() => {
        if(servType && servType.toLowerCase() === "offline" && user) {
            setIsLocLoading(true)
            getOfflineLocationsFromDb(managerId, appConfigReducer.type === APP_MANAGER_TYPES[1]).then((data)=>{
                setLocations([...data])
                setIsLocLoading(false)
                if(data.length < 1) {
                    showSnackBar("Hey, you have no locations added. Add location, then use the offline feature", "error")
                }else {

                    
                }

            }).catch((err)=>{
                
                setIsLocLoading(false)
            })
        } else if(servType && servType.toLowerCase() === "custom" && user) {
            setIsLocLoading(true)
            getCustomLocationsFromDb(managerId, appConfigReducer.type === APP_MANAGER_TYPES[1]).then((data)=>{
                
                setLocations([...data])
                setIsLocLoading(false)
                if(data.length < 1) {
                    showSnackBar("Hey, please create a location with settings for a custom location first", "error")
                }else {

                    
                    
                }

            }).catch((err)=>{
                
                setIsLocLoading(false)
            })
        }

        return () => {
            setLocations([])
        }
    }, [servType])

    React.useEffect(() => {
      
        setIsCatLoading(true)
        getCategoriesDataFromDb(
            managerId, (appConfigReducer.type === APP_MANAGER_TYPES[1])
        ).then((data)=>{
            setIsCatLoading(false)
            setCategories(data)

        }).catch((err)=>{
            
            setIsCatLoading(false)
        })
        return () => null
    }, [ ])
    
    React.useEffect(() => {
      
        if(editService) {
            setLocValue("serviceCity" in editService && editService.serviceCity?editService.serviceCity:"")
        }
    
      return () => null
    }, [editService ])

    useEffect(() => {
        
        if(locations.length > 0){
            setServiceData({
                ...serviceData,
                'serviceLocation': locations[0].LocationID
            })
        }
    
      return () => null
    }, [locations])
    
    
    useEffect(() => {
        if(inputLogoFile && !(typeof inputLogoFile === 'string' || inputLogoFile instanceof String)) {
          saveServiceLogo()
        }
      
        return () => null
      }, [inputLogoFile])


    useEffect(() => {
      
        setIsBusinessDetLoading(true) 
        getBusinessDetailsFromDb(user.uid, appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():null)
        .then((data)=>{
            setIsBusinessDetLoading(false)
            setBusinessDet({
                "currValue": data.currValue?data.currValue:"",
            })
        }).catch((err)=>{
            setIsBusinessDetLoading(false)
            
        })
    
      return () => null
    }, [])
    
    
    const formInputs = [
        // {
        //     name: "serviceTitle",
        //     required: true,
        //     holder: "Add service title",
        //     type: 'text'
        // }, 
        {
            name: "serviceDesc",
            required: true,
            holder: 'Description (300 chars max)',
            placeholder:"Enter text here",
            type: 'text'
        }, 
        {
            name: "duration",
            required: true,
            holder: 'Duration (mins)',
            placeholder:"Duration",
            type: 'number'
        },
    ]

    const formInputs_ = [
        {
            name: "currency",
            required: true,
            holder: 'Currency',
            placeholder:"",
            type: 'text',
            disabled: "true"
        }, 
        {
            name: "price",
            required: true,
            holder: 'Price',
            placeholder:"Price",
            type: 'number'
        }, 

    ]

    function getServiceIndustry(indId) {

        const val =  Industry.map(item=>item.indId)
        return (val.includes(indId))?Industry[val.indexOf(indId)].industry:indId
    }
    
    function getServiceIndId(industry) {

        const val =  Industry.map(item=>item.industry)
        return (val.includes(industry))?Industry[val.indexOf(industry)].indId:industry
    }


    function getServiceTag(serviceId) {

        const _specialization = removeDuplicates(specialization)
        const val =  _specialization.map(item=>item.serviceId)
        return (val.includes(serviceId))?_specialization[val.indexOf(serviceId)].tag:serviceId
    }
    
    function getServiceId(tag) {
        const _specialization = removeDuplicates(specialization)
        const val =  _specialization.map(item=>item.tag)
        return (val.includes(tag))?_specialization[val.indexOf(tag)].serviceId:tag
    }


    function removeDuplicates (obj){
        return obj.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.tag === value.tag
            ))
        )
    }

    function handleDeleteImg() {
        deleteServiceProfile(serviceData.serviceImg)
        .then(()=>{
            
            updateService({
                serviceImg: "",
                serviceId: serviceData.serviceId
            }, managerId.toString())
                .then(()=>{

                    showSnackBar("Image deleted successfully", "success")
                    setServiceData({
                        ...serviceData,
                        serviceImg: ""
                    })
                    dispatch(refreshServices())
                })
                
        })
        .catch((err)=>{
            
            showSnackBar("Could not delete image, try again later", "error")

        })
    }
    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}


    return (
        <>
            
            <Box
                className="pt-4"
                size="sm"
                style={{zIndex: 10}}
            >
                <div className="modal-body p-0">
                <div className="border-0">
                    <div className="px-4">
                        <Form >

                            

                            <div>
                                <div>
                                    <DivCenterContents _size={6}>
                                        
                                            <div className="position-relative">
                                                <input 
                                                    accept="image/*" id="icon-button-file" 
                                                    onChange={handleFileChange}
                                                    type="file" style={{display: 'none'}} />
                                                <label htmlFor="icon-button-file">
                                                    <Badge
                                                        overlap="circular"
                                                        badgeContent={
                                                            <Avatar sx={{bgcolor: "#63C3CF"}}><CameraAlt /></Avatar>
                                                        }
                                                    
                                                    >
                                                        {
                                                            (serviceData.serviceImg || inputLogoFile)?
                                                                <Avatar 
                                                                    // variant="square"
                                                                    // src={profileLink && Object.keys(profileLink).length !== 0?profileLink:profile}
                                                                    src={serviceData.serviceImg || inputLogoFile}
                                                                    sx={{width: '110px', height: '110px', border: 'none', background: "inherit"}} >
                                                                    
                                                                    <MiscellaneousServices style={{fontSize: '80px', color: "#F0973B"}} />

                                                                </Avatar>

                                                            :
                                                            
                                                            <Avatar 
                                                                // variant="square"
                                                                sx={{width: '110px', height: '110px', border: 'none', background: "inherit"}} 
                                                                color="primary"
                                                            >

                                                                <MiscellaneousServices style={{fontSize: '80px', color: "#F0973B"}} />
                                                            </Avatar>

                                                        }
                                                    </Badge>
                                                </label><   br />

                                                {
                                                    serviceData.serviceImg?
                                                    <Tooltip title="Delete image">
                                                        <IconButton
                                                            sx={{
                                                                position: 'absolute',
                                                                top: -5,
                                                                right: -50
                                                            }}
                                                            onClick={handleDeleteImg}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>

                                                    :""
                                                }

                                                <Typography variant='body2' sx={{fontSize: '11px', float: 'right', paddingLeft: '5px',paddingTop: '0'}} className="">
                                                    Max size: {MAX_LOGO_FILE_SIZE} kb
                                                </Typography>
                                            </div>
                                    </DivCenterContents>
                                </div>
                            </div>

                            <div>
                                <h6 style={{fontWeight: 'bold', margin: '15px 0'}} >
                                    Service profile details
                                </h6>
                                <div className='border p-3 mb-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '5px'}}>
                                    <label htmlFor='serviceTitle'>Add service title</label><br />
                                    <textarea id="serviceTitle" style={{
                                            ...inputStyles,
                                            borderRadius: '5px',
                                            border: 'none',
                                            padding: '5px',
                                            width: '100%',
                                            resize: 'none'
                                        }} 
                                        type="text" 
                                        name="serviceTitle" 
                                        placeholder='Enter text here'
                                        required={true} 
                                        onChange={(e)=>handleFormInputChange("serviceTitle", e)}
                                        value={
                                            serviceData && "serviceTitle" in serviceData?
                                                serviceData["serviceTitle"]
                                            :""
                                        }
                                        rows="1"
                                        cols="50"
                                    ></textarea>

                                
                                {
                                    formInputs.map((item, idx)=>(
                                        <FormControl fullWidth size="sm" key={idx} sx={{mb: 1}} >

                                            {
                                                (item.name !== "price") || (item.name === "price" && (appConfigReducer.type === APP_MANAGER_TYPES[0] || (appConfigReducer.type === APP_MANAGER_TYPES[1] && teamMember.memberRole === STAFF_TYPE[2])))?
                                                    <label id={item.name}>{item.holder}</label>
                                                :""
                                            }
                                            {

                                                item.name === 'serviceDesc'?
                                                    <Fragment>
                                                        <textarea 
                                                            style={inputStyles}
                                                            fullWidth 
                                                            id={item.name}
                                                            name={item.name} 
                                                            placeholder={item.placeholder}
                                                            required = {item.required}
                                                            value={
                                                                serviceData && item.name in serviceData?
                                                                    serviceData[item.name]
                                                                :""
                                                            }
                                                            onChange={(e)=>handleFormInputChange(item.name, e)} 
                                                            rows="4" 
                                                            cols="50"></textarea> 

                                                            
                                                        <div className='text-right mb-0 pb-0' style={{fontSize: '14px',color: "#999" , fontWeight: 300}}>
                                                            {
                                                                serviceData && item.name in serviceData?
                                                                    serviceData[item.name].length
                                                                :0
                                                            } /300
                                                        </div>

                                                        

                                                    </Fragment>
                                                :
                                                    (item.name === 'duration')?
                                                    <div className='d-flex'>
                                                        <IconButton className='mr-2' onClick={handleSubtractDuration } >
                                                            <Remove />
                                                        </IconButton>
                                                        <div className='w-75'>
                                                            <input
                                                                className="mb-2 pb-1 pt-2"
                                                                style={inputStyles}
                                                                placeholder={item.placeholder}
                                                                type={item.type}
                                                                name={item.name}
                                                                disabled={true}
                                                                id={item.name}
                                                                min={10}
                                                                step={10}
                                                                onChange={(e)=>handleFormInputChange(item.name, e)}
                                                                value={
                                                                    serviceData && item.name in serviceData?
                                                                        serviceData[item.name]
                                                                    :""
                                                                }
                                                            />
                                                        </div>
                                                        <IconButton className='ml-2' onClick={handleAddDuration}>
                                                            <Add />
                                                        </IconButton>
                                                    </div>
                                                :(item.name === "price") && (appConfigReducer.type === APP_MANAGER_TYPES[0] || (appConfigReducer.type === APP_MANAGER_TYPES[1] && teamMember.memberRole === STAFF_TYPE[2]))?

                                                    <input
                                                        className="mb-2"
                                                        style={inputStyles}
                                                        placeholder={item.placeholder}
                                                        type={item.type}
                                                        name={item.name}
                                                        id={item.name}
                                                        min={10}
                                                        step={10}
                                                        onChange={(e)=>handleFormInputChange(item.name, e)}
                                                        value={
                                                            serviceData && item.name in serviceData?
                                                                serviceData[item.name]
                                                            :""
                                                        }
                                                    />

                                                :""

                                            }
                                        </FormControl>
                                    ))

                                    
                                    
                                }
                                </div>
                            </div>
                            
                           {
                            
                            isBusinessDetLoading?
                                <SyncLoader
                                    loading={isBusinessDetLoading}
                                    size={10}
                                    color="#000"
                                />
                                :
                                <div>
                                    <h6 style={{fontWeight: 'bold', margin: '15px 0'}} >
                                        Service pricing details
                                        
                                        {
                                            businessDet.currValue?"":
                                            <div className='form-text pb-1' style={{fontSize: '12px',color: "#ff6666" , fontWeight: 300}}>
                                                {"* Please add a currency in your invoice settings."}
                                            </div>
                                        }
                                    </h6>
                                    <div className='border p-3 mb-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '5px'}}>
                                        
                                        
                                        {
                                            formInputs_.map((item, idx)=>(
                                                <FormControl fullWidth size="sm" key={idx} sx={{mb: 1}} >

                                                    {
                                                        (item.name !== "price") || (item.name === "price" && (appConfigReducer.type === APP_MANAGER_TYPES[0] || (appConfigReducer.type === APP_MANAGER_TYPES[1] && teamMember.memberRole === STAFF_TYPE[2])))?
                                                            <label id={item.name}>{item.holder}</label>
                                                        :""
                                                    }
                                                    {

                                                        (item.name === "price") && (appConfigReducer.type === APP_MANAGER_TYPES[0] || (appConfigReducer.type === APP_MANAGER_TYPES[1] && teamMember.memberRole === STAFF_TYPE[2]))?

                                                            <input
                                                                className="mb-2"
                                                                style={inputStyles}
                                                                placeholder={item.placeholder}
                                                                type={item.type}
                                                                name={item.name}
                                                                id={item.name}
                                                                min={10}
                                                                step={10}
                                                                onChange={(e)=>handleFormInputChange(item.name, e)}
                                                                value={
                                                                    serviceData && item.name in serviceData?
                                                                        serviceData[item.name]
                                                                    :""
                                                                }
                                                            />

                                                        :
                                                        <input
                                                            className="mb-2"
                                                            style={inputStyles}
                                                            placeholder={item.placeholder}
                                                            type={item.type}
                                                            name={item.name}
                                                            id={item.name}
                                                            disabled={item.disabled}
                                                            onChange={(e)=>handleFormInputChange(item.name, e)}
                                                            value={
                                                                
                                                                (serviceData[item.name] || businessDet.currValue || "")
                                                            }
                                                        />

                                                    }
                                                </FormControl>
                                            ))

                                            
                                            
                                        }


                                        <FormControl fullWidth size="sm" sx={{mb: 1}}>

                                            
                                            <ListItem alignItems="flex-start" sx={{mb: 0, pb: 0}}>
                                                <ListItemText
                                                    primary={
                                                        <Grid container>
                                                            <Grid item xs={10}>
                                    
                                                                <div color="secondary" style={{textTransform: 'none'}}>
                                                                    {"Enable coupon"}
                                                                    <div className='form-text pb-0 mb-0' style={{fontSize: '14px',color: "#999" , fontWeight: 300}}>
                                                                        {"Allow users to enter coupon codes for discounts during booking"}
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                        
                                                                <ListItemText primary={
                                                                    
                                                                    <label> 
                                                                        <Switch
                                                                            onChange={(checked)=>handleCouponChange(checked, "enableCoupon")}
                                                                            checked={"enableCoupon" in serviceData?serviceData["enableCoupon"]:false}
                                                                            onColor={"#F4F5FA"}
                                                                            onHandleColor={"#F0973B"}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            checkedHandleIcon={<Done style={{color: "#fff", margin: '5px'}} /> }
                                                                            offHandleColor={"#E7E8ED"}
                                                                            offColor="#F4F5FA"
                                                                            height={35}
                                                                            width={63}
                                                                        />
                                                                    </label>
                                                                }
                                    
                                                                />
                                                                
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />

                                            </ListItem>
                                        </FormControl>

                                    </div>
                                </div>
                                
                            } 
                            <div>
                                <h6 style={{fontWeight: 'bold', margin: '15px 0'}} >
                                    Service details
                                </h6>
                                <div className='border p-3 mb-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '5px'}}>

                                    <FormControl fullWidth size="sm" sx={{mb: 2}}>

                                        {
                                            isTagLoading?
                                                <SyncLoader
                                                    loading={isTagLoading}
                                                    size={10}
                                                    color="#000"
                                                />
                                            :
                                            <>
                                                <label id="specialization">Specialization* 
                                                
                                                    <div className='form-text pb-1' style={{fontSize: '12px',color: "#999" , fontWeight: 300}}>
                                                            {"Please select the specialization else type Other."}
                                                    </div>
                                                </label>

                                                <Autocomplete
                                                    disablePortal
                                                    id="specialization"
                                                    options={
                                                        removeDuplicates(specialization).map(item=>item.tag)
                                                    }
                                                    // item.serviceId
                                                    // value={serviceData && "serviceTag" in serviceData?getServiceTag(serviceData.serviceTag):""}
                                                    value={serviceData && "serviceTag" in serviceData?getServiceTag(serviceData.serviceTag):""}
                                                    inputValue={serviceData && "serviceTag" in serviceData?getServiceTag(serviceData.serviceTag):""}
                                                    sx={{ width: '100%', border: 'none' }}
                                                    disableClearable={true}
                                                    size="small"
                                                    onChange={(e, val)=>handleSpecializationChange(val)}    
                                                    onInputChange={(e, val)=>handleSpecializationChange(val)}
                                                    renderInput={(params) => 
                                                        <TextField 
                                                            style={inputStyles}  variant="standard" {...params} 
                                                            InputProps={{...params.InputProps,disableUnderline: true , style: { fontSize: 13,  } }} />
                                                    }
                                                />
                                                
                                                {/* <select
                                                    labelId="specialization-label"
                                                    id="specialization"
                                                    value={serviceData && "serviceTag" in serviceData?serviceData.serviceTag:""}
                                                    label="specialization"
                                                    style={inputStyles}
                                                    onChange={handleSpecializationChange}
                                                >

                                                    {
                                                        specialization.map(item=>
                                                            <option key={item.serviceId} value={item.serviceId}>
                                                                {item.tag}
                                                            </option>)
                                                    }
                                                </select> */}
                                            </>
                                        }
                                    </FormControl>

                                    {
                                        categories.length > 0?

                                    
                                            <FormControl fullWidth >
                                                {
                                                    isCatLoading?
                                                        
                                                    <div>
                                                        <SyncLoader
                                                            loading={isCatLoading}
                                                            size={10}
                                                            color="#000"
                                                        />
                                                    </div>

                                                    :
                                                    <>
                                                        
                                                        <label htmlFor="serviceCategory" className='py-0 my-0 mb-2'>
                                                            Select category
                                                        </label>
                                                        <select
                                                            id="serviceCategory"
                                                            style={inputStyles}
                                                            value={
                                                                serviceData && "serviceCategory" in serviceData && serviceData.serviceCategory?serviceData.serviceCategory:""
                                                            }
                                                            onChange={handleCategoryChange}
                                                        >
                                                            <option disabled selected value="">
                                                                Select category
                                                            </option>
                                                            {
                                                                categories.map((item, idx)=>(
                                                                    <option value={item.catId} key={item.catId}
                                                                        
                                                                    >
                                                                        {item.catTitle}
                                                                    </option>                                        
                                                                ))
                                                            }
                                                            
                                                        </select>
                                                    
                                                    </>
                                                    
                                                }

                                            </FormControl>
                                            
                                        :""
                                    }
                                    <FormControl fullWidth  sx={{my: 2}}>
                                        
                                        {
                                            isIndLoading?
                                                <SyncLoader
                                                    loading={isIndLoading}
                                                    size={10}
                                                    color="#d3d3d3"
                                                />
                                            :
                                            <>
                                                <label id="Industry">Industry*
                                                    <div className='form-text pt-1' style={{fontSize: '12px', color: "#999" , fontWeight: 300}}>
                                                        {"Please enter & choose the industry for the service"}
                                                    </div>
                                                </label>
                                                
                                                <Autocomplete
                                                    disablePortal
                                                    id="Industry"
                                                    options={
                                                        Industry.map(item=>item.industry)
                                                    }
                                                    // item.serviceId
                                                    // value={serviceData && "serviceTag" in serviceData?getServiceTag(serviceData.serviceTag):""}
                                                    value={serviceData && "serviceIndustry" in serviceData?getServiceIndustry(serviceData.serviceIndustry):""}
                                                    inputValue={serviceData && "serviceIndustry" in serviceData?getServiceIndustry(serviceData.serviceIndustry):""}
                                                    sx={{ width: '100%', border: 'none' }}
                                                    disableClearable={true}
                                                    size="small"
                                                    onChange={(e, val)=>handleIndustryChange(val)}
                                                    onInputChange={(e, val)=>handleIndustryChange(val)}
                                                    renderInput={(params) => 
                                                        <TextField 
                                                            style={inputStyles}  variant="standard" {...params} 
                                                            InputProps={{...params.InputProps,disableUnderline: true , style: { fontSize: 13,  } }} />
                                                    }
                                                />
                                                {/* <select
                                                    labelId="Industry-label"
                                                    id="Industry"
                                                    style={inputStyles}
                                                    label="Industry"
                                                    value={serviceData && "serviceIndustry" in serviceData?serviceData.serviceIndustry:""}
                                                    onChange={handleIndustryChange}
                                                >
                                                    {
                                                        Industry.map(item=>
                                                            <option key={item.indId} value={item.indId}>
                                                                {item.industry}
                                                            </option>)
                                                    }
                                                </select> */}

                                            </>
                                        }
                                    </FormControl>
                                    {
                                        appConfigReducer.type === APP_MANAGER_TYPES[1]?
                                            <Box sx={{mb: 2}}>
                    
                                            
                                                
                                                <label id="providers">Add service provider
                                                </label>
                                                <AddServiceProvider providers={staffMembers} 
                                                    existingSelectedProviders={serviceData && "serviceProviderId" in serviceData && serviceData.serviceProviderId?serviceData.serviceProviderId:null} />
                                            </Box>

                                        :""
                                    }
                                    
                                </div>
                            </div>
                            <div>
                                <h6 style={{fontWeight: 'bold', margin: '15px 0'}} >
                                    Service booking details
                                </h6>
                                <div className='border p-3 mb-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '5px'}}>
                                    <FormControl fullWidth  sx={{mb: 2}}>

                                    
                                        
                                        <label id="serviceType">Booking options
                                        </label>
                                        <select
                                            id="serviceType"
                                            style={inputStyles}
                                            value={serviceData && "serviceType" in serviceData?serviceData.serviceType:""}
                                            onChange={handleServTypeChange}
                                        >
                                            {
                                                serviceTypes.map((item)=>(
                                                    <option value={item.val} key={item.val}>
                                                        {item.holder}
                                                    </option>                                        
                                                ))
                                            }
                                            
                                        </select>
                                    </FormControl>
                                    
                                    {
                                        locations.length > 0?

                                    
                                            <FormControl fullWidth className="mb-2" >
                                                {
                                                    isLocLoading?
                                                        
                                                    <div class="text-center">
                                                        <SyncLoader
                                                            loading={isLocLoading}
                                                            size={10}
                                                            color="#000"
                                                        />
                                                    </div>

                                                    :(serviceData && "serviceLocation" in serviceData && serviceData.serviceType.toLowerCase() === "offline")
                                                    || 
                                                    (serviceData && "serviceLocation" in serviceData && serviceData.serviceType.toLowerCase() === "custom")
                                                    ||
                                                    (locations.length > 0 && serviceData.serviceType.toLowerCase() === "offline")
                                                    ||
                                                    (locations.length > 0 && serviceData.serviceType.toLowerCase() === "custom")?
                                                    <>
                                                        
                                                        <label htmlFor="serviceLoc" className='py-0 my-0 mb-2'>
                                                            Select location
                                                        </label>
                                                        <select
                                                            id="serviceLoc"
                                                            style={inputStyles}
                                                            value={
                                                                serviceData && "serviceLocation" in serviceData && serviceData.serviceLocation?serviceData.serviceLocation:""
                                                            }
                                                            onChange={handleLocationChange}
                                                        >
                                                            <option disabled selected value="">
                                                                Select Location
                                                            </option>
                                                            {
                                                                locations.map((item, idx)=>(
                                                                    <option value={item.LocationID} key={item.LocationID}
                                                                        
                                                                    >
                                                                        {item.locationName}
                                                                    </option>                                        
                                                                ))
                                                            }
                                                            
                                                        </select>
                                                    
                                                    </>
                                                    :""
                                                    
                                                }

                                            </FormControl>
                                            
                                        :""
                                    }
                                    
                                    <FormControl fullWidth  sx={{mb: 2, mt: 0}}>

                                        <>                                  
                                            
                                            <label htmlFor="serviceLoc1" className='py-0 my-0 mb-2'>
                                                Booking questions
                                                
                                            </label>
                                            {
                                                ["question1", "question2", "question3"].map((item, idx)=>(
                                                    <input
                                                        className='mt-1 w-100'
                                                        id={item}                                
                                                        key={item}
                                                        // fullWidth={true}
                                                        style={inputStyles}
                                                        // disableUnderline={true}
                                                        placeholder={"Enter question "+(idx+1)}
                                                        name={item}
                                                        value={item in serviceData?serviceData[item]:""}
                                                        onChange={(e)=>handleFormInputChange(item, e)}
                                                    />
                                                ))
                                            }
                                            
                                        </>


                                    </FormControl>
                                
                                </div>
                            </div>
                            
                                                       

                            <div className="d-flex">
                                <div style={{flex: 1}}></div>
                                <Button onClick={handleSaveService} 
                                    className={"rounded border my-4 mx-1"}
                                    color="primary" 
                                    type="button" disabled={isLoading}
                                    style={{background: '#F0973B',textTransform: 'none'}}
                                >
                                    {
                                        isLoading?
                                            <SyncLoader
                                                loading={isLoading}
                                                size={10}
                                                color="#d3d3d3"
                                            />
                                            :
                                        "Save"
                                    }
                                    
                                </Button>
                                {
                                    editService?
                                    <Button onClick={handleArchive} 
                                        className={"rounded border-0 my-4 mx-1"}
                                        color="primary" 
                                        type="button" disabled={isLoading}
                                        style={{background: '#000',textTransform: 'none'}}
                                    >
                                        Archive
                                    </Button>
                                    :""
                                }
                            </div>
                        </Form>
                    </div>
                </div>
                </div>
            </Box>
        </>
    )

}