import { SET_PROFILE, REFRESH_PROFILE } from './user.types'

const initialState = {
    data: {},
    refreshProfile: 0
  };
export const userReducer = (state = initialState, action)=> {

    switch (action.type) {
        case SET_PROFILE:
            return {
                ...state,
                data: action.payload,
            };

        case REFRESH_PROFILE:
            return {
                ...state,
                refreshProfile: state.refreshProfile + 1,
            };

        default:
            return state;
    }
}