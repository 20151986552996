import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import AddBooking from 'components/Forms/addBooking';
import BookingLocationForm from 'components/Forms/bookingLocation';
import BookingQuestionsForm from 'components/Forms/bookingQuestions';
import CategoryForm from 'components/Forms/category';
import ClientDetailsForm from 'components/Forms/clientDetails';
import CompanyForm from 'components/Forms/company';
import ContactForm from 'components/Forms/contact';
import CouponForm from 'components/Forms/coupon';
import AddEvent from 'components/Forms/event';
import InvoiceItems from 'components/Forms/invoiceItems';
import LocationForm from 'components/Forms/location';
import ServicesForm from 'components/Forms/services';
import StaffForm from 'components/Forms/staff';
import BookingDetailsModal from 'components/Modal/BookingDetails';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setTitle } from '../../Store/Reducers/NavBarTitle/title.actions';
import '../../Utils/css/calendar.css';
import '../../Utils/css/datePicker.css';
import '../../Utils/css/timepicker.css';

export default function MainDrawer(props) {
    const { open, toggleDrawer, pageType, data, action } = props
    const dispatch = useDispatch()
    const [pageTitle, setPageTitle] = useState("Add event")
    const [isEdit, setIsEdit] = useState(pageType.isEdit?pageType.isEdit:0)
    const navigate = useNavigate()
    const { companyName } = useParams()
    
    // 

    const toggleDrawer_ = (newOpen) => {
      
      
      
      toggleDrawer(newOpen)
    }

    const toggleMainDrawer = (newOpen) => () => {
      toggleDrawer(newOpen);
      if(pageType.type === "addBooking"){

        navigate(companyName?'/co/'+companyName+'/home':'/')
      }

    };

    const toggleViewAndEditEvemt = () =>{
      if(pageType.type === "event"){
        setPageTitle("Edit event")
        setIsEdit(1)
      }

    }
  

    useEffect(() => {
      
        if(pageType.type === "event"){
            const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
            setPageTitle(title + " event")
            setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "service"){

          setPageTitle("Add service")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "clientDetails"){

          setPageTitle("Confirm client details")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "addBooking"){

          setPageTitle("Add booking")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
          //dispatch title
          dispatch(setTitle("SELECT SERVICE", true, 1))
        }else if(pageType.type === "showBooking"){
          
          setPageTitle("Booking Details")
        }else if(pageType.type === "location") {

          const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
          setPageTitle(title+" location")
          
          // setIsEdit(pageType.isEdit)
        }else if(pageType.type === "company") {

          const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
          setPageTitle(title+" marketplace")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "staff") {

          const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
          setPageTitle(title+" team member")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "category") {

          const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
          setPageTitle(title+" category")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "booking_location") {

          // const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
          setPageTitle("Enter location of service")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "service_answers") {

          // const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
          setPageTitle("More booking details")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "contact") {

          const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
          setPageTitle(title+" contact")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "invoice_items") {

          const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
          setPageTitle(title+" item")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }else if(pageType.type === "coupon") {

          const title = pageType.isEdit == 2?"View":pageType.isEdit == 1?"Edit":"Add"
          setPageTitle(title+" coupon")
          setIsEdit(pageType.isEdit?pageType.isEdit:0)
        }

        
  
    
      return () => null
    }, [pageType])
    

    


  return (
    <div>
      
      <React.Fragment >
          <Drawer
            anchor={"right"}
            open={open}
            onClose={toggleMainDrawer(false)}
        >
            <Box
                sx={{ width: {md: 400, xs: '95%'} }}
                role="presentation"
            >
                <div className='py-2 pt-3 d-flex'>

                    <h4 className="pl-3 ml-md-0 ml-3 font-weight-bold" style={{color: "#000"}}>
                        {pageTitle}
                    </h4>
                    <div style={{flex: 1}}></div>

                    {
                      isEdit === 2?

                        <div style={{width: '30px'}} className="pr-3 mr-2">

                          <IconButton onClick={toggleViewAndEditEvemt} >
                              <Edit style={{color: "#000"}} />
                          </IconButton>
                        </div>
                      :""
                    }
                    
                    <div style={{width: '30px'}} className="pr-3 mr-4">

                      <IconButton onClick={toggleMainDrawer(false)} >
                          <Close style={{color: "#000"}} />
                      </IconButton>
                    </div>

                </div>
                <div className='px-0 px-md-0'>
                {
                  pageType.type === "event"?
                    isEdit === 0?
                        //add page
                        <AddEvent  isEdit={isEdit} data={data} configs={pageType.configs} />
                    :isEdit === 1?
                        //edit page
                      <AddEvent isEdit={isEdit} data={data}  configs={pageType.configs} />
                    :
                    //view details page
                    <AddEvent isEdit={isEdit} data={data} configs={pageType.configs} />
                  :pageType.type === "service"?

                    isEdit === 0?
                      //add page
                      <ServicesForm  editService={data}  configs={pageType.configs} isEdit={isEdit} />
                    :isEdit === 1?
                          //edit page
                      <ServicesForm  editService={data} isEdit={isEdit} configs={pageType.configs} />
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :
                  pageType.type === "clientDetails"?

                    isEdit === 0?
                      //add page
                      <ClientDetailsForm booking={data} handleUpdateBooking={action} configs={pageType.configs} />
                    :isEdit === 1?
                          //edit page
                          <p></p>
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :
                  pageType.type === "addBooking"?

                    isEdit === 0?
                      //add page
                      <AddBooking configs={pageType.configs} />
                    :isEdit === 1?
                          //edit page
                          <p></p>
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :
                  pageType.type === "showBooking"?

                    isEdit === 0?
                      //add page
                      <BookingDetailsModal booking={data} configs={pageType.configs} />
                    :isEdit === 1?
                          //edit page
                          <p></p>
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :
                  
                  pageType.type === "location"?

                    isEdit === 0?
                      //add page
                      <LocationForm  isEdit={isEdit} configs={pageType.configs} />
                    :isEdit === 1?
                      //edit page 
                      
                      <LocationForm editLocData={data} isEdit={isEdit}  configs={pageType.configs} />
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :
                  
                  pageType.type === "invoice_items"?

                    isEdit === 0?
                      //add page
                      <InvoiceItems toggleMainDrawer={toggleDrawer_} extraData={pageType.data} isEdit={isEdit} configs={pageType.configs} />
                    :isEdit === 1?
                      //edit page 
                      
                      <InvoiceItems toggleMainDrawer={toggleDrawer_} extraData={pageType.data} data={data} isEdit={isEdit}  configs={pageType.configs} />
                    :isEdit === 2?
                    //view billing item
                    
                      <InvoiceItems toggleMainDrawer={toggleDrawer_} extraData={pageType.data} data={data} isEdit={isEdit}  configs={pageType.configs} />
                    :isEdit === 3?
                      //add default billing item
                      
                      <InvoiceItems toggleMainDrawer={toggleDrawer_} extraData={pageType.data} data={data} isEdit={isEdit}  configs={pageType.configs} />
                    :isEdit === 4?
                    //edit default billing item
                    
                      <InvoiceItems toggleMainDrawer={toggleDrawer_} extraData={pageType.data} data={data} isEdit={isEdit}  configs={pageType.configs} />
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :
                  pageType.type === "company"?

                    isEdit === 0?
                      //add page
                      <CompanyForm isEdit={isEdit} configs={pageType.configs} />
                    :isEdit === 1?
                      //edit page
                      // <LocationForm editLocData={data} isEdit={isEdit} />
                      <p></p>
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :pageType.type === "staff"?

                    isEdit === 0?
                      //add page
                      <StaffForm isEdit={isEdit} configs={pageType.configs} />
                    :isEdit === 1?
                      //edit page
                      <StaffForm staffMemberData={data} isEdit={isEdit} configs={pageType.configs} />
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :pageType.type === "contact"?

                    isEdit === 0?
                      //add page
                      <ContactForm isEdit={isEdit} configs={pageType.configs} />
                    :isEdit === 1?
                      //edit page
                      <ContactForm editContactData={data} isEdit={isEdit} configs={pageType.configs} clientAnalytics={pageType.clientAnalytics} />
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :pageType.type === "category"?

                    isEdit === 0?
                      //add page
                      <CategoryForm isEdit={isEdit} configs={pageType.configs} />
                    :isEdit === 1?
                      //edit page
                      <CategoryForm data={data} isEdit={isEdit} configs={pageType.configs} />
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :pageType.type === "coupon"?

                    isEdit === 0?
                      //add page
                      <CouponForm isEdit={isEdit} configs={pageType.configs} />
                    :isEdit === 1?
                      //edit page
                      <CouponForm data={data} isEdit={isEdit} configs={pageType.configs} />
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p>
                  :pageType.type === "booking_location"?

                    isEdit === 0?
                      //add page
                      <BookingLocationForm locationData={pageType.data} configs={pageType.configs} isEdit={isEdit} isCompany={pageType.isCompany} serviceProviderId={pageType.serviceProviderId} />
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p> 
                  :
                  pageType.type === "service_answers"?

                    isEdit === 0?
                      //add page
                      <BookingQuestionsForm configs={pageType.configs} isEdit={isEdit} isCompany={pageType.isCompany} service={pageType.data}/>
                    :
                      //view details page
                      // <AddEvent isEdit={isEdit} editService={data} />
                      <p></p> 
                  :""

                  
                }
                
              </div>
                

            </Box>

            
              
          </Drawer>
        </React.Fragment>
    </div>
  );
}