import { Close } from '@mui/icons-material';
import { Button, FormControl, Grid, IconButton } from '@mui/material';
import Slide from '@mui/material/Slide';
import ServiceCard from 'components/ServicesCard';
import { deleteBooking } from '../../../FirebaseController/Booking/booking';
import { getCompanyData } from '../../../FirebaseController/Company';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { refreshBookings } from '../../../Store/Reducers/Bookings/bookings.actions';
import { dateTimeFormat } from '../../../Utils/constants';
import { inputStyles } from '../../../Utils/style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BookingDetailsModal(props) {

    let { booking } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [_booking, setBooking] = React.useState(booking)
  const [isLoading, setIsLoading] = React.useState(false)
  const [companyData, setCompanyData] = React.useState({});
  const userObj = JSON.parse(localStorage.getItem("user"))
  const user = userObj.user
  const dispatch = useDispatch()


    const handleClickOpen = () => {
        if(!("date" in booking)) {
            showSnackBar("Please select a date to continue", "error")
        }else {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleDelete = ()=>{
        if(window.confirm("Are you sure you want to cancel this booking?")){
            deleteBooking(booking.orderId).then(()=>{
                handleClose()
                dispatch(refreshBookings())
            })
        }
        
    }


    
  function showSnackBar(msg, variant = 'success'){
    enqueueSnackbar(msg, {
        variant: variant,            
        action: (key) => (
            <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                <Close />
            </IconButton>
        ),
})}


  React.useEffect(()=>{
    if(booking != _booking){
        setBooking(booking)
    }
  }, [booking])

  

  React.useEffect(() => {
    if("companyId" in booking && booking.companyId) {
        getCompanyData(booking.companyId).then(data=>{
            setCompanyData(data)
        })
    }

  return () => null
}, [booking])

  return (
    <div>

        
        <div className='p-3 mb-3'>

            {
                Object.keys(_booking).length > 0?



                    <div>

                        
                        
                        <div className='d-flex py-1' style={{fontSize: '18px'}}>
                                

                                
                                <div className='border p-3 w-100' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '10px'}}>
                                    <label htmlFor='eventTitle'>
                                    {_booking.provider.providerId === user.uid?"Client Name: ":"Service Provider: " }
                                    </label><br />
                                    <textarea id="eventTitle" style={{
                                            borderRadius: '5px',
                                            background: 'transparent',
                                            border: 'none',
                                            padding: '5px',
                                            width: '100%',
                                            resize: 'none'
                                        }} 
                                        type="text" 
                                        disabled={true}
                                        value= {_booking.provider.providerId === user.uid?
                                            (("client" in _booking)?_booking.client.fName + " "+_booking.client.sName:"")
                                        :
                                        "provider" in _booking?
                                            _booking.provider.fName + " "+_booking.provider.sName: ""}
                                        rows="1"
                                        cols="50"
                                    ></textarea>

                                </div>
                                

                            </div>

                            {
                                (("companyName" in companyData) && companyData.companyName)?                                    
                                <div>
                                    
                                    <FormControl variant="standard" fullWidth className="py-1 mb-1 mt-2" >
                                        <div className='d-flex mb-2'>
                                            <label htmlFor="input-with-icon-adornment12" className='py-0 my-0'>
                                                Company: 
                                            </label>
                                        </div>
                                        <input 
                                            disabled={true}
                                            style={inputStyles}
                                            fullWidth 
                                            id="input-with-icon-adornment12"
                                            value={
                                                companyData.companyName
                                            } />
                                    </FormControl>
                                </div>

                                :""
                            }
                            
                            <div>
                                
                                <FormControl variant="standard" fullWidth className="py-1 mb-1 mt-2" >
                                    <div className='d-flex mb-2'>
                                        <label htmlFor="input-with-icon-adornment4" className='py-0 my-0'>
                                            When: 
                                        </label>
                                    </div>
                                    <input 
                                        disabled={true}
                                        style={inputStyles}
                                        fullWidth 
                                        id="input-with-icon-adornment4"
                                        value={
                                            "date" in _booking?(new Date(_booking.date.replace(/-/g,'/')+ " UTC").toLocaleString("en-US", dateTimeFormat)):""
                                        } />
                                </FormControl>
                            </div>
                        
                            <div>
                                
                                <FormControl variant="standard" fullWidth className="py-1 mb-1 mt-2" >
                                    <div className='d-flex mb-2'>
                                        <label htmlFor="input-with-icon-adornment4" className='py-0 my-0'>
                                            Total duration: 
                                        </label>
                                    </div>
                                    <input 
                                        style={inputStyles}
                                        fullWidth 
                                        id="input-with-icon-adornment4"
                                        value={_booking.duration + " mins"}
                                        disabled={true}
                                        
                                        />
                                </FormControl>
                            </div>
                            
                            <div>
                                
                                <FormControl variant="standard" fullWidth className="py-1 mb-1 mt-2" >
                                    <div className='d-flex mb-2'>
                                        <label htmlFor="input-with-icon-adornment4" className='py-0 my-0'>
                                            Status
                                        </label>
                                    </div>
                                    <input 
                                        style={inputStyles}
                                        fullWidth 
                                        id="input-with-icon-adornment4"
                                        value= {_booking.appointmentStatus.toLowerCase()}
                                        disabled={true}
                                        
                                        />
                                </FormControl>
                            </div>
                        <div>
                            

                            <Grid container>
                                <label style={{fontWeight: 'normal', color: '#000'}}>Selected services</label>
                            {
                                _booking.services.map((item, idx)=>(
                                    <Grid item md={12} xs={12} key={idx}>
                                        <ServiceCard
                                            service_data={item}
                                            isEdit={true}
                                            isSelfBooking={false}
                                            // toggleModal={toggleModal}
                                            serviceProviderId={item.serviceProviderId}
                                            isServicePage={false}
                                            style={{background: "#F4F5FA"}}
                                            disable={true}
                                        />
                                    </Grid>
                                    
                                ))
                            }

                            {
                                "serviceAnswers" in _booking?

                                    <>
                                        {
                                            "question1" in _booking.services[0]?
                                                
                                                <Grid item md={12} xs={12}>
                                                    
                                                    <FormControl variant="standard" fullWidth className="py-1 mb-1 mt-2" >
                                                        <div className='d-flex mb-2'>
                                                            <label className='py-0 my-0'>
                                                                {_booking.services[0].question1}
                                                            </label>
                                                        </div>
                                                        <input 
                                                            style={inputStyles}
                                                            fullWidth 
                                                            id="input-with-icon-adornment4"
                                                            value= {_booking.serviceAnswers.answer1.toLowerCase()}
                                                            disabled={true}
                                                            
                                                            />
                                                    </FormControl>
                                                </Grid>
                                            :""
                                        }
                                        {
                                            "question2" in _booking.services[0]?
                                                
                                                <Grid item md={12} xs={12}>
                                                    
                                                    <FormControl variant="standard" fullWidth className="py-1 mb-1 mt-2" >
                                                        <div className='d-flex mb-2'>
                                                            <label className='py-0 my-0'>
                                                                {_booking.services[0].question2}
                                                            </label>
                                                        </div>
                                                        <input 
                                                            style={inputStyles}
                                                            fullWidth 
                                                            id="input-with-icon-adornment2345"
                                                            value= {_booking.serviceAnswers.answer2.toLowerCase()}
                                                            disabled={true}
                                                            
                                                            />
                                                    </FormControl>
                                                </Grid>
                                            :""
                                        }
                                        {
                                            "question3" in _booking.services[0]?
                                                
                                                <Grid item md={12} xs={12}>
                                                    
                                                    <FormControl variant="standard" fullWidth className="py-1 mb-1 mt-2" >
                                                        <div className='d-flex mb-2'>
                                                            <label className='py-0 my-0'>
                                                                {_booking.services[0].question3}
                                                            </label>
                                                        </div>
                                                        <input 
                                                            style={inputStyles}
                                                            fullWidth 
                                                            id="input-with-icon-adornment2323"
                                                            value= {_booking.serviceAnswers.answer3.toLowerCase()}
                                                            disabled={true}
                                                            
                                                            />
                                                    </FormControl>
                                                </Grid>
                                            :""
                                        }
                                    </>

                                :""
                            }
                                <Grid item md={12} xs={12} >

                                    <div className='d-flex mt-2'>
                                        <div style={{flex: 1}}></div>
                                        {
                                            
                                            new Date(booking.date.replace(/-/g,'/')+ " UTC") > new Date()?
                                                <Button  
                                                    onClick={handleDelete}
                                                    className={"rounded-pill border-light mx-2 text-capitalize"}                  
                                                    style={{background: '#000',color: "#fff"}}
                                                >
                                                    Delete
                                                </Button>

                                            :""
                                        }

                                        {
                                            
                                            ("googleMeetLink" in booking && booking.googleMeetLink)?
                                            
                                                <Button 
                                                    color="primary" 
                                                    href={booking.googleMeetLink}
                                                    target={"_blank"}
                                                    className={"rounded-pill border-light mx-2 text-capitalize"}                 
                                                    style={{background: 'linear-gradient(270.3deg, #63C3CF 5.25%, #A68AB4 42.7%, #F1785C 102.13%)',color: "#fff"}}
                                                >
                                                    {
                                                        _booking.provider.providerId === user.uid?"Start Meeting":"Join Meeting"
                                                    }
                                                </Button>

                                            :("zoomMeetingDetails" in _booking && _booking.zoomMeetingDetails)?
                                            
                                                <Button 
                                                    color="primary" 
                                                    href={(user.uid == _booking.provider.providerId)?_booking.zoomMeetingDetails.startLink:_booking.zoomMeetingDetails.joinLink} 
                                                    target={"_blank"}
                                                    className={"rounded-pill border-light mx-2 text-capitalize"}                 
                                                    style={{background: 'linear-gradient(270.3deg, #63C3CF 5.25%, #A68AB4 42.7%, #F1785C 102.13%)',color: "#fff"}}
                                                >
                                                    {
                                                        _booking.provider.providerId === user.uid?"Start Meeting":"Join Meeting"
                                                    }
                                                </Button>
                                            :""
                                        }
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                        
                    </div>

                :""
            }
        </div>
    </div>
  );
}
