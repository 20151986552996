import { Close } from '@mui/icons-material'
import { Autocomplete, Button, FormControl, Grid, IconButton, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import { deleteStaffMember, getCompanyStaffMembers, getStaffMember, saveStaffMember, updateStaffMember } from '../../FirebaseController/Company'
import { sendFcmNotification } from '../../FirebaseController/Notifications/fcmNotifications'
import { addNotif } from '../../FirebaseController/Notifications/localNotifs'
import { getUserDataFromDb, searchUserDirEmail } from '../../FirebaseController/User/user'
import { refreshStaffMembers } from '../../Store/Reducers/StaffMembers/staff.actions'
import { APP_MANAGER_TYPES, NOTIFICATION_STATUS, STAFF_STATUS, STAFF_TYPE } from '../../Utils/constants'
import { formatDateToDateTimeStr, validateEmail } from '../../Utils/functions'
import { inputStyles } from '../../Utils/style'
// import '../../Utils/css/input_file.css'


export default function StaffForm(props) {

    const { staffMemberData, isEdit, configs } = props
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null

    
    
    const localUserMemberData = localStorage.getItem("teamMemberData")?JSON.parse(localStorage.getItem("teamMemberData"))[0]:null
    

    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = React.useState(false)
    const [isCompanyStaffLoading, setIsCompanyStaffLoading] = React.useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
    const [isDisableLoading, setIsDisableLoading] = React.useState(false)
    const [staffOptions, setStaffOptions] = React.useState([]);
    const [staffData, setStaffData] = React.useState({"memberRole": STAFF_TYPE[0]})
    const [staffSearchData, setStaffSearchData] = React.useState([])
    const [staffValue, setStaffValue] = React.useState('');
    const [allStaffMembers, setAllCompanyStaffMembers] = React.useState([]);
    const [currentStaffDet, setCurrentStaffDet] = React.useState(null);
    const [staffInputValue, setStaffInputValue] = React.useState('');
    const [newAdminId, setNewAdminId] = React.useState(null);
    const dispatch = useDispatch()
    const staffReducer = useSelector((state) => state.userReducer)
    //const [appConfigReducer, setAppConfigReducer] = useOutletContext()
    const appConfigReducer = configs
    const { companyName } = useParams()

    //set manager id to user or company id
    const managerId = (appConfigReducer.type === APP_MANAGER_TYPES[0])?user.uid:appConfigReducer.data.companyID
      
    const handleInputChange = (event, value) => {
       handleStaffInputChange(value)
    }

    const handleStaffInputChange = (email) => {
        if(email != null && email != undefined) {
            setStaffInputValue(email)
            setStaffValue(email)
            if(email.length > 2) {
                searchUserDirEmail(email.toLowerCase()).then((data)=>{
                    // 
                    setStaffSearchData(data)

                    setStaffOptions([...new Set(data.map(item=>item.email))])
                })
            }
        }
    }

    const handleRoleChange = (e)=> {
        setStaffData(
            {
                ...staffData, 
                "memberRole": e.target.value
            }
        )
    }

    const handleAddStaff = ()=>{

        if(!staffValue || !(staffData && ("memberRole" in staffData) && staffData.memberRole)) {
            showSnackBar("All fields are required", "error")
        }else {
            const selectedSearchData = [...new Set(staffSearchData.filter(item=>item.email === staffValue))]
            if(!validateEmail(staffValue)) {
                showSnackBar("Invalid email", "error")
            }else if(!selectedSearchData.length > 0 && isEdit !== 1) {
                showSnackBar("Email must be of a registered latom user", "error")
            }else if(selectedSearchData.userId === user.uid) {
                showSnackBar("Team member already exists", "error")
            }else {
                setIsLoading(true)
                staffData["email"] = isEdit === 1?staffData['email']:staffValue
                staffData["staffId"] = isEdit === 1?staffData['staffId']:+ new Date()
                staffData["userId"] = isEdit === 1?staffData['userId']:selectedSearchData[0].userId
                staffData["companyId"] = isEdit === 1?staffData['companyId']:appConfigReducer.data.companyID
                staffData["creatorId"] = isEdit === 1?staffData["creatorId"]:user.uid
                staffData["updateAt"] =  + new Date()
                if(isEdit === 0){
                    staffData['status'] = STAFF_STATUS[0]
                    staffData["createdAt"] =  parseFloat(new Date().valueOf())
                }


                if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
                    getStaffMember(user.uid, managerId).then((data)=>{
                        if(data.length < 1 || (data.length > 0 && data[0]["memberRole"] === STAFF_TYPE[0])) {
                            showSnackBar("You are not authorised to add staff, contact admin for approval", "error")
                            return false
                        }
                         
                        if(isEdit === 0){
                            saveStaffMember(staffData, user.uid).then(()=>{
                                //send notification to added team member
                                let name = staffReducer.data.firstname + " " + staffReducer.data.surname
                                name = name && name !== " "?name:user["displayName"]
                                const notiText =  name +
                                    " has invited you to join " + appConfigReducer.data.companyName
                                const fcmData = {
                                    "user": user.uid,
                                    "icon": 0,
                                    "body": notiText,
                                    "title": "Join company team",
                                    "sented": staffData.userId,
                                }
                                addStaffUserData(staffData.staffId, staffData.userId)
                                sendFcmNotification(fcmData, staffData.userId)
                                showSnackBar("Team member added successfully, an approval request has been sent to the team member.", "success")
                                setIsLoading(false)
                                dispatch(refreshStaffMembers())
                            }).catch((err)=>{
                                if (typeof err === 'string' || err instanceof String) {
                                    showSnackBar(err, "error")

                                }
                                setIsLoading(false)
                                dispatch(refreshStaffMembers())
                                
                                
                            })
                        }else {
                            //

                            getCompanyStaffMembers(appConfigReducer.data.companyID, false, 1000).then((_data)=>{
                                if(_data.length > 0) {
                                    if(
                                        _data.filter(item=>item.memberRole === STAFF_TYPE[1]).length >= 2 && 
                                        staffData.memberRole === STAFF_TYPE[1] &&
                                        staffMemberData.memberRole !== STAFF_TYPE[1]
                                    ) {
                                            showSnackBar("You have reached maximum number of company managers, only 2 are allowed", "error")
                                    }else {
                                        run()
                                    }
                                }else {
                                    run()
                                }
            
                            })
                    
                            function run () {
                               
                                updateStaffMember(staffData.staffId , {"memberRole": staffData.memberRole}).then(()=>{
                                    //delete new admin if admin has changed                                
                                    if(staffMemberData && staffData && staffMemberData.memberRole === STAFF_TYPE[2] && staffData.memberRole !== STAFF_TYPE[2]){
                                        updateStaffMember(newAdminId, {"memberRole": STAFF_TYPE[2]}).then(()=>{
                                            showSnackBar("Admin has changed, hence your role has changed to Team Member.")       //send notification to added team member
                                            const notiText = "Your role with " + appConfigReducer.data.companyName
                                                + " has been changed to " + staffData.memberRole.toLowerCase()
                                            const fcmData = {
                                                "user": user.uid,
                                                "icon": 0,
                                                "body": notiText,
                                                "title": "Company updates",
                                                "sented": staffData.userId,
                                            }
                                            sendFcmNotification(fcmData, staffData.userId)
                                            setNotif("Company updates", notiText, user.uid, staffData.userId)
                                            showSnackBar("Team member updated successfully, an approval request has been sent to the Team Member.", "success")
                                            setIsLoading(false)
                                            dispatch(refreshStaffMembers())
                                        
                                        })
                                        .catch((err)=>{
                                            
                                            
                                        })
                                    }else {
                                        //send notification to added team member
                                        const notiText = "Your role with " + appConfigReducer.data.companyName
                                            + " has been changed to " + staffData.memberRole.toLowerCase()
                                        const fcmData = {
                                            "user": user.uid,
                                            "icon": 0,
                                            "body": notiText,
                                            "title": "Company updates",
                                            "sented": staffData.userId,
                                        }
                                        sendFcmNotification(fcmData, staffData.userId)
                                        setNotif("Company updates", notiText, user.uid, staffData.userId)
                                        showSnackBar("Team member role has been updated to "+staffData.memberRole.toLowerCase()+" successfully.", "success")
                                        setIsLoading(false)
                                        dispatch(refreshStaffMembers())
                                    }
                                }).catch((err)=>{
                                    console.log(err)
                                    if (typeof err === 'string' || err instanceof String) {
                                        showSnackBar(err, "error")

                                    }
                                    setIsLoading(false)
                                    dispatch(refreshStaffMembers())
                                    
                                })
                            }
                        }
                    }).catch((err)=>{
                        
                        showSnackBar("Could not add staff, please try again later", "error")
                        setIsLoading(false)
                    })  
                }else {
                    showSnackBar("Sorry, you cannot add staff members to non company accounts.", "error")
                    navigate('/')
                }
            }
        }

    }

    const handleDisableStaff = (isDisabled)=>{
        setIsDisableLoading(true)
        updateStaffMember(staffData.staffId , {"disabled": !isDisabled}).then(()=>{
            setIsDisableLoading(false)
            setStaffData(
                {
                    ...staffData, 
                    "disabled": !isDisabled
                }
            )
            showSnackBar("Staff member account has been updated successfully", "success")
            dispatch(refreshStaffMembers())
        })
        .catch((err)=>{
            
            setIsDisableLoading(false)
            showSnackBar("Could not update account, try again later", "error")
        })
    }



    function addStaffUserData (staffId, userId) {
        getUserDataFromDb(userId).then((data)=>{
            if(Object.keys(data).length > 0) {
                delete data["uid"]
                data['cname'] = appConfigReducer.data.companyName
                //delete data["primaryEmail"]
                updateStaffMember(staffId, data).then(()=>{
                    
                })
            }
        })
    }

    
    function setNotif(title, body, userId, sented){
        //set local notification
      //we are not going to set all notifs since some are handled by app logic. 
        //only notifs that dont need user action are stored in local

        const notif = {
            title: title,
            body: body,
            sender: userId,
            receiver: sented,
            companyId: appConfigReducer.data.companyID.toString(),
            date: formatDateToDateTimeStr(new Date()),
            notifId: + new Date(),
            userId: sented,
            status: NOTIFICATION_STATUS[0]
        }
        
        addNotif(sented, notif).then(()=>{
            
        })
    }


    const handleDelete = () => {
        if(window.confirm("Are you sure you want to remove staff from company?")){
            
            setIsDeleteLoading(true)
            deleteStaffMember(staffData.staffId).then(()=>{
                setIsDeleteLoading(false)
                
                showSnackBar("Staff member has been deleted", "success")
                dispatch(refreshStaffMembers())
            }).catch((err)=>{
                setIsDeleteLoading(false)
                
                showSnackBar("Could not delete staff member, try again later", "error")
            })
        }
    }

    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}


    useEffect(() => {     
        if(staffMemberData) {
            setStaffData(staffMemberData)
            setStaffValue(staffMemberData.email)
            setStaffInputValue(staffMemberData.email)
        }
    
      return () => null
    }, [staffMemberData])

    
    useEffect(() => {     
        getStaffMember(user.uid, appConfigReducer.data.companyID.toString())
            .then((data)=>{
                if(data.length > 0) {
                    setCurrentStaffDet(data[0])
                }
            })
            
    
      return () => null
    }, [])


    
    useEffect(() => {

        if(staffMemberData && staffMemberData.memberRole === STAFF_TYPE[2] && staffData.memberRole !== STAFF_TYPE[2]){
            setIsCompanyStaffLoading(true)
            
            getCompanyStaffMembers(staffMemberData.companyId).then((members)=>{
                setIsCompanyStaffLoading(false)
                members.forEach(member => {

                    
                    getStaffMember(member.userId, appConfigReducer.data.companyID).then((_data)=>{
                        if(_data.length  > 0){
                            if(_data && (!(_data[0].firstname) || !(_data[0].surname))) {
                                            
                                getUserDataFromDb(member.userId).then((userData)=>{
                                    allStaffMembers.push(userData)
                                    setAllCompanyStaffMembers([
                                        ...allStaffMembers
                                    ])
                                    // setTeamMemberUserInfo()
                                }).catch((err)=>{
                                    
                                })
                            }else {
                                allStaffMembers.push(_data[0])
                                setAllCompanyStaffMembers([
                                    ...allStaffMembers
                                ])
                            }
                        }
                        
                    }).catch((err)=>{
                        
                    })
                    
                });
                // 
            }).catch((err)=>{
                
                setIsCompanyStaffLoading(false)
            })
        }
    
      return () => null
    }, [staffData])
    
    

    return (
        <Grid container spacing={1} className='px-4 pb-4 my-3'>

                        
            <Grid item xs={12}>
                <label htmlFor="location" className='py-0 my-0 mb-2'>
                    Search team member email
                </label>
                <Autocomplete
                    disablePortal
                    id="location"
                    options={staffOptions}
                    color="grey"
                    size={"small"} 
                    sx={{
                        padding: 0,
                        margin: 0,
                        width: '100%', border: 'none',
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        fontSize: '10px',
                    }}
                    value={staffValue}
                    inputValue={staffInputValue}
                    onChange={handleInputChange} 
                    placeholder="Member email"                              
                    onInputChange={(e)=>handleStaffInputChange(e.target.value)}
                    disabled={isEdit === 1}
                    renderInput={(params) => 
                        <TextField {...params} variant="outlined" 
                            InputProps={{ ...params.InputProps, disableUnderline: true, style: {...inputStyles, borderRadius: 15} }} />}
                />
            </Grid>
            <Grid item xs={12} >
                <FormControl variant="standard" className="py-1 mb-1" fullWidth>
                    <label htmlFor="role" className='py-0 my-0' id="roleLabel">
                        Member role
                    </label>
                    <select
                        labelId="roleLabel"
                        id="role"
                        style={inputStyles}
                        label="Role"
                        value={(staffData && "memberRole" in staffData && staffData.memberRole)?staffData.memberRole:""}
                        onChange={handleRoleChange}
                    >
                        {
                            STAFF_TYPE.map(item=>{
                                
                                    return (
                                        item.toLowerCase() === 'admin' && localUserMemberData && localUserMemberData.memberRole !== STAFF_TYPE[2]
                                        ||
                                        (item.toLowerCase() === 'admin' && staffMemberData && staffMemberData.userId !== user.uid && staffMemberData.memberRole !== STAFF_TYPE[2])
                                        ||
                                        (item.toLowerCase() === 'admin' && !staffMemberData)
                                        // item.toLowerCase() === 'admin'
                                    )? "":
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                    // return (
                                    //         localUserMemberData 
                                    //         && (localUserMemberData.memberRole !== STAFF_TYPE[2])
                                    //         && item.toLowerCase() === 'admin'
                                            
                                    //     )? "":
                                    //     <option key={item} value={item}>
                                    //         {item}
                                    //     </option>
                                }                                
                            )
                        }
                    </select>

                </FormControl>
            </Grid>

            {
                (staffMemberData && staffMemberData.memberRole === STAFF_TYPE[2] && staffData && staffData.memberRole !== STAFF_TYPE[2])?
                isCompanyStaffLoading?
                <SyncLoader
                    loading={isCompanyStaffLoading}
                    size={10}
                    color="#000"
                />
                : 
                <Grid item xs={12} >
                    <FormControl variant="standard" className="py-1 mb-1" fullWidth>
                        <label htmlFor="admin" className='py-0 my-0' id="adminLabel123">
                            Select alternative admin
                        </label>
                        <select
                            labelId="adminLabel123"
                            id="role"
                            style={inputStyles}
                            label="admin"
                            value={newAdminId}
                            onChange={(e)=>setNewAdminId(e.target.value)}
                        >
                            <option selected disabled>
                                Select new admin
                            </option>
                            {
                                allStaffMembers.map((item)=>
                                    (item.uid !== staffMemberData.staffData)? (
                                    <option key={item.uid} value={item.uid}>
                                        {item.firstName + " " + item.surname}
                                    </option>

                                    ):""
                                )
                            }
                        </select>

                    </FormControl>
                </Grid>
                :""
            }

            <Grid item xs={12}>
                <div style={{margin: '30px 0'}} className='d-flex'>
                    <div style={{flex: 1}}></div>
                    <Button variant='contained'
                            className="my-4 rounded border-0"
                            style={{color: "#fff", textTransform: 'none', background: '##F0973B',}}
                            disabled={isLoading} color="primary"
                            onClick={handleAddStaff}
                        >
                        {
                        isLoading?
                            <SyncLoader
                            loading={isLoading}
                            size={10}
                            color="#fff"
                            />
                            :
                        "Save"
                        }
                    </Button>
                    {
                        isEdit === 1 && currentStaffDet && currentStaffDet.memberRole === STAFF_TYPE[2]?
                            <Button variant='contained'
                                className="my-4 rounded border-0 mx-1"
                                style={{color: staffData.disabled === true?"#000":"#fff", textTransform: 'none', background: staffData.disabled === true?'#F5DAE9':"#63C3CF",}}
                                disabled={isLoading} color="primary"
                                onClick={()=>handleDisableStaff(staffData.disabled === true)}
                            >
                                {
                                    isDisableLoading?
                                        <SyncLoader
                                            loading={isDisableLoading}
                                            size={10}
                                            color="#000"
                                        />
                                    :
                                    staffData.disabled === true?
                                        "Enable"
                                    :
                                        "Disable"
                                }
                            </Button>

                        :""
                    }
                    {/*
                        isEdit === 1?
                        <Button onClick={handleDelete} 
                            className={"rounded border-0 my-4 mx-1"}
                            color="primary" 
                            type="button" disabled={isDeleteLoading}
                            style={{background: '#000',color: "#fff", textTransform: 'none'}}
                        >

                            {
                                isDeleteLoading?
                                    <SyncLoader
                                    loading={isDeleteLoading}
                                    size={10}
                                    color="#fff"
                                    />
                                    :
                                "Delete"
                            }
                            
                        </Button>
                        :""

                        */
                    }
                </div>

            </Grid>

        </Grid>
    )


}
