export const AppName = "Latom";
//export const VERSION = "1.2.71 11/28/2022";
export const VERSION = "1.3.85 06/17/2023"
//dev server settings

/*
export const firebaseConfig = {
  apiKey: "AIzaSyCElMUouOq2W_TB7FzGkMJcvTWbpmnX00Y",
  authDomain: "latom-dev.firebaseapp.com",
  databaseURL: "https://latom-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "latom-dev",
  storageBucket: "latom-dev.appspot.com",
  messagingSenderId: "1013280007714",
  appId: "1:1013280007714:web:9c89b87f678b9aca3ecc7b",
  measurementId: "G-N6WEPN9PTS"
};

export const serverKey = "AAAA6-wx5iI:APA91bEf9Uw5p73VA-rto9p0f_sBenEVoADMVXDa2SSLshm3Von0GQMNBEt-z7YFvvPLPhqW9R4GnJ4HrMujmvlGbwlRFo_Xazb81MkYY7vCvp9tZsZzFrq6IrjUD8nBpgN91vGL_0WU"
export const vapidKey = "BF12mjSDZYhKleciD48E7kg5_2uRpykq3EfAKT4Kwup9CpkY-F6xVfhZ4e2jCoOwgS-_TMNSC-BPDXWiTXNOAA8"
export const baseFirebaseApiUrl = "https://us-central1-latom-dev.cloudfunctions.net"

*/
// export const clientId = "1013280007714-8hv1ff49q09fup0fkld2085trfbh9mcv.apps.googleusercontent.com"

//TODO: uncomment this after zoom verification

//export const zoomClientId = "mKtABVEPToui6FAasi0TXA"
//export const zoomClientPwd = "h1VmJHJ0y6kPFEAuqvqLCtp9ehcPM6wl"
//export const zoomRedirectUrl = "https://latom.in/zoom/dev"

//live server settings

export const firebaseConfig = {
   apiKey: "AIzaSyBtTNbRQZzc0afFYlfGt-Vu5YMcKshzzQA",
   authDomain: "visual-clarity-322308.firebaseapp.com",
   databaseURL: "https://visual-clarity-322308-default-rtdb.asia-southeast1.firebasedatabase.app",
   projectId: "visual-clarity-322308",
   storageBucket: "visual-clarity-322308.appspot.com",
   messagingSenderId: "474583989222",
   appId: "1:474583989222:web:276c54c83f1f3bf3b0f427",
   measurementId: "G-9V7RP97CBR"
};



export const serverKey = "AAAAbn9o--Y:APA91bFEy4iyINh-_J8l7p2c9u329hUeIIrzg_WJNO-tnVuWePpoWLqUbwJL1FNVTorQVBiKw87bBBhNTqBfpcTAXDiStDFx7AtgWg8hGFgZyV-c4zQ-qrVcv_pCKFcqEHeo5UJm2rxd"
export const vapidKey = "BGD4uL49imebvtbC6dcOS-jQLwX6BjQg2b_UPo85cP6KTGn4QgPOg3k-oTCGaGaGG52nrO1_p84v7LMPDKtF1Ao"
export const baseFirebaseApiUrl = "https://us-central1-visual-clarity-322308.cloudfunctions.net"




export const clientId = '474583989222-hrcqjtc9uddoc68r5roqb7jjpn6qpjrs.apps.googleusercontent.com'



export const zoomClientId = "BzmF2PJxRJWrI9QfzFD79Q"
export const zoomClientPwd = "yIG7l46nlkuf1Fzu2BDoMOJiyyrJwmC0"
export const zoomRedirectUrl = "https://latom.in/zoom/prod"

//other common settings

export const androidPackageName = "in.latom.latomapp"


//export const zoomClientId = "wJsBrHeFR22ZyNtq4q167w"
//export const zoomClientPwd = "AzXJeYNKDtbpVvX7usIlDVfOP4XKWJ7D"
//export const zoomRedirectUrl = "https://dennis-gitonga-web.000webhostapp.com/zoom/zoom.php"

export const mapApiKey = "AIzaSyA6wt-FIvUc8ECpsGHs6r0fbwc7J9QHKaw"
export const __hereMapsApiKey = "shHP9xNP0GEehJrXxjSteIiwLKCiCFOntkhlIPrv_Xk"
export const hereMapsApiKey = "PUqVfcIWrUpl9IBEkdN7Ig"


export const captchaSiteKey = "6LdiQDQiAAAAANbpEUoSFqE1dGfBkvHRptSuFbXF"
export const LatomAppGAnalyticsTrackingCode = "G-M9VEEYQ6P1"


//STRIPE

//export const STRIPE_TEST_SECRET_API_KEY = "sk_test_51MFrTOSHKTiUVbMfFExga4WTYMzf5rCOMHZJdUXnYQsIX4NIw7mEAVI7KFtOnLyMPnLPab5hcf4xZlqHNJfWLJAQ00zj8weI1n"
export const STRIPE_TEST_API_KEY = "pk_test_51MFrTOSHKTiUVbMfH58CmnCb5O2cOEUdCuOertgn50pfCz1Q5BS9BEU7xVelTM54FjA8s5M8shDVwWNpG7a6c1jK00wk1M4iKF"
//const STRIPE_PROD_API_KEY = "sk_test_51MFrTOSHKTiUVbMfFExga4WTYMzf5rCOMHZJdUXnYQsIX4NIw7mEAVI7KFtOnLyMPnLPab5hcf4xZlqHNJfWLJAQ00zj8weI1n"
