import { AccountBox, Analytics, AttachMoney, CalendarToday, Dashboard, Domain, Groups, InfoOutlined, MiscellaneousServices, Settings, Storefront } from "@mui/icons-material";
import { baseFirebaseApiUrl } from "AppSettings";

export const navItems = [
    
    {
        //icon: <Avatar variant="square" src={store} style={{marginLeft: '2px', width: '17px', height: '17px', color: '#fff'}} />,
        icon: <Storefront  style={{fontSize: '21px',}}  />,
        name: "Home",
        url: '/client',
        id: 'Home'
    },
    // {
    //     icon: <SyncAlt style={{fontSize: '25px',}}  />,
    //     name: "Switch to user mode",
    //     url: '/'
    // },
    {
        icon: <Dashboard style={{fontSize: '21px',}} />,
        name: "Dashboard",
        url: '/',
        id: 'Dashboard'
    },
    
    {
        icon: <CalendarToday style={{fontSize: '21px',}}/>,
        name: "Calendar",
        url: '/calendar',
        id: 'Calendar'
    },
    
    {
        icon: <AccountBox style={{fontSize: '21px',}}/>,
        name: "Contacts",
        url: '/crm',
        id: 'Contacts'
    },
    {
        icon: <InfoOutlined style={{fontSize: '21px',}}  />,
        name: "Invoicing",
        url: '/invoices',
        id: 'Invoicing'
    },

    /*
    {
        icon: <AttachMoney style={{fontSize: '21px',}}  />,
        name: "Payments",
        url: '/payments',
        id: 'Payments'
    },
*/

    // {
    //     icon: <AddTask style={{fontSize: '25px',}}  />,
    //     name: "Tasks",
    //     url: '/'
    // },
    {
        icon: <MiscellaneousServices style={{fontSize: '21px',}}  />,
        name: "Services",
        url: '/services',
        id: 'Services'
    },
    {
        icon: <Domain style={{fontSize: '21px'}} />,
        name: "Marketplaces",
        url: '/companies',
        id: 'Marketplaces'
    },
    {
        icon: <Analytics style={{fontSize: '21px'}} />,
        name: "Analytics",
        url: '/analytics',
        id: 'Analytics'
    },
    {
        icon: <Groups style={{fontSize: '21px'}} />,
        name: "Team Members",
        url: '/members',
        id: 'Team'
    },
    // {
    //     icon: <InsertDriveFile style={{fontSize: '25px',}}  />,
    //     name: "Notes",
    //     url: '/'
    // },
    {
        icon: <Settings style={{fontSize: '21px',}}  />,
        name: "Settings",
        url: '/settings',
        id: 'Settings'
    },
    
    // {
    //     icon: <AccountCircle style={{fontSize: '30px'}} />,
    //     name: "Profile",
    //     url: '/profile'
    // },
    
]


export const BASE_SERVER_URL = "https://fcm.googleapis.com/";

export const ALLOWED_TEAM_MEMBERS = 100;

export const dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    
export const dateTimeFormat = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric',
minute: 'numeric', seconds: 'numeric' };

export const APP_MANAGER_TYPES = ["USER", "COMPANY"]
export const LATOM_PLATFORM = ["WEB", "ANDROID", "IOS"]
export const STAFF_TYPE = ["Team member", "Manager", "Admin"]
export const STAFF_STATUS = ["PENDING", "APPROVED"]
export const LOCATION_STATUS = ["ACTIVE", "ARCHIVED"]
export const SERVICE_STATUS = ["ACTIVE", "ARCHIVED"]
export const NOTIFICATION_STATUS = ["READ", "UNREAD"]

export const DEFAULT_SERVICE = {
    serviceTitle: "Introduction",
    serviceType: "Online",
    duration: "30",
    serviceDesc: "Introduction service"
}

export const MAX_LOGO_FILE_SIZE = 300

export const YOUTUBE_LINK = "https://www.youtube.com/embed/Uheu60mbp44"

export const CURRENCY_VALUES = "INR,AED,USD,CAD,EUR,GBP,AUD,MYR,ARS,BHD,BBD,BRL,XAF,CLP,CNY,CYP,CZK,DKK,XCD,EGP,EEK,HKD,HUF,ISK,IDR,ILS,JMD,JPY,JOD,KES,LVL,LBP,LTL,MXN,MAD,NAD,NPR,NZD,NOK,OMR,PKR,PAB,PHP,PLN,QAR,RON,RUB,SAR,SGD,ZAR,KRW,LKR,SEK,CHF,THB,TRY,VEF,XOF"

export const stripeAppearance = {
    theme: 'flat',
    variables: {
      fontFamily: ' "Gill Sans", sans-serif',
      fontLineHeight: '1.5',
      borderRadius: '10px',
      colorBackground: '#F6F8FA',
      colorPrimaryText: '#262626'
    },
    rules: {
      '.Block': {
        backgroundColor: 'var(--colorBackground)',
        boxShadow: 'none',
        padding: '12px'
      },
      '.Input': {
        padding: '12px'
      },
      '.Input:disabled, .Input--invalid:disabled': {
        color: 'lightgray'
      },
      '.Tab': {
        padding: '10px 12px 8px 12px',
        border: 'none'
      },
      '.Tab:hover': {
        border: 'none',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
      },
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        border: 'none',
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
      },
      '.Label': {
        fontWeight: '500'
      }
    }
};


export const stripePaymentIntentRestUrl = baseFirebaseApiUrl + "/createPaymentIntent/"

