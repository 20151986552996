import { SET_SUBSCRIPTION } from './subscriptions.types'

const initialState = {
    data: {},
    settings: {}
  };
export const subscriptionsReducer = (state = initialState, action)=> {

    switch (action.type) {
        case SET_SUBSCRIPTION:
            return {
                ...state,
                data: action.payload,
                settings: action.settings,
            };
        
        default:
            return state;
    }
}