import { SET_PROFILE, REFRESH_PROFILE } from "./user.types"

export const setProfile = (data) => (dispatch) => {

    
    dispatch({
        type: SET_PROFILE,
        payload: data
    })

}

export const refreshProfile = (data) => (dispatch) => {

    
    dispatch({
        type: REFRESH_PROFILE,
    })

}

