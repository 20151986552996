import { Grid } from '@mui/material'

export default function DivCenterContents(props) {
    const { _size, height } = props
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: height?height:'auto', }}
            {...props}
        >

            <Grid item xs={_size?_size:4}>
                
                {props.children}

            </Grid>   
        
        </Grid> 
    )
}