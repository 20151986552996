import { Close } from '@mui/icons-material'
import { Button, IconButton, Tooltip } from '@mui/material'
import { generateProfileDynamicLink } from 'FirebaseController/User/user'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { SyncLoader } from 'react-spinners'
import { APP_MANAGER_TYPES } from 'Utils/constants'
import profile from '../../Utils/images/logo.png'
import SocialMediaShareModal from '../Modal/SocialMediaShareModal'

export default function ShareBtn(props) {

    
    const [shareLoading, setShareLoading] = useState(false)
    const userObj = JSON.parse(localStorage.getItem("user"))
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [shareModalOpen, setShareModalOpen] = React.useState(false)
    const [dynamicLink, setDynamicLink] = React.useState("")
    const user = userObj?userObj.user:null
    const appConfigReducer = props.configs
    const managerReducer = useSelector((state) => state.userReducer)
    // const [appConfigReducer, setAppConfigReducer] = useOutletContext()
    //set manager id to user or company id
    const managerId = (appConfigReducer.type === APP_MANAGER_TYPES[0])?user.uid:appConfigReducer.data.companyID


    const handleModalClose = ()=>{
        setShareModalOpen(false)
    }

    const handleShareClick = (e)=> {
        e.preventDefault()
        if(user || (appConfigReducer.type === APP_MANAGER_TYPES[1] && "profileURLLink" in appConfigReducer.data)) {
            setShareLoading(true)
            //send base 64 data
            

            const socialMediaData = {
                name: (appConfigReducer.type === APP_MANAGER_TYPES[1])?appConfigReducer.data.companyName:(appConfigReducer.data.firstname + " " + appConfigReducer.data.surname),
                desc: (appConfigReducer.type === APP_MANAGER_TYPES[1])?
                appConfigReducer.data.shortCompanyDesc?appConfigReducer.data.shortCompanyDesc:"":appConfigReducer.data.about,
                profile: (appConfigReducer.type === APP_MANAGER_TYPES[1])?
                  appConfigReducer.data.logoUrl:appConfigReducer.data.profile,
              }
            generateProfileDynamicLink(managerId, (appConfigReducer.type === APP_MANAGER_TYPES[1]), false, appConfigReducer.data.profileURLLink, socialMediaData)
            .then((response)=>{
                setShareLoading(false)
                setShareModalOpen(true)
                setDynamicLink(response.data.shortLink)
                // if (navigator.share) {
                //     navigator.share({
                //     title: 'Latom Profile Share',
                //     url: response.data.shortLink
                //     });
                // } 
                
            }).catch((err)=>{
                    setShareLoading(false)
                    showSnackBar("could not shareable generate link", "error")
                    
                }
            )
        }else {
            if(user) {
                showSnackBar("Could not generate link, check that you have set your profile link in settings", "error")
            }else {
                showSnackBar("Could not generate link, user has not set up their profile link", "error")
            }
        }
    }

    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    
    

    return (
        <>
            <Tooltip title="Link for client booking">
                <Button
                    onClick={handleShareClick}
                    style={{color:"#F0973B"}}
                >

                    
                    {
                        shareLoading?
                        <SyncLoader
                            loading={shareLoading}
                            size={10}
                            color="#000"
                        />
                        :
                        <>                        
                            Share
                        </>
                    }
                </Button>
            </Tooltip>
            <SocialMediaShareModal 
                url={dynamicLink} 
                open={shareModalOpen} 
                handleClose={handleModalClose} 
                img={appConfigReducer.type === APP_MANAGER_TYPES[0]? (managerReducer.data.profile || profile):(appConfigReducer.data.logoUrl || profile)}
                name={appConfigReducer.type === APP_MANAGER_TYPES[0]?managerReducer.data.firstname + " " + managerReducer.data.surname:appConfigReducer.data.companyName}
            />
        </>
    )


}