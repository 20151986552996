import { Close, Done } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import * as React from 'react';
import { getUserDataFromDb } from '../../../FirebaseController/User/user';
import { capitalizeFirstLetter } from '../../../Utils/functions';


export default function AddServiceProvider(props) {
    const { providers, existingSelectedProviders, btnTxt, handleSubscriptionSubmit, bgColor } = props
//   const emails = ['username@gmail.com', 'user02@gmail.com'];    
    const [open, setOpen] = React.useState(false);
    const [selectedProviders, setSelectedProviders] = React.useState({})
    const [providersData, setProvidersData] = React.useState([])
    // const [inputEmails, setInputEmails] = React.useState("")
    const [errors, setErrors] = React.useState("")
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null

    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = ()=>{
        if(selectedProviders) {
            localStorage.setItem("serviceProviderId", JSON.stringify(selectedProviders))
        }
        handleClose()
        if(handleSubscriptionSubmit) {
            handleSubscriptionSubmit()
        }
    }
    
    const handleChange = (event) => {
        if(handleSubscriptionSubmit){

            const id = event.target.value
            if(selectedProviders.includes(id)) {
                selectedProviders.splice(selectedProviders.indexOf(id), 1)
            }else {
                selectedProviders.push(id)
            }
            setSelectedProviders(
                [
                    ...selectedProviders
                ]
            )
        }else {

            setSelectedProviders(
            
                {  
                    ...selectedProviders,
                    [event.target.value]: event.target.checked
                    
                }
            )
        }
        
    }

    React.useEffect(() => {
      
        if(existingSelectedProviders) {
            setSelectedProviders(existingSelectedProviders)
        }
    
      return () => null
    }, [existingSelectedProviders])
    
    React.useEffect(() => {
        providers.forEach((item)=>{
            if(((!("firstname" in item)  || item.firstname === "")
                && 
                (!("surname" in item) || item.surname  === ""))
            ) {
                getUserDataFromDb(item.userId).then((___user)=>{
                    ___user["userId"] = ___user["uid"] //used for filtering
                    providersData.push(___user)
                    const __data = providersData.filter((v,i,a)=>a.findIndex(v2=>(v2.userId===v.userId))===i)
                        .sort((a,b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))
                    setProvidersData([...__data])
                })
            }else {
                providersData.push(item)
                const __data = providersData.filter((v,i,a)=>a.findIndex(v2=>(v2.userId===v.userId))===i)
                    .sort((a,b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))
                setProvidersData([...__data])
            }
        })


      return () => null
    }, [providers])

    

    return (
        <div>
        
            <Button color="secondary" variant='contained' onClick={handleClickOpen} className='w-100 my-0' 
                sx={{
                    textTransform: 'none', 
                    borderRadius: '5px', 
                    backgroundColor: bgColor?bgColor:'#f4f5fA', 
                    boxShadow: 'none', 
                    borderColor: bgColor?bgColor:'#f4f5fA', 
                    justifyContent: "flex-start",
                    fontSize: '14px',
                    // fontFamily: 'Montserrat',
                    color: bgColor?'#fff':'#000',
                    '&:hover': {
                        background: bgColor?bgColor:'#f4f5fA', 
                        boxShadow: 'none',
                    }
                }}
            >

                {btnTxt?btnTxt:"Add service providers"}
            </Button>
            <Dialog onClose={handleClose} open={open}
                fullWidth={true}
                maxWidth={"xs"}
            >

                <Box className="d-flex py-3 pr-2">
                    <Typography variant="body2" sx={{m: 0, pb: 1.5, pt: 1.5, pl: 2 , fontSize: '16px' }} >
                       
                        {btnTxt?btnTxt:"Add service providers"}
                        
                    </Typography>
                    <div style={{flex: 1}}></div>

                    <IconButton onClick={handleClose} type="submit" sx={{ p: '10px', mr: 0.3 }} aria-label="Add">
                        <Close />
                    </IconButton>
                    <IconButton onClick={handleDone} type="submit" sx={{ p: '10px', mr: 0.3 }} aria-label="Add">
                        <Done />
                    </IconButton>
                </Box>
                <Box className="px-3 pb-2 mb-0"  >
                    
                    <Box className="d-flex px-1">
                        
                        <FormGroup>
                            {
                                providersData.map((item)=>
                                    <FormControlLabel control={<Checkbox checked={
                                        handleSubscriptionSubmit?selectedProviders.includes(item.userId):
                                        (item.userId in selectedProviders && selectedProviders[item.userId])?selectedProviders[item.userId]:false
                                    }
                                        value={item.userId} onChange={handleChange} />} label={capitalizeFirstLetter(item.firstname) + " " + capitalizeFirstLetter(item.surname)} />
                                )
                            }
                        </FormGroup>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}
