
export function createDateFromString(timeStr, timezone = defaultTimezone()){
    // 
    // 
    var d = convertTimeZone(new Date(), timezone),
        parts = timeStr.match(/(\d+)\:(\d+) (\w+)/),
        hours = /am/i.test(parts[3].toLowerCase()) ? parseInt(parts[1], 10) : 
            parseInt(parts[1], 10) === 12?12:parseInt(parts[1], 10) + 12,
        minutes = parseInt(parts[2], 10);
    d.setHours(hours);
    d.setMinutes(minutes);
    d.setSeconds(0)
    return d
}


export function createDateFrom24hrString(timeStr){
    var d = new Date()
    var parts = timeStr.split(":")    
    d.setHours(parts[0]);
    d.setMinutes(parts[1]);
    d.setSeconds(0)
    return d
}


export function formatDateToTimeStr(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours < 10? "0" + hours : hours:12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime
    //return (date.getFullYear()) + "/" + date.getDate() + "/" + ( date.getMonth()+1 ) + "  " + strTime;
}


export function formatDateToDateTimeStr(newDate) {
    const date = new Date(newDate)
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let secs = date.getSeconds();
    hours = hours < 10 ? '0'+hours.toString() : hours.toString();
    minutes = minutes < 10 ? '0'+minutes.toString() : minutes.toString();
    secs = secs < 10 ? '0'+secs.toString() : secs.toString();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;  
    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    const strTime = hours + ':' + minutes +':'+ secs;
    return ( date.getFullYear() + "-" + (month) + "-" + day + " " + strTime);
}

export function formatStrDateTimeToDate(str) {
    if(str) {
        
        const dateParts = str.split(" ")
        const dates = dateParts[0].split('-')
        const time = dateParts[1].split(':')
        const dateObject = new Date()
        dateObject.setFullYear(dates[0]);
        dateObject.setMonth(dates[1]-1)
        dateObject.setDate(dates[2])
        dateObject.setHours(time[0])
        dateObject.setMinutes(time[1])
        dateObject.setSeconds(time[2])
        return dateObject
    }
}

export function addDateHours(date, hours){
    date = new Date(date)
    date.setTime(date.getTime() + (hours*60*60*1000))
    return date
}

export function addDateMins(date, mins){
    
    date = new Date(date)
    date.setTime(date.getTime() + (mins*60*1000))
    return date
}

export function getLocalTimezone () {
    return (Intl.DateTimeFormat().resolvedOptions().timeZone)
}

export function convertTimeZone(date, tzString, isUtc = false) {
    const utc = isUtc?" UTC":""
    const newDate = new Date((date && typeof date === "string" ? new Date(date.replace(/-/g,'/')+utc) : date).toLocaleString("en-US", {timeZone: tzString}));   
    return newDate
}

export function convertToSameDate(date1, mainDate) {
    const date = new Date(mainDate)
    date.setHours(date1.getHours())
    date.setMinutes(date1.getMinutes())
    date.setSeconds(date1.getSeconds())
    return date
}

export const defaultTimezone = ()=>Intl.DateTimeFormat().resolvedOptions().timeZone;

export function getHrsMins(servDuration) {
    let durhrs = 0,
        durmins = 0;
    
    if(servDuration == 0){
        durhrs = 0
        durmins = 0
    }else if (servDuration < 60) {
        durmins = servDuration
        durhrs = 0
    } else {
        const hrs = servDuration / 60
        const mins = servDuration - (hrs * 60)
        durhrs = hrs
        durmins = mins
    }

    return {"durhrs":durhrs, "durmins":durmins}
}


export const isTimeValid = (time)=> /^([0-1]\d):([0-5]\d)\s?(?:am|pm|Am|Pm|aM|pM|AM|PM)?$/i.test(time)

export const convert24HrTo12Hr = (timeString)=> (
    new Date('1970-01-01T' + timeString + 'Z')
        .toLocaleTimeString('en-US', {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'})
)

export const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier.toUpperCase() === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    hours = (hours.length < 2 )?"0"+hours:hours
    //validating
    hours = hours && !isNaN(hours)?hours:'00'
    minutes = minutes && !isNaN(minutes)?minutes:'00'
    return `${hours}:${minutes}:00`;
  }


  export function yearRange(start = 0, stop, step = 1, reverseOrder = false) {
    let a = [start], b = start;
    while (b < stop) {
        a.push(b += step || 1);
    }
    return reverseOrder?a.reverse():a;
  }
  
  export const capitalizeFirstLetter = (string) => (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase())
  
  export function isValidHttpUrl(string) {
      let url
      
      try {
        url = new URL(string)
      } catch (_) {
        return false 
      }
    
      //return url.protocol === "http:" || url.protocol === "https:"
      return Boolean(url)
  }
  
  export const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
  }

  export const validatePhoneNumber = (phoneNo) => {
    return String(phoneNo)
    .toLowerCase()
    .match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
  }

  export const getUserRedirectData = (userId, isCompany = false, isTeamMember = false) => btoa(JSON.stringify({
    user: userId,
    isCompany: isCompany.toString(),
    isTeamMember: isTeamMember.toString()
  }))

  export const getServiceRedirectData = (serviceId,isCompany = false, isTeamMember = false, providerId = null, companyId = null) => btoa(JSON.stringify({
    serviceId: serviceId,
    providerId: providerId,
    isCompany: isCompany?isCompany.toString():null,
    isTeamMember: isTeamMember?isTeamMember.toString():null,
    companyId: companyId?companyId:"",
  }))

    export function openSocialUrl(url) {
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        
        return url
    }



    export function getFiscalYr (finYr, createdAt) {
        const finYrDate = new Date(parseFloat(finYr))
        const createdAtDate = new Date(parseFloat(createdAt))
        let year = createdAtDate.getFullYear()
        
        
        
        
        if(createdAtDate.getMonth() > finYrDate.getMonth()) {
            year = parseFloat(year) + 1
        }
        

        return parseFloat(year)
        
    }