import { APP_MANAGER_TYPES } from "Utils/constants"
import { SET_MANAGER_TYPE, UPDATE_MANAGER_DATA } from "./config.types"

export const setAppManagerConfig = (data, type = APP_MANAGER_TYPES[0]) => (dispatch) => {

    
    dispatch({
        type: SET_MANAGER_TYPE,
        payload: {
            type: type,
            data: data
        }
    })

}

export const updateCompanyConfigData = (data) => (dispatch) => {

    
    dispatch({
        type: UPDATE_MANAGER_DATA,
        payload: {
            data: data
        }
    })

}
