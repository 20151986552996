
import ArrowBack from '@mui/icons-material/ArrowBack';
import Attachment from '@mui/icons-material/Attachment';
import Close from '@mui/icons-material/Close';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import QrCode2 from '@mui/icons-material/QrCode2';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Box from '@mui/system/Box';
import axios from 'axios';
import DivCenterContents from 'components/DivCenterContents';
import { jsPDF } from "jspdf";
import { useSnackbar } from 'notistack';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import logo from '../../../Utils/images/logo.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SocialMediaShareModal(props) {

    let { url, open, handleClose, img, name } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const pageType = ["LINKS", "QR_CODE", "QR_CODE_PDF"]
    const [sharePageType, setSharePageType] = useState(pageType[0])
    const [selectShareOptions, setSelectShareOptions] = useState(true)
    const [selectShareOptions1, setSelectShareOptions1] = useState(null)
    const [qrCodeDataUri , setqrCodeDataUri ] = useState("")
    // const qrCodeCanvas = useRef(null)
  
    const handleModalClose = () => {
        setSelectShareOptions(true)
        handleClose()
    }

    const handleCopyLink = ()=> {
        
        navigator.clipboard.writeText(url).then(function() {
            showSnackBar('Link was copied', "success");
        }, function(err) {
            showSnackBar('Could not copy text ', "error");
        });
    }


    const handlePageType = (type) => {


        if(type === pageType[2]) {
            
            const qrCodeCanvas =  window.document.getElementById('downloadCanvas')
            if(qrCodeCanvas) {

                showSnackBar('Loading please wait.', "success")
               
                console.log(img)
                if(img){
                    axios({
                        method: 'get',
                        url: img,
                        responseType: 'blob',
                    }).then(function(response){
                        var reader = new FileReader();
                        reader.readAsDataURL(response.data); 
                        reader.onload = function() {
                            var base64data = reader.result;                            
                            run(base64data)                            
                        }
                        reader.onerror = (err)=>{
                            
                            console.log(err)
                            console.log(err.data.message)
                            run(img)
                        }
                
                    })
                    
                }else{
                    setqrCodeDataUri(qrCodeCanvas.toDataURL('image/jpg', 0.3))
                    run(null)
                    
                } 

                function run(blob) {
                    const doc = new jsPDF()
                    const imgWidth = 25
                    const halfWidth = doc.internal.pageSize.width/2
                    var xOffset = halfWidth - (imgWidth/2)
                    xOffset = halfWidth - (imgWidth/2)
                    doc.addImage((blob || logo), 'jpeg', xOffset, 10, imgWidth, imgWidth)
                    doc.setFont('Montserrat', 'normal', 'bold')
                    xOffset = halfWidth - 20
                    doc.text(((name || "Anonymous User")), halfWidth, 45, {maxWidth: doc.internal.pageSize.width, align: 'center'})
                    doc.setFont('Montserrat', 'normal', 'normal')
                    doc.setFontSize(12)
                    doc.text("Scan QR code below to visit our profile", halfWidth, 52, {maxWidth: doc.internal.pageSize.width, align: 'center'})
                    const qrCodeImgWidth = 80
                    doc.addImage(qrCodeCanvas.toDataURL('image/png', 0.3), 'jpeg',  halfWidth - (qrCodeImgWidth/2), 65, qrCodeImgWidth, qrCodeImgWidth)
                    doc.setFontSize(12)
                    doc.setFont('Montserrat', 'normal', 'normal')
                    doc.text("Powered by www.latom.in", halfWidth, 160, {maxWidth: doc.internal.pageSize.width, align: 'center'})
                    doc.save(name +"_qr_code.pdf")
                }
                
                
            }
        }else {            
            setSelectShareOptions(false)
        }
        setSharePageType(type)
    }

    function getTextWidth(text, font) {
        // re-use canvas object for better performance
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
      }
    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}


    useEffect(() => {
        
        return () => null

    }, [])
    

    

  return (
    <div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='pt-3 pb-2 pl-4 pr-0' my={2}>
            <div className="d-flex pr-2">
                <Typography  style={{fontSize: '18px', lineHeight: '45px' ,margin: 0, padding: 0}} >
                    {
                        selectShareOptions?"":
                        <IconButton sx={{mr: 1, }} onClick={()=>setSelectShareOptions(true)}>
                            <ArrowBack />
                        </IconButton>
                        
                    }
                    Share link via
                </Typography>
                <div style={{flex: 1}}></div>
                {/* {
                    selectShareOptions1?
                        <Avatar src={selectShareOptions1}></Avatar>
                    :""
                } */}
                <span>
                    <IconButton onClick={handleModalClose} >
                        <Close />
                    </IconButton>
                </span>
            </div>
        </div>
        <DialogContent sx={{pt: 0, mr: selectShareOptions?0:0,}} >

            {
                selectShareOptions?

                <Box sx={{width: {md: '400px', xs: 'auto'}}}>

                    <DivCenterContents>
                        <Grid container spacing={1} sx={{width: {md: '350px', xs: 'auto'}}} >
                            <Grid item md={3.6} xs={3.5}>
                                
                                <Card className="text-center" sx={{padding: 0, boxShadow: 'none',}} onClick={()=>{handlePageType(pageType[0])}}>
                                    <CardActionArea sx={{ width: '100%', height: 100, display: 'block',padding: '10px', paddingTop: '12px', cursor: 'pointer' }}>
                                        <Attachment color='primary' style={{fontSize: '30px', marginBottom: '10px'}} />
                                        <Typography variant='body2' sx={{fontSize: '13px'}}>
                                            Share link
                                        </Typography>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item md={0.1} >
                                <div style={{borderRight: '1px solid rgba(0,0,0,0.1)',marginTop: '10px', width: '2px', height: '80px'}}></div>
                            </Grid>

                            <Grid item md={3.8} xs={3.5}>
                                <Card className="text-center" sx={{padding: 0, boxShadow: 'none'}} onClick={()=>{handlePageType(pageType[1])}}>
                                    <CardActionArea sx={{ width: '100%', height: 100, display: 'block',padding: '10px', paddingTop: '12px', cursor: 'pointer' }}>
                                        
                                        <QrCode2 color='primary' style={{fontSize: '30px', marginBottom: '10px'}}/>
                                        <Typography variant='body2' sx={{fontSize: '13px'}}>
                                            QR code
                                        </Typography>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item md={0.1} >
                                <div style={{borderRight: '1px solid rgba(0,0,0,0.1)',marginTop: '10px', width: '2px', height: '80px'}}></div>
                            </Grid>
                            <Grid item md={4} xs={3.5}>

                            {/* <PDFDownloadLink document={<QRCodePDF />} fileName="fee_acceptance.pdf">
                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                            </PDFDownloadLink> */}
                                <Card className="text-center" sx={{padding: 0, boxShadow: 'none'}} onClick={()=>{handlePageType(pageType[2])}}>
                                    <CardActionArea sx={{ width: '100%', height: 100, display: 'block',padding: '10px', paddingTop: '12px', cursor: 'pointer' }}>
                                        
                                        <PictureAsPdf color='primary' style={{fontSize: '30px', marginBottom: '10px'}}/>
                                        <Typography variant='body2' sx={{fontSize: '13px'}}>
                                            Download QR
                                        </Typography>
                                    </CardActionArea>
                                </Card>

                            </Grid>
                        </Grid>
                    </DivCenterContents>
                </Box>

                :
                <div className="order-lg-1 w-100" lg="4">
                    <div className="card-profile-stats d-flex w-100">
                        <div>
                            
                            {
                                sharePageType === pageType[0]?

                                    <div className="mt-0 pt-0">

                                    
                                        <Button 
                                            className="btn-icon-only rounded-circle mt-2"
                                            color="default"
                                            target="_blank"
                                            style={{width: '50px', height: '50px', fontSize: '20px'}}
                                            onClick={handleCopyLink}
                                        >
                                            <i className="fa fa-copy" />
                                        </Button>
                                        <Button  
                                            className="btn-icon-only rounded-circle mt-2"
                                            color="info"
                                            target="_blank"
                                            style={{width: '50px', height: '50px', fontSize: '20px'}}
                                            href={"https://twitter.com/intent/tweet?text="+encodeURIComponent("Check out my business profile on latom app")
                                            +"&url="+encodeURI(url)}
                                        >
                                            <i className="fa fa-twitter" />
                                        </Button>  
                                        <Button 
                                            className="btn-icon-only rounded-circle mt-2"
                                            color="primary"
                                            target="_blank"
                                            style={{width: '50px', height: '50px', fontSize: '20px'}}
                                            href={"https://www.facebook.com/sharer/sharer.php?quote="+encodeURIComponent("Check out my business profile on latom app")
                                            +"&u="+encodeURI(url)}
                                        >
                                            <i className="fa fa-facebook" />
                                        </Button> 
                                        <Button 
                                            className="btn-icon-only rounded-circle mt-2"
                                            color="danger"
                                            target="_blank"
                                            style={{width: '50px', height: '50px', fontSize: '20px'}}
                                            href={"https://mail.google.com/mail/u/0/?view=cm&to&su="+encodeURIComponent("Check out my business profile on latom app")+"&body="+encodeURI(url)+"&bcc&cc&fs=1&tf=1"}
                                        >
                                            <i className="fa fa-envelope" />
                                        </Button> 
                                        <Button 
                                            className="btn-icon-only rounded-circle mt-2"
                                            color="success"
                                            target="_blank"
                                            style={{width: '50px', height: '50px', fontSize: '20px'}}
                                            href={"https://wa.me/?text="+encodeURIComponent("Check out my business profile on latom app via "+url)}
                                        >
                                            <i className="fa fa-whatsapp" />
                                        </Button> 
                                        <Button 
                                            className="btn-icon-only rounded-circle mt-2"
                                            color="primary"
                                            style={{width: '50px', height: '50px', fontSize: '20px'}}
                                            target="_blank"
                                            href={"https://www.linkedin.com/sharing/share-offsite/?url="+encodeURI(url)}
                                        >
                                            <i className="fa fa-linkedin" />
                                        </Button> 
                                        <Button 
                                            className="btn-icon-only rounded-circle mt-2"
                                            style={{width: '50px', height: '50px', fontSize: '20px'}}
                                            color="warning"
                                            target="_blank"
                                            href={"https://t.me/share/url?url="+encodeURI(url)+"text="
                                            +encodeURIComponent("Check out my business profile on latom app")}
                                        >
                                            <i className="fa fa-telegram" />
                                        </Button> 
                                    
                                    
                                    </div>


                                :sharePageType === pageType[1]?
                                    <div style={{width: '375px', height: '300px',}} className="pt-3 mt-2">
                                        <DivCenterContents>
                                            <QRCodeCanvas style={{ width: '200px', height: '200px'}} value={url} />
                                        </DivCenterContents>
                                        <p className='text-center mt-3' style={{fontWeight: 'bold'}}>
                                            My QR code</p>
                                    </div>
                                :""
                            }                          
                        </div>
                    </div>
                </div>
                
            }
        </DialogContent>
        <DialogActions></DialogActions>
        
        {/* <img src={img} style={{display: 'none', borderRadius: '50%'}} id="share_img"/> */}
        <QRCodeCanvas id="downloadCanvas" fgColor={"#000"} style={{width: '200px', height: '200px', display: 'none'}} value={url} />

      </Dialog>
      
    </div>
  );
}
