
import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, signOut } from "firebase/auth";
import { child, endAt, get, getDatabase, limitToFirst, limitToLast, onValue, orderByChild, push, query, ref, set, startAt, update } from "firebase/database";
import { collection, doc, getDoc, getDocs, getFirestore, setDoc, updateDoc, where } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { getProviderTotalLoggedUsers } from "FirebaseController/Analytics";
import { getSubscriptionSettings } from "FirebaseController/Settings";
import { getSubscriptionPlanInfo } from "FirebaseController/StripePayments";
import { androidPackageName, firebaseConfig, vapidKey } from "../../AppSettings";
import { checkDefaultServiceExists, saveService } from "../../FirebaseController/Services/services";
import {  DEFAULT_SERVICE } from "../../Utils/constants";
import { baseFirebaseApiUrl } from 'AppSettings'
import { formatDateToDateTimeStr, getServiceRedirectData, getUserRedirectData } from "../../Utils/functions";
import { revokeAccessToken } from "../../ZoomController";



const app = initializeApp(firebaseConfig);
const db = getDatabase()
const firestoreDB = getFirestore (app)



export const siginInAsGuest = ()=>new Promise(function(successCallback, errorCallback){
    
    //const db = getDatabase()
    const auth = getAuth();
    signInAnonymously(auth)
        .then(() => {
            successCallback(auth)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
            errorCallback(errorMessage)
        });

    

})


export const saveUserInfo = (data, firebaseUserID, successCallBack, isCompany = false)=>{

    let serviceUpdatedAt = null
    let _serviceUpdatedAt = null
    const userRef = ref(db, 'Users/' + firebaseUserID)

    onValue(userRef, (snapshot) => {
        data["uid"] = firebaseUserID
        if(snapshot.exists() !== true) {
            data['isFromCompanyLogin'] = (isCompany === true)
            set(userRef, data).then(()=>{
                saveUserDetails(data, successCallBack, false,null, true)
            });
        }else {
            const userData_ = snapshot.val()
            if(!("firstname" in userData_) || !("surname" in userData_) || !("search" in userData_) || 
                !("about" in userData_) || !("city" in userData_) || !("country" in userData_) 
            ){
                saveUserDetails(data, successCallBack, isCompany?isCompany:false, null, false)
            }else{
                saveUserDetails(data, successCallBack, true,null, false)
            }
        }

        //save default settings

        
        if(!serviceUpdatedAt || (new Date() - serviceUpdatedAt)/1000 > 2) {
            checkDefaultServiceExists(firebaseUserID).then((exists)=>{

                
                if(!exists && (!_serviceUpdatedAt || (new Date() - _serviceUpdatedAt)/1000 > 2)) {
                    
                    
                    const serviceData = DEFAULT_SERVICE
                    serviceData['serviceProviderId'] = {
                        [firebaseUserID]: true
                    }
                    serviceData['companyID'] = null
                    saveService(serviceData, firebaseUserID).then(()=>{
                        
                    }).catch((err)=>{
                        
                        
                    })
                    _serviceUpdatedAt = new Date()
                }
                serviceUpdatedAt = new Date()

            }).catch((err)=>{
                
                
            })
        }else {

        }


    });
}
 

let updatedAt = null
export const saveUserDetails = (data, successCallBack, isSignedUp, errorCallback = null, isFirstLogin = false)=>{
    //const db = getDatabase()
    
    const headers = {
        "Content-Type": "application/json",
    }
    axios.get(`${baseFirebaseApiUrl}/searchDataFromRealTime/?path=UserDirectory&childName=userId&searchValue=${data['uid']}`, {headers:headers})
        .then((data)=>{
            if(data.data.length <= 0 && (!updatedAt || ((new Date() - updatedAt)/1000 > 2))) {
            
            
                const fId = push(child(ref(db), 'UserDirectory')).key
                const _data = {}
                _data['id'] = fId
                _data['email'] = (data['primaryEmail'] || "")
                _data['phoneNo'] = (data['phoneNo'] || "")
                _data['userId'] = data['uid']
                _data['name'] = (data['firstname'] || "") + " " +(data['surname'] || "")
                _data['timestamp'] = (new Date().valueOf())
    
                
    
                updatedAt = new Date()
                const userDirRef = ref(db, 'UserDirectory/' + fId)
                set(userDirRef, _data).then(()=>{
                    successCallBack(isSignedUp, isFirstLogin)
                    //goOffline(db)
                }).catch((err)=>{
                    
                    if(errorCallback) {
                        errorCallback(err)
                    }
                });
            }else {
                successCallBack(isSignedUp, isFirstLogin)
                //goOffline(db)
            }
        })
        // .catch(errorCallback)




    // const searchRef = query(ref(db, 'UserDirectory/'), orderByChild('userId'), equalTo(data['uid']))
    // onValue(searchRef, (snapshot) => {


        
    //     if(!snapshot.exists() && (!updatedAt || ((new Date() - updatedAt)/1000 > 2))) {
            
            
    //         const fId = push(child(ref(db), 'UserDirectory')).key
    //         const _data = {}
    //         _data['id'] = fId
    //         _data['email'] = (data['primaryEmail'] || "")
    //         _data['phoneNo'] = (data['phoneNo'] || "")
    //         _data['userId'] = data['uid']
    //         _data['name'] = (data['firstname'] || "") + " " +(data['surname'] || "")
    //         _data['timestamp'] = (new Date().valueOf())

            

    //         updatedAt = new Date()
    //         const userDirRef = ref(db, 'UserDirectory/' + fId)
    //         set(userDirRef, _data).then(()=>{
    //             successCallBack(isSignedUp, isFirstLogin)
    //             //goOffline(db)
    //         }).catch((err)=>{
                
    //             if(errorCallback) {
    //                 errorCallback(err)
    //             }
    //         });
    //     }else {
    //         successCallBack(isSignedUp, isFirstLogin)
    //         //goOffline(db)
    //     }
    // });
}
 

export const saveUserSignedInFromApp =(data, firebaseId)=>new Promise(async function(successCallback, errorCallback){
    successCallback('success')
    // try {
    //     const myDocRef = doc(firestoreDB, "LatomUserBase/"+ firebaseId)
    //     const docSnap = await getDoc(myDocRef)
    //     if(docSnap.exists()) {
    //         errorCallback("User already exists")
    //     }else { 
    //         setDoc(myDocRef, data).then(successCallback).catch(errorCallback)         
    //     }
    // }catch(err) {
    //     
    //     errorCallback(err)
    // }
})

export const getUserSignedInViaProvider =(providerId, clientId)=>new Promise(async function(successCallback, errorCallback){
    try {
        const myDocRef = collection(firestoreDB, "UserClientBase/"+ providerId + "/Users/")
        const q = query(myDocRef, where("contactId", "==", clientId))
        const docSnap = await getDocs(q)
        if(!docSnap.empty) {
            successCallback(docSnap.docs[0].data())
        }else {
            successCallback({})
        }
    }catch(err) {
        errorCallback(err)
    }
})

export const checkIfUserIsSignedInViaProvider = (email, providerId) => new Promise(async function(successCallback, errorCallback){
  
    try {
        const myDocRef = collection(firestoreDB, "UserClientBase/"+ providerId + "/Users/")
        const q = query(myDocRef, where('email', '==',email))
        
        let docSnap = await getDocs(q)
        const _data = []
        
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        if(_data.length > 0) {
            
            successCallback(_data)
        }else {
            
            const q = query(myDocRef, where('userId', '==',email))
            docSnap = await getDocs(q)
            
            docSnap.forEach((doc) => {
                _data.push(doc.data())        
            });
            successCallback(_data)
        }
        
    }catch(err) {
        
        errorCallback(err)
    }
})

export const saveUserSignedInViaProvider = (data, providerId)=>new Promise(function(successCallback, errorCallback){
    try {
         
        getSubscriptionSettings(providerId).then((subscription)=>{

            
            const type = (subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) > new Date())
            ?subscription.subscriptionType:"Free"

            
            //get plan
            getSubscriptionPlanInfo(type).then((plans)=>{
                if(plans.length > 0) {
                    run(parseFloat(plans[0].Contacts))
                }else {
                    errorCallback("Invalid subscription plan, try again later.")
                }
            }).catch(errorCallback)

        }).catch((err)=>{
            errorCallback(err)
        })

        function run(limit_ = 2000) {
            getProviderTotalLoggedUsers(providerId)
                .then(async (companyAnalytics)=>{
                    const totalContacts = "totalGuests" in companyAnalytics && companyAnalytics.totalGuests &&
                    "totalClients" in companyAnalytics && companyAnalytics.totalClients?
                    parseFloat(companyAnalytics.totalGuests) + parseFloat(companyAnalytics.totalClients)
                    :"totalClients" in companyAnalytics && companyAnalytics.totalClients?companyAnalytics.totalClients
                    :"totalGuests" in companyAnalytics && companyAnalytics.totalGuests?companyAnalytics.totalGuests
                    // :sortedContactData.length > 0?sortedContactData.length
                    :0

                    
                    if(totalContacts >= limit_) {

                        
                        errorCallback("Sorry, you can only add upto "+limit_+" contacts. Upgrade your plan to add more contacts.")

                    }else {
                        if((data.email || data.userId)) {
                            checkIfUserIsSignedInViaProvider((data['email'] || data['userId']), providerId).then(async (_data)=>{
                                if(_data.length > 0) {
                                    errorCallback("user exists")
                                }else { 
                                    const myDocRef = doc(firestoreDB, "UserClientBase/"+ providerId + "/Users/"+ data.userId)
                                    setDoc(myDocRef, data).then(successCallback).catch(errorCallback)         
                                }
                            }).catch((err) => {
                                
                                errorCallback(err)
                            })
                        }else {
                            const myDocRef = doc(firestoreDB, "UserClientBase/"+ providerId + "/Users/"+ data.userId)
                            setDoc(myDocRef, data).then(successCallback).catch(errorCallback)
                        }
                    }
                })
        }
        
    }catch(err) {
        
        errorCallback(err)
    }
})

export const updateUserSignedInViaProvider = (data, providerId)=>new Promise(async function(successCallback, errorCallback){
    try {
        
        const myDocRef = collection(firestoreDB, "UserClientBase/"+ providerId + "/Users/")
        const q = query(myDocRef, where("contactId", "==", data.contactId))
        const docSnap = await getDocs(q)
        if(!docSnap.empty) {
            
            updateDoc(docSnap.docs[0].ref, data).then(successCallback).catch(errorCallback) 
        }else {
            errorCallback("Could not find contact")
        }
    }catch(err) {
        
        errorCallback(err)
    }
})

export const getUserSignedInViaProviderAddr =(providerId, clientId)=>new Promise(async function(successCallback, errorCallback){
    try {
        const myDocRef = doc(firestoreDB, "UserClientBase/"+ providerId + "/UsersAddress/"+clientId)
        const docSnap = await getDoc(myDocRef)
        if(docSnap.exists()) {
            successCallback(docSnap.data())
        }else {
            successCallback({})
        }
    }catch(err) {
        errorCallback(err)
    }
})

export const saveUserSignedInViaProviderAddr = (data, providerId)=>new Promise(function(successCallback, errorCallback){
    const myDocRef = doc(firestoreDB, "UserClientBase/"+ providerId + "/UsersAddress/"+ data.contactId)
    setDoc(myDocRef, data).then(successCallback).catch(errorCallback) 
})


export const updateProviderContactProfile = (file, providerId, firebaseID, firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    
    const storage = getStorage();

    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        const imgRef = storageRef(storage, firebaseUrl);
        deleteObject(imgRef).then(()=>{
            
        })
    }
    const imgRef = storageRef(storage, "UserClientBase/contacts/" + providerId+"/"+firebaseID+"/"+(new Date().valueOf()) + '.jpg');
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                
                successCallBack(url)
            })
            .catch(errorCallback);
    }).catch(errorCallback)

})

export const updateUserSignedInViaProviderAddr = (data, providerId)=>new Promise(function(successCallback, errorCallback){
    try {
        
        const myDocRef = doc(firestoreDB, "UserClientBase/"+ providerId + "/UsersAddress/"+ data.contactId)
        updateDoc(myDocRef, data).then(successCallback).catch(errorCallback) 
    }catch(err) {
        
        errorCallback(err)
    }
})

export const saveUserToken = (firebaseUserID)=>{

    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
        return false
      }
    
      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {

        generateFCMToken(firebaseUserID)
        
      }
    
      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {

            generateFCMToken(firebaseUserID)
              
            // const headers = {
            //     'Content-Type': 'application/json',
            //     "Authorization": "key="+serverKey
            // }
            // axios.post('https://fcm.googleapis.com/fcm/token', {}, {headers:headers})
            // 
            // .catch((er)=>
          }
        });
    }
    
}

function generateFCMToken(firebaseUserID) {
    
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: vapidKey}).then((currentToken)=>{

        //const db = getDatabase()
        const msgTokenRef = ref(db, 'Tokens/' + firebaseUserID)
        set(msgTokenRef, {
            "token": currentToken
        });
    })
}

export const getUserToken = (firebaseUserID)=>new Promise(function(successCallback, errorCallback){
    
    //const db = getDatabase()
    const msgTokenRef = ref(db, 'Tokens/' + firebaseUserID)
    
    onValue(msgTokenRef, (snapshot) => {
        if(snapshot.exists()) {
            
            successCallback(snapshot.val())
        }else {
            successCallback({})
        }
    }, errorCallback);

})

export const getUserDataFromDb = (userId = null)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    if(userId){
        
        const firebaseUserID = userId
        const userRef = ref(db)
        get(child(userRef, "Users/" + firebaseUserID)).then( (snapshot) => {
            if(snapshot.exists()) {
                
                successCallback(snapshot.val())
            }else {
                successCallback({})
            }
        }).catch(errorCallback)
        
    }else {
        getAuth().onAuthStateChanged(user => {
            if (user) {
                const firebaseUserID = user.uid
                const userRef = ref(db, 'Users/' + firebaseUserID)
                onValue(userRef, (snapshot) => {
                    if(snapshot.exists()) {
                        
                        successCallback(snapshot.val())
                    }else {
                        successCallback({})
                    }
                }, errorCallback);
            }
            else {
                errorCallback("no data")
            }
        })
    }
});




export const getUserDirectoryDataFromDb = (uid = null)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    if(uid){
        
        const firebaseUserID = uid
        const headers = {
           "Content-Type": "application/json",
        }
        axios.get(`${baseFirebaseApiUrl}/searchDataFromRealTime/?path=UserDirectory&childName=userId&searchValue=${firebaseUserID}`, {headers:headers})
            .then((data)=>{
                if (data.data.length > 0) {
                    successCallback([data.data[0]])
    
                }else {
                    errorCallback("no data")
                }

            })
            .catch(errorCallback) 
        // const searchRef = query(ref(db, 'UserDirectory/'),limitToFirst(1), orderByChild('userId'), equalTo(firebaseUserID))
        // onValue(searchRef, (snapshot) => {
        //     if (snapshot.exists()) {
        //         const d = []
        //         snapshot.forEach((childSnapshot) => {
        //             d.push(childSnapshot.val())
        //         })
        //         successCallback(d)

        //     }else {
        //         errorCallback("no data")
        //     }
        // }, errorCallback)
    }else{
        getAuth().onAuthStateChanged(user => {
            if (user) {
                const firebaseUserID = user.uid
                const headers = {
                    "Content-Type": "application/json",
                 }
                 axios.get(`${baseFirebaseApiUrl}/searchDataFromRealTime/?path=UserDirectory&childName=userId&searchValue=${firebaseUserID}`, {headers:headers})
                     .then((data)=>{
                         if (data.data.length > 0) {
                             successCallback([data.data[0]])
             
                         }else {
                             errorCallback("no data")
                         }
         
                     })
                     .catch(errorCallback) 
                // const searchRef = query(ref(db, 'UserDirectory/'),limitToFirst(1), orderByChild('userId'), equalTo(firebaseUserID))
                // onValue(searchRef, (snapshot) => {
                //     if (snapshot.exists()) {
                //         const d = []
                //         snapshot.forEach((childSnapshot) => {
                //             d.push(childSnapshot.val())
                //         })
                //         successCallback(d)

                //     }else {
                //         errorCallback("no data")
                //     }
                // }, errorCallback)
            }
            else {
                errorCallback("no data")
            }
        })
    }
});


export const searchUserDirEmail = (email)=>new Promise(function(successCallback, errorCallback){
    //const db = getDatabase()
    const searchRef = query(ref(db, 'UserDirectory/'), orderByChild('email'), startAt(email), endAt(email+"\uf8ff"))
    onValue(searchRef, (snapshot) => {
        if (snapshot.exists()) {
            const _data = []
            snapshot.forEach((childSnapshot) => {
                _data.push(childSnapshot.val())
            })
            successCallback(_data)

        }else {
            errorCallback("no data")
        }
    });
})


export const searchUserByProfileUrlLink = (profileLinkName)=>new Promise(function(successCallback, errorCallback){
    //const db = getDatabase()
    const headers = {
        "Content-Type": "application/json",
     }
     axios.get(`${baseFirebaseApiUrl}/searchDataFromRealTime/?path=Users&childName=profileURLLink&searchValue=${profileLinkName}`, {headers:headers})
         .then((data)=>{
             if (data.data.length > 0) {
                 successCallback(data.data)
 
             }else {
                successCallback([])
             }

         })
         .catch(errorCallback) 


    // const searchRef = query(ref(db, 'Users/'), orderByChild('profileURLLink'), equalTo(profileLinkName))
    // onValue(searchRef, (snapshot) => {
    //     if (snapshot.exists()) {
    //         const _data = []
    //         snapshot.forEach((childSnapshot) => {
    //             _data.push(childSnapshot.val())
    //         })
    //         successCallback(_data)

    //     }else {
    //         successCallback([])
    //     }
    // });
})


		
export const updateUserInfo = (data)=>new Promise(function(successCallBack, errorCallback){


    
    //const db = getDatabase()
    const userRef = ref(db, 'Users/' + data["uid"])
    update(userRef, data).then((res)=>{
        

        getUserDirectoryDataFromDb(data["uid"]).then((_data)=>{

            if(_data.length > 0) {
                const newData = {}
                if(data['primaryEmail']) {
                    newData['email'] = data['primaryEmail']
                }
                if(data['phoneNo']) {
                    newData['phoneNo'] = data['phoneNo']
                }
                if(data['firstname'] && data['surname']) {
                    newData['name'] = data['firstname'] + " " + data['surname']
                }
        
        
                const userDirRef = ref(db, 'UserDirectory/' + _data[0]["id"])
                update(userDirRef, newData).then(()=>{
                    successCallBack("success")
                    //goOffline(db)
                }).catch((err)=>{
                    
                    if(errorCallback) {
                        errorCallback(err)
                    }
                });
            }else {
                successCallBack("success")
            }
        }).catch((err)=>{
            
            
            if(err === "no data") {

                //set the data
                saveUserDetails = (data, successCallBack, false, errorCallback)

            }else{
                if(errorCallback) {
                    errorCallback(err)
                }
            }
        });
        //goOffline(db)
    }).catch(errorCallback);
})


export const updateUserDirectoryData = (_data) => new Promise(function(successCallBack, errorCallback){
    
    
    const headers = {
        "Content-Type": "application/json",
     }
     axios.get(`${baseFirebaseApiUrl}/searchDataFromRealTime/?path=UserDirectory&childName=userId&searchValue=${_data['userId']}`, {headers:headers})
         .then((data)=>{
             if (data.data.length > 0) {
                 
                const userDirRef = ref(db, 'UserDirectory/' + data.data[0].id)
                update(userDirRef, _data).then(()=>{
                    successCallBack("success")
                    //goOffline(db)
                }).catch((err)=>{
                    
                    errorCallback(err)
                })
 
            }else {
                errorCallback("no data")
            }

         })
         .catch(errorCallback) 

    // const searchRef = query(ref(db, 'UserDirectory/'), orderByChild('userId'), equalTo(_data['userId']))
    // onValue(searchRef, (snapshot) => {
    //     if (snapshot.exists()) {
    //         const data = []
    //         snapshot.forEach((childSnapshot) => {
    //             data.push(childSnapshot.val())
    //         })

    //         if(data.length > 0) {

    //             const userDirRef = ref(db, 'UserDirectory/' + data[0].id)
    //             update(userDirRef, _data).then(()=>{
    //                 successCallBack("success")
    //                 //goOffline(db)
    //             }).catch((err)=>{
                    
    //                 errorCallback(err)
    //             });
    //         }

    //     }
    // });
})
 
export const updateUserProfile = (file, firebaseUserID, firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    
    
    const storage = getStorage();

    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        try{ 
            const imgRef = storageRef(storage, firebaseUrl);
            deleteObject(imgRef).then(()=>{
                
            })
        }catch(err) {
            
        }
    }
    const imgRef = storageRef(storage, "Users/profile/"+firebaseUserID+"/" + (new Date().valueOf()) + '.jpg');
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                

                //update users
                
                updateUserInfo({
                    "profile": url.toString(),
                    "uid": firebaseUserID
                })
                .then(()=>{
                    
                })
                

                //update userDirectory

                //const db = getDatabase()
                            
                const headers = {
                    "Content-Type": "application/json",
                }
                axios.get(`${baseFirebaseApiUrl}/searchDataFromRealTime/?path=UserDirectory&childName=userId&searchValue=${firebaseUserID}`, {headers:headers})
                    .then((data)=>{
                        if (data.data.length > 0) {
                            
                            
                            const user = data.data[0]
                            update(ref(db, 'UserDirectory/'+user.id), {"profileLink": url.toString()})
            
                        }
                        
                        successCallBack(url)

                    })
                    .catch(errorCallback) 
                // const searchRef = query(ref(db, 'UserDirectory/'),limitToFirst(1), orderByChild('userId'), equalTo(firebaseUserID))
                // let counter = 0
                // onValue(searchRef, (snapshot) => {
                //     if (snapshot.exists()) {
                //         //update value
                //         if(counter > 0) {
                //             return
                //         }
                //         counter++
                        
                //         snapshot.forEach((childSnapshot) => {
                //             const user = childSnapshot.val();
                //             update(ref(db, 'UserDirectory/'+user.id), {"profileLink": url.toString()})
                            

                //         })

                //     }
                //     successCallBack(url)
                //     //goOffline(db)
                // }, (err)=>{
                //     //goOffline(db)
                //     errorCallback(err)
                // })
            })
            .catch((err)=>{
                errorCallback(err)
            });
    }).catch((err)=>{
        errorCallback(err)
    })

})
export const updateBannerImg = (file, firebaseUserID, firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    
    
    const storage = getStorage();

    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        try{ 
            const imgRef = storageRef(storage, firebaseUrl);
            deleteObject(imgRef).then(()=>{
                
            })
        }catch(err) {
            
        }
    }
    const imgRef = storageRef(storage, "Users/banners/"+firebaseUserID+"/" + (new Date().valueOf()) + '.jpg');
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                

                //update users
                
                updateUserInfo({
                    "bannerImg": url.toString(),
                    "uid": firebaseUserID
                })
                .then(()=>{
                    successCallBack(url)
                })
                
            })
            .catch((err)=>{
                errorCallback(err)
            });
    }).catch((err)=>{
        errorCallback(err)
    })

})

export const logout = ()=> new Promise(function(successCallBack, errorCallback) {
    const auth = getAuth();
    signOut(auth).then(()=>{
        revokeAccessToken()//revoking zoom tokens on logout
        
        const userObj = JSON.parse(localStorage.getItem("user"))
        if(userObj && "tokenDetails" in userObj && userObj.tokenDetails) {
            localStorage.setItem("tempTokenDetails", JSON.stringify(userObj.tokenDetails))
        }
        localStorage.removeItem("user")
        localStorage.removeItem("zoomAccessToken")
        localStorage.removeItem("manager_type")
        localStorage.removeItem("company")
        successCallBack()
    }).catch((err)=>{
        
        errorCallback(err)
    })
})

export const generateProfileDynamicLink = 
    (
        firebaseUserID, 
        isCompany = false, 
        isTeamMember = false, 
        companyName = "", 
        socialMediaData = null, 
        isSelectProvider = false, 
        serviceId = null, 
        companyId = null
        
    ) => new Promise(function(successCallBack, errorCallback){
    //using rest api to generate dynamic link
    const headers = {
        'Content-Type': 'application/json',
    } 

    // const deepLink = "https://www.latom.in/referral=" + "-" + profileURLLink 
    const redirectBase64info = (serviceId)?getServiceRedirectData(serviceId, isCompany,isTeamMember, firebaseUserID, companyId)
        :getUserRedirectData(firebaseUserID, isCompany, isTeamMember, companyId)
    // const userData = JSON.stringify({
    //     user: firebaseUserID,
    //     isCompany: isCompany.toString()
    // })

    let redirectUrl = isCompany?"/co/" + companyName:serviceId?"":"/profile"
    redirectUrl += serviceId?
            isSelectProvider?`/profile/provider`:`/profile/bookings/${btoa(firebaseUserID)}`
        :"?user=" + getUserRedirectData(firebaseUserID, isCompany, isTeamMember)
    redirectUrl = serviceId?redirectUrl+`?service=${btoa(serviceId.toLowerCase())}&user=${redirectBase64info}`:redirectUrl
    
    const redirectLink = encodeURI( 
        window.location.origin + redirectUrl
        // "https://app.latom.in/" + redirectUrl
    )

    
    let deepLink = "https://www.latom.in/redirect_links?fallback_url=" + redirectLink
    
    // const deepLink = "https://www.latom.in/referral=" + "-" + firebaseUserID
    // const redirectLink = encodeURI( 
    //     window.location.origin + "/profile/?user=" + btoa(firebaseUserID)
    // )

        //production
    //let data = {
      //  "longDynamicLink": "https://latom.page.link/?link="+deepLink+"/&apn="+androidPackageName+"&ofl="
//        +redirectLink+
        //"&ifl="+redirectLink+"&ipfl="+redirectLink+"&ibi="+androidPackageName

//     }

     //dev 

     console.log(redirectLink)

    let data = {
        "longDynamicLink": "https://latom.page.link/?link="+deepLink
        //+
        //"/&apn="+androidPackageName+
        //"&ofl="+redirectLink+
        //"&ifl="+redirectLink+
        //"&ipfl="+redirectLink+
        //"&ofl="+redirectLink+
        //"&ibi="+androidPackageName

     }

     /*
     if(isCompany) {//redirect to web 
        data["longDynamicLink"]  = data["longDynamicLink"] + "&afl="+redirectLink
     }
     */

     if(socialMediaData) {
        data["longDynamicLink"]  = data["longDynamicLink"] + "&st=" + socialMediaData.name //title
        data["longDynamicLink"]  = data["longDynamicLink"] + "&sd=" + socialMediaData.desc //description
        data["longDynamicLink"]  = data["longDynamicLink"] + "&si=" + socialMediaData.profile //Image
     }
     

    axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key='+firebaseConfig.apiKey, data, {headers:headers})
      .then(successCallBack)
      .catch(errorCallback);

      
})


export const generateRoundRobinLink = (redirectLink)=>new Promise(function(successCallBack, errorCallback){

    const headers = {
        'Content-Type': 'application/json',
    } 
    const deepLink = "https://www.latom.in/referral=" + "-" + redirectLink
    


    let data = {
        "longDynamicLink": "https://latom.page.link/?link="+deepLink+
        "/&apn="+androidPackageName+
        "&ofl="+redirectLink+
        "&ifl="+redirectLink+
        "&ipfl="+redirectLink+
        "&ofl="+redirectLink+
        "&ibi="+androidPackageName

     }
    data["longDynamicLink"]  = data["longDynamicLink"] + "&afl="+redirectLink


    axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key='+firebaseConfig.apiKey, data, {headers:headers})
      .then(successCallBack)
      .catch(errorCallback);

      
})


export const getUserVisitors = (firebaseUserID)=>new Promise(function(successCallback, errorCallback) {


    //const db = getDatabase()
    const searchRef = query(ref(db, 'ProfileVisits/'+ firebaseUserID), orderByChild('updatedAt'), limitToLast(5))
    onValue(searchRef, (snapshot) => {
        if(snapshot.exists()) {
            
            const data = snapshot.val()
            const newData = []
            Object.keys(data).forEach(function(key) {
                newData.push(data[key])
            })
            successCallback(newData)
            //goOffline(db)
        }else {
            successCallback([])
            //goOffline(db)
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});

export const saveProfileVisitors = (visitorId, firebaseUserID)=>new Promise(function(successCallback, errorCallback){
    
    //get user visitors from db
    getUserVisitors(firebaseUserID).then((data)=>{
        const newData = {
            visitorId: visitorId,
            userId: firebaseUserID,
            updatedAt: formatDateToDateTimeStr(new Date())
        }
        //const db = getDatabase()
        const userRef = ref(db, 'ProfileVisits/' + firebaseUserID + "/"+visitorId)
        if(data.length < 1) {

            set(userRef, newData).then(()=>{
                successCallback("success")
                //goOffline(db)
            }).catch((err)=>{
                //goOffline(db)
                errorCallback(err)
            });
        }else {
            
            if(data.length < 5) {//only add data if visitors are below 5
                //set/update data
                set(userRef, newData).then(()=>{
                    successCallback("success")
                    //goOffline(db)
                }).catch((err)=>{
                    //goOffline(db)
                    
                })

            }else {
                //remove the oldest data and add new data 
                const dataToBeRemoved = data[(data.length -1)]
                const delRef = ref(db, 'ProfileVisits/' + firebaseUserID + "/"+dataToBeRemoved.visitorId)
                set(delRef, null).then(()=>{
                    //set new data
                    set(userRef, newData).then(()=>{
                        //goOffline(db)
                        successCallback("success")
                    }).catch((err)=>{
                        //goOffline(db)
                        errorCallback(err)
                    })
                }).catch((err)=>{
                    //goOffline(db)
                    errorCallback(err)
                })
                

            }

        }

    }).catch(errorCallback)
    
})


export const getVisitedUsers = (firebaseUserID)=>new Promise(function(successCallback, errorCallback) {

    //const db = getDatabase()
    const searchRef = query(ref(db, 'UserVisitedProfiles/'+ firebaseUserID), orderByChild('updatedAt'), limitToLast(5))
    onValue(searchRef, (snapshot) => {
        if(snapshot.exists()) {
            
            const data = snapshot.val()
            const newData = []
            Object.keys(data).forEach(function(key) {
                newData.push(data[key])
            })
            successCallback(newData)
        }else {
            successCallback([])
        }
    }, (err)=>{
        //goOffline(db)
        errorCallback(err)
    });
});
export const saveVisitedProfiles = (visitedId, firebaseUserID)=>new Promise(function(successCallback, errorCallback){
    
    //get user visitors from db
    getUserVisitors(firebaseUserID).then((data)=>{
        const newData = {
            visitedId: visitedId,
            userId: firebaseUserID,
            updatedAt: formatDateToDateTimeStr(new Date())
        }
        //const db = getDatabase()
        const userRef = ref(db, 'UserVisitedProfiles/' + firebaseUserID + "/"+ visitedId )
        if(data.length < 1) {

            set(userRef, newData).then(()=>{
                successCallback("success")
                // goOffline(db)
            }).catch((err)=>{
                //goOffline(db)
                errorCallback(err)
            });
        }else {
            
            if(data.length < 5) {//only add data if visitors are below 5
                //set/update data
                set(userRef, newData).then(()=>{
                    successCallback("success")
                    //goOffline(db)
                }).catch((err)=>{
                    //goOffline(db)
                    
                })

            }else {
                //remove the oldest data and add new data 
                const dataToBeRemoved = data[(data.length -1)]
                const delRef = ref(db, 'ProfileVisits/' +  firebaseUserID + "/"+dataToBeRemoved.visitedId)
                set(delRef, null).then(()=>{
                    //set new data
                    set(userRef, newData).then(()=>{
                        successCallback("success")
                    }).catch((err)=>{
                        //goOffline(db)
                        errorCallback(err)
                    })
                }).catch((err)=>{
                    //goOffline(db)
                    errorCallback(err)
                })
                

            }

        }

    }).catch((err)=>{
        errorCallback(err)
    })
    
})



export const saveUserBillingAddress = (data, firebaseUserID, isUpdate = false)=>new Promise((successCallBack, errorCallback)=>{

    const userRef = ref(db, 'UserBillingAddress/' + firebaseUserID)
    const actionFn = isUpdate?update(userRef, data):set(userRef, data)
    actionFn
    .then(successCallBack)
    .catch(errorCallback)

})

export const getUserBillingAddress = (firebaseUserID)=>new Promise((successCallBack, errorCallback)=>{

    const userRef = ref(db, 'UserBillingAddress/' + firebaseUserID)
    get(userRef)
    .then((snapshot)=>{

        if(snapshot.exists()) {
            successCallBack(snapshot.val())
        } else {
            successCallBack({})
        }

    })
    .catch(errorCallback)

})
 
 



