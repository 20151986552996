
/* global gapi */ 
//do not remove above line, it may seem as a comment but it is needed for google login on eslint js

import { Avatar, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getMessaging, onMessage } from "firebase/messaging";
import { SnackbarProvider } from 'notistack';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router-dom';
import { clientId, firebaseConfig } from './AppSettings';
import DivCenterContents from './components/DivCenterContents';
import EditNameSettingsModal from './components/Modal/EditNameSettingsModal';
import { showNotification } from './FirebaseController/Notifications/fcmNotifications';
import { deleteOldReadNotifs } from './FirebaseController/Notifications/localNotifs';
import { getUserDataFromDb, logout, saveUserToken } from './FirebaseController/User/user';
import { routes } from './Routes/routes';
import { refreshNotifs } from './Store/Reducers/NavBarTitle/title.actions';
import { setProfile } from './Store/Reducers/User/user.actions';
import './Utils/css/notifSnackbar.css';
import { addDateMins } from './Utils/functions';
import logo_loader from './Utils/images/loader-logo.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F0973B'
    },
    secondary: {
      main: '#63C3CF'
    },
    white: {
      main: '#fff'
    },
    black: {
      main: "#000"
    }, 
    grey: {
      main: '#F4F5FA'
    },
    purpleish: {
      main: "#6e7390"
    },
    purple: {
      main: '#9c27b0'
    },
    danger: {
      main: "#F93154"
    },
    lightColor: {
      main: "#f4f5fa",
    },
    lightDanger: {
      main: "#F5DAE9"
    },
    darkGreen: {
      main: "#007a6a"
    }
  }
});


function App() {


  const app = initializeApp(firebaseConfig)
  const analytics = getAnalytics(app)
  const [loading, setLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isOnline, setIsOnline] = useState(true)
  const [openEditNameModal, setOpenEditNameModal] = useState(false)
  // const { companyName } = useParams()
  let companyName = (window.location.href).split("/co/")
  companyName = companyName.length > 1?companyName[1].split("/")[0]:null 
  const loggedOutRedirectLink = localStorage.getItem("loggedOutRedirectLink")
  const element = useRoutes(routes(isLoggedIn, companyName, loggedOutRedirectLink))
  const navigate = useNavigate()
  const dispatch = useDispatch()


 
  const token = JSON.parse(localStorage.getItem("zoomAccessToken"))
  const __user = localStorage.getItem("user")
  const localUser = __user?JSON.parse(__user):null



  useEffect(()=>{
    
    //remove redirect link to avoid redirecting everytime
    if(loggedOutRedirectLink) {
      localStorage.removeItem("loggedOutRedirectLink")
    }
  }, [])
 
  useEffect(() => {
    //check if user is logged in google 
    //then check firebase
    
    gapi.load('auth2', function(){
      let auth2 = gapi.auth2.init({
          client_id: clientId
      });

      //check if access token is expired an silent sign in
      if(localUser && "tokenDetails" in localUser && localUser.tokenDetails){
        const expiresAt = addDateMins(new Date(localUser.tokenDetails.expires_at), -30) //refresh after 30 mins
        if((new Date() > expiresAt  )) {
          
          gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse().then((resp)=>{
            localUser.tokenDetails = resp
            localUser.token = resp.access_token
            localStorage.setItem("user", JSON.stringify(localUser))
            
          }) 
        }
      }

      //check if user is logged in
      
      const userObj = JSON.parse(localStorage.getItem("user"))
      const user__ = userObj?userObj.user:null
      // if(user__) {
      //   setIsLoggedIn(true)
      //   saveUserToken(user__.uid)
      // }else {
        if(auth2.currentUser.get()) {
            
          getAuth().onAuthStateChanged(user => {
            setLoading(false)
            
            if (user) {

              setIsLoggedIn(true)
              saveUserToken(user.uid)
            }
            else {
              setIsLoggedIn(false)
            }
          })
        }else {

          //check if user is logged in firebase then log them off
          getAuth().onAuthStateChanged(user => {
            setLoading(false)
            if (user) {
              //saveUserToken(user.uid)
              logout()
              setIsLoggedIn(false)
            }
            else {
              setIsLoggedIn(false)
            }
          })
        }
      // }

      // auth2.isSignedIn.listen((val)=> {
      //   //auth2.currentUser.get()
        
      // })
      // auth2.currentUser.listen(userChanged); // This is what you use to listen for user changes
  });

  return () => null
}, [])


  useEffect(() => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      
      const user = payload.data.user
      const sented = payload.data.sented
      const title = payload.data.title
    //   const icon = payload.data.icon
      const body = payload.data.body
      const notificationOptions = {
        body: body,
        icon: "/latomcollogo.png"
      };
      
      showNotification(title, notificationOptions)
      dispatch(refreshNotifs())

      
      


      // dispatch(setAppNotifications({

      // }))
    })
  }, [])
  




  useEffect(() => {
    
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    
    
    
    
    
    if(user && (localStorage.getItem("isModalLogin") !== "true")) { 

      getUserDataFromDb(user.uid).then((data)=>{
        
        if(data.profile != userObj.user["photoURL"]) {

          userObj.user["photoURL"] = data.profile
          localStorage.setItem("user", JSON.stringify(userObj))
        }
        if(          
            ("profileURLLink" in data && !("profileURLLink" in userObj.user))
            ||
            ("profileURLLink" in data && data.profileURLLink != userObj.user["profileURLLink"])
          ) {

          userObj.user["profileURLLink"] = data.profileURLLink
          localStorage.setItem("user", JSON.stringify(userObj))

        }
        
        
        if(!"firstname" in data || !data.firstname || !"surname" in data || !data.surname || !"primaryEmail" in data || !data.primaryEmail) {
          if(localStorage.getItem("isModalLogin") === "true") {
            //if login has occured via modal, dont redirect to prevent app from losing the booking
            localStorage.removeItem("isModalLogin")
            // 
          }else {

            //if not in companymode open settings modal
            // 
            // 
            if(!companyName && (localStorage.getItem("isGuestLogin") !== "true")) {
                localStorage.removeItem("isGuestLogin")
              // 
              // 
              setOpenEditNameModal(true)
            }
            // navigate("/settings/edit-name")
            // window.scrollTo(0,0) //solves bug with react router whereby it loads bottom of page if previous page was in bottom part

          } 

        }else {
          
          if(!"displayName" in user || !user.displayName) {
            
            const newObj = userObj
            newObj.user["displayName"] = (data.firstname + " " + data.surname)
            localStorage.setItem("user", JSON.stringify(newObj))
          }

          const redirectBookingUrl = localStorage.getItem("redirectBookingUrl")
          
          if(typeof redirectBookingUrl === 'string'){
            localStorage.removeItem("redirectBookingUrl")
            window.location.href = redirectBookingUrl 
            

          }else {
            if(localStorage.getItem("isFirstLogin") === "true") {
              if(!companyName) {
                localStorage.setItem("askForServiceProviderDet", "true")
                setOpenEditNameModal(true)
              }
            }

          }
        }
        dispatch( setProfile(data) )
          
      })
    }else {
    }
  }, [isLoggedIn])


  useEffect(() => {
    //detecting if internet conection is online

    window.addEventListener("online", ()=>{
      setIsOnline(true)
    })
    window.addEventListener("offline", ()=>{setIsOnline(false)})
  
  }, [])

  useEffect(() => {
    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState === 'visible') {
        
        window.localforage.getItem('notifReceived', function(err, value) {
          // Run this code once the value has been
          // loaded from the offline store.
          // 
          // 
          if(value === "true"){ 
            dispatch(refreshNotifs())
            window.localforage.removeItem('notifReceived').then(function() {
              //
            }).catch(function(err) {
                
            });
          }
        });
      }else {
        
      }
    });
  
    return () => null
  }, [])


  useEffect(()=>{
    //redirecting to default browser when website is opened in webview
    let standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);
  
    if (ios) {
      if (!standalone && safari) {
        // Safari
        
        
      } else if (!standalone && !safari) {
        // iOS webview
        
        window.open(window.location.href, '_system')
      };
    } else {
      if (userAgent.includes('wv')) {
        // Android webview
        
        window.open(window.location.href, '_system')
      } else {
        // Chrome
        
      }
    };
  }, [])
  

  useEffect(() => {
    
    // if(isLoggedIn) {
    //   const managerType = localStorage.getItem("manager_type")//get MANAGER TYPE TO COMPANY
    //   let company = localStorage.getItem("company")//get company local data if any
    //   company = company?JSON.parse(company):null
    //   if (company && managerType) {
    //     dispatch(setAppManagerConfig(company, managerType ))//SET REDUX MANAGER TO COMPANY
    //   }else if (localUser) {
    //     dispatch(setAppManagerConfig(localUser, APP_MANAGER_TYPES[0] ))//SET REDUX MANAGER TO USER
    //   }else {
        
    //     dispatch(setAppManagerConfig({}, APP_MANAGER_TYPES[0] ))//SET REDUX MANAGER TO USER
    //   }
    // }

  
    return () => null
  }, [isLoggedIn])

  
  useEffect(() => {
    
    if(navigator.geolocation) {
      // 
    }
    
    if(window.localStorage.length < 1){
      // window.alert("Cookies are not enabled. Please enable cookies on your browser to continue...")

    }
    return () => null
  }, [])

  useEffect(() => {
    if(localUser && ("user" in localUser) && isLoggedIn) {
      deleteOldReadNotifs(localUser.user.uid)
    }
  
    return () => null
  }, [localUser, isLoggedIn])
  
  

  


  // 
  

  return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider  
          preventDuplicate={true}
          maxSnack={1}
          hideIconVariant
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
          }}
        >
            {
              isOnline?
                loading?
                  <DivCenterContents style={{height: '100vh'}}>
                    {/* <Typography variant='h4'> {AppName} </Typography>
                    <SyncLoader loading={loading} /> */}
                    <Avatar src={logo_loader} sx={{width: '90px', height: '90px'}} />
                  </DivCenterContents>
                :
                <Suspense
                    fallback={
                      <DivCenterContents style={{height: '100vh'}}>
                        {/* <Typography variant='h4'> {AppName} </Typography>
                        <SyncLoader loading={loading} /> */}
                        <Avatar src={logo_loader} sx={{width: '90px', height: '90px'}} />
                      </DivCenterContents>
                    }
                >
                  {element}
                </Suspense>
                
                :
                <>
                  <DivCenterContents style={{height: '100vh'}}>
                      {/* <Typography variant='h4'> {AppName} </Typography>
                      <SyncLoader loading={loading} /> */}
                      <DivCenterContents style={{marginBottom: '12px'}}>
                        <Avatar src={logo_loader} sx={{width: '90px', height: '90px'}} />
                      </DivCenterContents>
                      <Typography variant='h6' className='text-center' sx={{fontSize: "14px"}}>
                        You are currently offline
                      </Typography>
                  </DivCenterContents>
                    
                </>
            }

            <EditNameSettingsModal
              openProps={openEditNameModal}
            />
            
        </SnackbarProvider>
      </ThemeProvider>
  );
}

export default App;
