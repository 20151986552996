import { REFRESH_DATA_COUNTER } from './company.types'

const initialState = {
    refreshCounter: 0
  };
export const companyReducer = (state = initialState, action)=> {

    switch (action.type) {
        case REFRESH_DATA_COUNTER:
            return {
                ...state,
                refreshCounter: (state.refreshCounter + 1)
            };
        
        default:
            return state;
    }
}