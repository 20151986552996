import { Close, Done } from '@mui/icons-material';
import { Autocomplete, FormControl, Grid, IconButton, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { deleteDefaultInvoiceItem, saveDefaultInvoiceItem, searchDefaultInvoicesItems, updateDefaultInvoiceItem } from 'FirebaseController/Invoice/invoice';
import { getBusinessDetailsFromDb } from 'FirebaseController/Settings';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import Switch from "react-switch";
import { APP_MANAGER_TYPES } from 'Utils/constants';
import { inputStyles } from '../../Utils/style';

export default function InvoiceItems(props) {

    const { isEdit, data, extraData, toggleMainDrawer, configs} = props
    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    const [invoiceItems, setInvoiceItems] = useState(isEdit === 1 || isEdit === 4?data:{})
    const { pageType } = useParams()
    const appConfigReducer = configs
    const navigate = useNavigate()
    const { companyName }  = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [defaultItem, setDefaultItem] = React.useState(data?(data.itemTitle || ''):'');
    const [defaultItemInputValue, setDefaultItemInputValue] = React.useState(data?(data.itemTitle || ''):'');
    const [defaultItemOptions, setDefaultItemOptions] = React.useState([]);
    const [defaultItemSearchData, setDefaultItemSearchData] = React.useState([])
    const [currencyVal, setCurrencyVal] = React.useState("")
    

    const handleFormData = (name, value)=>{
        
        // if(name === 'itemRate' && ((value) < 1 || value > 100)) {
        //     showSnackBar("Invalid item rate. Item rate can only range between 1 to 100", "error")
        // }else 
        if(name === 'itemQty' && ((value) < 0 || value > 100)) {
            showSnackBar("Invalid quantity. Item quantity can only range between 0 to 100", "error")
        }else {

            let itemTotal = 0
            if(name === "itemRate") {
                if(invoiceItems.itemQty && value) {
                    itemTotal = parseFloat(invoiceItems.itemQty) * parseFloat(value)
                }
            } else if(name === "itemQty" && value) {
                if( invoiceItems.itemRate) {
                    itemTotal = parseFloat(invoiceItems.itemRate) * parseFloat(value)
                }
            } else {
                itemTotal = (parseFloat(invoiceItems.itemQty) || 0) * parseFloat(invoiceItems.itemRate || 0)
            }

            const taxRate = (extraData && extraData.taxRate)?extraData.taxRate:0
            const itemTaxable = ((name === "itemTaxable"))?value:invoiceItems.itemTaxable?invoiceItems.itemTaxable:false

            let total = itemTotal //(parseFloat(invoiceItems.itemQty) || 0) * parseFloat(invoiceItems.itemRate || 0)
            total = itemTaxable === true?(total + (total  * (parseFloat(taxRate)/100))):total

            setInvoiceItems({
                ...invoiceItems,
                [name] : value,
                "itemTotal": total.toFixed(2) //Math.round(total * 100) / 100
            })
        }
    }

    const handleItemDelete = () => {
        setIsDeleteLoading(true)
        deleteDefaultInvoiceItem(            
            data.itemNo,
            appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
            (appConfigReducer.type === APP_MANAGER_TYPES[1])
        )
        .then(()=>{
            setIsDeleteLoading(false)
            showSnackBar("Billing item deleted successfully")
            const url =  appConfigReducer.type === APP_MANAGER_TYPES[0]?"/invoices":"/co/"+companyName+"/invoices"
            window.location.href = (url)
        })
        .catch((err)=>{
            setIsDeleteLoading(false)
            
            showSnackBar("Sorry, could not delete item, try again later.")
        })
    }
    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    const handleItemAdd = () => { 
        if(!invoiceItems.itemTitle) {
            showSnackBar("Please enter item title", "error")
        }
        // else if(!(isEdit === 3 || isEdit === 4) && !invoiceItems.itemDesc) {
        //     showSnackBar("Please enter item description", "error")
        // }
        else if(!(isEdit === 3 || isEdit === 4) && !invoiceItems.itemQty) {
            showSnackBar("Please enter a value for quantity", "error")
        } else if(!invoiceItems.itemRate) {
            showSnackBar("Please enter a charge rate", "error")
        }else {
            const item = (isEdit !== 3 && isEdit !== 4  && pageType.toLowerCase() === "invoices")?"invoiceID":"estimateID"
            const data_ = {
                ...invoiceItems,
                // "itemQty": "1",
                "itemNo": invoiceItems.itemId?invoiceItems.itemId:parseInt(new Date().valueOf()),
                [item] : (invoiceItems[item]?invoiceItems[item]:(extraData && extraData.invoiceID)?extraData.invoiceID:null),
                "itemTaxable" : invoiceItems.itemTaxable?invoiceItems.itemTaxable:false
            }
            if(!(isEdit === 3 || isEdit === 4)) {
                data_["billItemNo"] = invoiceItems["billItemNo"]?invoiceItems["billItemNo"]:parseInt(new Date().valueOf()).toString()
            }
            if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
                
                data_["companyId"] = appConfigReducer.data.companyID.toString()
            }

            const editData = {
                "itemTitle" : data_["itemTitle"],
                "itemDesc" : (data_["itemDesc"] || null),
                "itemRate" : (data_["itemRate"] || parseFloat(0).toFixed(2)),
            }

            if(isEdit === 3 || isEdit === 4) {

                //save default billing item to db
                const ref = isEdit === 3?
                    saveDefaultInvoiceItem(
                        data_, 
                        appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
                        (appConfigReducer.type === APP_MANAGER_TYPES[1])
                    )
                :isEdit === 4?
                    updateDefaultInvoiceItem(
                        editData, 
                        data.itemNo,
                        appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
                        (appConfigReducer.type === APP_MANAGER_TYPES[1])
                    )
                :null

                if(ref) {
                    setIsLoading(true)
                    ref.then(()=>{
                        showSnackBar("Billing item added successfully.", "success")   
                        setIsLoading(false) 
                        const url =  appConfigReducer.type === APP_MANAGER_TYPES[0]?"/invoices":"/co/"+companyName+"/invoices"
                        navigate(url)                   
                    })
                    .catch((err)=>{
                        
                        showSnackBar("Sorry, could not add billing item, try again later.", "error")
                        setIsLoading(false)
                    })
                }


            }else {
                //submit custom billing item to invoice page
                let itemLists = localStorage.getItem("invoiceItemsList")
                itemLists = itemLists?JSON.parse(itemLists):[]
                let updatedItemLists = []
                const index_ = (itemLists.map(item=>(item.billItemNo || item.billItemNoe)).indexOf(data_.billItemNo || data_.billItemNoe))
                if(index_ > -1) {
                    itemLists[index_] = data_
                    updatedItemLists.push(data_.billItemNoe || data_.billItemNo)
                }else {
                    itemLists.push(data_)
                }
                localStorage.setItem("invoiceItemsList", JSON.stringify(itemLists))
                localStorage.setItem("updatedItemLists", JSON.stringify(updatedItemLists))
                toggleMainDrawer(false)
            }
        }
    }


    const handleDefaultItemInputChange = (name) => {
        setInvoiceItems({
            ...invoiceItems,
            itemTitle : name,
        })
        setDefaultItemInputValue((name || ""))
        setDefaultItem((name || ""))
        if(name && (isEdit !== 3 || isEdit !== 4)) {
            if(name.length > 2) {
                const providerId = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                    appConfigReducer.data.companyID:user.uid
                searchDefaultInvoicesItems(name, providerId, (appConfigReducer.type === APP_MANAGER_TYPES[1])).then((data)=>{
                    // 
                    const newData = data.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.itemNo === value.itemNo
                    ))
                  )
                    setDefaultItemSearchData(newData)
                    setDefaultItemOptions((newData.map(item=>item.itemTitle)))
                })
            }
        }
    }
    const handleDefaultItemChange = (value) => {
        // const value = e.target.value
        setDefaultItem(value)
        let item_idx = []
        defaultItemSearchData.forEach((item, idx) => {
            if( (item.itemTitle === value)) {
                item_idx.push(idx)
            }
        } )
        
        
        if(item_idx.length > 0) {

            setInvoiceItems({
                ...invoiceItems,
                itemTitle :  (defaultItemSearchData[item_idx[0]].itemTitle),
                itemDesc :  (defaultItemSearchData[item_idx[0]].itemDesc),
                itemRate :  (defaultItemSearchData[item_idx[0]].itemRate),
            })
            // setDefaultItem(defaultItemSearchData[item_idx[0]])
        }else {
            
        }

    }
  

    useEffect(() => {
      
        if(extraData && extraData.currency) {
            setCurrencyVal(extraData.currency)
        }else {
            getBusinessDetailsFromDb(user.uid, appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():null)
                .then((data)=>{
                    setCurrencyVal(data.currValue?data.currValue:"")
                }).catch((err)=>{
                    
                })
        }
    
      return () => null
    }, [])
    
    

    return (
        
        <Grid container spacing={1} className='px-4 pb-4 my-3'>



            <Grid item xs={12}>
                <div className='border p-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '10px'}}>
                    <label htmlFor='eventTitle'>
                       {"Item title"} 
                    </label><br />
                    {
                        isEdit !== 3 && isEdit !== 4?

                            <Autocomplete
                                disablePortal
                                id="location"
                                options={defaultItemOptions}
                                color="grey"
                                size={"small"} 
                                sx={{
                                    padding: 0,
                                    margin: 0,
                                    width: '100%', border: 'none',
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                    fontSize: '10px',
                                    "& .MuiInputBase-root": {
                                        "& > fieldset": {
                                            borderColor: "transparent !important",
                                            outlineColor: 'transparent'
                                        }
                                    }
                                }}
                                value={defaultItem}
                                inputValue={defaultItemInputValue}
                                onChange={(event, newInputValue)=>handleDefaultItemChange(newInputValue)} 
                                placeholder="Contact name"                              
                                onInputChange={(event, newInputValue)=>handleDefaultItemInputChange(newInputValue)}
                                renderInput={(params) => 
                                    <TextField {...params} variant="outlined" 
                                        color="secondary"
                                        placeholder='Enter text here'
                                        InputProps={{ ...params.InputProps, disableUnderline: true, style: {...inputStyles, background: '#ECFDFF', borderRadius: 15,  } }} />}
                                
                            />
                        :
                            <textarea id="eventTitle" style={{
                                    borderRadius: '5px',
                                    background: 'transparent',
                                    border: 'none',
                                    padding: '5px',
                                    width: '100%',
                                    resize: 'none'
                                }} 
                                type="text" 
                                name="itemTitle" 
                                placeholder='Enter text here'
                                value={invoiceItems["itemTitle"]?invoiceItems["itemTitle"]:""}
                                rows="1"
                                cols="50"
                                onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                            ></textarea>
                    }

                </div>
            </Grid>
            <Grid item xs={12} >
                <FormControl variant="standard" className="py-1 mb-1" fullWidth>
                    <label htmlFor="input-with-icon-adornment6" className='py-0 my-0'>
                        Description
                    </label>
                    <input
                        className='mt-1 w-100'
                        id="input-with-icon-adornment6"                                
                        // fullWidth={true}
                        style={inputStyles}
                        // disableUnderline={true}
                        placeholder="Details if any"
                        name="itemDesc"
                        value={invoiceItems["itemDesc"]?invoiceItems["itemDesc"]:""}
                        onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                    />
                </FormControl>
            </Grid>
            {
                (isEdit === 3 || isEdit === 4)?""
                :
                <Grid item xs={12} md={6} >
                    <FormControl variant="standard" className="py-1 mb-1" fullWidth>
                        <label htmlFor="input-with-icon-adornment6" className='py-0 my-0'>
                            Quantity/Hrs
                        </label>
                        <input
                            className='mt-1 w-100'
                            id="input-with-icon-adornment61"                                
                            // fullWidth={true}
                            style={inputStyles}
                            // disableUnderline={true}
                            placeholder="In numbers"
                            type="number"
                            name="itemQty"
                            value={invoiceItems["itemQty"]?invoiceItems["itemQty"]:""}
                            onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                        />
                    </FormControl>
                </Grid>
                

            }
            <Grid item xs={12} md={(isEdit === 3 || isEdit === 4)?12:6} >
                <FormControl variant="standard" className="py-1 mb-1" fullWidth>
                    <label htmlFor="input-with-icon-adornment62" className='py-0 my-0'>
                        Charge rate ( { currencyVal } )
                    </label>
                    <input
                        className='mt-1 w-100'
                        id="input-with-icon-adornment63"                                
                        // fullWidth={true}
                        style={inputStyles}
                        // disableUnderline={true}
                        placeholder="per hr/unit"
                        name="itemRate"
                        type="number"
                        value={invoiceItems["itemRate"]?invoiceItems["itemRate"]:""}
                        onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                    />
                </FormControl>
            </Grid>
            
            {
                (isEdit === 3 || isEdit === 4)?""
                :
                    <>
                        <Grid item xs={12} >
                            <Box className='d-flex p-2 rounded align-items-center' 
                                sx={{
                                    background: '#FEEEEE',
                                }}
                            >
                                <span>
                                    Taxable
                                </span>
                                <div style={{flex: 1}}></div>
                                <Switch 
                                    checked={invoiceItems["itemTaxable"]?invoiceItems["itemTaxable"]:false}
                                    onChange={(checked)=>handleFormData("itemTaxable", checked)}
                                    onColor={"#F4F5FA"}
                                    onHandleColor={"#F0973B"}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    checkedHandleIcon={<Done style={{color: "#fff", margin: '5px'}} /> }
                                    offHandleColor={"#E7E8ED"}
                                    offColor="#F4F5FA"
                                    height={35}
                                    width={63}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={12} xs={12} style={{marginTop: '10px',}}>
                            
                            <Box className="rounded d-flex" sx={{
                                background: '#63C3CF',
                                padding: '12px'
                            }}>
                                <span>Item total</span>
                                <div style={{flex: 1}}></div>
                                <span>
                                    {
                                        invoiceItems.itemTotal || "00.00"
                                    }
                                </span>
                            </Box>
                        </Grid>
                    </>
            }
            
            <Grid item xs={12} className="d-flex">
                <div style={{flex: 1}}></div>                
                <Button
                    className={"rounded border-0 my-4 ml-4 text-capitalize"}
                    color="white"
                    type="button" 
                    style={{background: '#F0973B',
                        
                    }}
                    onClick={handleItemAdd}
                >
                    {
                        
                        isLoading?
                            <SyncLoader
                                loading={isLoading}
                                size={10}
                                color="#fff"
                            />
                        :
                        "Save"
                    }
                    
                    
                </Button>

                {
                    isEdit === 4?

                        <Button
                            className={"rounded border-0 my-4 ml-2 text-capitalize"}
                            color="white"
                            type="button" 
                            style={{background: '#000',
                                
                            }}
                            onClick={handleItemDelete}
                        >
                            {
                                
                                isDeleteLoading?
                                    <SyncLoader
                                        loading={isDeleteLoading}
                                        size={10}
                                        color="#fff"
                                    />
                                :
                                "Delete"
                            }
                            
                            
                        </Button>

                    :""

                }
                
                
            </Grid>

            
            
            
            
            

        </Grid>

    )

}