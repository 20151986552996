import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { getSubscriptionSettings, saveSubscriptionSettings } from 'FirebaseController/Settings';
import { getSubscriptionPlanInfo } from 'FirebaseController/StripePayments';
import { updateUserInfo } from 'FirebaseController/User/user';
import { getUserDataFromDb } from 'FirebaseController/User/user';
import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Outlet
} from 'react-router-dom';
import { setSubscription } from 'Store/Reducers/Subscription/subscriptions.actions';
import { APP_MANAGER_TYPES } from 'Utils/constants';
import { baseFirebaseApiUrl } from 'AppSettings'
import NavBar from '../../components/NavBar';
import '../../Utils/css/override.css';
// import DemoNavbar from 'components/Navbars/DemoNavbar';
const UserContext = createContext()


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MainTheme(props) {
  const { showNavBar } = props
  const userObj = JSON.parse(localStorage.getItem("user"))
  const user = userObj?userObj.user:null
  const dispatch = useDispatch()
  // const [openSurvey, setOpenSurvey] = useState(false)  
  const [appConfigReducer, setAppConfigReducer] = useState(
    {
      data: {},
      type: APP_MANAGER_TYPES[0]
    }
  )


  useEffect(() => {
    if (user) {
      // dispatch(setAppManagerConfig(user, APP_MANAGER_TYPES[0] ))//SET REDUX MANAGER TO USER
      
      getUserDataFromDb(user.uid)
      .then((userData)=>{
        
        setAppConfigReducer({
          ...appConfigReducer,
          data: userData
        })

        //check if user had logged in via company and this is the first time they are logging in in user mode
        if(userData.isFromCompanyLogin === true) {
          updateUserInfo({
            uid: userData.uid,
            isFromCompanyLogin: null
          })
        }

      })
    }
  
    return () => null
  }, [])

  
  useEffect(() => {
    if(user) {
      getSubscriptionSettings(user.uid).then((subscription)=>{

        
        let type = (subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) > new Date())
        ?subscription.subscriptionType:"Free"

        if((subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) < new Date())) {
          //get subscription from chargebee
          const headers = {
            "Content-Type": "application/json",
          }
          axios.get(
                baseFirebaseApiUrl + "/getSubscription?subscriptionId="+subscription.subscriptionId,
                {headers:headers}
            )
            .then((data)=>{

              if(subscription.currentTermEnd !== data.subscription.current_term_end && new Date(parseFloat(data.subscription.current_term_end)) > new Date()){
                type = subscription.subscriptionType
                run()

                saveSubscriptionSettings(
                  {
                    currentTermEnd: data.subscription.current_term_end,
                    currentTermStart: data.subscription.current_term_start,
                    status: data.subscription.status,
                    updatedAt: data.subscription.updated_at,
                  },
                  user.uid
                )
                //update realtime db
              }else {
                run()
              }

            })
            .catch((err)=>{
                
                run()
            })
        }else {
          run()
        }
        
        function run() {
          //get plan
          getSubscriptionPlanInfo(type).then((plans)=>{
            if(plans.length > 0) {
                dispatch(setSubscription(plans[0], subscription))
            }else {
                
            }
          })
        }

      }).catch((err)=>{
          
      })
    }
    return () => null
  }, [])

  // useEffect(() => {
    
  //   setTimeout(() => {

  //     const surveyToken_ = localStorage.getItem("surveyToken")
  //     if(surveyToken_) {

  //       const surveyToken = JSON.parse(surveyToken_)
  //       const date = new Date(parseFloat(surveyToken.date))

  //       if(new Date().getDate() - date.getDate() >= 3 && surveyToken.isAnswered === false || surveyToken.isAnswered === null) { //if its 3 days and above, show survey modal

  //         localStorage.setItem("surveyToken", JSON.stringify({
  //           date: new Date().getTime().toString(),
  //           isAnswered: null
  //         }))
  //         setOpenSurvey(true)
  //       }


  //     }else {
  //       localStorage.setItem("surveyToken", JSON.stringify({
  //         date: new Date().getTime().toString(),
  //         isAnswered: null
  //       }))
  //       setOpenSurvey(true)
  //     }
      
  //   }, 10*1000);
  
  //   return () => null
  // }, [])
  
  


  return (
    <div>
    
      
      <Box sx={{ display: 'flex' }} >
        <CssBaseline />

        {showNavBar? <NavBar configs={appConfigReducer} />: "" }

        
        <Box component="main" sx={{ flexGrow: 1, pl: 2,pr: 0, pt: 0.8, pb: 3, minHeight: '100vh', position: 'relative' }}>
        {
          showNavBar?<DrawerHeader />:""
        } 
          <Grid container spacing={0} sx={{ pb: 3, pl: 0, mb: 3, mt: 0}}>
            <Grid item md={12} xs={12} >
              {/* //route outlet */}
                <Outlet context={[appConfigReducer, setAppConfigReducer]} /> 
            </Grid>

            
            
          </Grid>

          <div style={{
            position: 'absolute',
            width: '100%',
            bottom: 20
          }}>
            
            <p className=' w-100 text-center pt-2 pb-2 mb-0 pr-3' style={{fontSize: '13px', wordWrap:'break-all'}}>
              All Rights Reserved. Copyright &copy; 2023 Latom Networks.
            </p>
          </div>
        </Box>

        
        {/* <SurveyQuestionsDialog
          openDialog={openSurvey}
        /> */}
      </Box>
    </div>
  );
}
