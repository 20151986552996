
import AccessTime from '@mui/icons-material/AccessTime';
import Close from '@mui/icons-material/Close';
import LocationOn from '@mui/icons-material/LocationOn';
import MiscellaneousServices from '@mui/icons-material/MiscellaneousServices';
import Share from '@mui/icons-material/Share';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Rating from '@mui/material/Rating';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import MainDrawer from 'components/MainDrawer';
import SocialMediaShareModal from 'components/Modal/SocialMediaShareModal';
import { getCompanyStaffServiceRatingsAnalytics } from 'FirebaseController/Ratings';
import { useSnackbar } from 'notistack';
import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { getServiceTotalBookingsAnalytics, saveCompanyStaffInitiatedBookings } from '../../FirebaseController/Analytics';
import { getLocInfoFromDb } from '../../FirebaseController/Locations';
import { getBusinessDetailsFromDb, getCompanypProfileSetingsData, getProviderProfileSetingsData } from '../../FirebaseController/Settings';
import { generateProfileDynamicLink } from '../../FirebaseController/User/user';
import { APP_MANAGER_TYPES } from '../../Utils/constants';
import '../../Utils/css/override.css';
import { getHrsMins } from '../../Utils/functions';
import profile from '../../Utils/images/logo.png';


function ServiceCard(props) {
 
    

    const { isEdit, service_data, openCard, serviceProviderId, isTeamMemberProfile, companyProfileSettings,  isSelfBooking, isServicePage, disable, isFromServicePage, isBookingEnabled, isSelectProviderPage, providerData } = props
    const navigate = useNavigate()  
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(true);
    const [isBookingLoading, setIsBookingLoading] = React.useState(false);
    const [drawerCounter, setDrawerCounter] = React.useState(0);
    const [mainDrawerOpen, setMainDrawerOpen] = React.useState(false);
    const [pageType, setPageType ] = React.useState({type: 'service', isEdit: 0, updated: false})
    const duration = getHrsMins(service_data.duration)
    const [appConfigReducer, setAppConfigReducer] = useOutletContext()
    const { companyName } = useParams()
    const [location, setLocation] = React.useState({})
    const [shareLoading, setShareLoading] = useState(false)
    const [shareModalOpen, setShareModalOpen] = React.useState(false)
    const [dynamicLink, setDynamicLink] = React.useState("")
    const [serviceAnalytics, setServiceAnalytics] = React.useState(null)
    const [companySettings, setCompanySettings] = React.useState(null)
    const _providerData = providerData?providerData:appConfigReducer.data
    const [businessDet, setBusinessDet] = React.useState({})
    



    const roundBtn = {
        borderRadius: '40px',
    }
    
    
    const toggleDrawer = (_open) => {
        setMainDrawerOpen(_open)
        if(_open === false )setDrawerCounter(drawerCounter + 1)

    };

    useEffect(() => {
      
        setMainDrawerOpen(false)

        return ()=>{
            setDrawerCounter(0)
        }
    
    }, [drawerCounter])

    useEffect(()=>{
        //setOpen(openCard !== null || openCard !== undefined?openCard === true:true)
        
    }, [openCard])

    // useEffect(()=>{
    //     getBookingSlotsFromDb((data)=>{  })
    // },[])
    

    const handleBtnClick = (e)=>{
        e.preventDefault()
        localStorage.setItem("serviceData", JSON.stringify(service_data))
        localStorage.setItem("isSelfBooking", isSelfBooking?"true":"false")
       
        

        if(disable){ 
            
        }else{ 
            if(isSelfBooking){
                //place booking
                // navigate('/profile/bookings/'+btoa(serviceProviderId))
                setPageType({
                    isEdit: 0,
                    type: 'addBooking',
                    updated: true,
                    configs: appConfigReducer,
                })
            }else{
                const _url = companyName?"/co/"+companyName+"/":"/"
                // 
                // 
                if(isEdit){

                    if(isServicePage) {
                        setPageType({
                            isEdit: 1, 
                            type: 'service',
                            updated: true,
                            configs: appConfigReducer,
                        })
                    }
                }
                else if(isBookingEnabled) {
                    if("companyID" in service_data && service_data.companyID) {
                        if(isSelectProviderPage) { //if is a team member redirect to bookings page else ask user to select provider
                            // 
                            window.location.href = (_url + 'profile/bookings/'+btoa(serviceProviderId)) 

                        }else {
                            navigate(_url + 'profile/provider')
                        }
                    }else if("serviceLocation" in service_data && service_data.serviceLocation){
                        setIsBookingLoading(true)
                        getLocInfoFromDb(service_data.serviceLocation).then((data)=>{
                            
                            
                            if("locType" in data && data.locType.toLowerCase() === "onsite") {
                                /*
                                setPageType({
                                    isEdit: 0,
                                    type: 'booking_location',
                                    updated: true,
                                    isCompany: ("companyID" in service_data && service_data.companyID)?true:false,
                                    serviceProviderId: serviceProviderId,
                                    data: data
                                })
                                */
                               //
                               window.location.href = (_url + 'profile/bookings/'+btoa(serviceProviderId))
                            }else if("locType" in data && data.locType.toLowerCase() === "offline"){

                                
                                localStorage.setItem("selectedClientLocation", JSON.stringify(data))
                                window.location.href = (_url + 'profile/bookings/'+btoa(serviceProviderId)+'?isCompany='+(("companyID" in service_data && service_data.companyID) == true).toString())

                            }else if("companyID" in service_data && service_data.companyID){
                                if(isSelectProviderPage) {
                                    window.location.href = (_url + 'profile/bookings/'+btoa(serviceProviderId))
                                }else {
                                    window.location.href = (_url + 'profile/provider')
                                }
                                // navigate('/profile/provider')
                            }else {
                                window.location.href = (_url + 'profile/bookings/'+btoa(serviceProviderId))
                            }
                            setIsBookingLoading(false)
                            
                        }).catch((err)=>{
                            
                            setIsBookingLoading(false)
                        })
                    }else {
                        //place booking
                        window.location.href = (_url + 'profile/bookings/'+btoa(serviceProviderId))
                        
                        // setPageType({
                        //     isEdit: 0,
                        //     type: 'addBooking',
                        //     updated: true,
                        // })
                    }
                }
            }
        }

        
        if(isSelectProviderPage && appConfigReducer.type === APP_MANAGER_TYPES[1]) {// if is team member
            //add initiated booking

            saveCompanyStaffInitiatedBookings(appConfigReducer.data.companyID, serviceProviderId).then(()=>{
                
            })
        }

    }

    const handleShareService = (e)=>{ 
        e.stopPropagation()
        setShareLoading(true)
        if(appConfigReducer.type === APP_MANAGER_TYPES[0] 
          ||
          appConfigReducer.data && Object.keys(appConfigReducer.data).length !== 0
        ) {
            //send base 64 data

            const socialMediaData = {
              name: (_providerData.companyName || (_providerData.firstname + " " + _providerData.surname)),
              desc: (_providerData.shortCompanyDesc || _providerData.about),
              profile: (_providerData.logoUrl || _providerData.profile || profile),
            }
            const _userId_ = serviceProviderId

            console.log(_userId_)
            console.log(service_data)

            generateProfileDynamicLink(
                _userId_.toString(), 
                (appConfigReducer.type === APP_MANAGER_TYPES[1] || isSelectProviderPage), 
                isSelectProviderPage, 
                (appConfigReducer.type === APP_MANAGER_TYPES[1])?appConfigReducer.data.profileURLLink:"", 
                socialMediaData, 
                (appConfigReducer.type === APP_MANAGER_TYPES[1] && !isSelectProviderPage), 
                (service_data.serviceId || null), 
                (appConfigReducer.data.companyID || null)
            )
            .then((response)=>{
                setShareLoading(false)
                setShareModalOpen(true)
                setDynamicLink(response.data.shortLink)
                // if (navigator.share) {
                //     navigator.share({
                //     title: 'Latom Profile Share',
                //     url: response.data.shortLink
                //     });
                // } 
            }).catch((err)=>{
                    
                    setShareLoading(false)
                    showSnackBar("could not shareable generate link", "error")
                }
            )
        }else {
            setShareLoading(false)
        }
    }

    const handleCollapseOpen = (e) => {
        e.stopPropagation()
        setOpen(!open)
    }

    const handleOpenServiceProfile = (e) => {
        e.stopPropagation()
        if(appConfigReducer.type === APP_MANAGER_TYPES[0]) {
            if(_providerData["profileURLLink"]){
                navigate("/"+_providerData["profileURLLink"]+"/services/"+service_data.serviceTitle)
            }else {
                
                navigate("/"+btoa(_providerData["uid"] || _providerData["userId"])+"/services/"+service_data.serviceTitle)                
            }
        }else {
            navigate("/co/"+companyName+"/services/"+service_data.serviceTitle)
            
        }
    }

    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    useEffect(() => {
        if(pageType.updated){
            toggleDrawer(true)
        }
    
      
    }, [pageType])

    
    useEffect(() => {
        if("serviceLocation" in service_data && service_data.serviceLocation && isBookingEnabled && !isEdit){
            getLocInfoFromDb(service_data.serviceLocation)
            .then((data)=>{
                setLocation(data)
            })
        }
    
      return () => null
    }, [])

    useEffect(() => {


        const dummyFn = (data)=>new Promise((successCallBack, errorCallBack)=>{
            successCallBack(data)
        })
        
        //getting total bookings per provider
        const actionFn = (appConfigReducer.type === APP_MANAGER_TYPES[0])?
            getProviderProfileSetingsData( serviceProviderId)
            :(companyProfileSettings && Object.keys(companyProfileSettings).length > 0)?
                dummyFn(companyProfileSettings):getCompanypProfileSetingsData(appConfigReducer.data.companyID)
            
        actionFn.then((settings)=>{
                
            setCompanySettings(settings)

            if(
                (settings && !settings.hideServiceTotalBookings)
                || (settings && !settings.hideDisplayRatings)
                || !settings
            ){

                if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {

                    if(isTeamMemberProfile) {
                        getCompanyStaffServiceRatingsAnalytics(appConfigReducer.data.companyID, serviceProviderId, service_data.serviceId)
                        .then((data)=>{
                      
                            setServiceAnalytics(data)
                        })
                    }else {
                        
                        const providerId_ = appConfigReducer.type === APP_MANAGER_TYPES[0]?serviceProviderId:appConfigReducer.data.companyID
                        getServiceTotalBookingsAnalytics(service_data.serviceId, providerId_, appConfigReducer.type === APP_MANAGER_TYPES[1])
                        .then((data)=>{
                        
                            setServiceAnalytics(data)
                        })
                    }

                }else {
                    const providerId_ = appConfigReducer.type === APP_MANAGER_TYPES[0]?serviceProviderId:appConfigReducer.data.companyID
                    getServiceTotalBookingsAnalytics(service_data.serviceId, providerId_, appConfigReducer.type === APP_MANAGER_TYPES[1]).then((data)=>{
                    
                        setServiceAnalytics(data)
                    })
                }
            }
            
        }, (err)=>{
            
        })
        
    
    
      return () => null
    }, [])
    
    useEffect(() => {
      
        if(service_data.serviceProviderId) {
            getBusinessDetailsFromDb(Object.keys(service_data.serviceProviderId)[0], appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():null)
            .then((data)=>{
                setBusinessDet({
                    "currValue": data.currValue?data.currValue:"",
                })
            }).catch((err)=>{
                
            })
        }
    
      return () => null
    }, [])
    
    function BookButton() {
        return (
            <Box 
                className="d-flex w-100 "
                sx={{
                    flexDirection: {xs: 'row', md: 'column-reverse'},
                    display: {md: 'block', xs: 'flex'},
                    justifyContent: {md: 'start', xs: 'center'}
                }}
            >
                <Box sx={{
                    marginLeft: {md: '0', xs: '5px'},
                    mr: {md: 0, xs: 1},
                }}>
                
                    
                    {
                        isEdit?""
                        /*
                        <IconButton
                            onClick={handleCollapseOpen}
                        >
                            {open?<Remove />:<Add />}
                        </IconButton>
                        */
                        :
                        isBookingEnabled?
                            <div className='text-center w-100'>
                                <Button
                                    //style={{background: 'linear-gradient(270.3deg, #63C3CF 5.25%, #A68AB4 42.7%, #F1785C 102.13%)', color: "#fff", borderColor: 'transparent',fontFamily: 'Montserrat', textTransform: 'none'}} 
                                    style={{background: '#F0973B', color: "#fff", borderColor: 'transparent',fontFamily: 'Montserrat', textTransform: 'none', marginTop: '5px'}} 

                                    className=" rounded px-2 px-md-4"
                                    size="small"
                                    onClick={(e)=>handleBtnClick(e, service_data)}
                                    sx={{minWidth: {md: '64px', xs: 0},}}
                                >
                                    {
                                        isBookingLoading?
                                            <SyncLoader
                                                loading={isBookingLoading}
                                                size={10}
                                                color="#fff"
                                            />
                                            :
                                        "Book"
                                    }
                                    
                                </Button>

                            {/* <IconButton aria-label="Delete"
                                onClick={(e)=>handleBtnClick(e, (isEdit?"":service_data))}>
                                <Edit />
                            </IconButton> */}
                            </div>
                        :
                        ""
                        /*
                        <IconButton
                            onClick={handleCollapseOpen}
                        >
                            {open?<Remove />:<Add />}
                        </IconButton>
                        */
                    }
                
                </Box>

                <Box 
                    className="d-flex justify-content-center align-items-center"
                    sx={{
                        // marginLeft: {lg: '45px', xs: 0}
                    }}
                >
                    {
                        
                        (service_data.price) ? 
                            // <Tooltip title={"Total bookings: "+(serviceAnalytics?(serviceAnalytics.totalBookings || 0):0)} >
                                <span 
                                    className="py-0 mt-1 mt-md-0 mb-0 text-center" 
                                    style={{
                                        fontSize: '17px', 
                                        fontWeight: 'bold', 
                                        color: '#cc0000', 
                                        lineHeight: '17px',
                                    }}
                                >
                                    {(businessDet.currValue || "" ) + " " + service_data.price}
                                </span>
                            // </Tooltip> 
                        : 
                        <span 
                            className="py-0 mt-1 mt-md-0 mb-0 text-center" 
                            style={{
                                fontSize: '17px', 
                                fontWeight: 'bold', 
                                color: '#cc0000', 
                                lineHeight: '17px',
                            }}
                        >
                            Free
                        </span>
                    }
                </Box>

            </Box>
        )
    }

    return (


        
        <Card variant='outlined' 
             sx={{ borderRadius: '5px', borderColor: "#fff", width: '95%', boxShadow: '0 4px 8px 0 #e1e6ed, 0 6px 20px 0 #e1e6ed', minHeight: '150px'}}
            style={{...props.style, backgroundColor: '#fff'}}     
        >
            {/* <CardHeader
                sx={{p: 1.5, pb: 0.5, m:0, 
                    alignItems: 'start'
                }}
                action={
                }
                title={
                }
            /> */}

            <Box 
                sx={{p: 1.5, pb: 0.5, m:0, 
                    alignItems: 'start'
                }}
                className="d-block d-lg-flex"
            >

                {
                    (!companySettings || (companySettings && !companySettings.hideServiceLogo))?


                    
                        <Box 
                            style={{cursor: 'pointer'}}
                           onClick={handleOpenServiceProfile}
                           sx={{
                                mb: {md: 0, xs: 2}
                           }}
                           className="d-flex justify-content-center"
                        >
                            <Avatar
                                src={"serviceImg" in service_data && service_data.serviceImg?service_data.serviceImg:null }
                                sx={{ height: {md: '80px', xs: '70px'}, width:  {md: '80px', xs: '70px'},background: 'inherit'}} 
                                aria-label="profile"
                            >
                                <MiscellaneousServices color="primary"  sx={{fontSize: '50px'}}/>
                            </Avatar>
                        </Box>
                    :""
                }

                <Box className="w-100" sx={{pl : {md: 1, xs: 0},  ml: {md: .4, xs: 0}, mr: 2}}>
                    
                    <Grid container>
                        
                        <Grid item xs={12} md={9.5} className="d-block justify-content-center">
                            <h6 
                                onClick={isFromServicePage?handleBtnClick:handleOpenServiceProfile}
                                //onClick={mainDrawerOpen?null:handleBtnClick}
                                style={{color: "#000", lineHeight: '19px', fontFamily: 'Montserrat', fontWeight: 600, cursor: 'pointer', width: 'auto !important', marginBottom: 0}}
                                className="text-center text-lg-left"
                            >
                                {service_data.serviceTitle}
                                {
                                    // serviceAnalytics && Object.keys(serviceAnalytics).length > 0?

                                        <>
                                            <br/>
                                            {
                                                
                                                (!companySettings || !companySettings.hideDisplayRatings)?
                                                    <Box sx={{display: {md: 'block', xs: 'flex'} , justifyContent: {xs: 'center', md: 'left'}, }} className="d-flex">
                                                        
                                                        <Rating
                                                            name="serviceRating"
                                                            value={
                                                                serviceAnalytics?
                                                                    Math.round((serviceAnalytics.totalRatings/serviceAnalytics.totalRaters) * 10) / 10
                                                                :0
                                                            }
                                                            max={5}
                                                            size="large"
                                                            className='mt-1'
                                                            precision={0.5}
                                                            sx={{fontSize: '20px'}}
                                                            readOnly={true}
                                                        />  
                                                        {
                                                            
                                                            serviceAnalytics && serviceAnalytics.totalRaters?


                                                                <Typography variant="span" className="ml-1" sx={{marginTop: '0.30rem', fontSize: '15px', fontWeight: 'bold',  display: 'inline-flex'}}>
                                                                (
                                                                    {
                                                                        (serviceAnalytics.totalRaters)
                                                                    }
                                                                )
                                                                </Typography>
                                                            :""
                                                        }
                                                    </Box>
                                                :""
                                                
                                            }
                                            {/* {
                                                
                                                (!companySettings || (!companySettings.hideServiceTotalBookings && (serviceAnalytics?serviceAnalytics.totalBookings:    0) > 0)) ? 
                                                    <Tooltip title={"Total bookings: "+(serviceAnalytics?(serviceAnalytics.totalBookings || 0):0)} >
                                                        <span className="" style={{fontWeight: 'bold', color: '#63C3CF',  fontSize: '13px', lineHeight: '5px'}}>
                                                        Total bookings: {serviceAnalytics?(serviceAnalytics.totalBookings || 0):0}
                                                        </span>
                                                    </Tooltip> 
                                                :""
                                            } */}
                                        
                                            
                                        </>

                                    // :""
                                }
                                {
                                    location && Object.keys(location).length > 0 ?
                                        <>
                                        
                                            <Tooltip title={(location.address || location.locationName)} >
                                                <Typography variant="span" className="d-block py-2" sx={{textAlign: {xs: 'center', md: 'left'}, color: 'rgba(0, 0, 0, 0.45)', fontWeight: 'normal', fontSize: '13px', lineHeight: '5px'}}>
                                                    Location: {(location.locationName)}
                                                </Typography>
                                            </Tooltip>
                                        </>
                                    :""
                                }
                                
                            </h6>

                            
                            <Box sx={{display : {xs: 'block', md: 'none'}}} >
                                <BookButton />
                            </Box>

                            <Box
                                className='mt-0 mt-md-2'
                            >
                                <Box sx={{width: '100%'}} >
                                    <Box className='d-flex mt-1' sx={{display: {md: 'block', xs: 'flex'} , justifyContent: {xs: 'center', md: 'left'},}}>
                                        <div onClick={handleShareService} className='d-flex align-items-center pl-0 mt-0 ml-0 mb-0' style={{color: "#050505", fontSize: '14px', marginRight: '10px', cursor: 'pointer'}}>
                                            {
                                                shareLoading?
                                                    <SyncLoader
                                                        loading={shareLoading}
                                                        size={8}
                                                        color="#000"
                                                    />
                                                :
                                                <>
                                                
                                                    <Share  style={{color: "#F29D38", fontSize: '18px', marginRight: '3px'}} /> 
                                                    <span>
                                                        Share 
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <div className='d-flex align-items-center pl-0 mt-0 ml-0 mb-0' style={{color: "#050505", fontSize: '14px', marginRight: '10px'}}>
                                            <AccessTime  style={{color: "#F29D38", fontSize: '18px', marginRight: '3px'}} /> 
                                            <span>
                                            {
                                                duration["durhrs"] > 0?duration["durhrs"] + "hrs":""
                                            } 
                                            {
                                                duration["durmins"] > 0?duration["durmins"] + " mins":""
                                            } 
                                            </span>
                                        </div>
                                        <div className='d-flex align-items-center m-0' style={{color: "#050505", fontSize: '14px',fontFamily: 'Montserrat'}}>
                                            <LocationOn style={{color: "#F29D38", fontSize: '18px', marginRight :'3px'}}  /> 
                                            {"serviceType" in service_data?service_data.serviceType:"Online"}
                                        </div>
                                    </Box>
                                    
                                    <Box className="">

                                        <Collapse in={true} timeout="auto" unmountOnExit>
                                            <CardContent className="pb-2" sx={{m: 0, p: 0,}}>

                                                <Box sx={{display: {md: 'block', xs: 'flex'} , justifyContent: {xs: 'center', md: 'left'},}}>
                                                    <Typography variant="body2" gutterBottom 
                                                        className="desBox"
                                                        sx={{
                                                            fontFamily: 'Montserrat',                        
                                                            fontStyle: 'normal',
                                                            fontWeight: '500',
                                                            fontSize: '15px',
                                                            color: 'rgba(5, 5, 5, 1)',
                                                            paddingTop: '5px',
                                                        }}
                                                    >
                                                        {service_data.serviceDesc}
                                                        
                                                    </Typography>
                                                </Box>


                                                
                                            </CardContent>
                                        </Collapse>
                                    </Box>
                                </Box>
                            </Box>
                                
                        </Grid>
                        <Grid item xs={12} md={2.5} sx={{display : {md: 'block', xs: 'none'}}} >
                            <BookButton />
                        </Grid>
                    </Grid>
                </Box>

            </Box>



            <MainDrawer key={mainDrawerOpen} open={mainDrawerOpen} toggleDrawer={toggleDrawer} pageType={pageType} data={service_data} />
            <SocialMediaShareModal
                url={dynamicLink} 
                open={shareModalOpen} 
                handleClose={()=>setShareModalOpen(false)}
                img={_providerData.profile || _providerData.logoUrl || profile}
                name={appConfigReducer.type === APP_MANAGER_TYPES[1]?service_data.serviceTitle + ", " + appConfigReducer.data.companyName:service_data.serviceTitle + ", " + _providerData.firstname + " " + _providerData.surname}
            />
        </Card>
        
       
        
        
    )


}


export default memo(ServiceCard)