import { SET_NOTIF_DATA } from './notifs.types'

const initialState = {
    data: 0
  };
export const notificationReducer = (state = initialState, action)=> {

    switch (action.type) {
        case SET_NOTIF_DATA:
            return {
                ...state,
                data: action.payload
            };
        
        default:
            return state;
    }
}