import { Close } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Grid, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';
import { saveSurveyResponses } from 'FirebaseController/Analytics';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { SyncLoader } from 'react-spinners';
import { inputStyles } from 'Utils/style';

export default function SurveyQuestionsDialog(props) {

    const { openDialog } = props

    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [displayTextField, setDisplayTextField] = React.useState(false);    
    const [question, setQuestion] = React.useState(null);
    const [answers, setAnswers] = React.useState({});
    const [otherAns, setOtherAns] = React.useState("");

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const allQuestions = [
        {
            qn: "Hi there, what information would you like to know to help you make a booking? Please select all that apply",
            options: [
                "Reviews", "More details on the Doctor", "Other"
            ]
        },
    ]

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        

        setOpen(false)
    }

    const handleMaybeLater = () =>{

        if(question !== null) {
            if(question !== 0) setQuestion(question-1)
        }else {
            localStorage.setItem("surveyToken", JSON.stringify({
                date: new Date().getTime().toString(),
                isAnswered: false
            }))

            handleClose()
        }
    }

    const handleAddAnswer = (e, option) => {

        //check if selected option is others then show textfield

        if(e.target.checked === true) {
            if(option.toLowerCase() === allQuestions[question].options[allQuestions[question].options.length -1].toLowerCase()){
                
                setDisplayTextField(true)
                
            }
            setAnswers({
                ...answers,
                [question]: [...new Set([...(answers[question] || []), option])]
            })
        }else {

            const newAns = (answers[question] || [])
            if(newAns.includes(option)) {
                newAns.splice(newAns.indexOf(option), 1)
            }
            setAnswers({
                ...answers,
                [question]: newAns
            })

        }
    }

    


    const handleContinue = () => {
        if(question !== null) {
            if(question === (allQuestions.length - 1)) {
                //submit to db
                if(Object.values(answers).length <= 0) {
                    showSnackBar("Please provide an answer to atleast one question to submit", "error")
                }
                else if (answers[0].includes("Other") && !otherAns) {
                    showSnackBar("Please specify other", "error")
                }
                else {
                    setIsLoading(true)
                    const newAnswer = {}
                    Object.keys(answers).forEach((key)=>{
                        if(answers[key].includes("Other")) {
                            answers[key].splice(answers[key].indexOf("Other"), 1)
                            answers[key].push(otherAns)
                        }
                        newAnswer["question" + (parseInt(key) + 1)] = {
                            qn: allQuestions[key].qn,
                            ans: [...new Set(answers[key])]
                        }
                    })
                    
                    const userId = (user)? user.uid: window.crypto.randomUUID().split('-').join('')
                    newAnswer["userId"] = userId
                    newAnswer["answerId"] = new Date().valueOf()
                    newAnswer["createdAt"] = new Date().valueOf()
                    

                    
                    saveSurveyResponses(userId, newAnswer).then(()=>{
                        
                        setIsLoading(false)
                        localStorage.setItem("surveyToken", JSON.stringify({
                            date: new Date().getTime().toString(),
                            isAnswered: true
                        }))

                        handleClose()
                        showSnackBar("Survey submitted successfully, thankyou for your time", "success")
                    }).catch((err)=>{
                        setIsLoading(false)
                        
                        showSnackBar("Sorry could not submit your response, try again later", "error")
                    })
                    
                }


            }else {
                setQuestion(question + 1)
            }
        }else {
            setQuestion(0)
        }
    }

    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    React.useEffect(() => {
      setOpen(openDialog)
    }, [openDialog])
    

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="w-100 d-flex pt-1 px-1">
                    <div style={{flex: 1}}></div>
                    <IconButton onClick={()=>handleClose(false)}>
                        <Close />
                    </IconButton>
                </div>
                
                <DialogContent sx={{pt: 0, mt: 0}}>

                    <Box>
                        {question === null?
                            <>
                                <h5 className='text-center pb-2' style={{fontSize: '18px'}}>
                                    Hello, would you like to take a minute to answer a survey question?
                                </h5>
                                
                            </>
                            :
                            <>

                                <div>
                                    <p>
                                        {
                                            allQuestions[question].qn
                                        }
                                    </p>

                                    <Grid container>
                                        {/* <FormControl> */}
                                                {
                                                    allQuestions[question].options.map((option, idx)=>
                                                    (
                                                        <Grid item md={6} xs={12} key={idx}>
                                                            <FormControlLabel                                                                 
                                                                control={<Checkbox 
                                                                    //name={option} 
                                                                    onChange={(e)=>handleAddAnswer(e, option)}
                                                                    checked={(answers[question] && answers[question].includes(option)) || false}
                                                                    //checked={false}
                                                                />} 
                                                                label={<span style={{fontSize: '14px'}}>{option}</span>} 
                                                            />
                                                        </Grid>
                                                    )
                                                    
                                                    )
                                                }


                                                {
                                                    displayTextField?

                                                        <input
                                                            style={inputStyles}
                                                            className="mt-2"
                                                            id={"others"}
                                                            // name={item.name} 
                                                            placeholder={"Enter text here"}
                                                            required = {true}
                                                            value={(otherAns || "")}
                                                            onChange={(e)=>setOtherAns(e.target.value)}
                                                            type={"text"}
                                                        />

                                                    :""
                                                }
                                        {/* </FormControl> */}
                                        
                                    </Grid>
                                </div>
                            
                            </>
                        }
                        
                        <div style={{margin: '0'}} className='text-center justify-content-center my-3'>
                            {
                                question === 0?"":
                                <Button 
                                    variant='contained'
                                    className="mr-2 mb-2 mb-md-0  rounded border-0"
                                    style={{color: "#fff", textTransform: 'none', background: '#000',}}
                                    // disabled={isLoading} 
                                    sx={{width: {md: 'auto', xs: '70%'}}}
                                    color="primary"
                                    onClick={handleMaybeLater}
                                >
                                    {question !== null?"Previous": "Maybe later"}
                                </Button>
                            }
                            <Button 
                                variant='contained'
                                className="rounded border-0"
                                style={{color: "#fff", textTransform: 'none', background: '#F0973B',}}
                                // disabled={isLoading} 
                                color="primary"
                                sx={{width: {md: 'auto', xs: '70%'}}}
                                onClick={handleContinue}
                            >
                                {
                                    isLoading?
                                        <SyncLoader
                                            loading={isLoading}
                                            size={10}
                                            color="#fff"
                                        />
                                    :
                                    question !== null?
                                        question === allQuestions.length - 1 ? "Submit"
                                        :"Next"
                                    :
                                    "Continue"
                                }
                                
                            </Button>
                        </div>
                    </Box>
                    
                </DialogContent>

            </Dialog>
        </div>
    );
}
