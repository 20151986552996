import { Close } from '@mui/icons-material';
import { FormControl, Grid, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { deleteCoupon, saveCoupon, updateCoupon } from 'FirebaseController/StripePayments';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { APP_MANAGER_TYPES } from 'Utils/constants';
import 'Utils/css/coupon.css';
import { inputStyles } from 'Utils/style';
import '../../Utils/css/invoice_date_picker.css';

export default function CouponForm(props) {

    const { isEdit, data, configs} = props
    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    const appConfigReducer = configs
    const navigate = useNavigate()
    const { companyName }  = useParams()
    const [coupon, setCoupon] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    // const [businessDet, setBusinessDet] = useState({})
    
    
    const formInputs = [
        {
            name: "couponCode",
            required: true,
            holder: 'Code',
        }, 
        {
            name: "discountAmount",
            required: true,
            holder: 'Discount rate',
            type: "number",
            min: 0,
            max: 100,
        }, 

    ]


    const handleFormData = (name, value)=>{
        if(name === 'discountAmount' && ((value) < 0 || value > 100)) {
            showSnackBar("Invalid discount rate. Discount rate can only range between 0 to 100", "error")
        }else {
            setCoupon({
                ...coupon,
                [name] : name === "couponCode"?value.toUpperCase():value,
            })
        }
    }

    const handleDateData = (name,value)=>{
        setCoupon({
            ...coupon,
            [name]: parseFloat(new Date(value).valueOf())
        })
    }
    
    

    const handleCouponDelete = () => {
        setIsDeleteLoading(true)
        deleteCoupon(            
            coupon.couponId,
            appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
            (appConfigReducer.type === APP_MANAGER_TYPES[1])
        )
        .then(()=>{
            setIsDeleteLoading(false)
            showSnackBar("Coupon deleted successfully")
            const url =  appConfigReducer.type === APP_MANAGER_TYPES[0]?"/payments":"/co/"+companyName+"/payments"
            window.location.href = (url)
        })
        .catch((err)=>{
            setIsDeleteLoading(false)
            
            showSnackBar("Sorry, could not delete coupon, try again later.")
        })
    }

    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    const handleCouponAdd = () => {
        if (!coupon.couponName) {
            showSnackBar("Please enter coupon title.", "error")
        } else if(!coupon.discountAmount) {
            showSnackBar("Please enter coupon discount amount.", "error")
        }else if(!coupon.startDate || !coupon.endDate) {
            showSnackBar("Please enter coupon start and end duration.", "error")
        } else if(coupon.couponCode && (coupon.couponCode.length < 5 || coupon.couponCode.length > 10)) {
            showSnackBar("Coupon code can only have 5-10 characters.", "error")
        }  else {
            
            if(!coupon.couponCode) {
                coupon.couponCode = generateCode(6)
            }

            const data_ = {
                ...coupon,
                "couponId": (coupon.couponId || window.crypto.randomUUID().split('-').join('')),
                "createdAt": coupon.createdAt?parseFloat(coupon.createdAt):parseFloat(new Date().valueOf()),
                "updatedAt": parseFloat(new Date().valueOf()),
                "providerId": user.uid
            }
            
            if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
                
                data_["companyId"] = appConfigReducer.data.companyID.toString()
            }


            const editData = {
                "couponName" : data_["couponName"],
                "discountAmount" : (data_["discountAmount"] || 0),
                "updatedAt" : data_["updatedAt"],
            }

            const ref = isEdit === 0?
                saveCoupon(
                    data_, 
                    appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
                    (appConfigReducer.type === APP_MANAGER_TYPES[1])
                )
            :isEdit === 1?
                updateCoupon(
                    editData, 
                    coupon.couponId,
                    appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
                    (appConfigReducer.type === APP_MANAGER_TYPES[1])
                )
            :null

            if(ref) {
                setIsLoading(true)
                const txt = isEdit === 0?"added":"updated"
                ref.then(()=>{
                    showSnackBar("Coupon "+txt+" successfully.", "success")   
                    setIsLoading(false) 
                    const url =  appConfigReducer.type === APP_MANAGER_TYPES[0]?"/payments":"/co/"+companyName+"/payments"
                    // dispatch(refreshServices())
                    navigate(url)                   
                })
                .catch((err)=>{
                    
                    const errMsg = (err === "exists")?"Sorry, coupon code is already in use.":"Sorry, could not "+txt+" coupon, try again later."
                    showSnackBar(errMsg, "error")
                    setIsLoading(false)
                })
            }


            
        }
    }

    function generateCode(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result.toUpperCase();
    }

    useEffect(() => {
      if(data) {
        setCoupon(data)
      }
    
      return () => null
    }, [data])
    
      
    // useEffect(() => {
      
    //     getBusinessDetailsFromDb(user.uid, appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():null)
    //     .then((data)=>{
    //         setBusinessDet({
    //             "currValue": data.currValue?data.currValue:"",
    //         })
    //     }).catch((err)=>{
    //         
    //     })
    
    //   return () => null
    // }, [])
    

    return (
        
        <Grid container spacing={1} className='px-4 pb-4 my-3'>
 

            

            <Grid item xs={12}>
                <div className='border p-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '10px'}}>
                    <label htmlFor='couponName'>
                       {"Coupon name"} 
                    </label><br />
                    
                    <textarea id="couponName" style={{
                            borderRadius: '5px',
                            background: 'transparent',
                            border: 'none',
                            padding: '5px',
                            width: '100%',
                            resize: 'none'
                        }} 
                        type="text" 
                        name="couponName" 
                        placeholder='Enter text here'
                        value={coupon["couponName"]?coupon["couponName"]:""}
                        rows="1"
                        cols="50"
                        onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                    ></textarea>

                </div>
            </Grid>
            {
                formInputs.map((item, idx)=>
                    <Grid item md={12} xs={12} key={item.name} style={{marginTop: '0',}}>
                        <div className=''>
                                                        
                            <label >
                                
                                {/* {item.holder} { item.name === "discountAmount" && businessDet.currValue? "("+businessDet.currValue+")" : "" } */}
                                {item.holder} { item.name === "discountAmount"? "(%)" : "" }
                            </label>
                            {
                                item.name === "couponCode"?

                                    <div className='mb-0 pb-0' style={{fontSize: '14px',color: "#999" , fontWeight: 300}}>
                                        This will identify this coupon. We recommend leaving this blank so we can generate a code for you.
                                    </div>
                                :''
                            }
                        </div>

                        

                        <>                                   

                            
                            <input
                                style={inputStyles}
                                id={item.name}
                                name={item.name} 
                                // placeholder={item.holder}
                                required = {item.required}
                                value={
                                    coupon && item.name in coupon?
                                    coupon[item.name]
                                    :""
                                }
                                onChange={(e)=>handleFormData(e.target.name, e.target.value)}
                                type={"type" in item?item.type:"text"}
                                min={item.min || 0}
                                max={item.max || 0}
                            />
                        
                        
                        </>
                        
                    </Grid>
                )

                
            }
            <Grid item xs={12} md={6}>
                <div className='d-flex'>
                                                
                    <label >
                        
                        Date from
                    </label>
                </div>

                <FormControl fullWidth={true} htmlFor="startDate"  >
                    <DatePicker


                        selected={coupon["startDate"]?new Date(coupon["startDate"]):new Date()} 
                        onChange={(val)=>handleDateData("startDate",val)} 
                        id="startDate"
                        // value={coupon["startDate"]?new Date(coupon["startDate"]):""} 
                        // onChange={(val)=>handleDateData("startDate",val)} 
                        // style={{width: '100%'}}
                        // id="startDate"
                        // minDate={new Date()}
                        // name="Date from"
                        // clearIcon={null}
                        // calendarIcon={<DateRange color="primary" />}
                        // isOpen={false}
                        // disabled={false}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className='d-flex'>
                                                
                    <label htmlFor="endDate" >
                        
                        Date to
                    </label>
                </div>
                <FormControl fullWidth variant="standard">
                    <DatePicker


                        selected={coupon["endDate"]?new Date(coupon["endDate"]):new Date()} 
                        onChange={(val)=>handleDateData("endDate",val)} 
                        id="endDate"
                        // value={coupon["endDate"]?new Date(parseFloat(coupon["endDate"])):""} 
                        // onChange={(val)=>handleDateData("endDate",val)} 
                        // style={{width: '100%'}}
                        // id="endDate"
                        // minDate={new Date()}
                        // name="Date to"
                        // clearIcon={null}
                        // calendarIcon={<DateRange color="primary" />}
                        // isOpen={false}
                        // disabled={false}
                    />
                </FormControl>
            </Grid>
            
            <Grid item xs={12} className="d-flex">
                <div style={{flex: 1}}></div>                
                <Button
                    className={"rounded border-0 my-4 ml-4 text-capitalize"}
                    color="white"
                    type="button" 
                    style={{background: '#F0973B',
                        
                    }}
                    onClick={handleCouponAdd}
                >
                    {
                        
                        isLoading?
                            <SyncLoader
                                loading={isLoading}
                                size={10}
                                color="#fff"
                            />
                        :
                        "Save"
                    }
                    
                    
                </Button>

                {
                    isEdit === 4?

                        <Button
                            className={"rounded border-0 my-4 ml-2 text-capitalize"}
                            color="white"
                            type="button" 
                            style={{background: '#000',
                                
                            }}
                            onClick={handleCouponDelete}
                        >
                            {
                                
                                isDeleteLoading?
                                    <SyncLoader
                                        loading={isDeleteLoading}
                                        size={10}
                                        color="#fff"
                                    />
                                :
                                "Delete"
                            }
                            
                            
                        </Button>

                    :""

                }
                
                
            </Grid>

            
            
            
            
            

        </Grid>

    )

}