import Close from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import DivCenterContents from 'components/DivCenterContents';
import SurveyQuestionsDialog from 'components/Modal/SurveyModal';
import { getSubscriptionSettings, saveSubscriptionSettings } from 'FirebaseController/Settings';
import { getSubscriptionPlanInfo } from 'FirebaseController/StripePayments';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Outlet, useNavigate, useParams
} from 'react-router-dom';
import { setSubscription } from 'Store/Reducers/Subscription/subscriptions.actions';
import NavBar from '../../components/NavBar';
import { getCompanyAdmin, getCompanyStaffMembers, getStaffMember, makeFakeCallToGetFirestoreStartedUp, searchCompanyProfileLink } from '../../FirebaseController/Company';
import { setProfile } from '../../Store/Reducers/User/user.actions';
import { APP_MANAGER_TYPES, STAFF_TYPE } from '../../Utils/constants';
import { baseFirebaseApiUrl } from 'AppSettings'
import logo_loader from '../../Utils/images/loader-logo.png';
// import DemoNavbar from 'components/Navbars/DemoNavbar';


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function CompanyTheme(props) {
  const { showNavBar } = props
  const { companyName } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userObj = JSON.parse(localStorage.getItem("user"))
  const user = userObj?userObj.user:null
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isCompanyLoading, setIsCompanyLoading] = useState(true)
  const [companyAdmin, setCompanyAdmin] = useState({})
  const [openSurvey, setOpenSurvey] = useState(false)  
  const [appConfigReducer, setAppConfigReducer] = useState(
    {
      data: {},
      type: APP_MANAGER_TYPES[1]
    }
  )
  



  function showSnackBar(msg, variant = 'success'){
    enqueueSnackbar(msg, {
        variant: variant,            
        action: (key) => (
            <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                <Close />
            </IconButton>
        ),
  })}

  useEffect(() => {
    
    setIsCompanyLoading(true)

    searchCompanyProfileLink(companyName)
    .then((data)=>{
      console.log("data", data)
      if(data.length  > 0) {
        setAppConfigReducer({
          ...appConfigReducer,
          data: data[0]
        })
        
        //get staff member data if any
        
        // getCompanyStaffMembers(data[0].companyID).then((staffData)=>{
          // const list = staffData.map(item=>({
          //   ...item,
          //   userId: item.userId || item.uid
          // }))

          // setCompanyAdmin(list.filter(item=>item.memberRole === STAFF_TYPE[2])[0])

          if(user && data.length > 0) {
            getStaffMember(user.uid, data[0].companyID).then((currentStaff)=>{
              console.log("currentStaff", currentStaff)
              if(currentStaff.length > 0 && "firstname" in currentStaff[0] && "surname" in currentStaff[0]) {
                currentStaff[0]["uid"] = currentStaff[0]["userId"]
                dispatch(setProfile(currentStaff[0]))
              }
            }).catch((err)=>{
              
              setIsCompanyLoading(false)
            })
          }else {
            setIsCompanyLoading(false)
          }
        // }).catch((err)=>{
          
        //   setIsCompanyLoading(false)
        // })
        

        //show survey for lyef company
        

        /*


        //survey lyef
        if(data[0].companyID.toString() === "1656927746402") {
          setTimeout(() => {

            const surveyToken_ = localStorage.getItem("surveyToken")
            if(surveyToken_) {

              const surveyToken = JSON.parse(surveyToken_)
              const date = new Date(parseFloat(surveyToken.date))

              if(new Date().getDate() - date.getDate() >= 3 && surveyToken.isAnswered === false || surveyToken.isAnswered === null) { //if its 3 days and above, show survey modal

                localStorage.setItem("surveyToken", JSON.stringify({
                  date: new Date().getTime().toString(),
                  isAnswered: null
                }))
                setOpenSurvey(true)
              }


            }else {
              localStorage.setItem("surveyToken", JSON.stringify({
                date: new Date().getTime().toString(),
                isAnswered: null
              }))
              setOpenSurvey(true)
            }
            
          }, 10*1000);
        }
        */


      }else {
        showSnackBar("Invalid url/link, please recheck the same.", "error")
        // navigate('/')
      }
      setIsCompanyLoading(false)


    }).catch((err)=>{
      
      console.log("err", err)
      setIsCompanyLoading(false)
      showSnackBar("Invalid url/link, please recheck the same.", "error")
      navigate('/')
    })
    // //localStorage.setItem("manager_type", APP_MANAGER_TYPES[1].toString())//SAVE MANAGER TYPE TO COMPANY
    // const data = localStorage.getItem("company")?JSON.parse(localStorage.getItem("company")):null
    // if(data) dispatch(setAppManagerConfig(data, APP_MANAGER_TYPES[1] ))//SET REDUX MANAGER TO COMPANY
    return () => null
  }, [])

 
  
  
  useEffect(()=>{

    //initiliaze firestore as page loads
    makeFakeCallToGetFirestoreStartedUp().then(()=>console.log("initialized")).catch(console.log)

  }, [])


  useEffect(() => { 
    if(Object.keys(companyAdmin).length > 0) {
      getSubscriptionSettings(companyAdmin.userId).then((subscription)=>{

        
        const type = (subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) > new Date())
        ?subscription.subscriptionType:"Free"


        if((subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) < new Date())) {
          //get subscription from chargebee
          const headers = {
            "Content-Type": "application/json",
          }
          axios.get(
                baseFirebaseApiUrl + "/getSubscription?subscriptionId="+subscription.subscriptionId,
                {headers:headers}
            )
            .then((data)=>{

              if(subscription.currentTermEnd !== data.subscription.current_term_end && new Date(parseFloat(data.subscription.current_term_end)) > new Date()){
                type = subscription.subscriptionType
                run()

                saveSubscriptionSettings(
                  {
                    currentTermEnd: data.subscription.current_term_end,
                    currentTermStart: data.subscription.current_term_start,
                    status: data.subscription.status,
                    updatedAt: data.subscription.updated_at,
                  },
                  user.uid
                )
                //update realtime db
              }else {
                run()
              }

            })
            .catch((err)=>{
                
                run()
            })
        }else {
          run()
        }
        

        function run() {
          //get plan
          getSubscriptionPlanInfo(type).then((plans)=>{
            
              if(plans.length > 0) {
                  dispatch(setSubscription(plans[0], subscription))
              }else {
                  
              }
          }).catch((err)=>{
            
            
          })
        }

      }).catch((err)=>{
          
      })
    }
    return () => null
  }, [companyAdmin])
  
  useEffect(()=>{
    if(appConfigReducer.data.companyID) {
      getCompanyAdmin(appConfigReducer.data.companyID).then((data)=>{
        if(data.length > 0) setCompanyAdmin(data[0])
      })
    }
  }, [appConfigReducer.data])


  return (
    <div>
    
      {
        
        isCompanyLoading?        
          <DivCenterContents style={{height: '100vh'}}>
            {/* <Typography variant='h4'> {AppName} </Typography>
            <SyncLoader loading={loading} /> */}
            <Avatar src={logo_loader} sx={{width: '90px', height: '90px'}} />
          </DivCenterContents>
        :
        <Box sx={{ display: 'flex' }} >
          <CssBaseline />

          {showNavBar? <NavBar  configs={appConfigReducer} />: "" }

          <Box component="main" sx={{ flexGrow: 1, pl: {md: 2, xs: .7},pr: {md: 0, xs: 1}, pt: 0.8, pb: 3, minHeight: '100vh', position: 'relative' }}>
          {
            showNavBar?<DrawerHeader />:""
          } 
            <Grid container spacing={0} sx={{ pb: 3, pl: 0, mb: 5}}>
              <Grid item md={12} xs={12} >
                {/* //route outlet */}
                
                <Outlet context={[appConfigReducer, setAppConfigReducer]} />  
                
              </Grid>

              
              
            </Grid>

            <div style={{
              position: 'absolute',
              width: '100%',
              bottom: 10
            }}>
              
              <p className=' w-100 text-center pt-md-4 pt-2 pb-2 mb-0 mt-1 mt-md-5 pr-3' style={{fontSize: '13px', wordWrap:'break-all'}}>
                All Rights Reserved. Copyright &copy; 2023 Latom Networks.
              </p>
            </div>
          </Box>

          
        <SurveyQuestionsDialog
          openDialog={openSurvey}
        />
        </Box>
      }
    </div>
  );
}
