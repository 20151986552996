import { SET_SUBSCRIPTION } from './subscriptions.types'

export const setSubscription = (data, settings = {}) => (dispatch) => {

    
    dispatch({
        type: SET_SUBSCRIPTION,
        payload: data,
        settings: settings
    })

}  