import axios from "axios";
import { endAt, endBefore, equalTo, getDatabase, limitToLast, onValue, orderByChild, query, ref, remove, set, update } from "firebase/database";
import { NOTIFICATION_STATUS } from "../../Utils/constants";
import { baseFirebaseApiUrl } from 'AppSettings'
import { convertTimeZone, formatDateToDateTimeStr } from "../../Utils/functions";

const db = getDatabase()
export const addNotif = (userId, notif) => new Promise(function(successCallBack, errorCallback){
    
    const notifRef = ref(db, 'Notifications/' + userId + "/" + notif.notifId)
    
    set(notifRef, notif).then(successCallBack).catch(errorCallback)
})


  
export const getAllNotifs = (userId, startDate, isNext = true, pageLimit = 10) => new Promise(function(successCallBack, errorCallback){
    
    
    
    
    // var d = new Date();
    // d.setHours(0,0,0,0);
    // const days_3_ago = addDays(d, -3)
    // const controller = isNext?endAt(startDate):startAt(startDate)
    const controller = endBefore(parseFloat(startDate))
    // const limiter = isNext?limitToLast(pageLimit):limitToFirst(pageLimit)
    const limiter = limitToLast(pageLimit)
    const searchRef = query(ref(db, 'Notifications/' + userId),orderByChild('notifId'), controller, limiter)
    onValue(searchRef, (snapshot) => {
        if(snapshot.exists()) {
            const notifs = []
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key
                const childData = childSnapshot.val()
                notifs.push(childData)
            });
            successCallBack(notifs)
        }else {
            successCallBack([])
        }
    }, errorCallback)


})


export const getUnreadLocalNotifs = (userId) => new Promise(function(successCallBack, errorCallback){
    
    // const notifRef = ref(db, 'Notifications/' + userId)
    // 
    // 
    const headers = {
        "Content-Type": "application/json",
    }
    axios.get(`${baseFirebaseApiUrl}/searchDataFromRealTime/?path=Notifications/${userId}&childName=status&searchValue=${NOTIFICATION_STATUS[1]}`, {headers:headers})
        .then((data)=>{
            
            successCallBack( data.data  )

        })
        .catch(errorCallback) 
    // const searchRef = query(ref(db, 'Notifications/' + userId),orderByChild('status'), equalTo(NOTIFICATION_STATUS[1]))
    // onValue(searchRef, (snapshot) => {
    //     if(snapshot.exists()) {
    //         const notifs = []
    //         snapshot.forEach((childSnapshot) => {
    //             const childKey = childSnapshot.key
    //             const childData = childSnapshot.val()
    //             notifs.push(childData)
    //         });
    //         successCallBack(notifs  )
    //     }else {
    //         successCallBack([])
    //     }
    // }, errorCallback)


})

export const markNotifsAsRead = (userId, notifId) => new Promise(function(successCallBack, errorCallback){
    
    const notifRef = ref(db, 'Notifications/' + userId + "/"+ notifId)
    update(notifRef, {status: NOTIFICATION_STATUS[0]}).then((res)=>{
        
        successCallBack(res)
    }).catch(errorCallback);

})


export const deleteOldReadNotifs = (userId) => new Promise(function(successCallBack, errorCallback){
    //delete notifs older than one week and have been read.
    const date = new Date()
    date.setDate(date.getDate() - 7);
    const _date = formatDateToDateTimeStr(convertTimeZone(date, "UTC"))
    const searchRef = query(ref(db, 'Notifications/' + userId),orderByChild('date'), endAt(_date))
    onValue(searchRef, (snapshot) => {
        if(snapshot.exists()) {
            const newData = {}
            snapshot.forEach((childSnapshot) => {
                const notif = childSnapshot.val()
                if(notif["status"] === NOTIFICATION_STATUS[0]) {
                    newData["/"+ notif["notifId"]] = null
                }
            });
            const notifRef = query(ref(db, 'Notifications/' + userId))
            update(notifRef, newData).then(()=>{
                successCallBack("Notifications deleted")
            })
            .catch(errorCallback)
        }else {
            successCallBack("Notifications deleted")
        }
    }, errorCallback)


})


export const deleteBookingNotifs = (userId, bookingId) => new Promise(function(successCallBack, errorCallback){
    //delete notifs older than one week and have been read.
    const headers = {
        "Content-Type": "application/json",
    }
    axios.get(`${baseFirebaseApiUrl}/searchDataFromRealTime/?path=Notifications/${userId}&childName=bookingOrderId&searchValue=${bookingId.toString()}`, {headers:headers})
        .then((data)=>{
            
            if( data.data.length > 0  ) {
                data.data.forEach((notif) => {
                    
                    remove(ref(db, "Notifications/"+userId+"/"+notif.notifId)).then(()=>{
                        successCallBack("Notifications deleted")
                    })
                    .catch(errorCallback)
                })
            }else {
                successCallBack("Notifications deleted")
            }

        })
        .catch(errorCallback) 

    // const searchRef = query(ref(db, 'Notifications/' + userId),orderByChild('bookingOrderId'), equalTo(bookingId.toString()))
    // onValue(searchRef, (snapshot) => {
    //     if(snapshot.exists()) {
    //         snapshot.forEach((childSnapshot) => {
    //             let notif = childSnapshot.val()
                
    //             remove(ref(db, "Notifications/"+userId+"/"+notif.notifId)).then(()=>{
    //                 successCallBack("Notifications deleted")
    //             })
    //             .catch(errorCallback)
    //         });
    //     }else {
    //         successCallBack("Notifications deleted")
    //     }
    // }, errorCallback)


})


