import { combineReducers } from 'redux';
import { bookingsReducer } from './Bookings/bookings.reducer';
import { eventsReducer } from './Events/events.reducer';
import { titleReducer } from './NavBarTitle/title.reducer';
import { servicesReducer } from './Services/services.reducer';
import { userReducer } from './User/user.reducer';
import { locationsReducer } from './Location/locatons.reducer';
import { appConfigReducer } from './appConfigsController/config.reducer';
import { companyReducer } from './Company/company.reducer'
import { notificationReducer } from './Notification/notifs.reducer'
import { staffReducer } from './StaffMembers/staff.reducer'
import { crmReducer } from './Crm/crm.reducer'
import { subscriptionsReducer } from './Subscription/subscriptions.reducer'


export default combineReducers({

    titleReducer: titleReducer,
    userReducer: userReducer,
    servicesReducer: servicesReducer,
    bookingsReducer: bookingsReducer,
    eventsReducer: eventsReducer,
    locationsReducer: locationsReducer,
    companyReducer: companyReducer,
    appConfigReducer: appConfigReducer,
    notificationReducer: notificationReducer,
    staffReducer: staffReducer,
    crmReducer: crmReducer,
    subscriptionsReducer: subscriptionsReducer,
});