import React, { lazy } from 'react'
import { Navigate } from 'react-router'
// import LoginForm from "../views/Auth/login"
//import lazy from 'components/hoc/RouterAsyncHoc'

import MainTheme from "../Themes/MainTheme"
import FormTheme from "../Themes/FormTheme"
import CompanyTheme from "../Themes/CompanyTheme"
// import ProfilePage from "../views/Profile"
// import RoundRobinTheme from 'Themes/RoundRobinTheme'
// import RoundRobinPage from 'views/RoundRobin'

//lazy loading to reduce loading time

const RoundRobinTheme = lazy(() => {
    return import("../Themes/RoundRobinTheme");
});


const LoginForm = lazy(() => {
    return import("../views/Auth/login");
});

const ProfilePage = lazy(() => {
    return import("../views/Profile");
});
const RoundRobinPage = lazy(() => {
    return import("../views/RoundRobin");
});


const DashBoardPage = lazy(() => {
    return import("../views/Dashboard");
});
const BookingsPage = lazy(() => {
    return import("../views/Bookings");
});
const PageNotFound = lazy(() => {
    return import("../views/PageNotFound");
});
const EditPage = lazy(() => {
    return import("../views/Auth/edit");
});
const ServicesPage = lazy(() => {
    return import("../views/Services");
});


const SettingsPage = lazy(() => {
    return import("../views/Settings");
});
const CompanyPage = lazy(() => {
    return import("../views/Companies");
});
const TeamMembersPage = lazy(() => {
    return import("../views/TeamMembers");
});
const AccountSettingsPage = lazy(() => {
    return import("../views/AccountSettings");
});
const GeneralSettingsPage = lazy(() => {
    return import("../views/GeneralSettings");
});
const BookingAvailabilitySettingsPage = lazy(() => {
    return import("../views/BookingAvailabilitySettings");
});
const ConfirmBooking = lazy(() => {
    return import("../views/Bookings/confirmBooking");
});
const SelectServiceProvider = lazy(() => {
    return import("../views/Bookings/selectServiceProvider");
});
const ProfileLinkPage = lazy(() => {
    return import("../views/Auth/profileLink");
});
const SelectProviderService = lazy(() => {
    return import("../views/Bookings/selectProviderServices");
});
const ViewBooking = lazy(() => {
    return import("../views/viewBooking");
});
const AnalyticsPage = lazy(() => {
    return import("../views/Analytics");
});
const EditNameSettings = lazy(() => {
    return import("../views/EditNameSettings");
});
const CRMDashboard = lazy(() => {
    return import("../views/CRM/index");
});
const ViewCrmContact = lazy(() => {
    return import("../views/CRM/viewContact");
});
const AllMyBookings = lazy(() => {
    return import("../views/AllMyBookings");
});
const RatingPage = lazy(() => {
    return import("../views/Ratings");
});
const BookingCalendar = lazy(() => {
    return import("../views/BookingCalendar");
});
const InvoicesPage = lazy(() => {
    return import("../views/Invoices");
});
const AddInvoicePage = lazy(() => {
    return import("../views/Invoices/addInvoices");
});

const CategoryProfile = lazy(() => {
    return import("../views/Profile/categoryProfile");
})

const ServiceProfile = lazy(() => {
    return import("../views/Profile/ServiceProfile");
})


const StripePaymentPage = lazy(() => {
    return import("../views/StripePaymentPage");
})


const SubscriptionSetupPage = lazy(() => {
    return import("../views/SubscriptionSetupPage");
})


const PaymentPage = lazy(() => {
    return import("../views/Payments");
})


export const routes = (isLoggedIn, companyName = "", userRedirect = "")=> [
    // A route object has the same properties as a <Route>
    // element. The `children` is just an array of child routes.
    {
        path: '/',
        element: isLoggedIn?<MainTheme showNavBar={true} />:<Navigate to={userRedirect?userRedirect:"/auth/login"} replace />,
        children: [
            { path: '/', element: <DashBoardPage /> },
            { path: '/companies', element: <CompanyPage /> },
            { path: '/members', element: <TeamMembersPage /> },
            { path: '/services', element: <ServicesPage  isSelfBooking={false} /> },
            { path: '/services/self-booking', element: <ServicesPage isSelfBooking={true} /> },
            // { path: '/register-page', element: <Register /> },
            { path: '/view-booking', element: <ViewBooking /> },
            { path: '/view-booking/:bookingId', element: <ViewBooking /> },
            { path: '/settings', element: <SettingsPage /> },
            { path: '/all-bookings', element: <AllMyBookings /> },
            { path: '/account-settings', element: <AccountSettingsPage /> },
            { path: '/crm', element: <CRMDashboard /> },
            { path: 'crm/:encodedContactId', element: <ViewCrmContact /> },  
            { path: '/ratings', element: <RatingPage /> },
            { path: '/calendar', element: <BookingCalendar /> },   
            { path: '/payments', element: <PaymentPage /> },                        
            { path: '/add-subscription/:subscriptionType/:itemPriceType', element: <SubscriptionSetupPage /> },                        
            { path: '/add-payments/:bookingId/:encodedClientId/:encodedPrice', element: <StripePaymentPage /> },            
            { path: '/invoices', element: <InvoicesPage /> },          
            { path: '/invoices/add/:pageType', element: <AddInvoicePage /> },    
            { path: 'invoices/add/:pageType/:invoiceID', element: <AddInvoicePage /> },   
            { path: 'invoices/add/:pageType/:invoiceID/:duplicate', element: <AddInvoicePage /> },
            { path: '/profile/category/:catTitle', element: <CategoryProfile /> },    
            { path: '*', element: <Navigate to="/404" replace /> }
        ]
    },
    {
        path: 'co/:companyName',//company
        element: <CompanyTheme showNavBar={true} />,
        children: [
            { path: '/co/:companyName/home', element: isLoggedIn?<DashBoardPage />:<Navigate to={companyName?"/co/"+companyName+"/auth/login":"/auth/login"} replace /> },
            { path: 'members', element: isLoggedIn?<TeamMembersPage />:<Navigate to={companyName?"/co/"+companyName+"/auth/login":"/auth/login"} replace /> },
            { path: 'services', element: isLoggedIn?<ServicesPage  isSelfBooking={false} />:<Navigate to={companyName?"/co/"+companyName+"/auth/login":"/auth/login"} replace /> },
            { path: 'services/self-booking', element: isLoggedIn?<ServicesPage isSelfBooking={true} />:<Navigate to={companyName?"/co/"+companyName+"/auth/login":"/auth/login"} replace /> },
            // { path: 'register-page', element: isLoggedIn?<Register />:<Navigate to={companyName?"/co/"+companyName+"/auth/login":"/auth/login"} replace /> },
            // { path: '/profile-page', element: <Profile /> },
            { path: 'settings', element: isLoggedIn?<SettingsPage />:<Navigate to={companyName?"/co/"+companyName+"/auth/login":"/auth/login"} replace /> },
            { path: 'account-settings', element: isLoggedIn?<AccountSettingsPage />:<Navigate to={companyName?"/co/"+companyName+"/auth/login":"/auth/login"} replace /> },
            { path: 'all-bookings', element: isLoggedIn?<AllMyBookings />:<Navigate to={companyName?"/co/"+companyName+"/auth/login":"/auth/login"} replace /> },
            { path: 'view-booking', element: <ViewBooking /> },   
            { path: 'view-booking/:bookingId', element: <ViewBooking /> },         
            { path: 'crm', element: <CRMDashboard /> },
            { path: 'crm/:encodedContactId', element: <ViewCrmContact /> },            
            { path: 'ratings', element: <RatingPage /> },
            { path: 'calendar', element: <BookingCalendar /> },    
            { path: 'payments', element: <PaymentPage /> },                
            { path: 'add-payments/:bookingId/:encodedClientId/:encodedPrice', element: <StripePaymentPage /> },             
            { path: 'add-subscription/:subscriptionType/:itemPriceType', element: <SubscriptionSetupPage /> },
            { path: 'invoices', element: <InvoicesPage /> },  
            { path: 'invoices/add/:pageType', element: <AddInvoicePage /> },     
            { path: 'invoices/add/:pageType/:invoiceID', element: <AddInvoicePage /> },    
            { path: 'invoices/add/:pageType/:invoiceID/:duplicate', element: <AddInvoicePage /> },    
            // { path: '*', element: <Navigate to="/404" replace /> },         
            { path: '/co/:companyName/category/:catTitle', element: <CategoryProfile /> }, 
            { path: '/co/:companyName/services/:serviceTitle', element: <ServiceProfile /> },             

            { path: '/co/:companyName', element: <ProfilePage /> },
            { path: 'profile', element: <Navigate to={"/co/"+companyName} replace />  },
            { path: 'profile/bookings/:userId', element: <BookingsPage /> },
            { path: 'profile/bookings-confirm/:isSelfBooking', element: <ConfirmBooking /> },
            { path: 'profile/provider', element: <SelectServiceProvider /> },
            { path: 'profile/provider/services', element: <SelectProviderService /> },
            
            { path: 'analytics', element: <AnalyticsPage /> },
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    {
        path: 'co/:companyName/round-robin',//company
        element: <RoundRobinTheme />,
        children: [
            { path: '/co/:companyName/round-robin', element: <RoundRobinPage />},
        ]
    },
    // {
    //     path: 'co/:companyName',//company
    //     element: <CompanyTheme showNavBar={true} />,
    //     children: [
    //         { path: 'co/:companyName', element: <ProfilePage /> },
    //         { path: 'profile', element: <ProfilePage /> },
    //         { path: 'profile/bookings/:userId', element: <BookingsPage /> },
    //         { path: 'profile/bookings-confirm/:isSelfBooking', element: <ConfirmBooking /> },
    //         { path: 'profile/provider', element: <SelectServiceProvider /> },
    //         { path: '*', element: <Navigate to="/404" replace /> },
    //     ]
    // },
    {
        path: 'auth',
        element : <FormTheme />,
        // element : <FormTheme />,    
        children: [
            // { path: 'login', element: <LoginForm /> },
            { path: 'login', element: isLoggedIn?<Navigate to="/" replace />:<LoginForm /> },
            { path: 'edit', element: <EditPage /> },
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    
    {
        path: 'co/:companyName/auth',
        element : <FormTheme />,
        // element : <FormTheme />,    
        children: [
            // { path: 'login', element: <LoginForm /> },
            { path: 'login', element: isLoggedIn?<Navigate to={"/co/"+companyName} replace />:<LoginForm /> },
            { path: 'edit', element: <EditPage /> },
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    
    {
        path: '/:userName',
        element: <MainTheme showNavBar={true} />,
        children: [
            { path: '/:userName', element: <ProfilePage /> },
            { path: '/:userName/category/:catTitle', element: <CategoryProfile /> }, 
            { path: '/:userName/services/:serviceTitle', element: <ServiceProfile /> },                        
        ]
    },
    {
        path: 'profile',
        element : <MainTheme showNavBar={true} />,
        children: [
            { path: '/profile', element: <ProfilePage /> },
            { path: '/profile/:userName', element: <ProfilePage /> },
            { path: 'bookings/:userId', element: <BookingsPage /> },
            { path: 'bookings-confirm/:isSelfBooking', element: <ConfirmBooking /> },
            { path: 'provider', element: <SelectServiceProvider /> },
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    {
        path: 'settings',
        element : isLoggedIn?<MainTheme showNavBar={false} />:<Navigate to="/" replace />,
        children: [
            { path: 'general', element: <GeneralSettingsPage /> },
            { path: 'my-availability', element: <BookingAvailabilitySettingsPage /> }, 
            { path: 'profile-link', element: <ProfileLinkPage /> },          
            { path: 'edit-name', element: <EditNameSettings /> },          
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    
    {
        path: 'co/:companyName/settings',
        element : isLoggedIn?<CompanyTheme showNavBar={true} />:<Navigate to="/co/:companyName/auth/login" replace />,
        children: [
            { path: 'general', element: <GeneralSettingsPage /> },
            { path: 'my-availability', element: <BookingAvailabilitySettingsPage /> }, 
            { path: 'profile-link', element: <ProfileLinkPage /> },          
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    {
        path: '404',
        element : <PageNotFound />,
        children: [
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    { path: '*', element: <Navigate to="404" replace /> },
]