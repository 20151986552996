import { Close } from '@mui/icons-material'
import { Autocomplete, Button, FormControl, Grid, IconButton, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useDispatch } from 'react-redux'
import { SyncLoader } from 'react-spinners'
import { getStaffMember } from '../../FirebaseController/Company'
import { deleteLocation, saveLocation } from '../../FirebaseController/Locations'
import { searchServiceByLocation } from '../../FirebaseController/Services/services'
import { searchLocation } from '../../LocationController'
import { refreshLocations } from '../../Store/Reducers/Location/locations.actions'
import { APP_MANAGER_TYPES, LOCATION_STATUS, STAFF_TYPE } from '../../Utils/constants'
import { inputStyles } from '../../Utils/style'
// import '../../Utils/css/input_file.css'


export default function LocationForm(props) {


    const { showTitle, editLocData, isEdit, configs } = props
    
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = React.useState(false)
    const [isLocLoading, setIsLocLoading] = React.useState(false)
    const [isKeyboardChange, setIsKeyBoardChange] = React.useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
    const [locOptions, setLocOptions] = React.useState([]);
    const [locData, setLocData] = React.useState([]);
    const [locValue, setLocValue] = React.useState(locOptions.length > 0?locOptions[0]:'');
    const [locInputValue, setLocInputValue] = React.useState('');
    const [locName, setLocName] = React.useState('');
    const [locCountry, setLocCountry] = React.useState('');
    const [isAssociatedWithService, setIsAssociatedWithService] = React.useState(false);
    const __locTypes = [
        {
            val: "Offline",
            holder: 'In-person (Offline)'
        
        },
        {
            val: "Onsite",
            holder: 'On-site (User defined)'
        }
    ]
    const [locType, setLocType] = React.useState(__locTypes[0].val);
    const dispatch = useDispatch()
    // const appConfigReducer = useSelector((state) => state.appConfigReducer)
    // const [appConfigReducer, setAppConfigReducer] = useOutletContext()
    const appConfigReducer = configs
    //set manager id to user or company id
    const managerId = (appConfigReducer.type === APP_MANAGER_TYPES[0])?user.uid:appConfigReducer.data.companyID
    
    const handleLocValueChange = (value)=>{
        const locVal = locValue
        setLocValue(value)
        setLocData(locData.filter(item=>(item.title === locVal || ("address" in item && item.address.label === locVal))))
    }
  
    const handleLocInputChange = (event, value) => {
        setLocInputValue(value)
        setLocValue(value)
        if(!isLocLoading){// reduce unnecessary calls to api
            if(isKeyboardChange) { // check if is user input
                if(value.length > 2) { //start searching after 3 characters
                    setIsLocLoading(true)
                    
                    if (window.navigator.geolocation) {
                        window.navigator.geolocation.getCurrentPosition((position)=>{
                            
                            const lat = position.coords.latitude
                            const long = position.coords.longitude
                            searchLocation(value, lat, long).then((data)=>{
                                //
                                // 
                                setIsLocLoading(false)
                                setLocOptions(
                                    data.data.items.map(item=>("address" in item?item.address.label:item.title))
                                )
                                setLocData(data.data.items)
                            }).catch((err)=>{
                                setIsLocLoading(false)
                                
                                
                            })
                        }, (error) => {
                            if (error.code == error.PERMISSION_DENIED)
                                setIsLocLoading(false)
                                showSnackBar("Location permission denied, please activate permission to continue.", "error")      
                            }
                        )
                    } else {
                        setIsLocLoading(false)
                        showSnackBar("Geolocation is not supported by this browser.", "error")
                    }
                }
            }
        }
    }

    const handleAddLocation = ()=>{
        if(locName === "") {
            showSnackBar("Location name field is required", "error")
        }else if(locValue === "") {
            showSnackBar("No location selected", "error")
        }else {
            setIsLoading(true)
            const LocationID = isEdit === 1?editLocData.LocationID:Math.random().toString(10).slice(2)
            const locationData = {
                "LocationID": LocationID,
                "locationName": locName,
                "lat": locData.length > 0?locData[0].position.lat:isEdit === 1?editLocData.lat:null,
                "long": locData.length > 0?locData[0].position.lng:isEdit === 1?editLocData.long:null,
                "creatorID": (appConfigReducer.type === APP_MANAGER_TYPES[0])?user.uid:null,
                "companyID": (appConfigReducer.type === APP_MANAGER_TYPES[0])?null:managerId,
                "createdAt": + new Date(),
                "updatedAt": + new Date(),
                "managerID": user.uid,
                "locType": locType,
                "address": locValue,
                "status": isEdit === 1 && "status" in editLocData?editLocData.status:LOCATION_STATUS[0]
            }

            if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
                getStaffMember(user.uid, managerId).then((data)=>{
                    if(data.length < 1 || (data.length > 0 && data[0]["memberRole"] === STAFF_TYPE[0])) {
                        showSnackBar("You are not authorised to add location, contact admin for approval", "error")
                        return false
                        setIsLoading(false)
                    }
                    
                    saveLocation(locationData, LocationID, true).then(()=>{
                        showSnackBar("Location "+(isEdit === 1?"updated":"added")+" successfully", "success")
                        setIsLoading(false)
                        dispatch(refreshLocations())
                    }).catch((err)=>{
                        
                        const msg = (typeof err === 'string' || err instanceof String)?err:"Could not add location, try again later"
                        showSnackBar(msg, "error")
                        setIsLoading(false)
                    })
                }).catch((err)=>{
                    
                    const msg = (typeof err === 'string' || err instanceof String)?err:"Could not add location, try again later"
                    showSnackBar(msg, "error")
                    setIsLoading(false)
                })

            }else { 
                
                saveLocation(locationData, LocationID).then(()=>{
                    showSnackBar("Location "+(isEdit === 1?"updated":"added")+" successfully", "success")
                    setIsLoading(false)
                    dispatch(refreshLocations())
                }).catch((err)=>{
                    
                    const msg = (typeof err === 'string' || err instanceof String)?err:"Could not add location, try again later"
                    showSnackBar(msg, "error")
                    setIsLoading(false)
                })
            }
        }
    }

    const handleDelete = () => {
        if(window.confirm("Are you sure you want to delete this location?")){
            setIsDeleteLoading(true)

            deleteLocation(editLocData.LocationID, managerId.toString()).then(()=>{
                setIsDeleteLoading(false)
                
                dispatch(refreshLocations())
            }).catch((err)=>{
                setIsDeleteLoading(false)
                
                if(err === "foreign constraint error") {
                    showSnackBar("Could not delete location; Location is already asociated with a service.", "error")
                }else {
                    showSnackBar("Could not delete location", "error")
                }
            })
        }
    }

    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    React.useEffect(() => {
        if(editLocData) {
            setLocName(editLocData.locationName)
            setLocValue("address" in editLocData && editLocData.address?editLocData.address:"")
            setLocType(editLocData.locType)
            //setLocCountry(editLocData.country)
        }
    
      return () => null
    }, [editLocData])

    
    React.useEffect(() => {
       
        if(editLocData && "locationID" in editLocData) {
            searchServiceByLocation(editLocData.LocationID, managerId ).then(setIsAssociatedWithService)
            .catch((err)=>{
                
                setIsAssociatedWithService(false)
            })
        }
      return () => null
    }, [editLocData])


    return (
        <Grid container spacing={1} className='px-4 pb-4 my-3'>

            
            <Grid item xs={12} >
                <div className='border p-3 mb-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '5px'}}>
                    <label htmlFor='loc_name'>
                        Location title
                    </label><br />
                    <textarea 
                        style={{
                            borderRadius: '5px',
                            background: 'transparent',
                            border: 'none',
                            padding: '5px',
                            width: '100%',
                            resize: 'none'
                        }} 
                        type="text" 
                        className='mt-1 w-100'
                        id="loc_name"
                        value={locName}
                        name="loc_name"
                        onChange={(e)=>setLocName(e.target.value)}
                        rows="1"
                        cols="50"
                        placeholder='Enter text here'
                    ></textarea>

                </div>
            </Grid>

            <Grid item xs={12} >
                <FormControl variant="standard" className="py-1 mb-1" fullWidth>
                    <label htmlFor="locType" className='py-0 my-0' id="locTypeLabel">
                        Select type
                    </label>

                    <select
                        labelId="locTypeLabel"
                        id="locType"
                        style={inputStyles}
                        label="Industry"
                        value={locType}
                        onChange={(e)=>setLocType(e.target.value)}
                    >
                        {
                            __locTypes.map(item=>
                                <option key={item.val} value={item.val} selected={item.val === locType}>
                                    {item.holder}
                                </option>
                            )
                        }
                    </select>

                </FormControl>
            </Grid>

            
            {/*
                locType.toLowerCase() === "custom"?
                    <Grid item xs={12} >
                        <FormControl variant="standard" className="py-1 mb-1" fullWidth>
                            <label htmlFor="loc_name" className='py-0 my-0'>
                                Country
                            </label>
                            <input
                                className='mt-1 w-100'
                                id="loc_name"                                
                                // fullWidth={true}
                                style={inputStyles}
                                // disableUnderline={true}
                                // placeholder="Enter city name"
                                value={locCountry}
                                name="country"
                                onChange={(e)=>setLocCountry(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                :""
                    */}

            
            <Grid item xs={12}>
                <div className='d-flex'>
                    <label htmlFor="location" className='py-0 my-0 mb-2'>
                        {
                            locType.toLowerCase() === "offline"?"Location address":"Enter area of service"
                        }
                        {
                            locType.toLowerCase() === "custom"?
                                <div className='form-text pb-1' style={{fontSize: '12px',color: "#999" , fontWeight: 300}}>
                                    {"Please enter the area of operations you will be providing services in. Your client will be asked to select a location within it."}
                                </div>
                            :""
                        }
                    </label>
                    <div style={{flex: 1}}></div>
                    <Button disabled={true} color="secondary"  className='py-0 my-0' style={{textTransform: 'none'}}>
                        {
                            isLocLoading?
                                <SyncLoader
                                loading={isLocLoading}
                                size={7}
                                color="#000"
                                />
                                :
                            ""
                        }
                        
                    </Button>

                </div>
                <Autocomplete
                    disablePortal
                    id="location"
                    options={locOptions}
                    color="grey"
                    size={"small"} 
                    sx={{
                        padding: 0,
                        margin: 0,
                        width: '100%', border: 'none',
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        fontSize: '10px',
                    }}
                    value={locValue}
                    inputValue={locInputValue}
                    onChange={(event, newValue) => {
                        handleLocValueChange(newValue)
                    }} 
                    onKeyDown={()=>setIsKeyBoardChange(true)}
                    placeholder="Location name"                              
                    onInputChange={handleLocInputChange}
                    renderInput={(params) => 
                        <TextField {...params} variant="outlined" 
                            InputProps={{ ...params.InputProps, disableUnderline: true, style: {...inputStyles, borderRadius: 5} }} />}
                />
            </Grid>

            <Grid item xs={12}>
                <div style={{margin: '30px 0'}} className='d-flex'>
                    <div style={{flex: 1}}></div>
                    <Button variant='contained'
                            className="my-4 rounded border-0"
                            style={{color: "#fff", textTransform: 'none', background: '#F0973B',}}
                            disabled={isLoading} color="primary"
                            onClick={handleAddLocation}
                        >
                        {
                        isLoading?
                            <SyncLoader
                            loading={isLoading}
                            size={10}
                            color="#fff"
                            />
                            :
                        "Save"
                        }
                    </Button>
                    {
                        isEdit === 1?
                        <Button onClick={handleDelete} 
                            className={"rounded border-0 my-4 mx-1"}
                            color="primary" 
                            type="button" disabled={isDeleteLoading}
                            style={{background: '#000',color: "#fff", textTransform: 'none'}}
                        >

                            {
                                isDeleteLoading?
                                    <SyncLoader
                                    loading={isDeleteLoading}
                                    size={10}
                                    color="#fff"
                                    />
                                    :
                                    isAssociatedWithService?"Archive":"Delete"
                            }
                            
                        </Button>
                        :""
                    }
                </div>

            </Grid>

        </Grid>
    )


}