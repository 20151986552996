import React, { Fragment, useEffect, useState } from 'react';

// reactstrap components
import { AccessTime, Close, Whatshot } from '@mui/icons-material';
import { Autocomplete, Avatar, IconButton, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { getScheduledBookings, updateBooking } from '../../FirebaseController/Booking/booking';
import { searchContact } from '../../FirebaseController/CRMController';
import { addEvent } from '../../FirebaseController/Events';
import { getSetingsData } from '../../FirebaseController/Settings';
import { getUserDirectoryDataFromDb } from '../../FirebaseController/User/user';
// import DatePicker from 'react-date-picker';
import DatePicker from 'react-datepicker';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import TimePicker from 'react-time-picker/dist/TimePicker';
import {
    Button, Form
} from "reactstrap";
import { APP_MANAGER_TYPES } from 'Utils/constants';
import 'Utils/css/add-booking.css';
import { addDateHours, addDateMins, convertTimeZone, convertToSameDate, createDateFrom24hrString, defaultTimezone, formatDateToDateTimeStr, formatStrDateTimeToDate, getHrsMins } from 'Utils/functions';
import { inputStyles } from 'Utils/style';
import profile from '../../Utils/images/logo.png';
import ServiceCard from '../ServicesCard';

export default function AddBooking(props) {

    const navigate = useNavigate()
    const [bookingData, setBookingData] = useState({})
    const [userData, setUserData] = useState({})
    const [isLoading, setIsLoading] = React.useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const isSelfBooking = (localStorage.getItem("isSelfBooking") === "true")
    const serviceData = JSON.parse(localStorage.getItem("serviceData"))
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    // const appConfigReducer = useSelector((state) => state.appConfigReducer)
    const [appConfigReducer, setAppConfigReducer] = useOutletContext()
    const { companyName } = useParams()
    const [contactName, setContactName] = React.useState('');
    const [contact, setContact] = React.useState(null);
    const [contactInputValue, setContactInputValue] = React.useState('');
    const [contactOptions, setContactOptions] = React.useState([]);
    const [contactSearchData, setContactSearchData] = React.useState([])

    
    const handleSaveBooking = ()=> {
        setIsLoading(true)
        const userObj = JSON.parse(localStorage.getItem("user"))
        const user = userObj.user
    }



    const handleFormInputChange = (key, e) => {

        const val = e.target.value
        if(key === 'email') {
            setBookingData({
                ...bookingData,
                "client": {
                    ...bookingData.client,
                    [key]: val,
                    // 'fName': val,

                },
            })
        }else {
            setBookingData({
                ...bookingData,
                [key]: val
            })
        }
    }

    console.log(bookingData)
    
    const handleDateData = (name,value)=>{
        setBookingData({
            ...bookingData,
            [name]: formatDateToDateTimeStr(value),
            "endDate": formatDateToDateTimeStr((addDateMins(value, parseInt(serviceData.duration))))
        })
        //formatDateToDateTimeStr(convertTimeZone(value, "UTC"))
    }

    const handleTimeData = (name,value)=>{
        setBookingData({
            ...bookingData,
            [name]: formatDateToDateTimeStr(
                convertToSameDate(
                    createDateFrom24hrString(value.toString()), 
                    formatStrDateTimeToDate(bookingData[name])
                )
            ),
            "endDate": formatDateToDateTimeStr(
                addDateMins(
                    convertToSameDate(
                        createDateFrom24hrString(value.toString()), 
                        formatStrDateTimeToDate(bookingData[name])
                    ),
                    parseInt(serviceData.duration)

                )
            ),
        })
        // formatDateToDateTimeStr(
        //     convertToSameDate(
        //         convertTimeZone(createDateFrom24hrString(value.toString()), "UTC"), 
        //         formatStrDateTimeToDate(bookingData[name])
        //     )
        // ) 
    }

    
    const formInputs = [ 
        {
            name: "bookingDetails",
            required: true,
            holder: 'Description',
            placeholder:"Add description",
            type: 'text'
        },
        // {
        //     name: "email",
        //     required: true,
        //     holder: 'Client email',
        //     placeholder:"Enter client email",
        //     type: 'email'
        // },
    ]

    const checkIfSlotIsBooked = (_slot) => {
        //const slot = convertTimeZone(formatStrDateTimeToDate(_slot), "UTC")
        const slot = formatStrDateTimeToDate(_slot)
        
        const startHourOfSelectedCalendar = new Date(slot.getTime())
        startHourOfSelectedCalendar.setHours(0)
        startHourOfSelectedCalendar.setMinutes(0)
        startHourOfSelectedCalendar.setSeconds(0)
        
        const endHourOfSelectedCalendar = new Date(startHourOfSelectedCalendar.getTime())
        endHourOfSelectedCalendar.setHours(23)
        endHourOfSelectedCalendar.setMinutes(59)
        endHourOfSelectedCalendar.setSeconds(0)

        getScheduledBookings(
            user.uid, 
            formatDateToDateTimeStr(convertTimeZone(startHourOfSelectedCalendar, "UTC")),
            formatDateToDateTimeStr(convertTimeZone(endHourOfSelectedCalendar, "UTC"))
        ).then((data)=>{  
            
            
            
            
            if(data.length > 0){
                for (let i = 0; i < data.length; i++) {

                    const appointment = data[i]                    
                    const startTimeOfExistingBooking = formatStrDateTimeToDate(appointment.date)
                    const serviceDuration = parseInt(appointment.duration)
                    const dur = getHrsMins(serviceDuration)
                    const durHrs = dur.durhrs
                    const durMins = dur.durmins
                    let endTimeOfExistingBooking = addDateHours(startTimeOfExistingBooking, durHrs)
                    endTimeOfExistingBooking = addDateMins(endTimeOfExistingBooking.getTime(), durMins)
                    const startTimeofSlots = convertTimeZone(slot, "UTC")
                    const endTimeofSlots = addDateHours(new Date(startTimeofSlots), serviceDuration)

                    if((startTimeOfExistingBooking >= startTimeofSlots && startTimeOfExistingBooking < endTimeofSlots)
                        ||
                        (endTimeOfExistingBooking <= endTimeofSlots && endTimeOfExistingBooking > startTimeofSlots)
                    ){

                        
                        showSnackBar("Sorry the selected booking slot is already booked", "error")
                        setIsLoading(false)
                        //return false
                        break

                    }
                    

                    if(i === (data.length-1)) {

                        setIsLoading(false)
                        const bookingDate = bookingData['date']
                        bookingData.date = formatDateToDateTimeStr(convertTimeZone(bookingDate, "UTC"))
                        bookingData.endDate = formatDateToDateTimeStr(convertTimeZone(bookingData['endDate'], "UTC"))

                        if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
                            bookingData["companyId"] = appConfigReducer.data.companyID
                        }

                        bookingData.addedBy = user.uid

                        localStorage.setItem("booking", JSON.stringify(bookingData))
                        localStorage.setItem("providerDir", JSON.stringify(userData))
                        const selfBookingParam = isSelfBooking?"true":"false"
                        const url  = companyName?'/co/'+companyName:''
                        navigate(url + '/profile/bookings-confirm/'+selfBookingParam)
                    }



                    //booking slot is not booked so add booking
                    // bookingData["creationTime"] = (+ new Date())
                    // bookingData["orderId"] = Math.random().toString(10).slice(2)
                    // bookingData["notificationStatus"] = "UNREAD"
                    // bookingData["modifyTime"] = (+ new Date())
                    // bookingData["date"] = formatDateToDateTimeStr(convertTimeZone(bookingData['date'], "UTC"))
                    // 
                    // saveBooking(bookingData).then(()=>{
                        
                    //     handleAddEvent(bookingData)
                    //     // const notiText = bookingData.client["fName"] + " " + bookingData.client["sName"]+
                    //     //     " has placed a booking with you."
                    //     // const fcmData = {
                    //     //     "user": user.uid,
                    //     //     "icon": 0,
                    //     //     "body": notiText,
                    //     //     "title": "New appointment request",
                    //     //     "sented": bookingData.provider.providerId,
                    //     // }
                    //     //sendFcmNotification(fcmData, bookingData.provider.providerId).then(()=>
                    //     // setIsLoading(false)
                    //     showSnackBar("Booking requested successfully", "success")
                    //     // handleClose()
                    //     navigate('/')
                    // }).catch((err)=>{
                    //     
                    //     setIsLoading(false)
                    // })
                


                }
            }else {
                
                setIsLoading(false)
                bookingData.date = formatDateToDateTimeStr(convertTimeZone(bookingData['date'], "UTC"))
                bookingData.endDate = formatDateToDateTimeStr(convertTimeZone(bookingData['endDate'], "UTC"))
                if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
                    bookingData["companyId"] = appConfigReducer.data.companyID
                }
                localStorage.setItem("booking", JSON.stringify(bookingData))
                localStorage.setItem("providerDir", JSON.stringify(userData))
                const selfBookingParam = isSelfBooking?"true":"false"
                const url  = companyName?'/co/'+companyName:''
                navigate(url + '/profile/bookings-confirm/'+selfBookingParam)
            }


        }).catch((err)=>{
            setIsLoading(false)
            
        })
    }

    
    const handleBooking = ()=>{
        if(!bookingData.date) {
            showSnackBar('Please add a date for the booking', "error")              
        }else if(!bookingData.client || !bookingData.client.clientId) {
            showSnackBar('Please add a contact to complete the booking', "error")            
        }else if(!bookingData.bookingDetails) {
            showSnackBar('Please add description to complete booking', "error")            
        }else{

            if(user){
                setIsLoading(true)
                checkIfSlotIsBooked(bookingData.date)
            }else {
                showSnackBar("sorry you are not logged, please login to continue", "error")
                setIsLoading(false)
            }
        }
    }

    
    function handleAddEvent(booking) {
        
        getSetingsData(user.uid, ("companyId" in booking && booking.companyId)?booking.companyId:null).then((settingsData)=>{

            //check if google calendar is activated
            if(settingsData["googleCalendar"] === true){

        

                let eventDes = "Service provider: "+booking.provider.fName+" "
                    +booking.provider.sName + "\n"+
                    "Client name: "+booking.client.fName+" "
                    +booking.client.sName + "\n"+
                    "Duration "+booking.duration+" mins \n"
                if(booking.bookingDetails) {
                    eventDes += "Booking details "+booking.bookingDetails+" \n"
                }
                if(booking.services) {
                    eventDes += "Services "+booking.services.map((item)=>item.serviceTitle).join(",")+" \n" +
                    "Services descriptions: "+booking.services.map((item)=>item.serviceDesc).join(",")+" \n" 
                }

                const eventData = {
                    "summary": "You have an appointment with "+booking.client.fName+" "
                        +booking.client.sName,
                    "description": eventDes,
                    // 'location': booking.location,
                    'start': {
                        'dateTime': new Date(booking.date.replace(/-/g,'/')+" UTC"),
                        'timeZone': defaultTimezone()
                    },
                    'end': {
                        'dateTime': addDateMins(new Date(booking.date.replace(/-/g,'/')+" UTC"), parseInt(booking.duration)),
                        'timeZone': defaultTimezone()
                    },
                    "extendedProperties": {
                        "private": {
                            "priority": "high"
                        }
                    },
                    'attendees': [
                        {
                            'email' : booking.client.email,
                            'displayName' : booking.client.fName ? booking.client.fName: "Unknown User",
                            'organizer' : false, 
                            'responseStatus' : "needsAction",
                        }
                    ],
                    'reminders': {
                        'useDefault': false,
                        'overrides': [
                            {'method': 'email', 'minutes': 24 * 60},
                            {'method': 'popup', 'minutes': 10}
                        ]
                    }
                };
                
                let gMeetVersion = 0
                if(settingsData["googleMeet"] === true){
                    //add meeting link details
                    gMeetVersion = 1
                    eventData["conferenceData"] = {
                        "createRequest" : {
                            "requestId": Math.random().toString(10).slice(2),
                            "conferenceSolutionKey": {
                                "type": "hangoutsMeet",
                            }
                        }
                    }

                }


                addEvent(eventData, gMeetVersion).then((gEventResult)=>{

                    //update booking
                    if("hangoutLink" in gEventResult.data) {

                        const data = {
                            "googleMeetLink" : gEventResult.data.hangoutLink
                        }
                        updateBooking(data, booking.orderId)
                    }

                }).catch((err)=>{
                    
                })
            }
        }).catch((err)=>{
            showSnackBar("could not get settings data", "error")
            
        })
    }

      
    const handleContactInputChange = (_name) => {
        
        const name = _name?_name.split("__id__")[0]:""
        
        
        setContactInputValue((name || ""))
        setContactName((name || ""))
        if(name) {
            if(name.length > 2) {
                const providerId = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                    appConfigReducer.data.companyID:user.uid
                searchContact(providerId, (appConfigReducer.type === APP_MANAGER_TYPES[1]),  name).then((data)=>{
                    // 
                    const newData = data.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.userId === value.userId
                    ))
                  )
                    setContactSearchData(newData)

                    setContactOptions((newData.map(item=>(item.fName || "Anonymous") + " " + (item.sName || "User") + "__id__" +(item.userId))))
                })
            }
        }
    }

     
    const handleInputChange = (val) => {
        // const value = e.target.value
        const value = val?val.split("__id__")[0]:""
        setContactName(value)
        let contact_idx = []
        contactSearchData.forEach((item, idx) => {
            if( item.fName.toLowerCase() === (value).split(" ")[0].toLowerCase()) {
                contact_idx.push(idx)
            }
        } )
        if(contact_idx.length > 0) {

            console.log(contactSearchData[contact_idx[0]])
            setBookingData({
                ...bookingData,
                "client" : {
                    "clientId": (contactSearchData[contact_idx[0]].userId || contactSearchData[contact_idx[0]].contactId)
                },
            })
            setContact(contactSearchData[contact_idx[0]])
        }else {
            
        }

    }

    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}


    useEffect(() => {
        
        getUserDirectoryDataFromDb(user.uid).then((data)=>{
            setUserData(data)
            setBookingData({
                ...bookingData,
                'totalPrice': ("price" in serviceData)?serviceData.price:0,
                'services': [serviceData],
                'duration': serviceData.duration,
                'providerTimeZone': defaultTimezone(),
                'appointmentStatus': 'ACCEPTED',
                'notificationStatus': 'UNREAD',
                'provider': {
                    // 'email': user.email,
                    // 'fName': user.displayName.split(" ")[0],
                    // 'phoneNumber': "",
                    'providerId': user.uid,
                    // 'sName': user.displayName.split(" ")[1],
                    // 'providerImg': "profileLink" in data?data.profileLink:user.photoURL
                },
                "client": {
                    "clientId": "",
                    // "clientImg": "",
                    // "email": "",
                    // "fName": "",
                    // "sName": "",
                    // "phoneNumber": ""
                },
                "hasMeetingLink": false,
            })
        })
    
    }, [])






    return (
        <>
            
            <Box
                className="pt-4"
                size="sm"
                style={{zIndex: 10}}
            >
                <div className="modal-body p-0">
                <div className="border-0">
                    <div className="px-4">
                    <Form >

                        
                            <div className='border p-3 mb-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '5px'}}>
                                <label htmlFor='bookingTitle'>Add title</label><br />
                                <textarea id="bookingTitle" style={{
                                        borderRadius: '5px',
                                        background: 'transparent',
                                        border: 'none',
                                        padding: '5px',
                                        width: '100%',
                                        resize: 'none'
                                    }} 
                                    type="text" 
                                    name="bookingTitle" 
                                    placeholder='Enter text here'
                                    required={true} 
                                    rows="1"
                                    cols="50"
                                ></textarea>

                            </div>

                        {
                            formInputs.map((item, idx)=>(
                                <FormControl fullWidth size="sm" key={idx} sx={{mb: 2,}} >

                                    <label id={item.name}>{item.holder}</label>
                                    {

                                        // item.name === 'description'?
                                            <Fragment>
                                                
                                                <textarea 
                                                    style={inputStyles}
                                                    fullWidth 
                                                    id={item.name}
                                                    name={item.name} 
                                                    placeholder={item.placeholder}
                                                    required = {item.required}
                                                    value={
                                                        bookingData && item.name in bookingData?
                                                            bookingData[item.name]
                                                        :""
                                                    }
                                                    onChange={(e)=>handleFormInputChange(item.name, e)} 
                                                    rows="4" 
                                                    cols="50"></textarea>

                                                </Fragment>
                                        // :
                                        //     <input
                                        //         className="mb-2"
                                        //         style={inputStyles}
                                        //         placeholder={item.placeholder}
                                        //         type={item.type}
                                        //         name={item.name}
                                        //         id={item.name}
                                        //         min={10}
                                        //         step={10}
                                        //         onChange={(e)=>handleFormInputChange(item.name, e)}
                                        //         value={
                                        //             (bookingData && ('client' in bookingData || item.name in bookingData))?
                                        //                 item.name == 'email'?bookingData.client["email"]:bookingData[item.name]
                                        //             :""
                                        //         }
                                        //     />

                                    }
                                </FormControl>
                            ))

                            
                            
                        }


                                  
                        <Box className='row' sx={{px: 2}} >
                            <label htmlFor="location" className='py-0 my-0 mb-2'>
                                Add contact
                            </label>
                            <Autocomplete
                                disablePortal
                                id="location"
                                options={contactOptions}
                                color="grey"
                                size={"small"} 
                                sx={{
                                    padding: 0,
                                    margin: 0,
                                    width: '100%', border: 'none',
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                    fontSize: '10px',
                                }}
                                value={contactName}
                                inputValue={contactInputValue}
                                onChange={(event, newInputValue)=>handleInputChange(newInputValue)} 
                                placeholder="Contact name"                              
                                onInputChange={(event, newInputValue)=>handleContactInputChange(newInputValue)}
                                renderInput={(params) => 
                                    <TextField {...params} variant="outlined" 
                                        InputProps={{ ...params.InputProps, disableUnderline: true, style: {...inputStyles, borderRadius: 15} }} />}
                                renderOption={
                                    (props, option)=>{
                                        return (
                                            <Box {...props}>

                                                <Box className="d-flex">
                                                    {/* <Grid container> */}
                                                        <Box className="mr-2">

                                                            <Avatar
                                                                src={
                                                                    contactOptions.indexOf(option) > -1?
                                                                        contactSearchData[contactOptions.indexOf(option)].profile
                                                                    :profile
                                                                }
                                                                sx={{ height: '35px', width:  '35px',background: 'inherit' }} 
                                                                aria-label="logo"
                                                            >
                                                                <Whatshot color="primary" />
                                                            </Avatar>
                                                        </Box>
                                                        <Box>
                                                            <Typography  sx={{
                                                                fontSize: '14px',
                                                            }} >
                                                                {option.split("__id__")[0]}
                                                            </Typography>
                                                            <span
                                                                style={{
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                {
                                                                    contactOptions.indexOf(option) > -1?
                                                                        (contactSearchData[contactOptions.indexOf(option)].email || contactSearchData[contactOptions.indexOf(option)].primaryEmail)
                                                                    :""
                                                                }
                                                            </span>
                                                        </Box>
                                                    {/* </Grid> */}
                                                </Box>
                                            </Box>
                                        )
                                    }
                                }
                            />
                        </Box>

                        
                        <div className='row'>
                            <div className='col-md-6'>
                                
                                <FormControl fullWidth variant="standard" className="py-1 mb-1 mr-1" >
                                        <label htmlFor="input-with-icon-adornment" className='py-2 my-0'>
                                            Date
                                        </label>
                                        
                                        <DatePicker


                                            selected={"date" in bookingData?convertTimeZone(formatStrDateTimeToDate(bookingData["date"]), defaultTimezone()): ""} 
                                            onChange={(val)=>handleDateData("date",val)} 
                                            id="finYrEnd"
                                            minDate={new Date()}

                                        />
                                        {/* <DatePicker
                
                                            value={"date" in bookingData?convertTimeZone(formatStrDateTimeToDate(bookingData["date"]), defaultTimezone()): ""} 
                                            onChange={(val)=>handleDateData("date",val)} 
                                            id="input-with-icon-adornment"
                                            minDate={new Date()}
                                            name="Date From"
                                            clearIcon={null}
                                            calendarIcon={<DateRange color="primary" />}
                                            isOpen={false}
                                            style={{padding: '8px 4px'}}
                                        /> */}
                                </FormControl>
                            </div>
                            <div className='col-md-6'>
                                
                                <FormControl fullWidth variant="standard" className="py-1 mb-1 mr-1" >
                                    <label htmlFor="input-with-icon-adornment2" className='py-2 my-0'>
                                        Time
                                    </label>
                                    <TimePicker 
                                        id="input-with-icon-adornment2"
                                        onChange={(val)=>handleTimeData("date",val)} 
                                        value={"date" in bookingData?convertTimeZone(formatStrDateTimeToDate(bookingData["date"]), defaultTimezone()): ""} 
                                        renderHourMarks={true}
                                        renderMinuteMarks={true}
                                        renderNumbers={true}
                                        clearIcon={null}
                                        clockIcon={<AccessTime color="primary" />}
                                    />
                                </FormControl>
                            </div>
                            
                        </div>

                        <div>
                            
                            <label className='py-2 my-0'>{"Services"}</label>
                            <ServiceCard
                                isEdit={(user && serviceData.serviceProviderId === user.uid)}
                                // toggleModal={toggleModal}
                                service_data={serviceData}
                                serviceProviderId={serviceData.serviceProviderId}
                                isServicePage={false}
                                style={{background: "#F4F5FA"}}
                            />
                        </div>

                        <div className="d-flex mt-3">
                            <div style={{flex: 1}}></div>
                            <Button onClick={handleBooking} 
                                className={"rounded border-0 mt-1 mb-2 mx-0"}
                                color="primary" 
                                type="button" disabled={isLoading}
                                style={{background: '#F0973B',}}
                            >
                                {
                                    isLoading?
                                        <SyncLoader
                                            loading={isLoading}
                                            size={10}
                                            color="#d3d3d3"
                                        />
                                        :
                                    "Save"
                                }
                                
                            </Button>
                        </div>
                    </Form>
                    </div>
                </div>
                </div>
            </Box>
        </>
    )

}