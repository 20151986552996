import { initializeApp } from "firebase/app";
import { get, getDatabase, ref as sRef, set } from "firebase/database";
import { collection, deleteDoc, doc, endAt, getCountFromServer, getDocs, getFirestore, limit, orderBy, query, setDoc, startAfter, startAt, updateDoc, where } from "firebase/firestore";
import { saveCompanyClientTotalBookings, saveCompanyStaffTotalBookings, saveCompanyTotalBookings, saveCompanyTotalGuests, saveCompanyTotalLoggedUsers, saveProviderClientTotalBooking, saveProviderTotalBookings, saveProviderTotalLoggedUsers, saveServiceTotalBookingsAnalytics } from "FirebaseController/Analytics";
import { capitalizeFirstLetter, getFiscalYr } from "Utils/functions";
import { firebaseConfig } from "../../AppSettings";


const app = initializeApp(firebaseConfig);
const db = getFirestore (app)
const realTimeDb = getDatabase()

  

export const getAllContacts = (providerId = null, isCompany, name, pageLimit = 10, teamMemberId = null)=>new Promise( async function(successCallback, errorCallback) {


    
 
    try {
        const myDocRef = isCompany?
            collection(db, "CompanyUserBase/"+providerId+"/Users"):
            collection(db, "UserClientBase/"+providerId+"/Users")
        
        
        let q = teamMemberId?
            query(
                myDocRef, 
                where('assignTo', '==', teamMemberId),
                orderBy("fName"),            
                startAfter(name),
                limit(pageLimit)
            )

        :
            query(
                myDocRef,     
                orderBy("fName"),            
                startAfter(name),
                limit(pageLimit)
            )
        let docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        
        const result = {
            data: _data,
            lastDoc: docSnap.docs[docSnap.docs.length -2]
        }
        successCallback( result )
    }catch(err){
        errorCallback(err)
        
    }
});

// export const getTotalContacts = (providerId = null, isCompany, teamMemberId = null)=>new Promise( async function(successCallback, errorCallback) {


    
 
//     try {
//         const myDocRef = isCompany?
//             collection(db, "CompanyUserBase/"+providerId+"/Users"):
//             collection(db, "UserClientBase/"+providerId+"/Users")
        
        
//         let q = teamMemberId?
//             query(
//                 myDocRef, 
//                 where('assignTo', '==', teamMemberId),
//                 orderBy("fName")
//             )

//         :
//             query(
//                 myDocRef,     
//                 orderBy("fName"),
//             )
//         let docSnap = await getCountFromServer(q)
        
//         successCallback( docSnap.data().count )
//     }catch(err){
//         errorCallback(err)
        
//     }
// });
// export const getTotalGuestContacts = (providerId = null, isCompany, teamMemberId = null)=>new Promise( async function(successCallback, errorCallback) {


    
 
//     try {
//         const myDocRef = isCompany?
//             collection(db, "CompanyUserBase/"+providerId+"/Users"):
//             collection(db, "UserClientBase/"+providerId+"/Users")
        
        
//         let q = teamMemberId?
//             query(
//                 myDocRef, 
//                 where('type', '==', 'GUEST'),
//                 where('assignTo', '==', teamMemberId),
//                 orderBy("fName")
//             )

//         :
//             query(
//                 myDocRef, 
//                 where('type', '==', 'GUEST'),    
//                 orderBy("fName"),
//             )
//         let docSnap = await getCountFromServer(q)
        
//         successCallback( docSnap.data().count )
//     }catch(err){
//         errorCallback(err)
        
//     }
// });


export const searchContact = (providerId = null, isCompany, name, teamMemberId = null)=>new Promise( async function(successCallback, errorCallback) {

    //first get data when name is lowercase and when first letter is capitalized
 
    try {
        const myDocRef = isCompany?collection(db, "CompanyUserBase/"+providerId+"/Users"):collection(db, "UserClientBase/"+providerId+"/Users")
                
        let q = teamMemberId?
            query(
                myDocRef,  
                where('assignTo', '==', teamMemberId),
                orderBy("fName"),  
                // where('fName', '>=', name),
                // where('fName', '<=', name+ '\uf8ff')          
                startAt(name),
                endAt(name + '\uf8ff')
            )

        :
            query(
                myDocRef,     
                orderBy("fName"),  
                // where('fName', '>=', name),
                // where('fName', '<=', name+ '\uf8ff')          
                startAt(name),
                endAt(name + '\uf8ff')
            )
        let docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });

      
       
            console.log("list of data")
            console.log(_data)
        successCallback( _data )
    }catch(err){
        errorCallback(err)
        
    }
});

export const getTotalContactsCount = (providerId = null, companyId = null, isTeamMember = false)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const path = companyId? "CompanyUserBase/"+companyId+"/Users/" : "UserClientBase/"+providerId+"/Users/"
        const myDocRef = collection(db, path)
        const qry = companyId?
            isTeamMember? //teammember
                query(
                    myDocRef, 
                    where("assignedTo", "==", providerId),
                    orderBy("fName")
                )

            : //for admins and managers
                query(
                    myDocRef,
                    orderBy("fName")
                )

        :
            query(
                myDocRef, 
                orderBy("fName")
            )
        const docSnap = await getCountFromServer(qry) 
        successCallback({totalContacts: docSnap.data().count})
    }catch(error) {
        errorCallback(error)
    }
});
export const getTotalGuestContactsCount = (providerId = null, companyId = null, isTeamMember = false)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const path = companyId? "CompanyUserBase/"+companyId+"/Users/" : "UserClientBase/"+providerId+"/Users/"
        const myDocRef = collection(db, `${path}`)
        const qry = companyId?
            isTeamMember? //teammember
                query(
                    myDocRef, 
                    where("type", "==", "GUEST"),
                    where("assignedTo", "==", providerId),
                    orderBy("fName")
                )

            : //for admins and managers
                query(
                    myDocRef,
                    where("type", "==", "GUEST"),
                    orderBy("fName")
                )

        :
            query(
                myDocRef, 
                where("type", "==", "GUEST"),
                orderBy("fName")
            )
        const docSnap = await getCountFromServer(qry) 
        successCallback({totalGuests: docSnap.data().count})
    }catch(error) {
        errorCallback(error)
    }
});

export const getContact = (providerId = null, isCompany, contactId)=>new Promise( async function(successCallback, errorCallback) {

 
    try {
        const myDocRef = isCompany?collection(db, "CompanyUserBase/"+providerId+"/Users/"):collection(db, "UserClientBase/"+providerId+"/Users/")
       let q = query(
            myDocRef,  
            where('contactId', '==', contactId),
        )
        let docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback( _data.length > 0?_data[0]:{} )
    }catch(err){
        errorCallback(err)
        
    }
});

export const getContactByLatomId = (providerId = null, isCompany, contactId)=>new Promise( async function(successCallback, errorCallback) {

 
    try {
        const myDocRef = isCompany?collection(db, "CompanyUserBase/"+providerId+"/Users/"):collection(db, "UserClientBase/"+providerId+"/Users/")
       let q = query(
            myDocRef,  
            where('userId', '==', contactId),
        )
        let docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback( _data.length > 0?_data[0]:{} )
    }catch(err){
        errorCallback(err)
        
    }
});

// export const getAllContacts = (providerId = null, isCompany, startDate,pageLimit = 10)=>new Promise( async function(successCallback, errorCallback) {

 
//     try {
//         const myDocRef = isCompany?collection(db, "CompanyUserBase/"+providerId+"/Users"):collection(db, "UserClientBase/"+providerId+"/Users")
//         let q = 
//             query(
//                 myDocRef,                 
//                 where("createdAt", "<", startDate.toString()),
//                 orderBy("createdAt", "desc"),
//                 limit(pageLimit)
//             )
//         let docSnap = await getDocs(q)
//         const _data = []
//         docSnap.forEach((doc) => {
//             _data.push(doc.data())        
//         });
//         q = 
//             query(
//                 myDocRef,                 
//                 where("createdAt", "<", parseFloat(startDate)),
//                 orderBy("createdAt", "desc"),
//                 limit(pageLimit)
//             )
//         docSnap = await getDocs(q)
//         docSnap.forEach((doc) => {
//             _data.push(doc.data())        
//         });
//         
//         successCallback(_data)
//     }catch(err){
//         errorCallback(err)
//         
//     }
// });

export const saveContactActivity = (providerId = null, activity, companyId = null)=>new Promise((successCallBack, errorCallBack)=>{

    const myDocRef = companyId?doc(db, 'CompanyUserBase/' + companyId+ '/Activities/'+activity.activityId)
        :doc(db, 'UserClientBase/' + providerId+ '/Activities/'+activity.activityId)    

    checkIfContactActivityExists(providerId, companyId, activity.createdAt ).then((activites)=>{

        if(activites.length <= 0) {            
            setDoc(myDocRef, activity).then(successCallBack).catch(errorCallBack)
        }

    })

})


export const checkIfContactActivityExists = (providerId = null, companyId = null, createdAt)=>new Promise(async(successCallBack, errorCallBack)=>{
    
    const myDocRef = companyId?collection(db, 'CompanyUserBase/' + companyId+ '/Activities/')
        :collection(db, 'UserClientBase/' + providerId+ '/Activities/')

    const q = query(myDocRef, 
        where("createdAt", "==", createdAt.toString()),
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallBack(_data)

})


export const deleteContactActivity = (providerId = null, companyId = null, activityId)=>new Promise(async(successCallBack, errorCallBack)=>{
    
    const myDocRef = companyId?doc(db, 'CompanyUserBase/' + companyId+ '/Activities/'+activityId)
        :doc(db, 'UserClientBase/' + providerId+ '/Activities/'+activityId)

    deleteDoc(myDocRef).then(successCallBack).catch(errorCallBack)

})


export const getAllContactActivities = (contactId, providerId = null, companyId = null, startDate, pageLimit = 10)=>new Promise(async(successCallBack, errorCallBack)=>{


    
    
    const myDocRef = companyId?collection(db, 'CompanyUserBase/' + companyId+ '/Activities/')
        :collection(db, 'UserClientBase/' + providerId+ '/Activities/')

    const q = query(myDocRef, 
        where("createdAt", "<", startDate.toString()),
        where("contactId", "==", contactId.toString()),
        limit(pageLimit),
        orderBy("createdAt", "desc")
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallBack(_data)

})


let updatedAt1 = null
export const bookingAnalyticsForCompany = () => {

    
    
    async function addAnalytics (){
        const userRef = sRef(realTimeDb, 'CompanyAnalytics/')
        get(userRef)
        .then(async (snapshot) => {
            if((!updatedAt1) || ((new Date() - updatedAt)/1000 > 2)) {
                const usersAvail = snapshot.val()
                // 
                //

                // console.log("yeey")
                const values = Object.values(usersAvail)
                
                const keys = Object.keys(usersAvail)
                

                
                let counter = 0

                let totalClients = {}
                let totalGuests = {}
                let totalBookingsArr = {}


                keys.forEach((id)=>{
                    run()
                    async function run () {
                        const providerDetails = usersAvail[id]
                        
                        
                        // if("ClientBookingAnalytics" in providerDetails && providerDetails.ClientBookingAnalytics) {
                            
                            
                        //     let totalBookings = 0
                        //     Object.values(providerDetails.ClientBookingAnalytics).forEach((client)=>{
                                
                        //         totalBookings += (parseFloat(client.totalBookings || 0))
                        //     })
                        //     // totalBookingsArr[id] = totalBookings
                    


                            
                            
                        //     //save total bookings
                        //     if(id in totalBookingsArr) {
                        //         saveCompanyTotalBookings(id, totalBookings)
                                    
                        //     }

                            
                        
                            
                        // }else {
                            
                        //     saveCompanyTotalBookings(id, 0)
                            
                        // }
                        
                        let isLooping = true
                        let startAfterDoc = null
                        let allGuestsCount = 0
                        let allClientsCount = 0
                        let allContactIds = []

                        while (isLooping) {
                            const contactsRef = collection(db, "CompanyUserBase/" + id+ "/Users/")
                            let q = startAfterDoc?

                            query(
                                contactsRef, 
                                startAfter(startAfterDoc),
                                limit(100)
                            )
                            :
                            query(
                                contactsRef, 
                                // orderBy("name", "desc"),
                                limit(100)
                            )
                            let docSnap = await getDocs(q)
                            const _data = []
                            
                            docSnap.forEach((doc) => {
                                const contact = doc.data()
                                if(allContactIds
                                    .filter(item=>(
                                        contact.fName === item.fName && 
                                        contact.sName === item.sName 
                                        // && contact.userId === item.userId
                                        )
                                    ).length > 0
                                    ||
                                    (!contact.fName)
                                ) {

                                    deleteDoc(doc.ref).then(()=>{console.log("contact deleted")})
                                }else {
                                    if(!contact.contactId) { 
                                        contact.contactId = window.crypto.randomUUID().split('-').join('')
                                        updateDoc(doc.ref, {contactId: contact.contactId})
                                    }
                                    
                                    _data.push(contact)
                                    allContactIds.push({
                                        contactId: contact.contactId,
                                        fName: contact.fName,
                                        sName: contact.sName,
                                        userId: contact.userId,
                                    }) 
                                }      
                            });
                            
                            
                            let totalGuestsCount = 0
                            _data.forEach((item)=>{
                                if(
                                    ("fName" in item && item.fName.toLowerCase() === "anonymous")
                                    || (!item.fName)
                                    || (item.type && item.type.toLowerCase() === "guest")
                                )
                                {
                                    totalGuestsCount += 1
                                }
                            })

                            let totalClientsCount = _data.length - totalGuestsCount

                            allGuestsCount += totalGuestsCount
                            allClientsCount += totalClientsCount

                            console.log(docSnap.docs.length)
                            console.log(totalGuestsCount)
                            console.log(totalClientsCount)

                            isLooping = docSnap.docs.length >= 100
                            startAfterDoc = docSnap.docs[docSnap.docs.length - 1]
                            
                            
                            console.log(isLooping)
                            

                        }
                        
                        console.log(id, allGuestsCount)
                        console.log(id, allClientsCount)
                        saveCompanyTotalGuests(id, allGuestsCount)                                
                        saveCompanyTotalLoggedUsers(id, allClientsCount)
                            
                    }
                })
                updatedAt1 = new Date()
            }
        })
    }
    addAnalytics()
  };

  
let updatedAt = null
export const bookingAnalyticsForProvider = () => {
    addAnalytics()
    async function addAnalytics (){
        const userRef = sRef(realTimeDb, 'ProviderClientBaseAnalytics/')
        get(userRef).then((snapshot) => {
                if((!updatedAt) || ((new Date() - updatedAt)/1000 > 2)) {
                    const usersAvail = snapshot.val()
                    // 
                    //

                    const values = Object.values(usersAvail)
                    
                    const keys = Object.keys(usersAvail)
                    

                    
                    let counter = 0

                    let totalClients = {}
                    let totalGuests = {}
                    let totalBookingsArr = {}


                    keys.forEach((id)=>{
                        run()
                        async function run () {
                            const providerDetails = usersAvail[id]
                            
                            
                            // if("ClientBookingAnalytics" in providerDetails && providerDetails.ClientBookingAnalytics) {
                                
                                
                            //     let totalBookings = 0
                            //     Object.values(providerDetails.ClientBookingAnalytics).forEach((client)=>{
                                    
                            //         totalBookings += (parseFloat(client.totalBookings || 0))
                            //     })
                            //     // totalBookingsArr[id] = totalBookings
                        

    
                                
                                
                            //     //save total bookings
                            //     if(id in totalBookingsArr) {
                            //         saveProviderTotalBookings(id, totalBookings)
                                        
                            //     }
    
                                
                            
                                
                            // }else {
                                
                            //     saveProviderTotalBookings(id, 0)
                                
                            // }
                            
                            
                            let isLooping = true
                            let startAfterDoc = null
                            let allGuestsCount = 0
                            let allClientsCount = 0
                            let allContactIds = []
                            while(isLooping) {
                                const contactsRef = collection(db, "UserClientBase/" + id+ "/Users/")
                                let q = startAfterDoc?
                                    query(
                                        contactsRef, 
                                        startAfter(startAfterDoc),
                                        limit(100)
                                    )
                                    :
                                    query(
                                        contactsRef, 
                                        // orderBy("name", "desc"),
                                        limit(100)
                                    )
                                let docSnap = await getDocs(q)
                                const _data = []
                                docSnap.forEach((doc) => {
                                    const contact = doc.data()
                                    if(
                                        allContactIds
                                            .filter(item=>(
                                                contact.fName === item.fName && 
                                                contact.sName === item.sName 
                                                // && contact.userId === item.userId
                                                )
                                            )
                                            .length > 0
                                            ||
                                            (!contact.fName)
                                        ) {

                                        deleteDoc(doc.ref).then(()=>{console.log(id, "contact deleted")})
                                    }else {
                                        if(!contact.contactId) { 
                                            contact.contactId = window.crypto.randomUUID().split('-').join('')
                                            updateDoc(doc.ref, {contactId: contact.contactId})
                                        }
                                        
                                        _data.push(contact)
                                        allContactIds.push({
                                            contactId: contact.contactId,
                                            fName: contact.fName,
                                            sName: contact.sName,
                                            userId: contact.userId,
                                        })
                                    }      
                                });
                                
                                let totalGuestsCount = 0
                                _data.forEach((item)=>{
                                    if(
                                        ("fName" in item && item.fName.toLowerCase() === "anonymous")
                                        || (!item.fName)
                                        || (item.type && item.type.toLowerCase() === "guest")
                                    )
                                    {
                                        totalGuestsCount += 1
                                    }
                                })
                                let totalClientsCount = _data.length - totalGuestsCount

                                console.log("_data.length", _data.length)
                                console.log("_data.length", totalGuestsCount)
                                console.log("_data.length", totalClientsCount)

                                allGuestsCount += totalGuestsCount
                                allClientsCount += totalClientsCount
                                isLooping = docSnap.docs.length >= 100
                                startAfterDoc = docSnap.docs[docSnap.docs.length - 1]


                            }
                            console.log("allContactIds", allGuestsCount)
                            console.log("allContactIds", allClientsCount)

                            saveProviderTotalLoggedUsers(id, allGuestsCount, allClientsCount)
                            .then(()=>console.log("analytics saved"))
                                
                        }
                    })
                    updatedAt = new Date()
                }
            })
    }
};



export const bookingAnalytics = () => {


    //get all bookingd
    //get total bookings per service/company/provider/client/staff
    //then  save
    


    addAnalytics()
    async function addAnalytics (){

        let isStillLoading = true
        let startDate = new Date()
        startDate.setDate(startDate.getDate() + 1)
        startDate = startDate.getTime().toString()

        const totalBookings = {}
        const totalCompanyAnalytics = {}
        const totalProvAnalytics = {}
        const totalClientAnalytics = {}
        const pageLimit = 50

        while (isStillLoading) {

            
            const analyticsRef = collection(db, "Bookings/")
            let q = 
            query(
                analyticsRef,
                where("creationTime", "<", startDate),
                orderBy("creationTime", "desc"),
                limit(pageLimit)
            )
            let docSnap = await getDocs(q)
            const allBookings = []
            docSnap.forEach((doc) => {
                const bk = doc.data()                
                allBookings.push(bk)
            });
            
            console.log("allBookings", allBookings.length)
            // 
            const uniqueServices = new Set(allBookings.map(bk=>bk.services[0].serviceId))
            uniqueServices.forEach((serviceId)=>{
                const totalBkPerService = allBookings.filter((bk)=>bk.services[0].serviceId === serviceId)
                if(totalBookings[serviceId]) {
                    totalBookings[serviceId].count = (totalBookings[serviceId].count + totalBkPerService.length)
                }else {
                    totalBookings[serviceId] = {
                        "count": (totalBkPerService.length),
                        "companyId": ("companyId" in totalBkPerService[0] && totalBkPerService[0].companyId)?totalBkPerService[0].companyId:null,
                        "providerId": ("companyId" in totalBkPerService[0] && totalBkPerService[0].companyId)?null:totalBkPerService[0].provider.providerId,
                    }
                }
            })

            //compnay analytics
            const uniqueCompanyIds = new Set(allBookings.filter(bk=>bk.companyId).map(bk=>bk.companyId))
            console.log("allBookings", allBookings.length)
            uniqueCompanyIds.forEach((companyId)=>{
                //company total analytics
                const totalBkPerCompany = allBookings.filter((bk)=>bk.companyId === companyId)
                if(totalCompanyAnalytics[companyId]) {
                    totalCompanyAnalytics[companyId].count = (totalCompanyAnalytics[companyId].count + totalBkPerCompany.length)
                    
                }else {
                    totalCompanyAnalytics[companyId] = {
                        "count": (totalBkPerCompany.length),
                    }
                }

                //set staff analytics too

                const uniqueProv = new Set(totalBkPerCompany.map(bk=>bk.provider.providerId))
                uniqueProv.forEach((provId)=>{
                    const totalBkPerService = totalBkPerCompany.filter((bk)=>bk.provider.providerId === provId)
                    
                    if(totalCompanyAnalytics[companyId]["staff"] && totalCompanyAnalytics[companyId]["staff"][provId]) {
                        totalCompanyAnalytics[companyId]["staff"][provId].count = (totalCompanyAnalytics[companyId]["staff"][provId].count + totalBkPerService.length)
                    }else {
                        totalCompanyAnalytics[companyId]["staff"] = {
                            [provId]: {
                                "count": (totalBkPerService.length),
                            }
                        }
                    }
                })

                
                //set client analytics too

                const uniqueClient = new Set(totalBkPerCompany.map(bk=>bk.client.clientId))
                uniqueClient.forEach((provId)=>{
                    const totalBkPerService = totalBkPerCompany.filter((bk)=>bk.client.clientId === provId)
                    if(totalCompanyAnalytics[companyId]["Client"] && totalCompanyAnalytics[companyId]["Client"][provId]) {
                        totalCompanyAnalytics[companyId]["Client"][provId].count = 
                            (totalCompanyAnalytics[companyId]["Client"][provId].count + totalBkPerService.length)
                    }else {
                        totalCompanyAnalytics[companyId]["Client"]  = {
                            [provId]: {
                                "count": (totalBkPerService.length),
                            }
                        }
                    }
                })

            })

            //provider analytics
            const provBookings = allBookings.filter(bk=>!bk.companyId)
            const uniqueProv = new Set(provBookings.map(bk=>bk.provider.providerId))
            uniqueProv.forEach((provId)=>{
                const totalBkPerService = provBookings.filter((bk)=>bk.provider.providerId === provId)
                if(totalProvAnalytics[provId]) {
                    totalProvAnalytics[provId].count = (totalProvAnalytics[provId].count + totalBkPerService.length)
                }else {
                    totalProvAnalytics[provId] = {
                        "count": (totalBkPerService.length),
                    }
                }

                const uniqueClient = new Set(totalBkPerService.map(bk=>bk.client.clientId))
                uniqueClient.forEach((clientId)=>{
                    const totalBkPerClient = totalBkPerService.filter((bk)=>bk.client.clientId === clientId)
                    if(totalProvAnalytics[provId]["Client"] && totalProvAnalytics[provId]["Client"][clientId]) {
                        totalProvAnalytics[provId]["Client"][clientId].count = 
                            (totalProvAnalytics[provId]["Client"][clientId].count + totalBkPerClient.length)
                    }else {
                        totalProvAnalytics[provId]["Client"] = {
                            [clientId]: {
                                "count": (totalBkPerClient.length),
                            }
                        }
                    }
                })

            })


            isStillLoading = allBookings.length >= pageLimit
            startDate = allBookings[allBookings.length - 1].creationTime

        }

        console.log(totalBookings)
        console.log(totalCompanyAnalytics)
        console.log(totalProvAnalytics)

        Object.keys(totalBookings).forEach((key)=>{
            
            if("companyId" in totalBookings[key] && totalBookings[key].companyId !== null) {

                
                
                saveServiceTotalBookingsAnalytics(totalBookings[key].count, key, totalBookings[key].companyId, true)
                    .then(()=>{
                        // console.log("service analytics saved")
                    })
            }else {
                
                
                
                saveServiceTotalBookingsAnalytics(totalBookings[key].count, key, totalBookings[key].providerId, false)
                    .then(()=>{
                        // console.log("service analytics saved")
                    })
            }
        })
        Object.keys(totalCompanyAnalytics).forEach((key)=>{
            
            //save company booking
            saveCompanyTotalBookings(key, totalCompanyAnalytics[key].count).then(()=>console.log(key, "company total bookings saved"))
            
            Object.keys(totalCompanyAnalytics[key].staff).forEach((staffId)=>{
                saveCompanyStaffTotalBookings(key, staffId, totalCompanyAnalytics[key]["staff"][staffId].count ).then(()=>console.log("company staff total bookings saved"))
            })
            Object.keys(totalCompanyAnalytics[key].Client).forEach((staffId)=>{
                saveCompanyClientTotalBookings(key, staffId, totalCompanyAnalytics[key]["Client"][staffId].count ).then(()=>console.log("company client total bookings saved"))
            })

        })

        Object.keys(totalProvAnalytics).forEach((key)=>{
            
            //save company booking
            saveProviderTotalBookings(key, totalProvAnalytics[key].count).then(()=>console.log(key, "user total bookings saved"))
            
            Object.keys(totalProvAnalytics[key].Client).forEach((staffId)=>{
                saveProviderClientTotalBooking(key, staffId, totalProvAnalytics[key]["Client"][staffId].count ).then(()=>console.log("company client total bookings saved"))
            })
        })


    }
};


// // creating activites
// let updatedAt2 = null
// export const bookingActivities = () => {


//     //get all services
//     //get total bookings per service
//     //then  save
    


//     addAnalytics()
//     async function addAnalytics (){

//         let isStillLoading = true
//         let startDate = new Date()
//         startDate.setDate(startDate.getDate() + 1)
//         startDate = startDate.getTime().toString()

//         const totalBookings = {}
//         const pageLimit = 50

//         while (isStillLoading) {

            
//             const analyticsRef = collection(db, "Bookings/")
//             let q = 
//             query(
//                 analyticsRef,
//                 where("creationTime", "<", startDate),
//                 orderBy("creationTime", "desc"),
//                 limit(pageLimit)
//             )
//             let docSnap = await getDocs(q)
//             const allBookings = []
//             docSnap.forEach((doc) => {
//                 const bk = doc.data()                
//                 allBookings.push(bk)
//             });
            
//             // 
//             allBookings.forEach((booking)=>{
                
//                 //if status is accepted


//                 if (booking.appointmentStatus === "ACCEPTED") {
//                     //
//                     let activity = {}
//                     activity['title'] = "Booking created for " + booking.services[0].serviceTitle
//                     activity["activityId"] = window.crypto.randomUUID().split('-').join('')
//                     activity["createdAt"] = booking.creationTime.toString()
//                     activity["contactId"] =  null
//                     activity["userId"] =  booking.client.clientId
//                     activity["createdBy"] =  booking.client.clientId
//                     activity["label"] =  "BOOKING"
//                     activity["bookingId"] =  booking.orderId

//                     saveContactActivity(booking.provider.providerId, activity, (booking.companyId?booking.companyId.toString():null))
//                         .then(()=>{
                            
//                         })
//                         .catch((err)=>{
                            
        
//                         })
//                     if(booking.modifyTime && booking.creationTime !== booking.modifyTime) {

//                         activity['title'] = "Booking accepted for " + booking.services[0].serviceTitle
//                         activity["createdAt"] = booking.modifyTime?booking.modifyTime.toString():booking.creationTime.toString()
//                         activity["label"] =  "BOOKING UPDATE"
    
//                         saveContactActivity(booking.provider.providerId, activity, (booking.companyId?booking.companyId.toString():null))
//                             .then(()=>{
                                
//                             })
//                             .catch((err)=>{
                                
            
//                             })
//                     }

//                 } 
//                 else if(booking.appointmentStatus === "CANCELLED") {
//                     let activity = {}
//                     activity['title'] = "Booking created for " + booking.services[0].serviceTitle
//                     activity["activityId"] = window.crypto.randomUUID().split('-').join('')
//                     activity["createdAt"] = booking.creationTime.toString()
//                     activity["contactId"] =  null
//                     activity["userId"] =  booking.client.clientId
//                     activity["createdBy"] =  booking.client.clientId
//                     activity["label"] =  "BOOKING"
//                     activity["bookingId"] =  booking.orderId

//                     saveContactActivity(booking.provider.providerId, activity, (booking.companyId?booking.companyId.toString():null))
//                         .then(()=>{
                            
//                         })
//                         .catch((err)=>{
                            
        
//                         })
//                     if(booking.creationTime !== booking.modifyTime) {

//                         activity['title'] = "Booking cancellation for " + booking.services[0].serviceTitle
//                         activity["label"] =  "CANCELLATION"
//                         activity["createdAt"] = booking.modifyTime?booking.modifyTime.toString():booking.creationTime.toString()
//                         activity["label"] =  "CANCELLATION"
    
//                         saveContactActivity(booking.provider.providerId, activity, (booking.companyId?booking.companyId.toString():null))
//                             .then(()=>{
                                
//                             })
//                             .catch((err)=>{
                                
            
//                             })
//                     }

//                 } 
//                 else if(booking.appointmentStatus === "PENDING") {
//                     let activity = {}
//                     activity['title'] = "Booking created for " + booking.services[0].serviceTitle
//                     activity["activityId"] = window.crypto.randomUUID().split('-').join('')
//                     activity["createdAt"] = booking.creationTime.toString()
//                     activity["contactId"] =  null
//                     activity["userId"] =  booking.client.clientId
//                     activity["createdBy"] =  booking.client.clientId
//                     activity["label"] =  "BOOKING"
//                     activity["bookingId"] =  booking.orderId

//                     saveContactActivity(booking.provider.providerId, activity, (booking.companyId?booking.companyId.toString():null))
//                         .then(()=>{
                            
//                         })
//                         .catch((err)=>{
                            
        
//                         })
//                 }



//             })


//             isStillLoading = allBookings.length === pageLimit
//             startDate = allBookings[allBookings.length - 1].creationTime

//         }

//     }
// };



// export const servicesBookingAnalytics__ = () => {


//     //get all services
//     //get total bookings per service
//     //then  save
    


//     addAnalytics()
//     async function addAnalytics (){

//         let isStillLoading = true
//         let startDate = new Date()
//         startDate.setDate(startDate.getDate() + 1)
//         startDate = startDate.getTime().toString()

//         const totalBookings = {}
//         const pageLimit = 50

//         while (isStillLoading) {

            
//             const analyticsRef = collection(db, "Bookings/")
//             let q = 
//             query(
//                 analyticsRef,
//                 where("creationTime", "<", startDate),
//                 orderBy("creationTime", "desc"),
//                 limit(pageLimit)
//             )
//             let docSnap = await getDocs(q)
//             const allBookings = []
//             docSnap.forEach((doc) => {
//                 const bk = doc.data()                
//                 allBookings.push(bk)
//             });
            
//             // 
//             allBookings.forEach((booking)=>{
                
//                 //if status is accepted


//                 if (booking.appointmentStatus === "ACCEPTED") {
//                     //
//                     let activity = {}
//                     activity["createdAt"] = booking.creationTime.toString()

//                     checkIfContactActivityExists(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), activity.createdAt ).then((activities)=>{

//                         if(activities.length > 1) {

//                             activities.slice(1).forEach((act)=> {
//                                 deleteContactActivity(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), act.activityId)
//                                 .then(()=>{
                                    
//                                 })
//                             })


//                         }
                
//                     })
                
//                     if(booking.modifyTime && booking.creationTime !== booking.modifyTime) {

    
//                         if(booking.modifyTime && !isNaN(booking.modifyTime)) {
//                             activity["createdAt"] = booking.modifyTime?booking.modifyTime.toString():booking.creationTime.toString()
//                             checkIfContactActivityExists(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), activity.createdAt ).then((activities)=>{

//                                 if(activities.length > 1) {

//                                     activities.slice(1).forEach((act)=> {
//                                         deleteContactActivity(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), act.activityId)
//                                         .then(()=>{
                                            
//                                         })
//                                     })


//                                 }
                        
//                             })
//                         }
//                     }

//                 } else if(booking.appointmentStatus === "CANCELLED") {
//                     let activity = {}
//                     activity["createdAt"] = booking.creationTime.toString()

//                     checkIfContactActivityExists(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), activity.createdAt ).then((activities)=>{

//                         if(activities.length > 1) {

//                             activities.slice(1).forEach((act)=> {
//                                 deleteContactActivity(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), act.activityId)
//                                 .then(()=>{
                                    
//                                 })
//                             })


//                         }
                
//                     })
                
//                     if(booking.modifyTime && booking.creationTime !== booking.modifyTime) {

    
//                         if(booking.modifyTime && !isNaN(booking.modifyTime)) {
//                             activity["createdAt"] = booking.modifyTime?booking.modifyTime.toString():booking.creationTime.toString()
//                             checkIfContactActivityExists(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), activity.createdAt ).then((activities)=>{

//                                 if(activities.length > 1) {

//                                     activities.slice(1).forEach((act)=> {
//                                         deleteContactActivity(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), act.activityId)
//                                         .then(()=>{
                                            
//                                         })
//                                     })


//                                 }
                        
//                             })
//                         }
//                     }

//                 } else if(booking.appointmentStatus === "PENDING") {
//                     let activity = {}
//                     activity["createdAt"] = booking.creationTime.toString()

                    
//                     checkIfContactActivityExists(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), activity.createdAt ).then((activities)=>{

//                         if(activities.length > 1) {

//                             activities.slice(1).forEach((act)=> {
//                                 deleteContactActivity(booking.provider.providerId, (booking.companyId?booking.companyId.toString():null), act.activityId)
//                                 .then(()=>{
                                    
//                                 })
//                             })


//                         }
                
//                     })
                
//                 }



//             })


//             isStillLoading = allBookings.length === pageLimit
//             startDate = allBookings[allBookings.length - 1].creationTime

//         }

//     }
// };


export const totalInvoicesANdEstimatesForCompany = (isInvoices = true) => {

    
    
    async function addAnalytics (){
        console.log("yeey")
        get(sRef(realTimeDb, 'Companies/')).then((snapshot) => {
            const companies = snapshot.val()
            Object.values(companies).forEach((company)=>{
                const id = company.companyID.toString()
                run()
                async function run () {
                    let isLooping = true
                    let startAfterDoc = null
                    let totalInvoices = {}
                    let fiscalYears = []
                    let totalIncome = {}
                    let totalPending = {}

                    while (isLooping) {
                        const key__ = isInvoices?"Invoices":"Estimates"
                        const contactsRef = collection(db, "Companies/" + id+ "/"+key__+"/")
                        let q = startAfterDoc?

                        query(
                            contactsRef, 
                            startAfter(startAfterDoc),
                            limit(100)
                        )
                        :
                        query(
                            contactsRef, 
                            // orderBy("name", "desc"),
                            limit(100)
                        )
                        let docSnap = await getDocs(q)
                        const invoices = []
                        docSnap.forEach((doc) => {
                            invoices.push(doc.data())
                        });
                        
                        invoices.forEach((invoice)=>{
                            const finYrEnd = getFiscalYr(new Date("2022/12/31").valueOf(), (invoice.invoiceCreatedAt || invoice.invoicecreatedAt || invoice.estimateCreatedAt || invoice.estimatecreatedAt))
                            console.log(finYrEnd)
                            const status = isInvoices?(invoice["invoiceStatus"] || "unpaid"):(invoice["estimateStatus"] || "created")
                            if(fiscalYears.indexOf(finYrEnd) < 0) {
                                fiscalYears.push(finYrEnd)
                                console.log(status)
                                if(status.toLowerCase() === "created" || status.toLowerCase() === "unpaid") {
                                    totalPending[finYrEnd] = parseFloat(invoice.invoiceTotal || invoice.estimateTotal || 0)
                                }
                                if(status.toLowerCase() === "accepted" || status.toLowerCase() === "paid") {
                                    totalIncome[finYrEnd] = parseFloat(invoice.invoiceTotal || invoice.estimateTotal || 0)
                                }
                                totalInvoices[finYrEnd] = 1
                            } else {
                                if(status.toLowerCase() === "created" || status.toLowerCase() === "unpaid") {
                                    totalPending[finYrEnd] = (totalPending[finYrEnd] || 0) + parseFloat(invoice.invoiceTotal || invoice.estimateTotal || 0)
                                }
                                if(status.toLowerCase() === "accepted" || status.toLowerCase() === "paid") {
                                    totalIncome[finYrEnd] = (totalIncome[finYrEnd] || 0) + parseFloat(invoice.invoiceTotal || invoice.estimateTotal || 0)
                                }
                                totalInvoices[finYrEnd] = totalInvoices[finYrEnd] + 1
                            }
                            // console.log(totalPending)
                            // console.log(totalIncome)


                            // totalIncome = invoices
                            //     .filter((item)=>item[key_].toLowerCase() === ("created" || "unpaid"))
                            //     .reduce( function(a, b){
                            //         return parseFloat(a.invoiceTotal || a.estimateTotal || 0) + parseFloat(b.invoiceTotal || b.estimateTotal || 0);
                            //     }, 0);
                            // totalPending = invoices
                            //     .filter((item)=>item[key_].toLowerCase() === ("accepted" || "paid"))
                            //     .reduce( function(a, b){
                            //         return parseFloat(a.invoiceTotal || a.estimateTotal || 0) + parseFloat(b.invoiceTotal || b.estimateTotal || 0);
                            //     }, 0);

                        })
                        isLooping = invoices.length >= 100
                        startAfterDoc = docSnap.docs[docSnap.docs.length - 1]                       
                        
                        

                    }
                    
                    fiscalYears.forEach((finYr)=>{
                        const txt = isInvoices?"invoices":"estimates"
                        const analyticRef = sRef(realTimeDb, 'CompanyInvoiceAnalytics/' + id+"/"+finYr+"/Analytics/"+txt+"/")
                            // :sRef(realTimeDb, 'UserInvoiceAnalytics/'+ id+"/"+finYr+"/Analytics/"+txt)
                    
                        const data = {
                            [isInvoices?"totalInvoices":"totalEstimates"]: totalInvoices[finYr]
                        }
                        if(totalPending[finYr]) {
                            data["totalPending"] = totalPending[finYr]
                        }
                        if(totalIncome[finYr]) {
                            data[isInvoices?"totalIncome":"totalApproved"] = totalIncome[finYr]
                        }
                        console.log(finYr, data)
                        if(totalPending[finYr] || totalIncome[finYr]) {
                            set(analyticRef, data).then(()=>{
                                console.log(`company ${id} successfully cleared`)
                            })
                            .catch((err)=>{
                                console.log(id, err)
                            })
                        }

                    })
                        
                }
            })
        
        })
    }
    addAnalytics()
  };

  export const totalInvoicesANdEstimatesForUsers = (isInvoices = true) => {

    
    
    async function addAnalytics (){
        console.log("yeey")
        get(sRef(realTimeDb, 'Users/')).then((snapshot) => {
            const users = snapshot.val()
            Object.values(users).forEach((user)=>{
                const id = user.uid
                run()
                async function run () {
                    let isLooping = true
                    let startAfterDoc = null
                    let totalInvoices = {}
                    let fiscalYears = []
                    let totalIncome = {}
                    let totalPending = {}

                    while (isLooping) {
                        const key__ = isInvoices?"Invoices":"Estimates"
                        const contactsRef = collection(db, "Users/" + id+ "/"+key__+"/")
                        let q = startAfterDoc?

                        query(
                            contactsRef, 
                            startAfter(startAfterDoc),
                            limit(100)
                        )
                        :
                        query(
                            contactsRef, 
                            // orderBy("name", "desc"),
                            limit(100)
                        )
                        let docSnap = await getDocs(q)
                        const invoices = []
                        docSnap.forEach((doc) => {
                            invoices.push(doc.data())
                        });
                        
                        invoices.forEach((invoice)=>{
                            const finYrEnd = getFiscalYr(new Date("2022/12/31").valueOf(), (invoice.invoiceCreatedAt || invoice.invoicecreatedAt || invoice.estimateCreatedAt || invoice.estimatecreatedAt))
                            console.log(finYrEnd)
                            const status = isInvoices?(invoice["invoiceStatus"] || "unpaid"):(invoice["estimateStatus"] || "created")
                            if(fiscalYears.indexOf(finYrEnd) < 0) {
                                fiscalYears.push(finYrEnd)
                                console.log(status)
                                if(status.toLowerCase() === "created" || status.toLowerCase() === "unpaid") {
                                    totalPending[finYrEnd] = parseFloat(invoice.invoiceTotal || invoice.estimateTotal || 0)
                                }
                                if(status.toLowerCase() === "accepted" || status.toLowerCase() === "paid") {
                                    totalIncome[finYrEnd] = parseFloat(invoice.invoiceTotal || invoice.estimateTotal || 0)
                                }
                                totalInvoices[finYrEnd] = 1
                            } else {
                                if(status.toLowerCase() === "created" || status.toLowerCase() === "unpaid") {
                                    totalPending[finYrEnd] = (totalPending[finYrEnd] || 0) + parseFloat(invoice.invoiceTotal || invoice.estimateTotal || 0)
                                }
                                if(status.toLowerCase() === "accepted" || status.toLowerCase() === "paid") {
                                    totalIncome[finYrEnd] = (totalIncome[finYrEnd] || 0) + parseFloat(invoice.invoiceTotal || invoice.estimateTotal || 0)
                                }
                                totalInvoices[finYrEnd] = totalInvoices[finYrEnd] + 1
                            }
                            // console.log(totalPending)
                            // console.log(totalIncome)


                            // totalIncome = invoices
                            //     .filter((item)=>item[key_].toLowerCase() === ("created" || "unpaid"))
                            //     .reduce( function(a, b){
                            //         return parseFloat(a.invoiceTotal || a.estimateTotal || 0) + parseFloat(b.invoiceTotal || b.estimateTotal || 0);
                            //     }, 0);
                            // totalPending = invoices
                            //     .filter((item)=>item[key_].toLowerCase() === ("accepted" || "paid"))
                            //     .reduce( function(a, b){
                            //         return parseFloat(a.invoiceTotal || a.estimateTotal || 0) + parseFloat(b.invoiceTotal || b.estimateTotal || 0);
                            //     }, 0);

                        })
                        isLooping = invoices.length >= 100
                        startAfterDoc = docSnap.docs[docSnap.docs.length - 1]                       
                        
                        

                    }
                    
                    fiscalYears.forEach((finYr)=>{
                        const txt = isInvoices?"invoices":"estimates"
                        const analyticRef = sRef(realTimeDb, 'UserInvoiceAnalytics/' + id+"/"+finYr+"/Analytics/"+txt+"/")
                            // :sRef(realTimeDb, 'UserInvoiceAnalytics/'+ id+"/"+finYr+"/Analytics/"+txt)
                    
                        const data = {
                            [isInvoices?"totalInvoices":"totalEstimates"]: totalInvoices[finYr]
                        }
                        if(totalPending[finYr]) {
                            data["totalPending"] = totalPending[finYr]
                        }
                        if(totalIncome[finYr]) {
                            data[isInvoices?"totalIncome":"totalApproved"] = totalIncome[finYr]
                        }
                        console.log(finYr, data)
                        if(totalPending[finYr] || totalIncome[finYr]) {
                            set(analyticRef, data).then(()=>{
                                console.log(`company ${id} successfully cleared`)
                            })
                            .catch((err)=>{
                                console.log(id, err)
                            })
                        }

                    })
                        
                }
            })
        
        })
    }
    addAnalytics()
  };


  
  
//   export const totalInvoicesANdEstimatesForUsers1 = async () => {
    
//     const plans = {
//         "Free": {
//             "chargeBeePlanId": "Free",
//             "monthlyPrice": 0,
//             "Services": 8,
//             "addOns": {
//                 "StaffMember": {
//                     "annualItemPlanId": "Additional-staff-member-Yearly",
//                     "monthlyPrice": 0,
//                     "annualPrice": 0,
//                     "monthItemPlanId": "Additional-staff-member-Monthly"
//                 }
//             },
//             "monthItemPlanId": "Latom_User_Free_Sub-INR-Monthly",
//             "Categories": 3,
//             "annualPrice": 0,
//             "annualItemPlanId": " Latom_User_Free_Sub-INR-Yearly",
//             "Staff": 15,
//             "Companies": 2,
//             "Contacts": 1000
//         },
//         "Premium": {
//             "chargeBeePlanId": "Premium",
//             "annualItemPlanId": "Latom_User_Pre_Sub-INR-Yearly",
//             "annualPrice": 5000,
//             "monthlyPrice": 500,
//             "Contacts": 7500,
//             "Staff": 5,
//             "Companies": 5,
//             "monthItemPlanId": "Latom_User_Pre_Sub-INR-Monthly",
//             "Services": 8,
//             "addOns": {
//                 "StaffMember": {
//                     "monthlyPrice": 500,
//                     "annualItemPlanId": " addtional_user_pre-INR-Yearly",
//                     "annualPrice": 5000,
//                     "monthItemPlanId": " addtional_staff_pre-INR-Monthly"
//                 }
//             },
//             "Categories": 5
//         },
//         "Professional": {
//             "chargeBeePlanId": "Professional",
//             "Contacts": 10000,
//             "monthItemPlanId": "Latom_User_Pro_Sub-INR-Monthly",
//             "Companies": 10,
//             "annualPrice": 8000,
//             "addOns": {
//                 "StaffMember": {
//                     "monthItemPlanId": "additional_users_pro-INR-Yearly",
//                     "annualPrice": 8000,
//                     "monthlyPrice": 800,
//                     "annualItemPlanId": "additional_users_pro-INR-Monthly"
//                 }
//             },
//             "annualItemPlanId": "Latom_User_Pro_Sub-INR-Yearly",
//             "monthlyPrice": 800,
//             "Staff": 10,
//             "Services": 25,
//             "Categories": 15
//         }
//     }

//     Object.keys(plans).forEach((key)=>{

//         const contactsRef = doc(db, "PlanTypes/ay8NfC27dPfTm1E6lW9n/"+key+"/"+window.crypto.randomUUID().split('-').join(''))
//         setDoc(contactsRef, plans[key])
//         .then(()=>console.log("yeey", key))
//     })

//   }