
import AccessTime from '@mui/icons-material/AccessTime';
import Close from '@mui/icons-material/Close';
import MiscellaneousServices from '@mui/icons-material/MiscellaneousServices';
import Room from '@mui/icons-material/Room';
import Whatshot from '@mui/icons-material/Whatshot';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { getBooking } from 'FirebaseController/Booking/booking';
import { markNotifsAsRead } from 'FirebaseController/Notifications/localNotifs';
import { getUserDataFromDb } from 'FirebaseController/User/user';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { Button } from 'reactstrap';
import { dateTimeFormat } from 'Utils/constants';
import { getUserRedirectData } from 'Utils/functions';
import profile1 from '../../Utils/images/logo.png';

export default function LocalNotificationCard(props) {

    const { notif, handleRefreshNotifs, isNotifOpen, configs } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj.user
    const [profile, setProfile] = useState(profile1)
    const [isViewBookingLoading, setIsViewBookingLoading] = useState(false)
    const  { companyName } = useParams()
    const appConfigReducer = configs

    const handleViewbooking = ()=> {

        setIsViewBookingLoading(true)
        getBooking(notif.bookingOrderId.toString()).then((booking)=>{

            setIsViewBookingLoading(false)
            localStorage.setItem("booking", JSON.stringify(booking))
            const url = companyName?'/co/'+companyName:''
            window.location.href = (url + '/view-booking')

        }).catch((err)=>{
            
            if("msg" in err) {
                showSnackBar(err.msg, "error")
            }
            setIsViewBookingLoading(false)
        })
    }
    useEffect(() => {
      getUserDataFromDb(notif.sender).then((data)=>{
        if(data && ("profile" in data))setProfile(data.profile)
      })
    
      return () => null
    }, [notif])
    


    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    useEffect(() => {
        if(isNotifOpen === true) {
            markNotifsAsRead(notif.receiver, notif.notifId)
            
        }
    
      return () => null
    }, [notif, isNotifOpen])
    
    


    return (
        <>
            <div className="alert text-dark m-0" fade={false} style={{background: "#F4F5FA", margin: 0, borderBottom: "1px solid #d3d3d3"}}>
                <div className='d-flex'>
                    <span className="alert-inner--icon">
                        <a style={{color: "#000"}} href={window.location.origin + "/profile/?user=" + getUserRedirectData(notif.sender)}>
                            <Avatar
                                src={profile}
                                sx={{ height: '45px', width: '45px', marginTop: '27%', background: "inherit"}} 
                                aria-label="profile"
                                color="#fff"
                            >
                                <Whatshot color="primary"  sx={{fontSize: '45px'}}/>
                            </Avatar>
                        </a>
                    </span>
                    <div className="alert-inner--text ml-1 mt-2">
                        <strong style={{fontSize: '16px'}}>
                            <p style={{fontSize: '14px', padding: '0', margin: 0}}>
                                
                                {notif.body}
                            </p>
                        </strong> 
                        <Typography variant="body2" style={{fontSize: '13px', padding: 5, color: '#828282'}}>
                            <span className='pr-1'>
                                <MiscellaneousServices style={{fontSize: '22px',}} color="primary" />
                            </span>
                            {notif.title}
                        </Typography>
                        {
                            "bookLocation" in notif && notif.bookLocation?
                            
                                <div className='d-flex'>
                                
                                    <Typography variant="body2" style={{fontSize: '13px', padding: 5, paddingRight: 0, color: '#828282'}}>
                                        <span className='pr-1'>
                                            <Room style={{fontSize: '22px',}} color="primary" />
                                        </span>
                                    </Typography>
                                    <Typography variant="body2" style={{fontSize: '13px', padding: 5, paddingLeft: 0, color: '#828282'}}>
                                        
                                        {notif.bookLocation}
                                    </Typography>
                                </div>
                            :""
                        }
                        <Typography variant="body2" style={{fontSize: '13px', padding: 5, color: '#828282'}}>
                            <span className='pr-1'>
                                <AccessTime style={{fontSize: '22px',}} color="primary" />
                            </span>
                            {new Date(notif.date.replace(/-/g,'/') + " UTC").toLocaleString("en-US", dateTimeFormat)}
                        </Typography>
                {
                    ("bookingOrderId" in notif && notif.bookingOrderId) &&
                     !notif.body.split(" ").includes("cancelled") &&
                      !notif.body.split(" ").includes("declined") ?
                      
                        <div className='d-flex mt-2'>
                                
                            <Button
                                size="sm"
                                color="warning"
                                onClick={handleViewbooking}
                                className='rounded btn-light border-0 px-4'
                                style={{backgroundColor: '#F0973B', textTransform: 'none'}}
                            >
                                {
                                    isViewBookingLoading?
                                        <SyncLoader
                                            loading={isViewBookingLoading}
                                            size={10}
                                            color="#fff"
                                        />
                                        :
                                    "View"
                                }
                                
                                
                            </Button>
                        </div>

                    :""
                }
                    </div>
                </div>
            
            </div>
        </>
       

        
    )


}