import { Close } from '@mui/icons-material'
import { Autocomplete, Button, Grid, IconButton, TextField } from '@mui/material'
import { reverseGeocodeLoc, searchLocation } from 'LocationController'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import { APP_MANAGER_TYPES } from '../../Utils/constants'
import { inputStyles } from '../../Utils/style'
// import '../../Utils/css/input_file.css'


export default function BookingLocationForm(props) {

    const {  isEdit, serviceProviderId, isCompany, locationData } = props
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    //const serviceData = JSON.parse(localStorage.getItem("serviceData"))
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = React.useState(false)
    const [isLocLoading, setIsLocLoading] = React.useState(false)
    const [isKeyboardChange, setIsKeyBoardChange] = React.useState(false)
    const [locOptions, setLocOptions] = React.useState([]);
    const [locData, setLocData] = React.useState([]);
    const [locValue, setLocValue] = React.useState(locOptions.length > 0?locOptions[0]:'');
    const [locInputValue, setLocInputValue] = React.useState('');
    const navigate = useNavigate()
    const __locTypes = [
        {
            val: "Offline",
            holder: 'In-person'
        
        },
        {
            val: "Onsite",
            holder: 'On-site (Customer defined)'
        }
    ]
    const [locType, setLocType] = React.useState(__locTypes[0].val);
    const dispatch = useDispatch()
    // const appConfigReducer = useSelector((state) => state.appConfigReducer)
    const [appConfigReducer, setAppConfigReducer] = useOutletContext()
    //set manager id to user or company id
    const managerId = (appConfigReducer.type === APP_MANAGER_TYPES[0]) && user?user.uid:appConfigReducer.data.companyID
    
    const handleLocValueChange = (value)=>{
        const locVal = locValue
        setLocValue(value)
        setLocData(locData.filter(item=>(item.title === locVal || ("address" in item && item.address.label === locVal))))
    }
  
    const handleLocInputChange = (event, value) => {
        
        setLocInputValue(value)
        setLocValue(value)
        if(!isLocLoading){// reduce unnecessary calls to api
            if(isKeyboardChange) { // check if is user input
                if(value.length > 2) { //start searching after 3 characters
                    setIsLocLoading(true)
                    
                    
                    if (window.navigator.geolocation) {
                        window.navigator.geolocation.getCurrentPosition((position)=>{
                            
                            const lat = position.coords.latitude
                            const long = position.coords.longitude
                            
                            searchLocation(value, lat, long).then((data)=>{
                                
                                // 
                                setIsLocLoading(false)
                                
                                setLocOptions(
                                    data.data.items.map(item=>("address" in item?item.address.label:item.title))
                                )
                                setLocData(data.data.items)
                            }).catch((err)=>{
                                
                                setIsLocLoading(false)
                                
                                
                            })
                        }, (error) => {
                            if (error.code == error.PERMISSION_DENIED)
                                setIsLocLoading(false)
                                showSnackBar("Location permission denied, please activate permission to continue.", "error")      
                            }
                        )
                    } else {
                        
                        showSnackBar("Geolocation is not supported by this browser.", "error")
                        setIsLocLoading(false)
                    }
                }
            }
        }else {
            
        }
    }

    const handleAddLocation = ()=>{
        if(locValue === "") {
            showSnackBar("No location selected", "error")
        }else {
            const selectedLocationData = {
                "lat": locData[0].position.lat,
                "long": locData[0].position.lng,
                "address": locValue,
            }

            //check if location is service location city
            setIsLoading(true)
            reverseGeocodeLoc(locationData.lat, locationData.long).then((data)=>{
                //
                // 
                // 
                const cityName = data.data.items[0].address.city
                // 
                if(cityName){
                    reverseGeocodeLoc(selectedLocationData.lat, selectedLocationData.long).then((data__)=>{
                        // 
                        if(cityName !== data__.data.items[0].address.city) {
                            showSnackBar("The selected region is not within the service provider service area...", "error")
                        }else {

                            localStorage.setItem("selectedClientLocation", JSON.stringify(selectedLocationData))
                            
                            navigate('/profile/bookings/'+btoa(serviceProviderId)+'?isCompany='+isCompany)
                        }
                        setIsLoading(false)
                    })  
                    .catch((err)=>{
                        setIsLoading(false)
                        
                        
                    })
                }else {
                    showSnackBar("Could not load city, please try again...", "error")
                    setIsLoading(false)
                }
            }).catch((err)=>{
                setIsLoading(false)
                
                
            })

        }
    }


    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}


    

    return (
        <Grid container spacing={1} className='px-4 pb-4 my-3'>

                        
            <Grid item xs={12}>
                <div className='d-flex'>
                    <label htmlFor="location" className='py-0 my-0 mb-2'>
                        Enter location address
                    </label>
                    <div style={{flex: 1}}></div>
                    <Button disabled={true} color="secondary"  className='py-0 my-0' style={{textTransform: 'none'}}>
                        {
                            isLocLoading?
                                <SyncLoader
                                loading={isLocLoading}
                                size={7}
                                color="#000"
                                />
                                :
                            ""
                        }
                        
                    </Button>

                </div>
                <Autocomplete
                    disablePortal
                    id="location"
                    options={locOptions}
                    color="grey"
                    size={"small"} 
                    sx={{
                        padding: 0,
                        margin: 0,
                        width: '100%', border: 'none',
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        fontSize: '10px',
                    }}
                    value={locValue}
                    inputValue={locInputValue}
                    onChange={(event, newValue) => {
                        handleLocValueChange(newValue)
                    }}
                    onKeyDown={()=>setIsKeyBoardChange(true)}
                    placeholder="Location name"                              
                    onInputChange={handleLocInputChange}
                    renderInput={(params) => 
                        <TextField {...params} variant="outlined" 
                            InputProps={{ ...params.InputProps, disableUnderline: true, style: {...inputStyles, borderRadius: 5} }} />}
                />
            </Grid>

            <Grid item xs={12}>
                <div style={{margin: '30px 0'}} className='d-flex'>
                    <div style={{flex: 1}}></div>
                    <Button variant='contained'
                            className="my-4 rounded border-0"
                            style={{color: "#fff", textTransform: 'none', background: '#F0973B',}}
                            disabled={isLoading} color="primary"
                            onClick={handleAddLocation}
                        >
                        {
                        isLoading?
                            <SyncLoader
                            loading={isLoading}
                            size={10}
                            color="#fff"
                            />
                            :
                        "Next"
                        }
                    </Button>
                </div>

            </Grid>

        </Grid>
    )


}