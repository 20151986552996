import { SET_TITLE, SET_DASHBOARD_DATA, REFRESH_NOTIF_COUNTER } from './title.types'

const initialState = {
    data: "Dashboard",
    dashboard: {},
    notifCounter: 0
  };
export const titleReducer = (state = initialState, action)=> {

    switch (action.type) {
        case SET_TITLE:
            return {
                ...state,
                data: action.payload,
            };

        case SET_DASHBOARD_DATA:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    ...action.payload
                },
            };

        case REFRESH_NOTIF_COUNTER:
            return {
                ...state,
                notifCounter: (parseInt(state.notifCounter) + 1)
            };


        
        default:
            return state;
    }
}