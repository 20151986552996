import { Close } from '@mui/icons-material'
import { Button, FormControl, Grid, IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import { APP_MANAGER_TYPES } from '../../Utils/constants'
import { inputStyles } from '../../Utils/style'
// import '../../Utils/css/input_file.css'


export default function BookingQuestionsForm(props) {

    const {  service, isCompany, configs } = props
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    //const serviceData = JSON.parse(localStorage.getItem("serviceData"))
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = React.useState(false)
    const [questionData, setQuestionData] = React.useState({});
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const appConfigReducer = useSelector((state) => state.appConfigReducer)
    const appConfigReducer = configs
    
    const { companyName } = useParams()
    

    const handleInputChange = (e) => {
        setQuestionData({
            ...questionData,
            [e.target.name]: e.target.value
        })
    }

    const handleContinueButton = ()=>{
        if(("question1" in service) && (!("answer1" in questionData) || !questionData.answer1)) {
            showSnackBar("All fields are required.","error")
        } else if(("question2" in service) && (!("answer2" in questionData) || !questionData.answer2)) {
            showSnackBar("All fields are required.","error")
        }else if(("question3" in service) && (!("answer3" in questionData) || !questionData.answer3)) {
            showSnackBar("All fields are required.","error")
        }else {
            const booking = localStorage.getItem("booking")?JSON.parse(localStorage.getItem("booking")):null
            if(booking) {
                
                booking["serviceAnswers"] = questionData
                localStorage.setItem("booking", JSON.stringify(booking))
                let url = companyName?"/co/"+companyName:""
                url += '/profile/bookings-confirm/false'
                if (isCompany) {
                    url += "?isCompany=true"
                }
                if(user) {
                    navigate(url)
                }else {
                    localStorage.setItem("redirectBookingUrl", window.location.origin + url)                
                    showSnackBar("Booking details have been saved, please login to continue to booking", "success")
                    if(appConfigReducer.type === APP_MANAGER_TYPES[0])
                        navigate('/auth/login')
                    else
                        navigate('/co/'+companyName+'/auth/login')
                }
            }else {
                showSnackBar("Could not complete the booking, try again later", "error")
            }

        }
    }


    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}


    

    return (
        <Grid container spacing={1} className='px-4 pb-4 my-3'>

                        
            <Grid item xs={12}>
                
                {
                    "question1" in service?
                        <FormControl fullWidth  sx={{mb: 2}}>

                            <>                 
                                
                                <label htmlFor="answer1" className='py-0 my-0 mb-2'>
                                    {
                                        service.question1
                                    }
                                    
                                </label>
                                <input
                                    className='mt-1 w-100'
                                    id={"answer1"}                                
                                    key={"answer1"}
                                    // fullWidth={true}
                                    style={inputStyles}
                                    // disableUnderline={true}
                                    // placeholder={"Enter" }
                                    name={"answer1"}
                                    // value={item in serviceData?serviceData[item]:""}
                                    onChange={handleInputChange}
                                />
                                
                            </>


                        </FormControl>
                    :""
                }
                {
                    "question2" in service?
                        <FormControl fullWidth  sx={{mb: 2}}>

                            <>                 
                                
                                <label htmlFor="answer2" className='py-0 my-0 mb-2'>
                                    {
                                        service.question2
                                    }
                                    
                                </label>
                                <input
                                    className='mt-1 w-100'
                                    id={"answer2"}                                
                                    key={"answer2"}
                                    // fullWidth={true}
                                    style={inputStyles}
                                    // disableUnderline={true}
                                    // placeholder={"Enter" }
                                    name={"answer2"}
                                    value={"answer2" in questionData?questionData["answer2"]:""}
                                    onChange={handleInputChange}
                                />
                                
                            </>


                        </FormControl>
                    :""
                }
                {
                    "question3" in service?
                        <FormControl fullWidth  sx={{mb: 2}}>

                            <>                 
                                
                                <label htmlFor="answer3" className='py-0 my-0 mb-2'>
                                    {
                                        service.question3
                                    }
                                    
                                </label>
                                <input
                                    className='mt-1 w-100'
                                    id={"answer3"}                                
                                    key={"answer3"}
                                    // fullWidth={true}
                                    style={inputStyles}
                                    // disableUnderline={true}
                                    // placeholder={"Enter" }
                                    name={"answer3"}
                                    // value={item in serviceData?serviceData[item]:""}
                                    onChange={handleInputChange}
                                />
                                
                            </>


                        </FormControl>
                    :""
                }
            </Grid>

            <Grid item xs={12}>
                <div style={{margin: '30px 0'}} className='d-flex'>
                    <div style={{flex: 1}}></div>
                    <Button variant='contained'
                            className="my-4 rounded border-0"
                            style={{color: "#fff", textTransform: 'none', background: '#F0973B',}}
                            disabled={isLoading} color="primary"
                            onClick={handleContinueButton}
                        >
                        {
                        isLoading?
                            <SyncLoader
                            loading={isLoading}
                            size={10}
                            color="#fff"
                            />
                            :
                        "Next"
                        }
                    </Button>
                </div>

            </Grid>

        </Grid>
    )


}