import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { collection, deleteDoc, doc, endAt, getDoc, getDocs, getFirestore, limit, orderBy, query, setDoc, startAt, updateDoc, where } from "firebase/firestore";
import { capitalizeFirstLetter } from "Utils/functions";
import { firebaseConfig } from "../../AppSettings";

const app = initializeApp(firebaseConfig);
const db = getFirestore (app)
const realTimeDb = getDatabase()


export const saveInvoice = (invoice, isInvoices = true)=>new Promise(function(successCallback, errorCallback){

    const txt = isInvoices?"Invoices":"Estimates"
    const myDocRef = (invoice.invoiceCompanyId || invoice.estimateCompanyId)?doc(db, "Companies/"+(invoice.invoiceCompanyId || invoice.estimateCompanyId)+"/"+txt+"/"+(invoice.invNo || invoice.estNo))
        :doc(db, "Users/"+invoice.userId+"/"+txt+"/"+(invoice.invNo || invoice.estNo))
    setDoc(myDocRef, invoice).then(successCallback).catch(errorCallback)
})




export const updateInvoice = (invoice, invNo,  providerId, isCompany = false, isInvoices = true)=>new Promise(function(successCallback, errorCallback){

    const txt = isInvoices?"Invoices":"Estimates"
    const myDocRef = (isCompany)?doc(db, "Companies/"+providerId+"/"+txt+"/"+invNo)
        :doc(db, "Users/"+providerId+"/"+txt+"/"+invNo)
    updateDoc(myDocRef, invoice).then(successCallback).catch(errorCallback)
})



export const deleteInvoice = (invNo, providerId, isCompany = false, isInvoices = true)=>new Promise(function(successCallback, errorCallback){

    const txt = isInvoices?"Invoices":"Estimates"
    const myDocRef = (isCompany)?doc(db, "Companies/"+providerId+"/"+txt+"/"+invNo)
        :doc(db, "Users/"+providerId+"/"+txt+"/"+invNo)
    deleteDoc(myDocRef).then(()=>{
        successCallback("success")
    }).catch(errorCallback)
})
 
export const getAllInvoices = (providerId, updatedAt, pageLimit = 10, isCompany = false, staffUserId = null, isInvoices = true)=>new Promise( async function(successCallback, errorCallback) {

    try {

        console.log("getAllInvoices")
        console.log("provId", providerId)
        console.log("updatedAt", new Date(updatedAt).toString())
        console.log("pageLimit", pageLimit)


        const txt = isInvoices?"Invoices":"Estimates"
        const myDocRef = (isCompany)?collection(db, "Companies/"+providerId.toString()+"/"+txt+"/")
            :collection(db, "Users/"+providerId.toString()+"/"+txt+"/")
            
        const q = staffUserId?
            query(myDocRef,
                where("updatedAt", "<", parseFloat(updatedAt)),
                where("userId", "==", staffUserId),
                orderBy("updatedAt", "desc"),
                limit(pageLimit)
            )
        :
            query(myDocRef,
                where("updatedAt", "<", parseFloat(updatedAt)),
                orderBy("updatedAt", "desc"),
                limit(pageLimit)
            )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});


export const getAllInvoice = (invNo, providerId, isCompany = false, isInvoices = true)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const txt = isInvoices?"Invoices":"Estimates"
        const myDocRef = (isCompany)?doc(db, "Companies/"+providerId.toString()+"/"+txt+"/"+invNo)
            :doc(db, "Users/"+providerId.toString()+"/"+txt+"/"+invNo)
            
        
        const docSnap = await getDoc(myDocRef)
        if(docSnap.exists()){
            successCallback(docSnap.data())
        } else {
            successCallback({})
        }
    }catch(error) {
        errorCallback(error)
    }
});


export const getAllContactInvoices = (providerId, updatedAt, pageLimit = 10, isCompany = false, contactId, staffUserId = null, isInvoices = true)=>new Promise( async function(successCallback, errorCallback) {

    try {

        
        

        const txt = isInvoices?"Invoices":"Estimates"
        const myDocRef = (isCompany)?collection(db, "Companies/"+providerId.toString()+"/"+txt+"/")
            :collection(db, "Users/"+providerId.toString()+"/"+txt+"/")
            
        const q = staffUserId?
            query(myDocRef,
                where("updatedAt", "<", parseFloat(updatedAt)),
                where(isInvoices?"invoiceClientId":"estimateClientId", "==", contactId),
                where("userId", "==", staffUserId),
                orderBy("updatedAt", "desc"),
                limit(pageLimit)
            )
        :
            query(myDocRef,
                where("updatedAt", "<", parseFloat(updatedAt)),
                where(isInvoices?"invoiceClientId":"estimateClientId", "==", contactId),
                orderBy("updatedAt", "desc"),
                limit(pageLimit)
            )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});



export const searchInvoices = (providerId, title, pageLimit = 10, isCompany = false, staffUserId = null, isInvoices = true)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const txt = isInvoices?"Invoices":"Estimates"
        const myDocRef = (isCompany)?collection(db, "Companies/"+providerId.toString()+"/"+txt+"/")
            :collection(db, "Users/"+providerId.toString()+"/"+txt+"/")
            
        const q = staffUserId?
            query(myDocRef,
                where("userId", "==", staffUserId),                
                orderBy(isInvoices?"invoiceTitle":"estimateTitle"),        
                startAt(title),
                endAt(title + '\uf8ff'),
                limit(pageLimit)
            )
        :
            query(myDocRef,             
                orderBy(isInvoices?"invoiceTitle":"estimateTitle"),        
                startAt(title),
                endAt(title + '\uf8ff'),
                limit(pageLimit)
            )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});

export const saveInvoiceItem = (item, providerId, isCompany= false, isInvoices = true)=>new Promise(function(successCallback, errorCallback){

    const txt = isInvoices?"InvItems":"EstItems"
    let url = (isCompany)?"Companies/"+providerId+"/"+txt+"/"+(item.invoiceID || item.estimateID)
        :"Users/"+providerId+"/"+txt+"/"+(item.invoiceID || item.estimateID)
    url += "/Items/"+(item.billItemNoe || item.billItemNo)

    const myDocRef = doc(db, url)
    setDoc(myDocRef, item).then(successCallback).catch(errorCallback)
})




export const updateInvoiceItem = (item, invoiceID, billItemNoe,  providerId, isCompany = false, isInvoices = true)=>new Promise(function(successCallback, errorCallback){

    const txt = isInvoices?"InvItems":"EstItems"
    let url = (isCompany)?"Companies/"+providerId+"/"+txt+"/"+invoiceID
        :"Users/"+providerId+"/"+txt+"/"+invoiceID
    url += "/Items/"+billItemNoe

    const myDocRef = doc(db, url)
    updateDoc(myDocRef, item).then(successCallback).catch(errorCallback)
})



export const deleteInvoiceItem = (invoiceID, billItemNoe, providerId, isCompany = false, isInvoices = true)=>new Promise(function(successCallback, errorCallback){

    const txt = isInvoices?"InvItems":"EstItems"
    let url = (isCompany)?"Companies/"+providerId+"/"+txt+"/"+invoiceID
        :"Users/"+providerId+"/"+txt+"/"+invoiceID
    url += "/Items/"+billItemNoe
    const myDocRef = doc(db, url)
    deleteDoc(myDocRef).then(()=>{
        successCallback("success")
    }).catch(errorCallback)
})
 
export const getAllInvoicesItems = (invoiceID, providerId, isCompany = false, isInvoices = true)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const txt = isInvoices?"InvItems":"EstItems"
        let url = (isCompany)?"Companies/"+providerId+"/"+txt+"/"+invoiceID
        :"Users/"+providerId+"/"+txt+"/"+invoiceID
        url += "/Items/"
        const myDocRef = collection(db, url)
            
        const docSnap = await getDocs(myDocRef)        
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});


export const saveDefaultInvoiceItem = (item, providerId, isCompany= false)=>new Promise(function(successCallback, errorCallback){

    let url = (isCompany)?"CompanyBillingItems/"+providerId+"/Items/"+(item.itemNo)
        :"UserBillingItems/"+providerId+"/Items/"+(item.itemNo)

    const myDocRef = doc(db, url)
    setDoc(myDocRef, item).then(successCallback).catch(errorCallback)
})




export const updateDefaultInvoiceItem = (item, itemNo,  providerId, isCompany = false)=>new Promise(function(successCallback, errorCallback){

    let url = (isCompany)?"CompanyBillingItems/"+providerId+"/Items/"+(itemNo)
        :"UserBillingItems/"+providerId+"/Items/"+(itemNo)

    const myDocRef = doc(db, url)
    updateDoc(myDocRef, item).then(successCallback).catch(errorCallback)
})



export const deleteDefaultInvoiceItem = (itemNo, providerId, isCompany = false)=>new Promise(function(successCallback, errorCallback){

    let url = (isCompany)?"CompanyBillingItems/"+providerId+"/Items/"+(itemNo)
        :"UserBillingItems/"+providerId+"/Items/"+(itemNo)
    const myDocRef = doc(db, url)
    deleteDoc(myDocRef).then(()=>{
        successCallback("success")
    }).catch(errorCallback)
})
 
export const getAllDefaultInvoicesItems = ( providerId, isCompany = false)=>new Promise( async function(successCallback, errorCallback) {

    try {


        let url = (isCompany)?"CompanyBillingItems/"+providerId+"/Items/"
        :"UserBillingItems/"+providerId+"/Items/"
        const myDocRef = collection(db, url)
            
        const docSnap = await getDocs(myDocRef)        
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});



export const searchDefaultInvoicesItems = (name, providerId, isCompany = false, pageLimit = 10)=>new Promise( async function(successCallback, errorCallback) {

    //first get data when name is lowercase and when first letter is capitalized
 
    try {

        let url = (isCompany)?"CompanyBillingItems/"+providerId+"/Items/"
        :"UserBillingItems/"+providerId+"/Items/"
        const myDocRef = collection(db, url)

        let q = 
            query(
                myDocRef,     
                orderBy("itemTitle"),        
                startAt(name.toLowerCase()),
                endAt(name.toLowerCase() + '\uf8ff'),
                limit(pageLimit)
            )
        let docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });

      
        q = 
            query(
                myDocRef,     
                orderBy("itemTitle"),  
                // where('fName', '>=', name),
                // where('fName', '<=', name+ '\uf8ff')   
                startAt(capitalizeFirstLetter(name)),
                endAt(capitalizeFirstLetter(name) + '\uf8ff'),
                limit(pageLimit)
            )
            
            docSnap = await getDocs(q)
            docSnap.forEach((doc) => {
                _data.push(doc.data())        
            });
        successCallback( _data )
    }catch(err){
        errorCallback(err)
        
    }
});

