
import { initializeApp } from "firebase/app";
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { LOCATION_STATUS } from "Utils/constants";
import { firebaseConfig } from "../../AppSettings";

const app = initializeApp(firebaseConfig);
const db = getFirestore (app)

export const saveLocation = (data, LocationID, isCompany = false)=>new Promise(function(successCallback, errorCallback){
    
    getLocationsFromDb(isCompany?data["companyID"]:data["creatorID"], isCompany).then((_data)=>{
        if(_data.length >= 5) {
            errorCallback("Sorry, you can only add upto 5 locations.")
        }else {

            const myDocRef = doc(db, "Locations", LocationID)
            setDoc(myDocRef, data).then(successCallback).catch(errorCallback)
        }
    }).catch(errorCallback)
})

export const archiveLocation = (LocationID)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = doc(db, "Locations", LocationID)
    updateDoc(myDocRef, {"status": LOCATION_STATUS[1]}).then(successCallback).catch(errorCallback)
})

export const getLocationsFromDb = (firebaseUserID, isCompany)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = collection(db, "Locations/" )
    // const q = query(myDocRef, orderBy("locationName"))
    const q = query(myDocRef, 
        where(isCompany?"companyID":"creatorID", "==", firebaseUserID),
        where("status", "==", LOCATION_STATUS[0]),
        orderBy("locationName"))
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((_doc) => {
        _data.push(_doc.data())        
    });
    successCallback(_data)
});


export const getLocInfoFromDb = (locId)=>new Promise( async function(successCallback, errorCallback) {

    try{
        const myDocRef = doc(db, "Locations/"+locId+"/" )
        const docSnap = await getDoc(myDocRef)
        successCallback(docSnap.data())
    }catch (err) {
        errorCallback(err)
    }
});

export const getCustomLocationsFromDb = (firebaseUserID, isCompany)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = collection(db, "Locations/" )
    // const q = query(myDocRef, orderBy("locationName"))
    const q = query(myDocRef, 
        where(isCompany?"companyID":"creatorID", "==", firebaseUserID),
        where("status", "==", LOCATION_STATUS[0]),
        where("locType", "==", "Onsite"),
        // orderBy("locationName")
        )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((_doc) => {
        _data.push(_doc.data())        
    });
    successCallback(_data)
});


export const getOfflineLocationsFromDb = (firebaseUserID, isCompany)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = collection(db, "Locations/" )
    // const q = query(myDocRef, orderBy("locationName"))
    const q = query(myDocRef, 
        where(isCompany?"companyID":"creatorID", "==", firebaseUserID),
        where("status", "==", LOCATION_STATUS[0]),
        where("locType", "==", "Offline"),
        // orderBy("locationName")
        )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((_doc) => {
        _data.push(_doc.data())        
    });
    successCallback(_data)
});





export  const deleteLocation = (locId, firebaseUserID)=>new Promise(function(successCallback, errorCallback){

    //first check if location is associated with any service
    //if associated dont delete else delete
    
    const myDocRef = collection(db, "UserServices/"+ firebaseUserID+ "/Services/")
    const q = query(myDocRef,  where("serviceLocation", "==", locId), orderBy("serviceTitle"))
    const getServices = async ()=>{

        const docSnap = await getDocs(q)
        
        const _data = []
        docSnap.forEach((_doc) => {
            _data.push(_doc.data())        
        });
        if(_data.length > 0) {
            // errorCallback("foreign constraint error")
            //archive location
            archiveLocation(locId).then(successCallback).catch(errorCallback)
        }else {
            
            const myDocRef1 = doc(db, "Locations", locId)
            deleteDoc(myDocRef1).then(successCallback).catch(errorCallback)
        }  
    } 
    getServices()
    
})