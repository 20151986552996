import { CameraAlt, Category, Close, Delete } from '@mui/icons-material';
import { Avatar, Badge, FormControl, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DivCenterContents from 'components/DivCenterContents';
import { deleteCategory, deleteCategoryProfile, saveCategory, updateCategory, updateCategoryProfile } from 'FirebaseController/Services/services';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { refreshServices } from 'Store/Reducers/Services/services.actions';
import { APP_MANAGER_TYPES, MAX_LOGO_FILE_SIZE } from 'Utils/constants';
import { inputStyles } from 'Utils/style';

export default function CategoryForm(props) {

    const { isEdit, data, configs} = props
    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    const appConfigReducer = configs
    const navigate = useNavigate()
    const { companyName }  = useParams()
    const [category, setCategory] = useState({})
    const [inputLogoFile, setInputLogoFile] = React.useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    

    const handleFormData = (name, value)=>{
        setCategory({
            ...category,
            [name] : value,
        })
    }

    
    
    const handleFileChange = (e) => {

        
        
        if(e.target.files.length > 0){
            if(e.target.files[0].size/ (1024) > MAX_LOGO_FILE_SIZE){//greater than 300 kb
                showSnackBar("File is too big, only 300kb are allowed.", "error")
            }else {
                setInputLogoFile(e.target.files[0])
                
                
            }
        }
    }
    
    const saveCategoryLogo = () => {
        

        showSnackBar("Uploading logo, please wait..", "success")

        updateCategoryProfile(
            inputLogoFile,
            appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
            category.catImg || "",
            appConfigReducer.type === APP_MANAGER_TYPES[1]
        )
        .then((url) => {//success callback function
                
                
                setCategory({
                    ...category,
                    "catImg": url,
                })
                showSnackBar("Upload successful. click save to update the category", "success")
            }           

        ).catch(
            (err) => {
                showSnackBar("Could not upload image, please try again later.", "error")                
                
            }
        )
    }

    const handleItemDelete = () => {
        setIsDeleteLoading(true)
        deleteCategory(            
            category.catId,
            appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
            (appConfigReducer.type === APP_MANAGER_TYPES[1])
        )
        .then(()=>{
            setIsDeleteLoading(false)
            showSnackBar("Category deleted successfully")
            const url =  appConfigReducer.type === APP_MANAGER_TYPES[0]?"/services":"/co/"+companyName+"/services"
            window.location.href = (url)
        })
        .catch((err)=>{
            setIsDeleteLoading(false)
            
            showSnackBar("Sorry, could not delete category, try again later.")
        })
    }

    
    function handleDeleteImg() {
        showSnackBar("Deleting image. Please wait...", "success")
        
        deleteCategoryProfile(category.catImg)
        .then(()=>{
            
            updateCategory(
                {catImg: ""}, 
                category.catId,
                appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
                (appConfigReducer.type === APP_MANAGER_TYPES[1])
            ).then(()=>{
                showSnackBar("Image deleted successfully", "success")
                setCategory({
                    ...category,
                    catImg: ""
                })
                dispatch(refreshServices())
            })
        })
        .catch((err)=>{
            
            showSnackBar("Could not delete image, try again later", "error")

        })
    }
    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    const handleItemAdd = () => {
        if (!category.catTitle) {
            showSnackBar("Please enter category title", "error")
        } else if(!category.catDesc) {
            showSnackBar("Please enter category description", "error")
        } else {
            const data_ = {
                ...category,
                "catId": category.catId?category.catId:window.crypto.randomUUID().split('-').join(''),
                "createdAt": category.createdAt?parseFloat(category.createdAt):parseFloat(new Date().valueOf()),
                "updatedAt": parseFloat(new Date().valueOf()),
            }
            
            if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
                
                data_["companyId"] = appConfigReducer.data.companyID.toString()
            }

            const editData = {
                "catTitle" : data_["catTitle"],
                "catDesc" : (data_["catDesc"] || null),
                "catImg" : (data_["catImg"] || null),
                "updatedAt" : data_["updatedAt"],
            }

            const ref = isEdit === 0?
                saveCategory(
                    data_, 
                    appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
                    (appConfigReducer.type === APP_MANAGER_TYPES[1]),
                    user.uid
                )
            :isEdit === 1?
                updateCategory(
                    editData, 
                    category.catId,
                    appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID.toString():user.uid,
                    (appConfigReducer.type === APP_MANAGER_TYPES[1])
                )
            :null

            if(ref) {
                setIsLoading(true)
                const txt = isEdit === 0?"added":"updated"
                ref.then(()=>{
                    showSnackBar("Category "+txt+" successfully.", "success")   
                    setIsLoading(false) 
                    const url =  appConfigReducer.type === APP_MANAGER_TYPES[0]?"/services":"/co/"+companyName+"/services"
                    dispatch(refreshServices())
                    navigate(url)                   
                })
                .catch((err)=>{
                    
                    const errMsg = (typeof err === 'string')?err:"Sorry, could not "+txt+" Category, try again later."
                    showSnackBar(errMsg, "error")
                    setIsLoading(false)
                })
            }


            
        }
    }

    useEffect(() => {
      if(data) {
        setCategory(data)
      }
    
      return () => null
    }, [data])
    
    

    useEffect(() => {
      if(inputLogoFile && !(typeof inputLogoFile === 'string' || inputLogoFile instanceof String)) {
        saveCategoryLogo()
      }
    
      return () => null
    }, [inputLogoFile])
    
    

    return (
        
        <Grid container spacing={1} className='px-4 pb-4 my-3'>
 

            <Grid item xs={12}>
                <div>
                    <DivCenterContents _size={6}>
                        
                            <div className="position-relative">
                                <input 
                                    accept="image/*" id="icon-button-file" 
                                    onChange={handleFileChange}
                                    type="file" style={{display: 'none'}} />
                                <label htmlFor="icon-button-file">
                                    <Badge
                                        overlap="circular"
                                        badgeContent={
                                            <Avatar sx={{bgcolor: "#63C3CF"}}><CameraAlt /></Avatar>
                                        }
                                    
                                    >
                                        {
                                            (category.catImg || inputLogoFile)?
                                                <Avatar 
                                                    // variant="square"
                                                    // src={profileLink && Object.keys(profileLink).length !== 0?profileLink:profile}
                                                    src={category.catImg || inputLogoFile}
                                                    sx={{width: '110px', height: '110px', border: 'none', background: "inherit"}} >
                                                    
                                                    <Category style={{fontSize: '80px', color: "#F0973B"}} />

                                                </Avatar>

                                            :
                                            
                                            <Avatar 
                                                // variant="square"
                                                sx={{width: '110px', height: '110px', border: 'none', background: "inherit"}} 
                                                color="primary"
                                            >

                                                <Category style={{fontSize: '80px', color: "#F0973B"}} />
                                            </Avatar>

                                        }
                                    </Badge>
                                </label><   br />
                                
                                {
                                    category.catImg?
                                    <Tooltip title="Delete image">
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                top: -5,
                                                right: -50
                                            }}
                                            onClick={handleDeleteImg}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>

                                    :""
                                }
                                <Typography variant='body2' sx={{fontSize: '11px', float: 'right', paddingLeft: '5px',paddingTop: '0'}} className="">
                                    Max size: {MAX_LOGO_FILE_SIZE} kb
                                </Typography>
                            </div>
                    </DivCenterContents>
                </div>

            </Grid>

            <Grid item xs={12}>
                <div className='border p-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '10px'}}>
                    <label htmlFor='catTitle'>
                       {"Category title"} 
                    </label><br />
                    
                    <textarea id="catTitle" style={{
                            borderRadius: '5px',
                            background: 'transparent',
                            border: 'none',
                            padding: '5px',
                            width: '100%',
                            resize: 'none'
                        }} 
                        type="text" 
                        name="catTitle" 
                        placeholder='Enter text here'
                        value={category["catTitle"]?category["catTitle"]:""}
                        rows="1"
                        cols="50"
                        onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                    ></textarea>

                </div>
            </Grid>
            <Grid item xs={12} >
                <FormControl variant="standard" className="py-1 mb-1" fullWidth>
                    <label htmlFor="input-with-icon-adornment6" className='py-0 my-0'>
                        Description
                    </label>
                    <textarea id="catDesc" style={inputStyles} 
                        type="text" 
                        name="catDesc" 
                        placeholder='Enter text here'
                        value={category["catDesc"]?category["catDesc"]:""}
                        rows="3"
                        cols="50"
                        onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                    ></textarea>
                </FormControl>
            </Grid>
            
            <Grid item xs={12} className="d-flex">
                <div style={{flex: 1}}></div>
                
                <Button
                    className={"rounded border-0 my-4 ml-4 text-capitalize"}
                    color="white"
                    type="button" 
                    style={{background: '#F0973B',
                        
                    }}
                    onClick={handleItemAdd}
                >
                    {
                        
                        isLoading?
                            <SyncLoader
                                loading={isLoading}
                                size={10}
                                color="#fff"
                            />
                        :
                        "Save"
                    }
                    
                    
                </Button>

                {
                    isEdit === 4?

                        <Button
                            className={"rounded border-0 my-4 ml-2 text-capitalize"}
                            color="white"
                            type="button" 
                            style={{background: '#000',
                                
                            }}
                            onClick={handleItemDelete}
                        >
                            {
                                
                                isDeleteLoading?
                                    <SyncLoader
                                        loading={isDeleteLoading}
                                        size={10}
                                        color="#fff"
                                    />
                                :
                                "Delete"
                            }
                            
                            
                        </Button>

                    :""

                }
                
                
            </Grid>

            
            
            
            
            

        </Grid>

    )

}