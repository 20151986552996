
/* global gapi */

import AccessTime from '@mui/icons-material/AccessTime';
import Business from '@mui/icons-material/Business';
import Close from '@mui/icons-material/Close';
import MiscellaneousServices from '@mui/icons-material/MiscellaneousServices';
import Whatshot from '@mui/icons-material/Whatshot';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { clientId } from 'AppSettings';
import AddMeetingModal from 'components/Modal/AddMeetingModal';
import { deleteField } from 'firebase/firestore';
import { saveCompanyClientTotalBookings, saveCompanyTotalBookings, saveProviderClientTotalBooking, saveProviderTotalBookings, saveServiceTotalBookingsAnalytics } from 'FirebaseController/Analytics';
import { updateBooking } from 'FirebaseController/Booking/booking';
import { getCompanyData, getCompanyManagers, getStaffMember, getUserSignedInViaCompany } from 'FirebaseController/Company';
import { saveContactActivity } from 'FirebaseController/CRMController';
import { addEvent, updateEvent } from 'FirebaseController/Events';
import { sendFcmNotification } from 'FirebaseController/Notifications/fcmNotifications';
import { addNotif } from 'FirebaseController/Notifications/localNotifs';
import { getSetingsData, saveSettings } from 'FirebaseController/Settings';
import { getUserDataFromDb, getUserSignedInViaProvider } from 'FirebaseController/User/user';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { Button } from 'reactstrap';
import { dateTimeFormat, NOTIFICATION_STATUS } from 'Utils/constants';
import { addDateMins, defaultTimezone, getUserRedirectData } from 'Utils/functions';
import { createMeeting } from 'ZoomController';
import profile from '../../Utils/images/logo.png';

export default function NotificationCard(props) {

    const { booking, handleRefreshNotifs, configs } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isAcceptLoading, setIsAcceptLoading] = React.useState(false);
    const [isDeclineLoading, setIsDeclineLoading] = React.useState(false);
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj.user
    const [companyManagers, setCompanyManagers] = React.useState([])
    const [clientData, setClientData] = React.useState({})
    const bookingId = booking.orderId
    const {companyName} = useParams()
    const [companyData, setCompanyData] = React.useState({});
    const [openPermissionDialog, setOpenPermissionDialog] = React.useState(false);
    const navigate = useNavigate()
    const [bookingClient, setBookingClient] = React.useState(null);
    const [bookingProvider, setBookingProvider] = React.useState(null);
    const appConfigReducer = configs



    const handleAcceptbooking = ()=> {
        const data = {
            "appointmentStatus" : "ACCEPTED",
            "modifyTime" :  new Date().valueOf().toString(),
            "notificationStatus" : "READ"
        }
        setIsAcceptLoading(true)
        updateBooking(data, bookingId).then(()=>{
            // handleRefreshNotifs()
            handleAddEvent()
           
            //setIsAcceptLoading(false)
            
            //save contact activity
            const activity = {}
            activity["activityId"] = window.crypto.randomUUID().split('-').join('')
            activity['title'] = "Booking accepted for " + booking.services[0].serviceTitle
            activity["createdAt"] = new Date().getTime().toString()
            activity["contactId"] =  null
            activity["userId"] =  booking.client.clientId
            activity["createdBy"] =  booking.provider.providerId
            activity["label"] =  "BOOKING UPDATE"
            activity["bookingId"] =  booking.orderId

            saveContactActivity(booking.provider.providerId, activity, (appConfigReducer.data.companyID?appConfigReducer.data.companyID.toString():null))
                .then(()=>{
                    
                })
                .catch((err)=>{
                    

                })

            if("companyId" in booking && booking.companyId) {
                saveCompanyClientTotalBookings(booking.companyId, booking.client.clientId, 1)
                    
                    
                saveCompanyTotalBookings(booking.companyId, 1)
                    
                saveServiceTotalBookingsAnalytics(1, booking.services[0].serviceId, booking.companyId, true)
            }else {
                saveProviderClientTotalBooking(booking.companyId, booking.client.clientId, 1)
                    
                saveProviderTotalBookings(booking.provider.providerId, 1)
                    
                saveServiceTotalBookingsAnalytics(1, booking.services[0].serviceId, booking.provider.providerId, false)
                
            }

            //setIsAcceptLoading(false)
        }).catch((err)=>{
            
            setIsAcceptLoading(false)
        })
    }

    function handleAddEvent(isFromSignIn = false) {
        
        if(isFromSignIn) { setIsAcceptLoading(isFromSignIn) }
        getSetingsData(user.uid, ("companyId" in booking && booking.companyId)?booking.companyId:null).then((settingsData)=>{

            
            
            
            //check if google calendar is activated
            if(settingsData["googleCalendar"] === true){

        

                let eventDes = "Service provider: "+bookingProvider.firstname+" "
                    +bookingProvider.surname + "\n"+
                    "Client name: "+(bookingClient.firstname || bookingClient.fName || "Anonymous") + " " + (bookingClient.surname || bookingClient.sName || "user") + "\n"+
                    "Duration: "+booking.services.reduce((partialSum, a) => partialSum + parseInt(a.duration), 0)+" mins \n"
                if(booking.bookingDetails) {
                    eventDes += "Booking details: "+booking.bookingDetails+" \n"
                }
                if(booking.services) {
                    eventDes += "Services: "+booking.services.map((item)=>item.serviceTitle).join(",")+" \n" +
                    "Services descriptions: "+booking.services.map((item)=>item.serviceDesc).join(",")+" \n" 
                }

                const eventData = {
                    //"summary": "You have an appointment with "+bookingClient.firstname+" "
                        //+bookingClient.surname,
                    "summary":  "Appointment booking for " + booking.services.map((item)=>item.serviceTitle).join(","),
                    "description": eventDes,
                    // 'location': booking.location,
                    'start': {
                        'dateTime': new Date(booking.date.replace(/-/g,'/')+" UTC"),
                        'timeZone': defaultTimezone() 
                    },
                    'end': {
                        'dateTime': addDateMins(new Date(booking.date.replace(/-/g,'/')+" UTC"), parseInt(booking.services.reduce((accum,item) => parseFloat(accum) + parseFloat(item.duration), 0))),
                        'timeZone': defaultTimezone()
                    },
                    "extendedProperties": {
                        "private": {
                            "priority": "high"
                        }
                    },
                    'reminders': {
                        'useDefault': false,
                        'overrides': [
                            {'method': 'email', 'minutes': 24 * 60},
                            {'method': 'popup', 'minutes': 10}
                        ]
                    }
                };
                

                
                if("primaryEmail" in bookingClient && bookingClient.primaryEmail) {
                    eventData['attendees'] = [
                        {
                            'email' : bookingClient.primaryEmail,
                            'displayName' : (bookingClient.firstname || bookingClient.fName || "Anonymous") + " " + (bookingClient.surname || bookingClient.sName || "user"),
                            'organizer' : false, 
                            'responseStatus' : "needsAction",
                        }
                    ]
                }

                //check if there is location
                if(("serviceLocation" in booking.services[0])) {
                    eventData["location"] = booking.location
                }

                //if no location then service is online hence set meeting link
                let gMeetVersion = 0
                if(settingsData["googleMeet"] === true && ("serviceType" in booking.services[0] && booking.services[0].serviceType.toLowerCase() === "online")){
                    //add meeting link details
                    gMeetVersion = 1
                    eventData["conferenceData"] = {
                        "createRequest" : {
                            "requestId": Math.random().toString(10).slice(2),
                            "conferenceSolutionKey": {
                                "type": "hangoutsMeet",
                            }
                        }
                    }

                }


                addEvent(eventData, gMeetVersion).then((gEventResult)=>{
                    

                    //update booking
                    
                    if("hangoutLink" in gEventResult.data) {

                        const data = {
                            "googleMeetLink" : gEventResult.data.hangoutLink,
                            "gEventId": gEventResult.data.id,
                            "hasMeetingLink" : true,
                            "notificationStatus" : deleteField(),
                            "location" : deleteField(),
                            "zoomMeetingDetails" : deleteField(),
                            "enableAutoBook" : deleteField(),
                            "modifyTime" :  new Date().valueOf().toString(),
                        }
                        updateBooking(data, bookingId).then(()=>{
                            
                            
                            setIsAcceptLoading(false)
                            handleRefreshNotifs()
                            showSnackBar("Appointment updated successfully", "success")
                            setIsAcceptLoading(false)
                        })
                        if("enableAutoBook" in booking && booking.enableAutoBook === true) {
                            setIsAcceptLoading(false)
                        }
                    }else if(settingsData["zoomMeet"] === true && ("serviceType" in booking.services[0] && booking.services[0].serviceType.toLowerCase() === "online")){
                    
                        
                        createMeeting( booking.date, booking.services.reduce((accum,item) => parseFloat(accum) + parseFloat(item.duration), 0),(bookingProvider["firstname"] + " " + bookingProvider["surname"]))
                        .then((_data)=>{
                            
                            
                            //update event
                            const newEvent = gEventResult.data
                            newEvent["location"] = _data.zoomMeetingDetails.joinLink
                            updateEvent( gEventResult.data.id, newEvent, 0).then((data)=>{
                                
                            })

                            //update booking
                            const ___data = {
                                ..._data, 
                                "hasMeetingLink": true,
                                "gEventId": gEventResult.data.id,
                                "hasMeetingLink" : true,
                                "notificationStatus" : deleteField(),
                                "location" : deleteField(),
                                "googleMeetLink": deleteField(),
                                "enableAutoBook" : deleteField(),
                                "modifyTime" :  new Date().valueOf().toString(),

                            }
                            updateBooking(___data, bookingId).then(()=>{
                                
                                
                                if("enableAutoBook" in booking && booking.enableAutoBook === true) {
                                    setIsAcceptLoading(false)
                                    handleRefreshNotifs()
                                    showSnackBar("Appointment added successfully", "success")
                                }
                            }).catch((err)=>{
                                
                                setIsAcceptLoading(false)
                            })
                        }).catch((err)=>{
                            
                            setIsAcceptLoading(false)
                        })
                    }else {                        
                        const data = {
                            "gEventId": gEventResult.data.id,
                            "notificationStatus" : deleteField(),
                            "enableAutoBook" : deleteField(),
                            "googleMeetLink" : deleteField(),
                            "modifyTime" : new Date().valueOf().toString(),
                        }
                        updateBooking(data, bookingId).then(()=>{
                            
                            setIsAcceptLoading(false)
                            handleRefreshNotifs()
                            showSnackBar("Appointment updated successfully", "success")
                        })
                    }
                    const notiText = bookingProvider["firstname"] + " " + bookingProvider["surname"]+
                    " has accepted an appointment with you."
                    const fcmData = {
                        "user": user.uid,
                        "icon": 0,
                        "body": notiText,
                        "title": "New appointment request",
                        "sented": booking.client.clientId,
    
                    }
                    sendFcmNotification(fcmData, booking.client.clientId)
                    .catch((err)=>{
                        
                        
                        
                        
                    })
                    setNotif("New appointment request", notiText, user.uid, booking.client.clientId)
    
                    
                    if(companyManagers.length > 0) {
                        const notiText = bookingProvider["firstname"] + " " + bookingProvider["surname"]+
                        " has accepted an appointment with "+
                            bookingClient["firstname"] + " " + bookingClient["surname"]
                        companyManagers.forEach(manager => {
                            if(manager.userId !== booking.client.clientId){//check if provider is a manager too, to avoid sending multiple notifs
                                const fcmData = {
                                    "user": user.uid,
                                    "icon": 0,
                                    "body": notiText,
                                    "title": "New appointment request",
                                    "sented": manager.userId,
                                }
                                sendFcmNotification(fcmData, manager.userId)
                            }
                        })
                    }

                }).catch((err)=>{
                    
                    if("response" in err && err.response.data.error.code === 403) {
                        setOpenPermissionDialog(true)
                        // showSnackBar("Sorry you have not enabled google calendar integration in settings", "error")
                    //    navigate(companyName?"/co/"+companyName+"/settings":"/settings")
                    //     if(window.confirm("Sorry you have not enabled google calendar, do you want to enable it now?")) {
                    //         signIn()
                    //     }else {                                
                    //         showSnackBar("Could not add booking to your events. You have to activate google calendar in order to use this feature", "error")
                    //         setIsAcceptLoading(false)
                    //     }
                    }
                    setIsAcceptLoading(false)
                })
            }else {
                if(("companyId" in booking && booking.companyId)) {
                    if((booking.services[0].serviceType.toLowerCase() === "online")) {
                        
                        // showSnackBar("Sorry you have not enabled google calendar integration in settings", "error")
                        setOpenPermissionDialog(true)
                        // navigate("/co/"+companyName+"/settings")
                        // if(window.confirm("Sorry you have not enabled google calendar, do you want to enable it now?")) {
                        //     signIn()
                        // }else {                                
                        //     showSnackBar("Could not add booking to your events. You have to activate google calendar in order to use this feature", "error")
                        //     setIsAcceptLoading(false)
                        // }
                    }
                }else {
                    setOpenPermissionDialog(true)
                    // if(window.confirm("Sorry you have not enabled google calendar, do you want to enable it now?")) {
                    //     signIn()
                    // }else {
                    //     showSnackBar("Could not add booking to your events. You have to activate google calendar in order to use this feature", "error")
                    //     setIsAcceptLoading(false)
                    // }
                }
            }
            //add zoom meeting url

        }).catch((err)=>{
            showSnackBar("could not get settings data", "error")
            
        })
    }

    
    const signIn = ()=> {

        gapi.load('auth2', function(){

            let auth2 = gapi.auth2.init({
                client_id: clientId
            });
            const option = new gapi.auth2.SigninOptionsBuilder();
            option.setScope('https://www.googleapis.com/auth/calendar');

            const googleUser = auth2.currentUser.get();
            googleUser.grant(option).then(
                function(success){
                    
                    //handleSignIn(success)
                    gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse().then((resp)=>{
                        userObj.tokenDetails = resp
                        userObj.token = resp.access_token
                        localStorage.setItem("user", JSON.stringify(userObj ))
                        // setChecked({
                        //     ..._checked,
                        //     'googleCalendar': true
                        // }
                        const _checked = {
                            'googleCalendar': true,
                            'googleMeet': true,
                        }
                        saveSettings(_checked, user.uid, "companyId" in booking?booking.companyId:null).then(()=>{

                            
                            handleAddEvent(true)
                            setIsAcceptLoading(false)
            
                        }, (err)=>{
                            
                            setIsAcceptLoading(false)
                        })
                    
                    }) 
                },
                function(fail){
                    alert(JSON.stringify({message: "fail", value: fail}));
                    handleSignInError(fail)
                }
            );

        });
  
    }
 

    function handleSignInError(err) {
        
        const data = {
            appointmentStatus: ("enableAutoBook" in booking && booking.enableAutoBook)? "ACCEPTED" : "PENDING"
        }
        updateBooking(data, booking.orderId)
        setIsAcceptLoading(false)
        showSnackBar("Could not add booking to your events. You have to activate google calendar in order to use this feature", "error")
    }
    
    function handleCancelPermission() {

        showSnackBar("Could not add booking to your events. You have to activate google calendar in order to use this feature", "error")
        setIsAcceptLoading(false)
        if(booking.enableAutoBook !== true) {
                
            const data = {
                "appointmentStatus" : "PENDING",
                "modifyTime" : new Date().valueOf().toString(),
                "notificationStatus" : "READ"
            }
            updateBooking(data, bookingId)
        }
    }

    const handleCancelbooking = ()=> {
        const data = {
            "appointmentStatus" : "CANCELLED",
            "modifyTime" :  new Date().valueOf().toString(),
            "notificationStatus" : "READ"
        }
        setIsDeclineLoading(true)
        updateBooking(data, bookingId).then(()=>{
            handleRefreshNotifs()
            showSnackBar("Appointment declined successfully", "success")
            setIsDeclineLoading(false)
            const notiText = bookingProvider["firstname"] + " " + bookingProvider["surname"]+
            " has cancelled an appointment with you."
            const fcmData = {
                "user": user.uid,
                "icon": 0,
                "body": notiText,
                "title": "New appointment request",
                "sented": booking.client.clientId,
            }
            sendFcmNotification(fcmData, booking.client.clientId)
            
            setNotif("New appointment request", notiText, user.uid, booking.client.clientId)

            if(companyManagers.length > 0) {
                const notiText = bookingProvider["firstname"] + " " + bookingProvider["surname"]+
                " has cancelled an appointment with "+
                    bookingClient["firstname"] + " " + bookingClient["surname"]
                companyManagers.forEach(manager => {
                    if(manager.userId !== booking.client.clientId && manager.userId !== user.uid){//check if provider is a manager too, to avoid sending multiple notifs
                        const fcmData = {
                            "user": user.uid,
                            "icon": 0,
                            "body": notiText,
                            "title": "New appointment request",
                            "sented": manager.userId,
                        }
                        sendFcmNotification(fcmData, manager.userId)
                        setNotif("New appointment request", notiText, user.uid, manager.userId)
                    }
                })
            }

            
            //save contact activity
            const activity = {}
            activity["activityId"] = window.crypto.randomUUID().split('-').join('')
            activity['title'] = "Booking cancellation for " + booking.services[0].serviceTitle
            activity["createdAt"] = new Date().getTime().toString()
            activity["contactId"] =  null
            activity["userId"] =  booking.client.clientId
            activity["createdBy"] =  booking.provider.providerId
            activity["label"] =  "CANCELLATION"
            activity["bookingId"] =  booking.orderId

            saveContactActivity(booking.provider.providerId, activity, (appConfigReducer.data.companyID?appConfigReducer.data.companyID.toString():null))
                .then(()=>{
                    
                })
                .catch((err)=>{
                    

                })

            
            // if("companyId" in booking && booking.companyId) {
            //     saveCompanyClientTotalBookings(booking.companyId, booking.client.clientId, -1)
            //         .then(()=>
            //         
            //     saveCompanyTotalBookings(booking.companyId, -1)
            //         .then(()=>
            //         
            // }else {
            //     saveProviderClientTotalBooking(booking.companyId, booking.client.clientId, -1)
            //         .then(()=>
            //         
            //     saveProviderTotalBookings(booking.provider.providerId, -1)
            //         .then(()=>
            //         
                
            // }
            
        })
    }

    const handleCreateMeetingForBooking = () => {
        
        setIsAcceptLoading(true)
        handleAddEvent()
    }

    const handleViewbooking = ()=> {

        localStorage.setItem("booking", JSON.stringify(booking))
        const url = companyName?'/co/'+companyName:''
        window.location.href = (url + '/view-booking')
    }

    function setNotif(title, body, userId, sented){
        //set local notification
      //we are not going to set all notifs since some are handled by app logic. 
        //only notifs that dont need user action are stored in local

        const notif = {
            title: title,
            body: body,
            sender: userId,
            receiver: sented,
            date: booking.date,
            notifId: + new Date(),
            userId: sented,
            status: NOTIFICATION_STATUS[1],
            createdAt:+ new Date(),
            companyId: "companyId" in booking && booking.companyId?booking.companyId.toString():null
        }
        addNotif(sented, notif).then(()=>{

            
        })
    }
    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    
    React.useEffect(()=>{
        if(booking && "companyId" in booking && booking.companyId) {
            getCompanyManagers((booking.companyId)).then((data)=>{
                setCompanyManagers(data)
            })
        }
    }, [])

    
    useEffect(() => {
        if("companyId" in booking && booking.companyId) {
            getCompanyData(booking.companyId).then(data=>{
                setCompanyData(data)
            })
        }
      return () => null
    }, [booking])

    React.useEffect(()=>{
        getUserDataFromDb(booking.client.clientId).then(data=>setClientData(data))

        //load google
        
    }, [])
    
    React.useEffect(() => {
      
        if(!bookingClient && "client" in booking && booking.client.clientId) {
            //get from latom profile
            getUserDataFromDb(booking.client.clientId).then((data)=>{
                
                if(Object.keys(data).length > 0) {
                    setBookingClient(data)
                }else {
                    //get from contacts
                    if("companyId" in booking && booking.companyId) {
                        getUserSignedInViaCompany(booking.companyId, booking.client.clientId)
                            .then(data_=>setBookingClient(data_))
                    }else{
                        getUserSignedInViaProvider(booking.provider.providerId, booking.client.clientId)
                            .then(data_=>setBookingClient(data_))
                    }
                }
            })
        }else {
            console.log("yeey", "yeey")
        }

        if(!bookingProvider && "provider" in booking && booking.provider.providerId) {
            
            if("companyId" in booking && booking.companyId ) {
                getStaffMember(booking.provider.providerId, booking.companyId).then((data)=>{
                    if(data.length  > 0) {
                        setBookingProvider(data[0])
                    }else {
                        getUserDataFromDb(booking.provider.providerId).then(data=>setBookingProvider(data))
                    }
                })
            }else {
                getUserDataFromDb(booking.provider.providerId).then(data=>setBookingProvider(data))
            }
        }
    
      return () => null
    }, [booking])


    
    

    return (

        <div className="alert text-dark d-flex m-0" fade={false.toString()} style={{background: "#F4F5FA", margin: 0, borderBottom: "1px solid #d3d3d3"}}>
          <span className="alert-inner--icon">
            <a style={{color: "#000"}} href={window.location.origin + "/profile/?user=" + getUserRedirectData(booking.client.clientId)}>
                <Avatar
                    src={(bookingClient && "profile" in bookingClient)?bookingClient.profile:profile}
                    sx={{ height: '45px', width: '45px', marginTop: '27%', background: 'inherit'}} 
                    aria-label="profile"
                    color="#fff"
                >
                    
                    <Whatshot color="primary" sx={{fontSize: '45px'}}/>
                </Avatar>
            </a>
          </span>
          <span className="alert-inner--text ml-1 mt-2">
            <strong style={{fontSize: '16px'}}>
                {
                    
                    bookingClient && bookingProvider?


                        <p style={{fontSize: '14px', padding: '0', margin: 0}}>
                            <a style={{color: "#000"}} href={window.location.origin + "/profile/?user=" + getUserRedirectData(booking.addedBy?booking.provider.providerId:booking.client.clientId)}>
                                <strong className="text-capitalize">
                                    {
                                        booking.addedBy?
                                            booking.addedBy === booking.provider.providerId?
                                                "You"
                                            :
                                                (bookingProvider.firstname || bookingProvider.fName || "Anonymous") + " " + (bookingProvider.surname || bookingProvider.sName || "user") + " "
                                        :
                                                (bookingClient.firstname || bookingClient.fName || "Anonymous") + " " + (bookingClient.surname || bookingClient.sName || "user") + " "
                                                
                                    }
                                </strong>
                            </a>
                            {
                                booking.addedBy?
                                " added "
                                :
                                (new Date(booking.date.replace(/-/g,'/')+ " UTC") < new Date())?" had requested ":
                                ("enableAutoBook" in booking && booking.enableAutoBook === true)?"booked ":" has requested "
                            }
                            an 
                                <strong> appointment</strong> with 
                                {
                                    booking.addedBy?
                                    " " + (bookingClient.firstname || bookingClient.fName || "Anonymous") + " " + (bookingClient.surname || bookingClient.sName || "user") + " "
                                    :
                                    (booking.provider.providerId !== user.uid)?
                                        " " +bookingProvider.firstname + " " +bookingProvider.surname + " "
                                    :
                                        " you"
                                }
                        </p>
                        
                    :""
                }
            </strong> 
            <Typography variant="body2" style={{fontSize: '13px', padding: 5, color: '#828282'}}>
                <span className='pr-1'>
                    <MiscellaneousServices style={{fontSize: '22px',}} color="primary" />
                </span>
                {booking.services.map(item=>item.serviceTitle).join(",")}
            </Typography>
            {
                Object.keys(companyData).length > 0 && "companyName" in companyData && companyData.companyName? 
                    <Typography variant="body2" style={{fontSize: '13px', padding: 5, color: '#828282'}}>
                        <span className='pr-1'>
                            <Business style={{fontSize: '22px',}} color="primary" />
                        </span>
                        {companyData.companyName}
                    </Typography>

                :""
            }
            <Typography variant="body2" style={{fontSize: '13px', padding: 5, color: '#828282'}}>
                <span className='pr-1'>
                    <AccessTime style={{fontSize: '22px',}} color="primary" />
                </span>
                {new Date(booking.date.replace(/-/g,'/')+ " UTC").toLocaleString("en-US", dateTimeFormat)}
            </Typography>
            
                <div className='d-flex mt-1'>
                    
                    <Button
                        size="sm"
                        color="warning"
                        onClick={handleViewbooking}
                        className='rounded px-4'
                        style={{backgroundColor: '#F0973B', borderColor: '#F0973B', textTransform: 'none'}}
                    >
                        View
                    </Button>
                        {
                            new Date(booking.date.replace(/-/g,'/')+ " UTC") > new Date()?
                            (
                                (
                                    ("enableAutoBook" in booking && booking.enableAutoBook === true)
                                    ||
                                    (booking.appointmentStatus === "ACCEPTED" && !booking.googleMeetLink)
                                ) 
                                 && booking.services[0].serviceType.toLowerCase() === "online")?

                                (booking.services[0].serviceType.toLowerCase() === "online" && 
                                "hasMeetingLink" in booking && booking.hasMeetingLink === false &&
                                (user && user.uid === booking.provider.providerId)
                                )?
                                
                                    <Button
                                        disabled={isAcceptLoading}
                                        size="sm"
                                        color="warning"
                                        onClick={handleCreateMeetingForBooking}
                                        className='rounded px-4'
                                        style={{backgroundColor: "#63C3CF", color: "#fff", borderColor: '#63C3CF', textTransform: 'none'}}
                                    >
                                        {
                                            isAcceptLoading?
                                                <SyncLoader
                                                loading={isAcceptLoading}
                                                size={10}
                                                color="#fff"
                                                />
                                                :
                                            "Add meeting"
                                        }
                                        
                                    </Button>
                                :""
                            :(user && user.uid !== booking.provider.providerId) || (booking.appointmentStatus !== "PENDING") ?"":
                                <>
                                    <Button
                                        size="sm"
                                        color="warning"
                                        disabled={isAcceptLoading}
                                        onClick={handleAcceptbooking}
                                        className='rounded px-4'
                                        style={{backgroundColor: "#63C3CF", color: "#fff", borderColor: '#63C3CF', textTransform: 'none'}}
                                    >
                                        {
                                            isAcceptLoading?
                                                <SyncLoader
                                                loading={isAcceptLoading}
                                                size={10}
                                                color="#fff"
                                                />
                                                :
                                            "Accept"
                                        }
                                        
                                    </Button>
                                    
                                    <Button
                                        size="sm"
                                        onClick={handleCancelbooking}
                                        className='rounded btn-light px-4'
                                        style={{background: "#6e7390", borderColor: "#6e7390", textTransform: 'none'}}
                                    >
                                        {
                                            isDeclineLoading?
                                                <SyncLoader
                                                loading={isDeclineLoading}
                                                size={10}
                                                color="#fff"
                                                />
                                                :
                                            "Decline"
                                        }
                                        
                                    </Button>
                                </>
                            
                            :""
                        }
                        
                    
                </div>
          </span>

          <AddMeetingModal 
            _propsOpen={openPermissionDialog}
            title={"Permission error"} 
            msg={"Sorry you have not enabled google calendar, do you want to enable it now?"} 
            sigIn={signIn} 
            handleCancelPermission={handleCancelPermission}
          
          />
        </div>
       

        
    )


}