import Close from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Outlet, useNavigate, useParams
} from 'react-router-dom';
import DivCenterContents from '../../components/DivCenterContents';
import { searchCompanyProfileLink } from '../../FirebaseController/Company';
import { APP_MANAGER_TYPES } from '../../Utils/constants';
import logo_loader from '../../Utils/images/loader-logo.png';

const UserContext = createContext()
export default function FormTheme(props) {

    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    const { companyName } = useParams()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isCompanyLoading, setIsCompanyLoading] = useState(true)
    const [appConfigReducer, setAppConfigReducer] = useState(
      {
        data: {},
        type: APP_MANAGER_TYPES[0]
      }
    )
  
    function showSnackBar(msg, variant = 'success'){
      enqueueSnackbar(msg, {
          variant: variant,            
          action: (key) => (
              <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                  <Close />
              </IconButton>
          ),
    })}
  
  
    useEffect(() => {
      if(companyName){

        searchCompanyProfileLink(companyName).then((data)=>{
          
          if(data.length  > 0) {
            setAppConfigReducer({
              type: APP_MANAGER_TYPES[1],
              data: data[0]
            })
          }else {
            showSnackBar("Invalid url/link, please recheck the same.", "error")
            navigate('/')
          }
          setIsCompanyLoading(false)
    
    
        }).catch((err)=>{
          
          setIsCompanyLoading(false)
          showSnackBar("Invalid url/link, please recheck the same.", "error")
          navigate('/')
        })
        setAppConfigReducer({
          ...appConfigReducer,
          type: APP_MANAGER_TYPES[0],
          data: user
        })
      }else if (user) {
        // dispatch(setAppManagerConfig(user, APP_MANAGER_TYPES[0] ))//SET REDUX MANAGER TO USER
        
        setAppConfigReducer({
          ...appConfigReducer,
          data: user
        })
        setIsCompanyLoading(false)
      }else{
        
        setIsCompanyLoading(false)
      }
    
      return () => null
    }, [])
    
  

    return (

      <>

        {
          
          isCompanyLoading?
          
            <DivCenterContents style={{height: '100vh'}}>
              {/* <Typography variant='h4'> {AppName} </Typography>
              <SyncLoader loading={loading} /> */}
              <Avatar src={logo_loader} sx={{width: '90px', height: '90px'}} />
            </DivCenterContents>
            :
            <DivCenterContents _size={4} mt={3}>
                <Outlet context={[appConfigReducer, setAppConfigReducer]} />
            </DivCenterContents>
        }
      </>
    )

}