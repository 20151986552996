import { APP_MANAGER_TYPES } from '../../../Utils/constants';
import { SET_MANAGER_TYPE, UPDATE_MANAGER_DATA } from './config.types'

const initialState = {
    data: {},
    type: APP_MANAGER_TYPES[0]
  };
export const appConfigReducer = (state = initialState, action)=> {

    switch (action.type) {
        case SET_MANAGER_TYPE:
            return {
                ...state,
                type: action.payload.type,
                data: action.payload.data,
            };
        
        case UPDATE_MANAGER_DATA:
            return {
                ...state,
                data: action.payload.data,
            };


        default:
            return state;
    }
}