import { SET_DASHBOARD_DATA, SET_TITLE, REFRESH_NOTIF_COUNTER } from "./title.types"

export const setTitle = (
    title = 'Dashboard', 
    isBooking = false, 
    step = 0, 
    hideMenus = false, 
    selectedNav = "dashboard", 
    totalSteps = 3,
    hideDrawer = false
) => (dispatch) => { 

    
    dispatch({
        type: SET_TITLE,
        payload: {
            title: title,
            isBooking: isBooking,
            step: step,
            hideMenus: hideMenus,
            selectedNav: selectedNav,
            totalSteps: totalSteps,
            hideDrawer: hideDrawer
        }
    })

}


export const setDashboard = (data) => (dispatch) => {

    
    dispatch({
        type: SET_DASHBOARD_DATA,
        payload: data
    })

}

export const refreshNotifs = () => (dispatch) => {

    
    dispatch({
        type: REFRESH_NOTIF_COUNTER,
    })

}