import { addDays } from "date-fns";
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref, remove, set, update } from "firebase/database";
import { collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, getFirestore, limit, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { convertTimeZone, formatDateToDateTimeStr, getLocalTimezone } from "Utils/functions";
import { firebaseConfig } from "../../AppSettings";

const app = initializeApp(firebaseConfig);
const db = getFirestore (app)
const realTimeDb = getDatabase()

export const saveBookingSlots = (_data, firebaseUserID, companyId = null)=>new Promise((successCallBack, errorCallBack)=>{

    const data = {}
    data['userId'] = firebaseUserID
    data['autoBook'] = false
    data['timeZone'] = getLocalTimezone()
    data['businessDayList'] = _data

    const company = companyId?companyId.toString():firebaseUserID
    const bookingRef = ref(realTimeDb, "AvailabilitySlots/" + firebaseUserID + "/bookingOptions/" + company)
    update(bookingRef, data).then(successCallBack).catch(errorCallBack)
    // const myDocRef = doc(db, "Users", firebaseUserID, "bookingOptions", companyId?companyId:firebaseUserID)
    // updateDoc(myDocRef, data).then(successCallBack).catch(errorCallBack)
})

export const saveBooking = (data)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = doc(db, "Bookings", data["orderId"])
    setDoc(myDocRef, data).then(successCallback).catch(errorCallback)
})


export const saveBookingAnswers = (data, firebaseId, bookingId, serviceId)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = doc(db, "ServiceAnswers",firebaseId, bookingId, serviceId)
    setDoc(myDocRef, data).then(successCallback).catch(errorCallback)
})

 
export const getBookingAnswers = (firebaseId, bookingId, serviceId)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const myDocRef = doc(db, "ServiceAnswers/"+firebaseId+"/"+bookingId+"/"+serviceId)
        const docSnap = await getDoc(myDocRef) 
        if(docSnap.exists()){
            successCallback(docSnap.data())
        }else {
            errorCallback("No results found")
        }
    }catch(error) {
        errorCallback(error)
    }
});


export const updateBooking = (data, bookingId)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = doc(db, "Bookings", bookingId)
    updateDoc(myDocRef, data).then(successCallback).catch(errorCallback)
})



export const deleteBooking = (bookingId, providerId = null)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = doc(db, "Bookings", bookingId)
    deleteDoc(myDocRef).then(()=>{
        //delete associated notifications
        if(providerId) {
            const notifRef = ref(realTimeDb, "Notifications/"+providerId)
            onValue(notifRef, (snapshot) => {
                if(snapshot.exists()) {
                    snapshot.forEach((childSnapshot) => {
                        remove(childSnapshot.ref).then(()=>{
                            successCallback("Success")
                        })
                        .catch(errorCallback)
                    });
                }else {
                    successCallback("Success")
                }
            }, (err)=>{
                errorCallback(err)
            });

        }else {
            successCallback("success")
        }
    }).catch(errorCallback)
})
 
export const getBooking= (bookingId)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const myDocRef = doc(db, "Bookings/"+bookingId)
        const docSnap = await getDoc(myDocRef) 
        if(docSnap.exists()){
            successCallback(docSnap.data())
        }else {
            errorCallback({msg: "Could not find booking"})
        }
    }catch(error) {
        errorCallback(error)
    }
});

export const getTotalBookingCount = (providerId = null, companyId = null, isTeamMember = false)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const myDocRef = collection(db, "Bookings/")
        const qry = companyId?
            isTeamMember? //teammember
                query(
                    myDocRef, 
                    where("provider.providerId", "==", providerId),
                    where("companyId", "==", companyId)
                )

            : //for admins and managers
                query(
                    myDocRef, 
                    where("companyId", "==", companyId)
                )

        :
            query(
                myDocRef, 
                where("provider.providerId", "==", providerId)
            )
        const docSnap = await getCountFromServer(qry) 
        successCallback({totalBookings: docSnap.data().count})
    }catch(error) {
        errorCallback(error)
    }
});


export const getUpComingClientBooking = (providerId = null, companyId = null,clientId, startDate)=>new Promise( async function(successCallback, errorCallback) {

    
    try{
        const myDocRef = collection(db, "Bookings/")
        const q = query(myDocRef,
            where('client.clientId', "==", clientId),
            where(companyId?'companyId':'client.clientId', "==", companyId?companyId:providerId),
            where('date', ">", startDate),
            orderBy("date"),
            limit(1)
        )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(err) {
        errorCallback(err)
    }
});

export const getBookingSlotsFromDb = (firebaseUserID, companyId = null)=>new Promise( async function(successCallback, errorCallback) {

    const company = companyId?companyId.toString():firebaseUserID
    const bookingRef = ref(realTimeDb, "AvailabilitySlots/" + firebaseUserID + "/bookingOptions/" + company)
    onValue(bookingRef, (snapshot) => {
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
        
            successCallback({})
        }
    }, errorCallback);

    // const myDocRef = doc(db, "Users", firebaseUserID, "bookingOptions", companyId?companyId.toString():firebaseUserID)
    // const docSnap = await getDoc(myDocRef) 
    // if(docSnap.exists()){
    //     successCallback(docSnap.data())
    // }else {
        
    //     successCallback({})
    // }
});


export const updateBookingSettings = (data, firebaseUserID, companyId = null)=>new Promise(function(successCallback, errorCallback){
    
    const company = companyId?companyId.toString():firebaseUserID
    const bookingRef = ref(realTimeDb, "AvailabilitySlots/" + firebaseUserID + "/bookingOptions/" + company)
    getBookingSlotsFromDb(firebaseUserID, companyId).then((newData)=>{
        if(newData && Object.keys(newData).length > 0) {

            update(bookingRef, data).then(successCallback).catch(errorCallback)
        }else {
            
            set(bookingRef, data).then(successCallback).catch(errorCallback)
        }
    })
})

export const getScheduledBookings = (providerId, startHourDayDate,endHourOfSelectedCalendar)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = collection(db, "Bookings/")
    const q = query(myDocRef, where("provider.providerId", "==", providerId),
        where("date", ">=", startHourDayDate),
        where("date", "<", endHourOfSelectedCalendar),
        orderBy("date")
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallback(_data)
});

 
export const getClientsScheduledBookings = (clientId, startHourDayDate,endHourOfSelectedCalendar)=>new Promise( async function(successCallback, errorCallback) {

    
    
    
    
    
    const myDocRef = collection(db, "Bookings/")
    const q = query(myDocRef, where("client.clientId", "==", clientId),
        where("date", ">=", startHourDayDate),
        where("date", "<", endHourOfSelectedCalendar),
        orderBy("date")
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallback(_data)
});


export const getAllScheduledBookings = (providerId, startDate, pageLimit = 10, isNext = true)=>new Promise( async function(successCallback, errorCallback) {


    
    const myDocRef = collection(db, "Bookings/")
    const q = query(myDocRef, where("provider.providerId", "==", providerId),
            // orderBy("date"),
            where("date", isNext?"<":">", startDate),
            orderBy("date", isNext?"desc":"asc"),
            limit(parseInt(pageLimit))
        )
        
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallback(_data)
});


export const getAllClientsScheduledBookings = (clientId, startDate, pageLimit = 10, isNext = true)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = collection(db, "Bookings/")
    const q = query(myDocRef, where("client.clientId", "==", clientId),
        // orderBy("date"),
        orderBy("date", isNext?"desc":"asc"),
        where("date", isNext?"<":">", startDate),
        limit(parseInt(pageLimit))
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallback(_data)
});
  

export const getCompanyBookings = (companyId, startHourDayDate,endHourOfSelectedCalendar, userId = null, isTeamMember = false, isClient = false)=>new Promise( async function(successCallback, errorCallback) {


    
    const myDocRef = collection(db, "Bookings/")
    const q = isClient && userId?
    query(myDocRef, where("companyId", "==", parseFloat(companyId)),
        where("date", ">=", startHourDayDate),
        where("date", "<", endHourOfSelectedCalendar),
        where("client.clientId", "==", userId)
    ):
    isTeamMember && userId?
    query(myDocRef, where("companyId", "==", parseFloat(companyId)),
        where("date", ">=", startHourDayDate),
        where("date", "<", endHourOfSelectedCalendar),
        where("provider.providerId", "==", userId)
    )
    :query(myDocRef, where("companyId", "==", parseFloat(companyId)),
        where("date", ">=", startHourDayDate),
        where("date", "<", endHourOfSelectedCalendar),
    )
    try{
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)

    }catch(err) {
        errorCallback(err)
    }
});


export const getAllCompanyBookings = (companyId, startDate, pageLimit = 10, isNext = true,  userId = null, isTeamMember = false, isClient = false)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = collection(db, "Bookings/")
    let q = null
    if(isClient && userId) {

        q = isNext?query(myDocRef,  where("companyId", "==", parseInt(companyId)), 
            where("date", "<", startDate),
            where("client.clientId", "==", userId),
            orderBy("date", "desc"),
            limit(parseInt(pageLimit)),
        ):
        query(myDocRef,  where("companyId", "==", parseInt(companyId)), 
            where("date", ">", startDate),
            where("client.clientId", "==", userId),
            orderBy("date", "asc"),
            limit(parseInt(pageLimit))
        )
    }else if(isTeamMember && userId) {
        q = isNext?query(myDocRef,  where("companyId", "==", parseInt(companyId)), 
            where("date", "<", startDate),
            where("provider.providerId", "==", userId),
            orderBy("date", "desc"),
            limit(parseInt(pageLimit)),
        ):
        query(myDocRef,  where("companyId", "==", parseInt(companyId)), 
            where("date", ">", startDate),
            where("provider.providerId", "==", userId),
            orderBy("date", "asc"),
            limit(parseInt(pageLimit))
        )
    }else {
        q = isNext?query(myDocRef,  where("companyId", "==", parseFloat(companyId)), 
            where("date", "<", startDate),
            orderBy("date", "desc"),
            limit(parseInt(pageLimit)),
        ):
        query(myDocRef,  where("companyId", "==", parseFloat(companyId)), 
            where("date", ">", startDate),
            orderBy("date", "asc"),
            limit(parseInt(pageLimit))
        )
    }
    try{
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)

    }catch(err) {
        errorCallback(err)
    }
});



export const getPendingBookings = (providerId,startDate,isNext,pageLimit = 10, companyId = null, isAdmin = null)=>new Promise( async function(successCallback, errorCallback) {

    var d = new Date();
    d.setHours(0,0,0,0);
    const days_7_ago = addDays(d, -7)

    try {
        const myDocRef = collection(db, "Bookings/")
        const q = companyId?
            isAdmin === true?
            query(
                myDocRef, 
                //where("appointmentStatus", "==", "PENDING"),
                where("companyId", "==", parseFloat(companyId)),
                // where("date", ">=", formatDateToDateTimeStr(convertTimeZone(days_7_ago, "UTC"))),
                
                // where("date", isNext?"<":">", startDate.toString()),
                where("creationTime", "<", startDate.toString()),
                // orderBy("date", isNext?"desc":"asc"),
                orderBy("creationTime", "desc"),
                limit(pageLimit)
                // orderBy("date")
            )
            :
            query(
                myDocRef, 
                where("provider.providerId", "==", providerId),
                //where("appointmentStatus", "==", "PENDING"),
                where("companyId", "==", parseFloat(companyId)),
                // where("date", ">=", formatDateToDateTimeStr(convertTimeZone(days_7_ago, "UTC"))),
                where("creationTime", "<", startDate.toString()),
                orderBy("creationTime", "desc"),
                limit(pageLimit)
                // orderBy("date")
            )
            :
            query(
                myDocRef, 
                where("provider.providerId", "==", providerId),
                //where("appointmentStatus", "==", "PENDING"),
                // where("date", ">=", formatDateToDateTimeStr(convertTimeZone(days_7_ago, "UTC"))),
                where("creationTime", "<", startDate.toString()),
                orderBy("creationTime", "desc"),
                limit(pageLimit)
                // orderBy("date")
            )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(err){
        errorCallback(err)
        
    }
});

export const getAutoBookedBookings = (providerId,startDate,isNext,pageLimit = 10, companyId = null, hasMeetingLinks = false, isAdmin = false)=>new Promise( async function(successCallback, errorCallback) {

    
    var d = new Date();
    d.setHours(0,0,0,0);
    const days_7_ago = addDays(d, -7)
    

    const myDocRef = collection(db, "Bookings/")
    const q = isAdmin?
        query(myDocRef, 
            // where("appointmentStatus", "==", "PENDING"),
            where("companyId", "==", parseFloat(companyId)),
            where("enableAutoBook", "==", true),
            where("hasMeetingLink", "==", hasMeetingLinks),
            where("date", isNext?"<":">", startDate),
            where("date", ">=", formatDateToDateTimeStr(convertTimeZone(days_7_ago, "UTC"))),
            orderBy("date", isNext?"desc":"asc"),
            limit(pageLimit),
            // orderBy("date")
        )
        :(companyId)?
            query(myDocRef, 
                where("provider.providerId", "==", providerId),
                // where("appointmentStatus", "==", "PENDING"),
                where("companyId", "==", parseFloat(companyId)),
                where("enableAutoBook", "==", true),
                where("hasMeetingLink", "==", hasMeetingLinks),
                where("date", isNext?"<":">", startDate.toString()),
                where("date", ">=", formatDateToDateTimeStr(convertTimeZone(days_7_ago, "UTC"))),
                orderBy("date", isNext?"desc":"asc"),
                limit(pageLimit),
                // orderBy("date")
            )
        :
            query(myDocRef, 
                where("provider.providerId", "==", providerId),
                // where("appointmentStatus", "==", "PENDING"),
                // where("companyId", "==", parseFloat(companyId)),
                where("enableAutoBook", "==", true),
                where("hasMeetingLink", "==", hasMeetingLinks),
                where("date", ">=", formatDateToDateTimeStr(convertTimeZone(days_7_ago, "UTC"))),
                // orderBy("date")
                where("date", isNext?"<":">", startDate.toString()),
                orderBy("date", isNext?"desc":"asc"),
                limit(pageLimit),
            )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    
    
    successCallback(_data)
});

export const getUnreadBookings = (providerId, companyId = null, isAdmin = false)=>new Promise( async function(successCallback, errorCallback) {

     
    const myDocRef = collection(db, "Bookings/")
    const q = (isAdmin && companyId !== null)?
    query(myDocRef, 
        // where("provider.providerId", "==", providerId),
        where("companyId", "==", parseFloat(companyId)),
        where("notificationStatus", "==", "UNREAD"),
        orderBy("date")
    )
    :
    query(myDocRef, where("provider.providerId", "==", providerId),
        where("notificationStatus", "==", "UNREAD"),
        orderBy("date") 
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallback(_data)
});

export const markBookingsAsRead = (providerId)=>new Promise( async function(successCallback, errorCallback) {

     
    const myDocRef = collection(db, "Bookings/")
    const q = query(myDocRef, where("provider.providerId", "==", providerId),
        where("notificationStatus", "==", "UNREAD")
    )
    const docSnap = await getDocs(q)
    docSnap.forEach((doc) => {
        const notif = doc.data()
        updateBooking({
            "notificationStatus": "READ"
          }, notif.orderId
        )
    });
});