import { CameraAlt, Close, Whatshot } from '@mui/icons-material'
import { Avatar, Badge, Button, Grid, IconButton, Typography } from '@mui/material'
import DivCenterContents from 'components/DivCenterContents'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SyncLoader } from 'react-spinners'
import { saveCompanyTotalGuests, saveProviderTotalLoggedUsers } from '../../FirebaseController/Analytics'
import { getCompanyStaffMembers, getStaffMember, getUserSignedInViaCompanyAddr, saveUserSignedInFromCompany, saveUserSignedInFromCompanyAddr, updateCompanyContactProfile, updateUserSignedInFromCompany, updateUserSignedInFromCompanyAddr } from '../../FirebaseController/Company'
import { getUserDataFromDb, getUserSignedInViaProviderAddr, saveUserSignedInViaProvider, saveUserSignedInViaProviderAddr, updateProviderContactProfile, updateUserSignedInViaProvider, updateUserSignedInViaProviderAddr } from '../../FirebaseController/User/user'
import { refreshCRM } from '../../Store/Reducers/Crm/crm.actions'
import { APP_MANAGER_TYPES, MAX_LOGO_FILE_SIZE, STAFF_TYPE } from '../../Utils/constants'
import { validateEmail, validatePhoneNumber } from '../../Utils/functions'
import { inputStyles } from '../../Utils/style'
// import '../../Utils/css/input_file.css'


export default function ContactForm(props) {

    const { editContactData, isEdit, configs, clientAnalytics } = props
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    
    const _teamMemberData = localStorage.getItem("teamMemberData")
    const teamMemberData = _teamMemberData?JSON.parse(_teamMemberData)[0]:null
    const [inputLogoFile, setInputLogoFile] = React.useState('')

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [contactData, setContactData] = useState({})
    const [contactAddrData, setContactAddrData] = useState({})
    const [isLoading, setIsLoading] = React.useState(false)
    const [isAddrLoading, setIsAddrLoading] = React.useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
    const [isFirstTimeAddingAddr, setIsFirstTimeAddingAddr] = React.useState(false)
    const [allStaffMembers, setAllCompanyStaffMembers] = React.useState([]);
    const dispatch = useDispatch()
    //const appConfigReducer = useSelector((state) => state.appConfigReducer)
    // const [appConfigReducer, setAppConfigReducer] = useOutletContext()
    const appConfigReducer = configs
    
    const formInputs = [
        {
            name: "fName",
            required: true,
            holder: 'First name',
        }, 
        {
            name: "sName",
            required: true,
            holder: 'Last name',
        }, 

    ]

    const salesInputs = [
        {
            holder: "Assign contact to",
            required: false,
            name: 'assignTo'
        },
        {
            holder: "Label contact as",
            required: false,
            name: 'label'
        },

    ]

    const mobileInputs = [
        
        {
            holder: "Mobile no",
            required: true,
            name: "phoneNo",
            type: 'text'
        }, 
        {
            holder: "Work mobile no",
            required: true,
            name: "workNo",
            type: 'text'
        }, 
        {
            holder: "Other mobile no",
            required: true,
            name: "otherNo",
            type: 'text'
        }, 
    ]

    const emailInputs = [
        
        {
            holder: "Home email",
            required: false,
            name: 'email'
        },  
        {
            holder: "Work email",
            required: false,
            name: 'workEmail'
        },  
        {
            holder: "Other email",
            required: false,
            name: 'otherEmail'
        },  
    ]

    const addressInputs = [
        {
            holder: "Buiding/House no",
            required: false,
            name: 'cont_add1'
        }, 
        {
            holder: "Road/Locality",
            required: false,
            name: 'cont_add2'
        },
        {
            holder: "Region/District",
            required: false,
            name: 'cont_add3'
        },
        {
            holder: "City",
            required: false,
            name: 'cont_city'
        },
        {
            holder: "State",
            required: false,
            name: 'cont_state'
        },
        {
            holder: "Country",
            required: false,
            name: 'cont_country'
        },
        {
            holder: "Po box no",
            required: false,
            name: 'cont_pobox'
        },
    ]
    
   

    const handleFormInputChange = (name, value) => {
        setContactData({
            ...contactData,
            [name]: (name === 'label' && value.toLowerCase() === "clear")?"":value
        })
    }




    const handleAddressInputChange = (name, value) => {
        setContactAddrData({
            ...contactAddrData,
            [name]: value
        })
    }

    const handleAddContact = ()=>{
        if(!("fName" in contactData)) {
            showSnackBar("First name is required", "error")
        }else if(!("sName" in contactData)) {
            showSnackBar("Surname is required", "error")
        }
        // else if(!("email" in contactData)) {
        //     showSnackBar("Home email is required", "error")
        // }else if(!("phoneNo" in contactData)) {
        //     showSnackBar("Mobile number is required", "error")
        // }
        else if(("phoneNo" in contactData && contactData.phoneNo) && !validatePhoneNumber(contactData.phoneNo)) {
            showSnackBar("Invalid mobile number", "error")
        }else if(("workNo" in contactData && contactData.workNo) && !validatePhoneNumber(contactData.workNo)) {
            showSnackBar("Invalid work number", "error")
        }else if(("otherNo" in contactData && contactData.otherNo) && !validatePhoneNumber(contactData.otherNo)) {
            showSnackBar("Invalid other mobile number", "error")
        }else if(("email" in contactData && contactData.email) && !validateEmail(contactData.email)) {
            showSnackBar("Invalid home email address", "error")
        }else if(("workEmail" in contactData && contactData.workEmail) && !validateEmail(contactData.workEmail)) {
            showSnackBar("Invalid work email address", "error")
        }else if(("otherEmail" in contactData && contactData.otherEmail) && !validateEmail(contactData.otherEmail)) {
            showSnackBar("Invalid other email address", "error")
        }
        else if((isEdit === 1 && teamMemberData && teamMemberData.memberRole === STAFF_TYPE[0])) {
            showSnackBar("Sorry, only an admin can edit contact details", "error")
        }else {
            setIsLoading(true)
            const contactId = isEdit === 1?contactData.contactId:window.crypto.randomUUID().split('-').join('')
            contactData["updatedAt"] = new Date().valueOf()

            //

            if(isEdit === 0){
                contactData['contactId'] = contactId
                contactData['userId'] = contactId
                contactData['type'] = "GUEST"
                contactData["createdBy"] = user.uid
                contactData["createdAt"] = new Date().valueOf()

                const executeFn = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                    saveUserSignedInFromCompany(contactData, appConfigReducer.data.companyID, user.uid)
                :saveUserSignedInViaProvider(contactData, user.uid)

                executeFn.then(()=>{
                    if(Object.keys(contactAddrData).length > 0) {
                        const executeFn__ = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                            saveUserSignedInFromCompanyAddr(contactAddrData, appConfigReducer.data.companyID)
                        :saveUserSignedInViaProviderAddr(contactAddrData, user.uid)

                        const analyticFn = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                        saveCompanyTotalGuests( appConfigReducer.data.companyID, 1)
                        :saveProviderTotalLoggedUsers(user.uid, 1, null)

                        analyticFn.then(()=>{
                            
                        })

                        executeFn__.then(()=>{
                            
                        })
                    }

                    showSnackBar("Contact details added successfully", "success")
                    setIsLoading(false)
                    dispatch(refreshCRM())//refreshing app configs
                    //save logo if any
                    


                }).catch((err)=>{
                    
                    setIsLoading(false)
                    showSnackBar("Sorry, something went wrong when adding contact. Please try again later.","error")
                })
            }else if(isEdit === 1) {
                
                contactData["contactId"] = (contactData["contactId"] || contactData["userId"] || window.crypto.randomUUID().split('-').join(''))
                const executeFn = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                    updateUserSignedInFromCompany(contactData, appConfigReducer.data.companyID)
                :
                    updateUserSignedInViaProvider(contactData,user.uid)


                executeFn.then(()=>{
                    if(Object.keys(contactAddrData).length > 0) {

                        let executeFn__ = null
                        console.log("isFirstTime--", isFirstTimeAddingAddr.toString() )
                        if(isFirstTimeAddingAddr) {
                            contactAddrData["contactId"] = contactData.contactId
                            executeFn__ = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                                saveUserSignedInFromCompanyAddr(contactAddrData, appConfigReducer.data.companyID)
                            :saveUserSignedInViaProviderAddr(contactAddrData, user.uid)
                        }else {
                            
                        executeFn__ = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                            updateUserSignedInFromCompanyAddr(contactAddrData, appConfigReducer.data.companyID)
                        :updateUserSignedInViaProviderAddr(contactAddrData, user.uid)
                        }

                        executeFn__.then(()=>{
                            console.log("addr saved")
                        })
                        .catch((err)=>{
                            console.log("error", err)
                        })
                    }
                    showSnackBar("Contact details updated successfully", "success")
                    setIsLoading(false)
                    //update redux configs data
                    dispatch(refreshCRM())//refreshing app configs
                }).catch((err)=>{
                    console.log(err)
                    setIsLoading(false)
                })
            }
        }
    }

    
    const handleFileChange = (e) => {

        if(e.target.files.length > 0){
            if(e.target.files[0].size/ (1024) > MAX_LOGO_FILE_SIZE){//greater than 300 kb
                showSnackBar("File is too big, only 300kb are allowed.", "error")
            }else {
                setInputLogoFile(e.target.files[0])
            }
        }
    }
    

    const saveContactLogo = () => {
        

        showSnackBar("Uploading logo, please wait..", "success")
        

        const executeFn = (appConfigReducer.type === APP_MANAGER_TYPES[1])?updateCompanyContactProfile(inputLogoFile, appConfigReducer.data.companyID,contactData.contactId, ("profile" in contactData)?contactData.profile:"")
            :updateProviderContactProfile(inputLogoFile, user.uid, contactData.contactId, ("profile" in contactData)?contactData.profile:"")

        executeFn.then(
            (url) => {//success callback function
                

                if(isEdit === 1) {
                    contactData["profile"] = url
                    contactData["contactId"] = (contactData["contactId"] || contactData["userId"] || window.crypto.randomUUID().split('-').join(''))

                    const executeFn = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                        updateUserSignedInFromCompany(contactData, appConfigReducer.data.companyID)
                    :
                        updateUserSignedInViaProvider(contactData,user.uid)


                    executeFn.then(()=>{
                        
                    })
                }

                
                setContactData({
                    ...contactData,
                    "profile": url,
                })
                showSnackBar("Upload successful. click save to update the contact", "success")

                // updateCompany({
                //     ...contactData,
                //     "logoUrl": url
                // }, companyId).then(()=>{
                //     setContactData({
                //         ...contactData,
                //         "logoUrl": url
                //     })
                // })
            }           

        ).catch(
            (err) => {
                showSnackBar("Could not upload image, please try again later.", "error")
                
                
            }
        )
    }



    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}


    React.useEffect(() => {
        if(editContactData) {
            if(!editContactData.label){
                editContactData["label"] = (("type" in editContactData && editContactData.type.toLowerCase() === "guest") || (editContactData.fName && editContactData.fName.toLowerCase() === "anonymous"))?
                "Guest"
                :clientAnalytics && "totalBookings" in clientAnalytics && clientAnalytics.totalBookings && parseFloat(clientAnalytics.totalBookings) > 0?
                    "Client"
                    :"Lead" 
            }

            
            
            
                
            setContactData( editContactData )
        }
    
      return () => null
    }, [editContactData])

    
    React.useEffect(() => {
        if(isEdit === 1) {
            //get adddress deatils
            // editContactData
            setIsAddrLoading(true)
            const executeFn = (appConfigReducer.type === APP_MANAGER_TYPES[1])?
            getUserSignedInViaCompanyAddr(appConfigReducer.data.companyID, (editContactData.contactId || editContactData.userId ))
            :getUserSignedInViaProviderAddr(user.uid, (editContactData.contactId || editContactData.userId ))

            executeFn
                .then((data)=>{
                    setContactAddrData(data)
                    setIsAddrLoading(false)
                    console.log("isFirstTime", Object.keys(data).length <= 0 )
                    setIsFirstTimeAddingAddr(Object.keys(data).length <= 0)
                })
                
        }else {            
            contactAddrData["contactId"] = contactData.contactId
        }
    
      return () => null
    }, [editContactData])


    useEffect(() => {

        getCompanyStaffMembers(appConfigReducer.data.companyID).then((members)=>{
            
            
            
            members.forEach(member => {

                
                
                if(member && (!(member.firstname) || !(member.surname))) {
                                        
                    getUserDataFromDb(member.userId).then((userData)=>{
                        allStaffMembers.push(userData)
                        setAllCompanyStaffMembers([
                            ...allStaffMembers.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    t.uid === value.uid
                                ))
                            )
                        ])
                        // setTeamMemberUserInfo()
                    }).catch((err)=>{
                        
                    })
                }else {
                    member['uid'] = (member['uid'] || member['userId'])
                    allStaffMembers.push(member)
                    setAllCompanyStaffMembers([
                        ...allStaffMembers.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.uid === value.uid
                            ))
                      )
                    ])
                }
                
            });
            // 
        }).catch((err)=>{
            
        })
            
      return () => null
    }, [])
    


    useEffect(() => {
        
        
      if(inputLogoFile && !(typeof inputLogoFile === 'string' || inputLogoFile instanceof String)) {
        saveContactLogo()
      }
    
      return () => null
    }, [inputLogoFile])
    
    

    console.log(contactData)

    return (
        
        <Grid container spacing={1} className='px-4 pb-4 mb-3 mt-1'>

            <Grid item xs={12}>
                <div>
                    <DivCenterContents _size={6}>
                        
                            <div className="position-relative">
                                <input 
                                    accept="image/*" id="icon-button-file" 
                                    onChange={handleFileChange}
                                    type="file" style={{display: 'none'}} />
                                <label htmlFor="icon-button-file">
                                    <Badge
                                        overlap="circular"
                                        badgeContent={
                                            <Avatar sx={{bgcolor: "#63C3CF"}}><CameraAlt /></Avatar>
                                        }
                                    
                                    >
                                        {
                                            (contactData.profile || inputLogoFile)?
                                                <Avatar 
                                                    // variant="square"
                                                    // src={profileLink && Object.keys(profileLink).length !== 0?profileLink:profile}
                                                    src={contactData.profile || inputLogoFile}
                                                    sx={{width: '110px', height: '110px', border: 'none', background: "inherit"}} >
                                                    
                                                    <Whatshot style={{fontSize: '80px', color: "#F0973B"}} />

                                                </Avatar>

                                            :
                                            
                                            <Avatar 
                                                // variant="square"
                                                sx={{width: '110px', height: '110px', border: 'none', background: "inherit"}} 
                                                color="primary"
                                            >

                                                <Whatshot style={{fontSize: '80px', color: "#F0973B"}} />
                                            </Avatar>

                                        }
                                    </Badge>
                                </label><   br />
                                <Typography variant='body2' sx={{fontSize: '11px', float: 'right', paddingLeft: '5px',paddingTop: '0'}} className="">
                                    Max size: {MAX_LOGO_FILE_SIZE} kb
                                </Typography>
                            </div>
                    </DivCenterContents>
                </div>

            </Grid>
            


            
                {
                    formInputs.map((item, idx)=>
                        <Grid item md={idx < 2?6:12} xs={12} key={item.name} style={{marginTop: '0',}}>

                            

                            <>                                   

                                
                                <input
                                    style={inputStyles}
                                    id={item.name}
                                    name={item.name} 
                                    // placeholder={item.holder}
                                    required = {item.required}
                                    value={
                                        contactData && item.name in contactData?
                                        contactData[item.name]
                                        :""
                                    }
                                    onChange={(e)=>handleFormInputChange(e.target.name, e.target.value)}
                                    type={"type" in item?item.type:"text"}
                                />
                            
                            
                            </>
                            
                        </Grid>
                    )

                    
                }


            
            {
                
                <Grid item xs={12}>
                    <h6 style={{fontWeight: 'bold', margin: '15px 0'}} >
                        Sales details
                    </h6>
                    <div className='border p-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '10px'}}>
                    {
                        salesInputs.map((item, idx)=>
                            

                                <>
                                    
                                        {
                                            
                                                (item.name === "assignTo") && appConfigReducer.type === APP_MANAGER_TYPES[0]?"":
                                                
                                                    <label htmlFor={item.name} style={{margin: '5px 0'}}>
                                                        {item.holder}
                                                    </label>
                                            }
                                            
            
                                            {
                                            (item.name === "assignTo")?appConfigReducer.type === APP_MANAGER_TYPES[1]?
                                                <>
                                                    <select
                                                        style={{...inputStyles, marginBottom: '5px'}}
                                                        id={item.name}
                                                        name={item.name} 
                                                        // placeholder={item.holder}
                                                        value={
                                                            contactData && item.name in contactData?
                                                            contactData[item.name]
                                                            :""
                                                        }
                                                        onChange={(e)=>handleFormInputChange(e.target.name, e.target.value)}
                                                    >
                                                        <option value={""} disabled>
                                                            Select staff member
                                                        </option>

                                                        {
                                                            allStaffMembers.map((item)=>                                            
                                                                (<option key={(item.uid)} value={(item.uid)}>
                                                                    {item.firstname + " " + item.surname}
                                                                </option>)
                                                            )
                                                        }
                                                    </select>
                                                </>
                                                :"":
                                            (item.name === "label")?
                                                <>
                                                    <select
                                                        style={inputStyles}
                                                        id={item.name}
                                                        name={item.name} 
                                                        // placeholder={item.holder}
                                                        value={
                                                            contactData && item.name in contactData?
                                                                contactData[item.name].toLowerCase() 
                                                            :""
                                                        }
                                                        onChange={(e)=>handleFormInputChange(e.target.name, e.target.value)}
                                                    >
                                                        {
                                                            ["New", "Guest", "Lead", "Qualified", "Proposal", "Client", 
                                                                "Invoiced", "Unpaid", "Personal", "Clear"].map(item=>
                                                                <option key={item} value={item.toLowerCase()}>
                                                                    {item}
                                                                </option>)
                                                        }
                                                    </select>
                                                </>
                                                :""
                                        }
                    
                                   
                                </>

                            
                        )
                    }
                    </div>

                </Grid>
            }    

            {
                
                <Grid item xs={12}>
                    <h6 style={{fontWeight: 'bold', margin: '15px 0'}} >Phone numbers</h6>
                    <div className='border p-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '10px'}}>
                    {
                        mobileInputs.map((item, idx)=>

                                <div>
                                        <label htmlFor={item.name} style={{margin: '5px 0'}}>
                                            {item.holder}
                                        </label><br />
                                        <input 
                                            id={item.name}
                                            name={item.name} 
                                            style={{...inputStyles, marginBottom: '5px'}}
                                            type="text" 
                                            placeholder='Enter text here'
                                            value={
                                                contactData && item.name in contactData?
                                                contactData[item.name]
                                                :""
                                            }
                                            onChange={(e)=>handleFormInputChange(e.target.name, e.target.value)}
                                            rows="1"
                                            cols="50"
                                        />
                    
                                    
                                </div>
                        )
                    }
                    </div>

                </Grid>
            }

            {
                
                <Grid item xs={12}>
                    <h6 style={{fontWeight: 'bold', margin: '15px 0'}} >Email addresses</h6>
                    <div className='border p-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '10px'}}>
                    {
                        emailInputs.map((item, idx)=>
                           
                            <div>
                                
                                <label htmlFor={item.name} style={{margin: '5px 0'}}>
                                    {item.holder}
                                </label><br />
                                <input 
                                    id={item.name}
                                    name={item.name} 
                                    style={{...inputStyles, marginBottom: '5px'}}
                                    type="text" 
                                    placeholder='Enter text here'
                                    value={
                                        contactData && item.name in contactData?
                                        contactData[item.name]
                                        :""
                                    }
                                    onChange={(e)=>handleFormInputChange(e.target.name, e.target.value)}
                                    rows="1"
                                    cols="50"
                                />

                            </div>

                        )
                    }
                    </div>

                </Grid>
            }

            {
                isAddrLoading?
                    <DivCenterContents>
                        <SyncLoader
                            loading={isAddrLoading}
                            size={10}
                            color="#000"
                        />
                    </DivCenterContents>
                    :
                    <Grid item xs={12}>
                        <h6 style={{fontWeight: 'bold', margin: '15px 0'}} >Contact address</h6>
                        
                        <div className='border p-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '10px'}}>
                            <Grid container spacing={1} className='pl-2'>
                            {
                                addressInputs.map((item, idx)=>
                                    (
                                        <Grid item md={((idx > 2) )?6:12} xs={12} key={item.name} style={{marginTop: '0',}}>
                                            <label htmlFor={item.name} style={{margin: '5px 0'}}>
                                                {item.holder}
                                            </label>

                                            {
                                                <input
                                                    style={{...inputStyles, marginBottom: '5px'}}
                                                    id={item.name}
                                                    name={item.name} 
                                                    placeholder={"Enter text here"}
                                                    required = {item.required}
                                                    
                                                    value={
                                                        contactAddrData && item.name in contactAddrData?
                                                        contactAddrData[item.name]
                                                        :""
                                                    }
                                                    onChange={(e)=>handleAddressInputChange(e.target.name, e.target.value)}
                                                    type={"type" in item?item.type:"text"}
                                                />
                                            }
                                        </Grid>
                                    )
                                )
                            }
                            </Grid>
                        </div>

                    </Grid>
            }

            <Grid item xs={12}>
                <div style={{margin: '30px 0'}} className='d-flex'>
                    <div style={{flex: 1}}></div>
                    <Button 
                        variant='contained'
                        className="my-4 rounded border-0"
                        style={{color: "#fff", textTransform: 'none', background: '#F0973B',}}
                        disabled={isLoading} color="primary"
                        onClick={handleAddContact}
                    >
                        {
                        isLoading?
                            <SyncLoader
                            loading={isLoading}
                            size={10}
                            color="#fff"
                            />
                            :
                        "Save"
                        }
                    </Button>
                </div>

            </Grid>

        </Grid>
    )


}