import axios from "axios"
import { serverKey } from "../../AppSettings"
import { getUserToken } from "../../FirebaseController/User/user"
import { BASE_SERVER_URL } from "../../Utils/constants"



export const sendFcmNotification = (data, firebaseUserID)=>new Promise((successCallBack, errorCallback)=>{


    getUserToken(firebaseUserID).then((userToken)=>{

        if(Object.keys(userToken).length > 0) {
            const newData = {
                "to": userToken.token,
                "data": data
            }


            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'key='+serverKey
            }

            axios.post(BASE_SERVER_URL + "fcm/send", newData, {
                headers: headers
            })
            .then(successCallBack)
            .catch(errorCallback)
        }else {
            errorCallback("no token found")
        }
        
    }).catch(errorCallback)

})


export const showNotification = (title, notificationOptions)=> {
    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(title, notificationOptions);
        notification.onclick = function() {
            window.open(window.location.origin);
        };
    }

    else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            var notification = new Notification(title, notificationOptions);
            notification.onclick = function() {
                window.open(window.location.origin);
            };
          }
        });
    }
}