import { Close, Notifications } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import DivCenterContents from 'components/DivCenterContents';
import NotificationCard from 'components/NotitificationCard';
import StaffNotificationCard from 'components/NotitificationCard/companyStaffNotif';
import LocalNotificationCard from 'components/NotitificationCard/localNotifs';
import { addDays } from 'date-fns';
import { getPendingBookings, getUnreadBookings, markBookingsAsRead } from 'FirebaseController/Booking/booking';
import { getPendingStaffMemberNotif, getStaffMember } from 'FirebaseController/Company';
import { getAllNotifs, getUnreadLocalNotifs } from 'FirebaseController/Notifications/localNotifs';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SyncLoader } from 'react-spinners';
import { NavLink } from 'reactstrap';
import { APP_MANAGER_TYPES, STAFF_TYPE } from 'Utils/constants';
import { addDateHours } from 'Utils/functions';

let lastDateList = []
export default function NotificationsDrawer(props) {
  const { configs } = props
  const [open, setOpen] = React.useState(false)
  const [hasNotifDrawerBeenOpened, setHasNotifDrawerBeenOpened] = React.useState(false)//will be used to detect whether user has opened drawer and is now closing the drawer
  const [notif, setNotif] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [isAdmin, setIsAdmin] = React.useState(null)
  const [unReadNotifs, setUnReadNotifs] = React.useState(0)
  const [_lastAppointmentDate, setLastAppointmentDate] = React.useState(new Date().valueOf())
  const [_firstAppointmentDate, setFirstAppointmentDate] = React.useState(+ new Date())
  const userObj = JSON.parse(localStorage.getItem("user"))
  const user = userObj?userObj.user:null
  const titleReducer = useSelector((state) => state.titleReducer)
  const appConfigReducer = configs
  const [page, setPage] = React.useState(1);
  const [lastVisitedPage, setLastVisitedPage] = React.useState(1);
  const pageLimit = 10

  

  const setLastDateList = (list)=>{
    lastDateList = list
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if(open === true) {
      setHasNotifDrawerBeenOpened(true)
    }
    setOpen(open);
  };


  const getNotifs = ()=>{
    if(user) {

      const companyId = appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID:null
      let firstAppointmentDate = (page === 1)?addDateHours(new Date(), 24 * 30 * 12 * 20).valueOf():_firstAppointmentDate
      let lastAppointmentDate  = (page === 1)?addDateHours(new Date(), 24 * 30 * 12 * 20).valueOf():_lastAppointmentDate

      if(page === 1 && lastDateList.length <= 0) {
        setLastDateList([...[lastAppointmentDate]])
      }

      setLoading(true)

      
       

      getPendingBookings(
        user.uid,
        lastVisitedPage <= page?lastAppointmentDate:firstAppointmentDate,
        lastVisitedPage <= page,
        pageLimit,
        companyId, 
        false
      ).then((data)=>{ 
        //get pending staff meber requests
        getPendingStaffMemberNotif(user.uid, companyId).then((_data)=>{
          


          getAllNotifs(
            user.uid,
            lastVisitedPage <= page?lastAppointmentDate:firstAppointmentDate,
            lastVisitedPage <= page,
            pageLimit,
          ).then((_allNotifs)=>{
            var allNotifs = appConfigReducer.type === APP_MANAGER_TYPES[1]?
              _allNotifs.filter(notif=>"companyId" in notif && notif.companyId.toString() === appConfigReducer.data.companyID.toString())
            :_allNotifs

            
            

            sortAndSetNotifs([...data, ..._data, ...allNotifs])
            setLoading(false)

            /*
            getAutoBookedBookings(
              user.uid,
              lastVisitedPage <= page?lastAppointmentDate:firstAppointmentDate,
              lastVisitedPage <= page,
              pageLimit,
              companyId,
              false, 
              false
            ).then((autoBookedNotifs)=>{
               
               
              //const _autoBookedNotifs = autoBookedNotifs.filter(item=>(item.services[0].serviceType.toLowerCase() === "online" || (item.services[0].serviceType.toLowerCase() !== "online" && item.notificationStatus.toUpperCase() === "UNREAD")))
             
              sortAndSetNotifs([...data, ..._data, ...allNotifs, ...autoBookedNotifs])
              setLoading(false)
            }).catch((err)=>{
              
              setLoading(false)
            })
            */
            // sortAndSetNotifs([...data, ..._data, ...allNotifs])
            // setLoading(false)


            // if(appConfigReducer.type === APP_MANAGER_TYPES[0]) {
            //   sortAndSetNotifs([...data, ..._data, ...allNotifs])
            //   setLoading(false)
            // }else if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {

              
            // }
          })
          .catch((err)=>{
            
          })
        }).catch((err)=>{
          
          setLoading(false)
        })
        
        //UPDATE NOTIFS TO READ
        if(!open && hasNotifDrawerBeenOpened) {
          markBookingsAsRead(user.uid)
        }
  
      })
      .catch((err)=>{
        
      })
    }
    
  }

  const sortAndSetNotifs = (_notifs) => {

    //remove old notifs

    let currentTime = new Date()
    currentTime.setHours(0,0,0,0);
    currentTime = addDays(currentTime, -7).valueOf()
    const notifs = _notifs.filter((notif)=>(
      "orderId" in notif && new Date(notif.date.replace(/-/g,'/') + " UTC").valueOf() >= currentTime ||
      "notifId" in notif && new Date(notif.date.replace(/-/g,'/')+" UTC").valueOf() >= currentTime ||
      (!("notifId" in notif) && !("orderId" in notif) && notif)
    ))

    notifs.sort((a, b) => {
      let time1 = "orderId" in a?
        (a.creationTime)
      :"staffId" in a?
        (a.createdAt)
      :"notifId" in a?
        (a.notifId)
      :new Date().valueOf()

      let  time2 = "orderId" in b?
        (b.creationTime)
      :"staffId" in b?
        (b.createdAt)
      :"notifId" in b?
        (b.notifId)
      :new Date().valueOf()

      return  (time2) - (time1)

    })
    


    const __notifs = notifs.slice(0, pageLimit)
    if(notifs.length >= 10){
      // setFirstAppointmentDate(newData[newData.length -1].date)
      // setLastAppointmentDate(newData[0].date)
      const date1 = (b)=>{
        return "orderId" in b?
          b.creationTime
        :"staffId" in b?
          b.staffId
        :"notifId" in b?
          b.notifId
        :new Date().valueOf()
      }
      setLastAppointmentDate(
        date1(__notifs[__notifs.length -1])
      )
      
      if(lastVisitedPage <= page) {
        if(lastDateList.length < page || (lastDateList.length < 2 && page == 1)) {//when activity is created fr first time and data has now loaded
          setLastDateList([...lastDateList, date1(__notifs[__notifs.length -1])])
        }
      }
    }
    setNotif([...__notifs])
  }


  const handlePaginationChange = (event, value) =>{
    setLastVisitedPage(value === 1?value:page)
    setPage(value);
  }
   
  const handleGetNotifs = ()=>{
    if(appConfigReducer.type === APP_MANAGER_TYPES[0]) {
      getNotifs()
    }else if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
      if(isAdmin !== null) {
        getNotifs()
      }else {
        getStaffMember(user.uid, appConfigReducer.data.companyID).then((staffData)=>{
          setIsAdmin(staffData.length > 0 && "memberRole" in staffData[0] && staffData[0].memberRole && staffData[0].memberRole !== STAFF_TYPE[0])
        }).catch((err)=>{
          
        })
      }
    }
  }

  React.useEffect(()=>{

    setFirstAppointmentDate(
      (page == 1)?lastDateList[0]:lastDateList[page-2]       
    )
  },[lastDateList])

  React.useEffect(() => {
    if(isAdmin !== null){
      getNotifs()
    }
  
    return () => null
  }, [isAdmin])
  
  

  React.useEffect(() => {
    
    // if(open){
    // }

    handleGetNotifs()
    
    //UPDATE NOTIFS TO READ
    if(!open && hasNotifDrawerBeenOpened) {
      markBookingsAsRead(user.uid)
    }

    return () => {
      setNotif([])
    }
  }, [open, titleReducer.notifCounter])

  React.useEffect(() => {


    //to optimize reads on firebase, we are only going to get notifications if they are less than 10
    if(user){
      const _companyId =  appConfigReducer.type === APP_MANAGER_TYPES[1]?appConfigReducer.data.companyID:null
       
      getUnreadBookings(user.uid, _companyId,false).then((data)=>{
          console.log("notif", data.length)
        if(data.length >= 10) {
          setUnReadNotifs(data.length)
        }else {
          getPendingStaffMemberNotif(user.uid, (appConfigReducer.data.companyID || null)).then((_data)=>{


            const notifsRes = [...data, ..._data]

            if(notifsRes.length >= 10) {
              setUnReadNotifs(notifsRes.length)
            }else {
              getUnreadLocalNotifs(user.uid).then((_allNotifs)=>{
                
                var allNotifs = appConfigReducer.type === APP_MANAGER_TYPES[1]?
                  _allNotifs.filter(notif=>"companyId" in notif && notif.companyId.toString() === appConfigReducer.data.companyID.toString())
                :_allNotifs
                
                
                let currentTime = new Date()
                currentTime.setHours(0,0,0,0);
                currentTime = addDays(currentTime, -7).valueOf()
                const notifsRes = [...data, ..._data, ...allNotifs].filter((notif)=>(
                  "orderId" in notif && new Date(notif.date.replace(/-/g,'/') + " UTC").valueOf() >= currentTime ||
                  "notifId" in notif && new Date(notif.date.replace(/-/g,'/')+" UTC").valueOf() >= currentTime ||
                  (!("notifId" in notif) && !("orderId" in notif) && notif)
                ))

                // const notifsRes = 
                setUnReadNotifs(notifsRes.length)
                /*
                if(notifsRes.length >= 10) {
                  setUnReadNotifs(notifsRes.length)
                }else {
                  getAutoBookedBookings(
                    user.uid, 
                    formatDateToDateTimeStr(convertTimeZone(addDateHours(new Date(), 24 * 30 * 12 * 20))),
                    true,
                    10,
                    appConfigReducer.data.companyID, 
                    false, 
                    false
                  ).then((autoBookedNotifs)=>{
                      
                    
                    
                    const _autoBookedNotifs = autoBookedNotifs
                      .filter(item=>"serviceType" in item.services[0] && item.services[0].serviceType.toLowerCase() === "online" && item.notificationStatus.toUpperCase() === "UNREAD")
                    
                    setUnReadNotifs([...data, ..._data, ...allNotifs, ..._autoBookedNotifs].length)
                    // 
                    setLoading(false)
                  }).catch((err)=>{
                    
                    setLoading(false)
                  })
                  

                  // if(appConfigReducer.type === APP_MANAGER_TYPES[0]) {
                  //   setUnReadNotifs([...data, ..._data, ...allNotifs].length)
                  // }else if(appConfigReducer.type === APP_MANAGER_TYPES[1]) {
                    
                  // }
                }
                */

              })
            }
          }).catch((err)=>{
            
          })
        }
      })
      // ///get company invites notifs
      // getPendingStaffMemberNotif(user.uid).then((data)=>{
      //   
      // })
    }
    return () => {
      setNotif([])
    }
  }, [open, titleReducer.notifCounter, isAdmin])

  React.useEffect(()=>{
    // if(notif.length)
    getNotifs()
  }, [page])
  


  return (
    <div>
        <React.Fragment>

            <NavLink 
                className="nav-link-icon" 
                variant="outlined"  
                onClick={toggleDrawer(true)}
                id="tooltip184698705123"
                style={{cursor: 'pointer'}}
            >
                <Badge badgeContent={
                  unReadNotifs > 10 ? 10 + "+" :unReadNotifs
                } color="primary">
                    <Notifications color="purpleish" style={{fontSize: '25px'}} />
                </Badge>
            </NavLink>
          <Drawer
            anchor={"right"}
            open={open}
            onClose={toggleDrawer(false)}
          >
                <Box
                    sx={{ width: {md: 400, xs: '100%'}, minWidth: '350px' }}
                    role="presentation"
                >
                  <div className='py-2 pt-3 d-flex'>

                      <h4 className="pl-3 font-weight-bold ml-3 ml-md-0" style={{color: "#000"}}>Notifications</h4>
                      <div style={{flex: 1}}></div>
                      
                      <Box sx={{width: {md: '50px', xs: 'auto'}}} className="pr-3">

                        <IconButton onClick={toggleDrawer(false)} >
                          <Close style={{color: "#000"}} />
                        </IconButton>
                      </Box>

                  </div>
                  {/* <Divider /> */}

                  


                    {

                        loading?
                          <DivCenterContents _size={12} style={{minHeight: '80vh', }}>
                            <div className='w-100'>
                              <SyncLoader
                                loading={loading}
                                size={10}
                                color="#000"
                              />
                            </div>
                          </DivCenterContents>
                        :
                        (notif.length > 0)? 
                          <Grid container>
                            {  notif.map((item, idx)=>(
                              <Grid item md={12} xs={12} key={item.orderId || item.staffId || item.notifId}>
                                {
                                  "orderId" in item?
                                    <NotificationCard
                                        booking={item}
                                        handleRefreshNotifs={handleGetNotifs}
                                        configs={appConfigReducer}
                                      />

                                  :"staffId" in item?
                                    <StaffNotificationCard
                                        teamMember={item}
                                        handleRefreshNotifs={handleGetNotifs}
                                        configs={appConfigReducer}
                                      />
                                      

                                  :"notifId" in item?
                                    <LocalNotificationCard
                                        notif={item}
                                        handleRefreshNotifs={handleGetNotifs}
                                        isNotifOpen={(!open && hasNotifDrawerBeenOpened)}
                                        configs={appConfigReducer}
                                      />
                                  :""
                                }
                              </Grid>

                            ))
                            }
                            </Grid>
                        :
                        <DivCenterContents _size={12} style={{minHeight: '80vh'}}>
                            <p className="w-100">
                              There are no pending notifications
                            </p>
                        </DivCenterContents>


                    }

                    <Box>
                      {notif.length > 0?
                      
                        <DivCenterContents height={'60px'}>
                          <Pagination count={notif.length >= pageLimit?(page+1):page} page={page} onChange={handlePaginationChange} />
                        </DivCenterContents>
                      :""
                      }
                    </Box>

                </Box>
          </Drawer>
        </React.Fragment>
    </div>
  );
}
