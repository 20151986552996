import { AccessTime, Close, Done } from '@mui/icons-material';
import { Autocomplete, FormControl, Grid, IconButton, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import parse from 'html-react-parser';
import { searchLocation } from 'LocationController';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Linkify from 'react-linkify';
import { useDispatch } from 'react-redux';
import { SyncLoader } from 'react-spinners';
import Switch from "react-switch";
import TimePicker from 'react-time-picker';
import { addEvent, updateEvent } from '../../FirebaseController/Events';
import { getSetingsData } from '../../FirebaseController/Settings';
import { refreshEvents } from '../../Store/Reducers/Events/events.actions';
import '../../Utils/css/invoice_date_picker.css';
import { addDateHours, convertToSameDate, createDateFrom24hrString, defaultTimezone } from '../../Utils/functions';
import { inputStyles } from '../../Utils/style';
import AddAttendee from '../Modal/AddAttendee';

export default function AddEvent(props) {

    const { isEdit, data } = props
    const dispatch = useDispatch()
    const [enableGMeeting, setEnableGMeeting] = useState((data && ("hangoutLink" in data && data.hangoutLink)));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    const [showDesTextarea, setShowDesTextarea] = useState((isEdit == 1 || isEdit == 0))
    const [showLocTextarea, setShowLocTextarea] = useState((isEdit == 1 || isEdit == 0))
    const [eventData, setEventData] = useState({
        "summary": null,
        "description": null,
        'location': null,
        'description': null,
        'start': {            
            'dateTime': new Date(),
            'timeZone': defaultTimezone()
        },
        'end': {
            'dateTime': addDateHours(new Date(), 1),
            'timeZone': defaultTimezone()
        },
        "extendedProperties": {
            "private": {
                "priority": "high"
            }
        },
        'reminders': {
            'useDefault': false,
            'overrides': [
                {'method': 'email', 'minutes': 24 * 60},
                {'method': 'popup', 'minutes': 10}
            ]
        }
    })
    
    const [locOptions, setLocOptions] = React.useState([]);
    const [locValue, setLocValue] = React.useState(locOptions.length > 0?locOptions[0]:'');
    const [locInputValue, setLocInputValue] = React.useState('');
    const [isLocLoading, setIsLocLoading] = React.useState(false)

    const handleFormData = (name, value)=>{
        setEventData({
            ...eventData,
            [name]: value
        })
    }

    const handleDateData = (name,value)=>{
        setEventData({
            ...eventData,
            [name]: {
                'dateTime': value,
                'timeZone': defaultTimezone()
            }
        })
    }

    const handleTimeData = (name,value)=>{
        setEventData({
            ...eventData,
            [name]: {
                'dateTime': convertToSameDate(createDateFrom24hrString(value.toString()), new Date(eventData[name].dateTime)),
                'timeZone': defaultTimezone()
            }
        })
    }

    const handleLocInputChange = (event, value) => {
        setLocInputValue(value)
        setLocValue(value)
        if(!isLocLoading){
            if (window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition((position)=>{
                    
                    const lat = position.coords.latitude
                    const long = position.coords.longitude                
                    if(value.length > 2) {
                        setIsLocLoading(true)
                        searchLocation(value, lat, long).then((data)=>{
                            setIsLocLoading(false)
                            setLocOptions(
                                data.data.items.map(item=>item.address.label)
                            )
                        }).catch((err)=>{
                            
                            setIsLocLoading(false)
                        })
                    }
                }, (error) => {
                    if (error.code == error.PERMISSION_DENIED)
                        setIsLocLoading(false)
                        showSnackBar("Location permission denied, please activate permission to continue.", "error")      
                    }
                )
            } else {
                showSnackBar("Geolocation is not supported by this browser.", "error")
            }
        }
        
    }

    const handleEventAdd = (_isEdit = false)=>{
        if(eventData["summary"] === null || eventData["summary"] === "") {
            showSnackBar("Event title is required", "error")
        }else if(eventData["description"] === null || eventData["description"] === "")  {
            showSnackBar("Event description is required", "error")
        }else if(eventData["start"].dateTime === null || eventData["start"].dateTime < new Date())  {
            showSnackBar("Invalid start time", "error")
        }else if(eventData["end"].dateTime === null || eventData["end"].dateTime < new Date())  {
            showSnackBar("Invalid end time", "error")
        }else if(eventData["end"].dateTime < eventData["start"].dateTime)  {
            showSnackBar("Invalid end time", "error")
        }else {

            if(locValue) {
                eventData['location'] = locValue
            }

            //check if google meeting is activated
            
            getSetingsData(user.uid).then((settingsData)=>{
                if(settingsData["googleCalendar"] === true){
                    let gMeetVersion = 0
                    if(enableGMeeting){
                        //add meeting link details
                        gMeetVersion = 1
                        eventData["conferenceData"] = {
                            "createRequest" : {
                                "requestId": Math.random().toString(10).slice(2),
                                "conferenceSolutionKey": {
                                    "type": "hangoutsMeet",
                                }
                            }
                        }

                    }

                    let attendees = localStorage.getItem("attendees")
                    if(attendees) {
                        attendees = attendees.split(",")
                        eventData["attendees"] = attendees.map(email => {
                            const attendee = {}
                            attendee["email"] = email
                            attendee["responseStatus"] = "needsAction"
                            // attendee["displayName"] = email.split("@")[0]
                            // attendee["organizer"] = it.isOrganizer
                            // attendee["additionalGuests"] = it.additionalGuests
                            // attendee["resource"] = it.resource  
                            return attendee                          
                        });
                    }

                    if(_isEdit){
                        const eventId  = eventData.id
                        delete eventData.id
                        updateEvent(eventId, eventData, gMeetVersion).then((gEventResult)=>{
                            
                            showSnackBar("Event updated successfully", "success")
                            localStorage.removeItem("attendees")
                            dispatch(refreshEvents())
                        }).catch((err)=>{
                            
                            localStorage.removeItem("attendees")
                            showSnackBar("Could not update event", "error")
                        })
                    }else {

                        addEvent(eventData, gMeetVersion).then((gEventResult)=>{
                            
                            showSnackBar("Event added successfully", "success")
                            localStorage.removeItem("attendees")
                            dispatch(refreshEvents())
                        }).catch((err)=>{
                            
                            localStorage.removeItem("attendees")
                            showSnackBar("Could not add event", "error")
                        })
                    }
                }else {                    
                    showSnackBar("Google calendar is not activated, please activate in settings", "error")
                }
            }).catch(()=>{
                
                showSnackBar("Could not load user settings, please try again later", "error")
            })

        }
    }


    function customizeEventDescription(eventDesc) {

        let newEventDesc = eventDesc
        let wordsToCheck = ["Service provider:", "Client name:", "Duration:", "Duration", "Service descriptions:","Services descriptions:", "Booking details:"]
        if(newEventDesc) {

            wordsToCheck.forEach((word, idx) => {
                const br = idx === 0?"":"<br />"//insert break line but not to first line
                let newWord = word.split(":")[0] + ":" //this will insert colon to all words even if theydont have colons
                newEventDesc = newEventDesc.replace(word, br + "<strong>"+newWord+"</strong>")//replace text with html tags
            });           

        }
        return newEventDesc

    }
    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}


    useEffect(() => {
    
        if(isEdit !== 0 && data) {
            data.start.dateTime = new Date(data.start.dateTime)
            data.end.dateTime = new Date(data.end.dateTime)
            setEventData(data)
            setLocValue(eventData.location?eventData.location:("hangoutLink" in eventData && eventData.hangoutLink)?eventData.hangoutLink:"")
            setLocInputValue(eventData.location?eventData.location:("hangoutLink" in eventData && eventData.hangoutLink)?eventData.hangoutLink:"")
        }
    }, [isEdit])

    

    return (
        
        <Grid container spacing={1} className='px-4 pb-4 my-3'>



            <Grid item xs={12}>
                <div className='border p-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '10px'}}>
                    <label htmlFor='eventTitle'>
                       {isEdit == 2?"Event Name":"Add event"} 
                    </label><br />
                    <textarea id="eventTitle" style={{
                            borderRadius: '5px',
                            background: 'transparent',
                            border: 'none',
                            padding: '5px',
                            width: '100%',
                            resize: 'none'
                        }} 
                        type="text" 
                        name="summary" 
                        placeholder='Enter text here'
                        value={(eventData.summary)?eventData.summary:""}
                        rows="1"
                        cols="50"
                        disabled={isEdit == 2}
                        onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                    ></textarea>

                </div>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard" className="py-1 my-1" >
                        <label htmlFor="input-with-icon-adornment" className='py-0 my-0'>
                            Date from
                        </label>
                        <DatePicker


                            selected={eventData["start"]["dateTime"]?new Date(eventData["start"]["dateTime"]):new Date()} 
                            onChange={(val)=>handleDateData("start",val)} 
                            
                            // value={eventData["start"]["dateTime"]} 
                            // onChange={(val)=>handleDateData("start",val)} 
                            id="input-with-icon-adornment"
                            // minDate={new Date()}
                            // name="Date From"
                            // clearIcon={null}
                            // calendarIcon={<DateRange color="primary" />}
                            // isOpen={false}
                            disabled={isEdit === 2}
                        />
                        {/* <Input
                            id="input-with-icon-adornment"
                            className='mt-1'
                            // disabled
                            fullWidth
                            style={inputStyles}
                            disableUnderline={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DateRange color="primary" />
                                </InputAdornment>
                            }
                        /> */}
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard" className="py-1 my-1" >
                    <label htmlFor="input-with-icon-adornment1" className='py-0 my-0'>
                        Date to
                    </label>
                    <DatePicker

                        id="input-with-icon-adornment11212"
                        selected={eventData["end"]["dateTime"]?new Date(eventData["end"]["dateTime"]):new Date()} 
                        onChange={(val)=>handleDateData("end",val)} 
                        // value={eventData["end"]["dateTime"]} 
                        // onChange={(val)=>handleDateData("end",val)} 
                        // minDate={new Date()}
                        // name="Date To"
                        // clearIcon={null}
                        // calendarIcon={<DateRange color="primary" />}
                        disabled={isEdit === 2}
                        />
                    {/* <Input
                        id="input-with-icon-adornment1"
                        className='mt-1'
                        disabled
                        fullWidth
                        style={inputStyles}
                        disableUnderline={true}
                        endAdornment={
                            <InputAdornment position="end">
                                <DateRange color="primary" />
                            </InputAdornment>
                        }
                    /> */}
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard" className="py-1 mb-1" >
                    <label htmlFor="input-with-icon-adornment2" className='py-0 my-0'>
                        Time from
                    </label>
                    <TimePicker 
                        id="input-with-icon-adornment2"
                        onChange={(val)=>handleTimeData("start",val)} 
                        value={eventData["start"]["dateTime"]} 
                        renderHourMarks={true}
                        renderMinuteMarks={true}
                        renderNumbers={true}
                        clearIcon={null}
                        clockIcon={<AccessTime color="primary" />}
                        disabled={isEdit === 2}
                        // disableClock={isEdit}
                    />
                    {/* <Input
                        id="input-with-icon-adornment2"
                        className='mt-1'
                        disabled
                        fullWidth
                        style={inputStyles}
                        disableUnderline={true}
                        endAdornment={
                            <InputAdornment position="end">
                                <AccessTime color="primary" />
                            </InputAdornment>
                        }
                    /> */}
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard" className="py-1 mb-1" >
                    <label htmlFor="input-with-icon-adornment3" className='py-0 my-0'>
                        Time to
                    </label>
                    <TimePicker 
                        id="input-with-icon-adornment3"
                        onChange={(val)=>handleTimeData("end",val)} 
                        value={eventData["end"]["dateTime"]} 
                        renderHourMarks={true}
                        renderMinuteMarks={true}
                        renderNumbers={true}
                        clockIcon={<AccessTime color="primary" />}
                        clearIcon={null}
                        disabled={isEdit === 2}
                        // disableClock={isEdit}
                    />
                    {/* <Input
                        id="input-with-icon-adornment3"
                        className='mt-1'
                        disabled
                        fullWidth
                        style={inputStyles}
                        disableUnderline={true}
                        endAdornment={
                            <InputAdornment position="end">
                                <AccessTime color="primary" />
                            </InputAdornment>
                        }
                    /> */}
                </FormControl>
            </Grid>
            <Grid item xs={12}sx={{display: (eventData.description || isEdit !== 2)?'block':'none' }}>
                <FormControl variant="standard" fullWidth className="py-1 mb-1 mt-2" >
                    <div className={isEdit === 2?'d-none':'d-flex'}>
                        <label htmlFor="input-with-icon-adornment4" className='py-0 my-0'>
                            Description
                        </label>
                        <div style={{flex: 1}}></div>
                        <AddAttendee 
                            existingAttendees={("attendees" in eventData && eventData.attendees)?eventData.attendees.map(item=>item.email).join(","):null} 
                        />
                    </div>
                    {

                        isEdit !== 2?
                            <textarea 
                                // contentEditable={true}
                                style={{
                                    minHeight: '130px',
                                    width: '100% !important',
                                    wordBreak: 'break-all',
                                    ...inputStyles,
                                }}
                                // fullWidth 
                                id="input-with-icon-adornment4"
                                name="description"
                                placeholder="Add description"
                                required = {true}
                                value={eventData.description?eventData.description:""}
                                //  dangerouslySetInnerHTML={{__html: eventData.description?eventData.description:""}}
                                onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                                // onBlur={(e)=>handleFormData(e.target.getAttribute("name"), e.target.innerHTML)}
                            >
                                {/* { eventData.description?eventData.description:"" } */}
                            </textarea>


                        :
                        <Linkify 
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" rel="nofollow" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >
                            <div 
                                // contentEditable={true}
                                style={{
                                    minHeight: '130px',
                                    width: '100% !important',
                                    wordBreak: 'break-all',
                                    ...inputStyles,
                                }}
                                // fullWidth 
                                id="input-with-icon-adornment4"
                                name="description"
                                placeholder="Add description"
                                // onClick={()=>setShowDesTextarea(true)} //uncomment this 2 lines to enable editing events
                                // onFocus={()=>setShowDesTextarea(true)}

                                // value={eventData.description?eventData.description:""}
                                //  dangerouslySetInnerHTML={{__html: eventData.description?eventData.description:""}}
                                // onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                                // onBlur={(e)=>handleFormData(e.target.getAttribute("name"), e.target.innerHTML)}
                            >
                                <span
                                    onClick={e => e.stopPropagation()}
                                    onFocus={e => e.stopPropagation()}
                                >
                                    { eventData.description?
                                        parse(customizeEventDescription(eventData.description)):"" }
                                </span>
                            </div>
                        </Linkify>

                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sx={{display: (eventData.location || isEdit !== 2 || ("hangoutLink" in eventData && eventData.hangoutLink))?'block':'none' }} >
                <FormControl variant="standard" className="py-1 mb-1" fullWidth >
                    <div className='d-flex'>
                        <label htmlFor="input-with-icon-adornment5" className='py-0 my-0'>
                            Location
                        </label>
                        <div style={{flex: 1}}></div>
                        <Button disabled={true} color="secondary"  className='py-0 my-0' style={{textTransform: 'none'}}>
                            {
                                isLocLoading?
                                    <SyncLoader
                                    loading={isLocLoading}
                                    size={7}
                                    color="#000"
                                    />
                                    :
                                ""
                            }
                            
                        </Button>
                    </div>
                    {
                        isEdit !== 2?
                            // <input
                            //     className='mt-1 w-100'
                            //     id="input-with-icon-adornment5"
                            //     // fullWidth
                            //     style={inputStyles}
                            //     // disableUnderline={true}
                            //     placeholder="Enter your location"
                            //     name="location"
                            //     value={eventData.location?eventData.location:("hangoutLink" in eventData && eventData.hangoutLink)?eventData.hangoutLink:""}
                            //     onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                            // />
                            <Autocomplete
                                disablePortal
                                style={inputStyles}
                                id="location"
                                options={locOptions}
                                size={"small"} 
                                sx={{
                                    padding: 0,
                                    margin: 0,
                                    width: '100%', border: 'none',
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                    fontSize: '15px',
                                }}
                                value={locValue}
                                inputValue={locInputValue}
                                onChange={(event, newValue) => {
                                  setLocValue(newValue);
                                }}                               
                                onInputChange={handleLocInputChange}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params}
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, disableUnderline: true, style: {...inputStyles, borderRadius: 15} }}
                                    />}
                            />


                        :
                        
                            <div
                                className='mt-1 w-100'
                                id="input-with-icon-adornment5"
                                // fullWidth
                                style={inputStyles}
                                // disableUnderline={true}
                                placeholder="Enter your location"
                                name="location"
                                // onClick={()=>setShowLocTextarea(true)}
                                // onFocus={()=>setShowLocTextarea(true)}

                                // value={eventData.location?eventData.location:""}
                                // onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                            >
                               <Linkify  
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" rel="nofollow" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}
                                >
                                    <span
                                        onClick={e => e.stopPropagation()}
                                        onFocus={e => e.stopPropagation()}
                                    >
                                        {eventData.location?
                                            parse(eventData.location)
                                            :("hangoutLink" in eventData && eventData.hangoutLink)?parse(eventData.hangoutLink):""}
                                    </span>
                                 </Linkify>
                            </div>
                        
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} sx={{display: isEdit === 2?'none':'block'}} >
                <FormControl variant="standard" className="py-1 mb-1" fullWidth>
                    <label htmlFor="input-with-icon-adornment6" className='py-0 my-0'>
                        Video conferencing
                    </label>
                    <input
                        className='mt-1 w-100'
                        id="input-with-icon-adornment6"                                
                        // fullWidth={true}
                        style={inputStyles}
                        // disableUnderline={true}
                        placeholder="Add video conferencing details"
                        name="videoDetails"
                        onChange={(e)=>handleFormData(e.target.getAttribute("name"), e.target.value)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}  sx={{display: isEdit === 2?'none':'block'}} >
                <div className='d-flex pt-3 pb-3'>
                    <p>
                        Google meet video calling
                    </p>
                    <div style={{flex: 1}}></div>
                    <label>
                        <Switch 
                            onChange={setEnableGMeeting}
                            checked={enableGMeeting}
                            onColor={"#F4F5FA"}
                            onHandleColor={"#F0973B"}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checkedHandleIcon={<Done style={{color: "#fff", margin: '5px'}} /> }
                            offHandleColor={"#E7E8ED"}
                            offColor="#F4F5FA"
                            height={35}
                            width={63}
                        />
                    </label>
                </div>
            </Grid>
            
            <Grid item xs={12} className="d-flex">
                <div style={{flex: 1}}></div>
                {isEdit === 0? 
                
                    <Button
                        className={"rounded border-0 my-4 mx-4 text-capitalize"}
                        color="white"
                        type="button" 
                        style={{background: '#F0973B',
                            
                        }}
                        onClick={()=>handleEventAdd(false)}
                    >
                        
                        Save
                        
                    </Button>
                
                :isEdit === 1?
                
                    <Button
                        className={"rounded border-0 my-4 mx-4 text-capitalize"}
                        color="white"
                        type="button" 
                        style={{background: '#F0973B',
                            
                        }}
                        onClick={()=>handleEventAdd(true)}
                    >
                        
                        Save
                        
                    </Button>
                
                :""}
            </Grid>

            
            
            
            
            

        </Grid>

    )

}