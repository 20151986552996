import { zoomClientId, zoomRedirectUrl } from "AppSettings"
import axios from "axios"
import { addDateHours, addDateMins } from "Utils/functions"


// let redirectUrl = "https://dennis-gitonga-web.000webhostapp.com/zoom/zoom.php"
// let redirectUrl = "https://penguins-zoom-api.herokuapp.com"
 
//let redirectUrl = "https://latom.in/zoom/dev/handler.php"
 let redirectUrl = "https://latom.in/zoom/prod/handler.php"

export const checkZoomAuth = () => new Promise((successCallBack, errorCallback)=>{
    const tokenStr = localStorage.getItem("zoomAccessToken")
    //check if token exists
    if(tokenStr){
        const token = JSON.parse(tokenStr)
        //check if token has expired
        const expiresAt = addDateMins(new Date(token["expiresAt"]), -10 )
        
        
        if(new Date() > expiresAt) {
            
            refreshAccessTokens(token["refreshToken"])
            .then(successCallBack)
            .catch(errorCallback)
        }else {
            
            successCallBack()
        }

    }else {
        authorizeZoom()
    }
})


function authorizeZoom() {

    const url = "https://zoom.us/oauth/authorize?response_type=code&client_id="+
        zoomClientId+"&redirect_uri="+encodeURIComponent( zoomRedirectUrl )
    window.location.href = url
}


export const getAccessToken = (code, state)=>new Promise(function(successCallBack, errorCallback){

    let token = JSON.parse(localStorage.getItem("zoomAccessToken"))
    token = token?token:{}
    const headers = {
        "Content-Type": "application/json",
    }
    let url = redirectUrl + "?action=access_token&code="+code+
        "&redirect_url="+zoomRedirectUrl
    // const formData = new FormData()
    // formData.append('code', code);
    // formData.append('redirect_url', url);
    if(state){
        // formData.append('state', state)
        url += "&state="+state
    }

    axios.get(url, {headers:headers})
      .then((resp)=>{

        if("data" in resp) {
            const data = JSON.parse(resp.data)
            if("error" in data) {
                errorCallback(data)
            }else {
                
                
                token["accessToken"] = data["access_token"].toString()
                token["refreshToken"] = data["refresh_token"].toString()
                token["tokenType"] = data["token_type"].toString()
                token["expiresIn"] = data["expires_in"].toString()
                token["scope"] = data["scope"].toString()
                token["expiresAt"] = addDateHours(new Date(), 1)// token are valid for 1 hour only
                localStorage.setItem("zoomAccessToken", JSON.stringify(token))
                
                successCallBack()
            }
        }else {
            errorCallback({"error": "No data"})
        }

      })
      .catch((error)=>{
        errorCallback(error)
      });
})

export const refreshAccessTokens = (refreshToken)=>new Promise(function(successCallBack, errorCallback){

    const token = JSON.parse(localStorage.getItem("zoomAccessToken"))
    const headers = {
        "Content-Type": "application/json",
    }
    let url = redirectUrl + "?action=refresh_token"
    const formData = new FormData()
    formData.append('refresh_token', refreshToken);

    axios.post(url, formData, {headers:headers})
      .then((resp)=>{
        
        if("data" in resp) {
            const data = JSON.parse(resp.data)
            if("error" in data) {
                errorCallback(data)
            }else {
                
                token["accessToken"] = data["access_token"].toString()
                token["refreshToken"] = data["refresh_token"].toString()
                token["tokenType"] = data["token_type"].toString()
                token["expiresIn"] = data["expires_in"].toString()
                token["scope"] = data["scope"].toString()
                token["expiresAt"] = addDateHours(new Date(), 1)// token are valid for 1 hour only
                localStorage.setItem("zoomAccessToken", JSON.stringify(token))
                
                successCallBack()
            }
        }else {
            errorCallback({"error": "No data"})
        }

      })
      .catch(errorCallback);
})


export const createMeeting = (startTime, duration, userName)=>new Promise(function(successCallBack, errorCallback){

    const token = JSON.parse(localStorage.getItem("zoomAccessToken"))
    if(token) {
        const headers = {
            "Content-Type": "application/json",
            // "Authorization": "Bearer "+token.accessToken,
        }

        const url = redirectUrl + "?action=get_zoom_user"
        const formData = new FormData()
        formData.append('access_token', token["accessToken"])

        axios.post(url, formData, {headers:headers})
        .then((results)=>{   
            
            
            if("data" in results) {
                const resp = JSON.parse(results.data)
                if("id" in resp){
                    const zoomId = resp["id"]
                    let meetingUrl = redirectUrl + "?action=create_meeting"

                    const formData = new FormData()
                    formData.append("start_time", new Date(startTime).toISOString())
                    formData.append("duration", duration.toString())
                    formData.append("topic", "Appointment with " + userName)
                    formData.append( "timezone", "UTC")
                    formData.append( "zoom_id", zoomId)
                    formData.append( "access_token", token["accessToken"])
                
                    axios.post(meetingUrl, formData, {headers:headers})
                    .then((meetingResp)=>{
                
                        
                        if("data" in meetingResp){
                            const meetingData =  JSON.parse(meetingResp.data)
                            const zoomMeetingDetails = {}
                            zoomMeetingDetails["startLink"] = meetingData["start_url"].toString()
                            zoomMeetingDetails["joinLink"] = meetingData["join_url"].toString()
                            successCallBack({"zoomMeetingDetails": zoomMeetingDetails})
                        }else {
                            errorCallback({'error':'could not create meeting'})
                        }
                    })  
                    .catch(errorCallback);
                }

            }
        })
        .catch((err)=>{
            errorCallback(err)
        });
    }
})


export const revokeAccessToken = ()=>new Promise(function(successCallBack, errorCallback){

    const token = JSON.parse(localStorage.getItem("zoomAccessToken"))
    if(token) {
        const headers = {
            "Content-Type": "application/json",
        }
        let url = redirectUrl + "?action=revoke_token"
        const formData = new FormData()
        formData.append('access_token', token.accessToken);

        axios.post(url, formData, {headers:headers})
        .then((resp)=>{
            
            if("data" in resp) {
                const data = JSON.parse(resp.data)
                if("error" in data) {
                    errorCallback(data)
                }else {
                    localStorage.removeItem("zoomAccessToken")
                    successCallBack("zoom tokens revoked successfully")
                }
            }else {
                errorCallback({"error": "No data"})
            }

        })
        .catch(errorCallback);
    }else {
        errorCallback("no token found")
    }
})


// export const getAccessToken = (code, state)=>new Promise(function(successCallBack, errorCallback){

//     
//     const token = JSON.parse(localStorage.getItem("zoomAccessToken"))
//     const headers = {
//         "Content-Type": "application/json",
//         "Authorization": "Basic "+ btoa(zoomClientId + ":"+ zoomClientPwd)
//     }
//     let url = "https://zoom.us/oauth/token"
//     const formData = new FormData()
//     formData.append('grant_type', 'authorization_code');
//     formData.append('code', code);
//     formData.append('redirect_uri', zoomRedirectUrl);
//     if(state){
//         formData.append('state', state)
//     }

//     axios.post(url, formData, {headers:headers})
//       .then((resp)=>{

        
//         token["accessToken"] = resp["access_token"].toString()
//         token["refreshToken"] = resp["refresh_token"].toString()
//         token["tokenType"] = resp["token_type"].toString()
//         token["expiresIn"] = resp["expires_in"].toString()
//         token["scope"] = resp["scope"].toString()
//         token["expiresAt"] = addDateHours(new Date(), 1)// token are valid for 1 hour only
//         localStorage.setItem("zoomAccessToken", JSON.stringify(token))
//         successCallBack()

//       })
//       .catch((err)=>{
//           
//           
//         errorCallback(err)
//       });
// })

// export const refreshAccessTokens = (refreshToken)=>new Promise(function(successCallBack, errorCallback){

//     const token = JSON.parse(localStorage.getItem("zoomAccessToken"))
//     const headers = {
//         "Content-Type": "application/json",
//         "Authorization": "Bearer "+ btoa(zoomClientId + ":"+ zoomClientPwd)
//     }
//     const url = "https://zoom.us/oauth/token?grant_type=refresh_token&refresh_token="+refreshToken
//     axios.post(url, {}, {headers:headers})
//       .then((resp)=>{

        
//         token["accessToken"] = resp["access_token"].toString()
//         token["refreshToken"] = resp["refresh_token"].toString()
//         token["tokenType"] = resp["token_type"].toString()
//         token["expiresIn"] = resp["expires_in"].toString()
//         token["scope"] = resp["scope"].toString()
//         token["expiresAt"] = addDateHours(new Date(), 1)// token are valid for 1 hour only
//         localStorage.setItem("zoomAccessToken", JSON.stringify(token))
//         successCallBack()

//       })
//       .catch(errorCallback);
// })