//google calendar and events api controllers
//resources https://developers.google.com/calendar/api/guides/create-events#javascript , https://developers.google.com/calendar/api/v3/reference/events/list

import { clientId } from "AppSettings";
import axios from "axios";
import { addDateMins } from "Utils/functions";

const gapi = window.gapi

export const refreshGoogleTokens = ()=>new Promise(function(successCallback, errorCallback){
    
  
  //check if access token is expired an silent sign in
  const localUser = JSON.parse(localStorage.getItem("user"))
  if(localUser && "tokenDetails" in localUser && localUser.tokenDetails){
      const expiresAt = addDateMins(new Date(localUser.tokenDetails.expires_at), -30) //refresh after 30 mins
      if((new Date() > expiresAt)) {
        //refresh tokens
        gapi.load('auth2', function(){
          let auth2 = gapi.auth2.init({
              client_id: clientId
          });
          
          gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse().then((resp)=>{
            localUser.tokenDetails = resp
            localUser.token = resp.access_token
            localStorage.setItem("user", JSON.stringify(localUser ))
            successCallback()
          
          }).catch(errorCallback)
          
    
        });
      }else {
        //token is okay, so no need for refresh
          successCallback()
      }
  }else {
      errorCallback("no user found")
  }


})

export const addEvent = (eventData, conferenceDataVersion = 0)=>new Promise(function(successCallBack, errorCallback){

    refreshGoogleTokens().then(()=>{

      const user = JSON.parse(localStorage.getItem("user"))
      // 
      // 
      const headers = {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+ user.token
      }
      const url = "https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion="
          +conferenceDataVersion+"&sendUpdates=all"
      
      axios.post(url, eventData, {headers:headers})
        .then(successCallBack)
        .catch(errorCallback);
    }).catch(errorCallback)
})

export const getCalendars = ()=>new Promise(function(successCallBack, errorCallback){

  refreshGoogleTokens().then(()=>{
    const user = JSON.parse(localStorage.getItem("user"))
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+ user.token
    }
    const url = "https://www.googleapis.com/calendar/v3/users/me/calendarList"
    
    axios.get(url, {headers:headers})
      .then(successCallBack)
      .catch(errorCallback);
  }).catch(errorCallback)
})

export const getEvents = (timeMin, timeMax)=>new Promise(function(successCallBack, errorCallback){

  refreshGoogleTokens().then(()=>{
      const user = JSON.parse(localStorage.getItem("user"))
      //
      const headers = {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+ user.token
      }

      const url = "https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin="+timeMin.toISOString()
            +"&timeMax="+timeMax.toISOString()+
            "&orderBy=updated"
            axios.get(url, {headers:headers})
            .then((results)=>{   
              successCallBack(results)
            })
            .catch((err)=>{
              errorCallback(err)
            });
      
      // getCalendars().then((data)=>{
      //   // 
      //   if("data" in data && "items" in data.data) {
      //     let eventsList = {}
      //     data.data.items.forEach(item => {
      //       // 
      //       const url = "https://www.googleapis.com/calendar/v3/calendars/"+encodeURIComponent(item.id  )+"/events?timeMin="+timeMin.toISOString()
      //       +"&timeMax="+timeMax.toISOString()+
      //       "&orderBy=updated"
      //       axios.get(url, {headers:headers})
      //       .then((results)=>{
      //         if("data" in results && "items" in results.data) {
      //           // 
      //           // 
      //           if("data" in eventsList && "items" in eventsList.data) {
      //             eventsList.data.items = [
      //               ...eventsList.data.items,
      //               ...results.data.items
      //             ]
      //           }else {
      //             eventsList = results
      //             // 
      //           }
      //         }
      //         // 
      //         successCallBack(eventsList)
      //       })
      //       .catch((err)=>{
      //         errorCallback(err)
      //       });
      //     });
      //   }
      // })
  }).catch(errorCallback)
})


export const updateEvent = (eventId, eventData, conferenceDataVersion = 0)=>new Promise(function(successCallBack, errorCallback){

  refreshGoogleTokens().then(()=>{
    const user = JSON.parse(localStorage.getItem("user"))
    // 
    // 
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+ user.token
    }
    const url = "https://www.googleapis.com/calendar/v3/calendars/primary/events/"+eventId+"?conferenceDataVersion="
        +conferenceDataVersion+"&sendUpdates=all"
    
    axios.put(url, eventData, {headers:headers})
      .then(successCallBack)
      .catch(errorCallback);
  }).catch(errorCallback)
})

export const deleteEvents = (eventId)=>new Promise(function(successCallBack, errorCallback){

    refreshGoogleTokens().then(()=>{
      const user = JSON.parse(localStorage.getItem("user"))
      const headers = {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+ user.token
      }
      const url = "https://www.googleapis.com/calendar/v3/calendars/primary/events/"+eventId

      axios.delete(url, {headers:headers})
        .then(successCallBack)
        .catch((err)=>{
          errorCallback(err)
        });
    }).catch(errorCallback)
})

