import { initializeApp } from "firebase/app";
import { child, get, getDatabase, ref as fRef } from "firebase/database";
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { getSubscriptionSettings } from "FirebaseController/Settings";
import { getSubscriptionPlanInfo } from "FirebaseController/StripePayments";
import { firebaseConfig } from "../../AppSettings";
import { DEFAULT_SERVICE, SERVICE_STATUS } from "../../Utils/constants";


const app = initializeApp(firebaseConfig);
const db = getFirestore (app)
const realTimeDB = getDatabase()


export const saveService = (data, firebaseUserID, subscribedUser)=>new Promise(function(successCallback, errorCallback){

    let serviceUpdatedAt = null
    
    getServiceDataFromDb(firebaseUserID).then((_data)=>{

        
        //get user subscriptions
          
        getSubscriptionSettings(subscribedUser).then((subscription)=>{

            
            const type = (subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) > new Date())
            ?subscription.subscriptionType:"Free"

            
            //get plan
            getSubscriptionPlanInfo(type).then((plans)=>{
                if(plans.length > 0) {
                    run(parseFloat(plans[0].Services))
                }else {
                    errorCallback("Invalid subscription plan, try again later.")
                }
            }).catch(errorCallback)

        }).catch((err)=>{
            errorCallback(err)
        })


        function run(limit){
            if(limit && !isNaN(limit)) {
                if(_data.length >= limit) {

                    
                    errorCallback("Sorry, you can only add upto "+limit+" services. Upgrade your plan to add more services.")

                }else {

                    
                    //add service
                    if(!serviceUpdatedAt || (new Date() - serviceUpdatedAt)/1000 > 2) {
                        
                        const serviceID = Math.random().toString(10).slice(2)
                        data['serviceId'] = serviceID
                        data['status'] = SERVICE_STATUS[0]
                        const myDocRef = doc(db, "UserServices", firebaseUserID, "Services", serviceID)
                        setDoc(myDocRef, data).then(successCallback).catch(errorCallback)
                    }
                    serviceUpdatedAt = new Date()
                }
            }else {
                errorCallback("Could not get your subscriptions details, check your internet or try again later.")
            }
        }

    }).catch(errorCallback)
})


export const getService = (firebaseUserID, serviceId)=>new Promise( async function(successCallback, errorCallback) {

    try {
        const myDocRef = doc(db, "UserServices/"+ firebaseUserID+ "/Services/" + serviceId)
        const docSnap = await getDoc(myDocRef)
        if(docSnap.exists()) {
            successCallback(docSnap.data())
        }else {
            successCallback({})            
        }
    }catch(err) {
        errorCallback(err)
    }
});


export const getServiceByTitle = (firebaseUserID, title, isCompany = false)=>new Promise(async function(successCallback, errorCallback) {
    try {
        
    
    
    
        const myDocRef = collection(db, "UserServices/"+firebaseUserID+"/Services/")
        const q = isCompany?
        query(myDocRef, 
            where("serviceTitle", "==", title),
            limit(1)
            // where("status", "==", SERVICE_STATUS[0])
            // orderBy("serviceType")
        )
        :
        query(myDocRef, 
            where("serviceTitle", "==", title),
            where("serviceProviderId."+firebaseUserID, "==", true),
            limit(1)
            // where("status", "==", SERVICE_STATUS[0])
            // orderBy("serviceType")
        )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())
        });
        //return boolean 
        //that is whether data exists or not
        successCallback(_data.length > 0?_data[0]:{})
    }catch (err) {
        errorCallback(err)
    }
});

export const getServiceDataFromDb = (firebaseUserID, pageLimit = 10)=>new Promise( async function(successCallback, errorCallback) {


    try{
        
        const myDocRef = collection(db, "UserServices/"+ firebaseUserID+ "/Services/")
        const q = query(
            myDocRef, 
            where("status", "==", SERVICE_STATUS[0]), 
            limit(pageLimit), 
            orderBy("serviceTitle")
        )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(err) {
        errorCallback(err)
    }
});


export const getMyCompanyServiceData = (companyId, firebaseUserID, pageLimit = 10)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = collection(db, "UserServices/"+companyId+"/Services/")
    const q = query(myDocRef, 
        where("companyID", "==", parseInt(companyId)),
        where("serviceProviderId."+firebaseUserID, "==", true), 
        where("status", "==", SERVICE_STATUS[0]),
        limit(pageLimit)
        // orderBy("serviceType")
    )
    try {
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)

    } catch (err) {
        errorCallback(err)
    }
});



export const searchServiceByLocation = (locId, firebaseUserID)=>new Promise( async function(successCallback, errorCallback) {

    
    
    const myDocRef = collection(db, "UserServices/"+ firebaseUserID+ "/Services/")
    const q = query(myDocRef,  where("serviceLocation", "==", locId), orderBy("serviceTitle"))
    try {
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data.length > 0)

    } catch (err) {
        errorCallback(err)
    }
});

export const checkDefaultServiceExists = (firebaseUserID)=>new Promise(async function(successCallback, errorCallback) {
    try {
        const myDocRef = collection(db, "UserServices/"+firebaseUserID+"/Services/")
        const q = query(myDocRef, 
            where("serviceTitle", "==", DEFAULT_SERVICE.serviceTitle),
            where("serviceProviderId."+firebaseUserID, "==", true),
            // where("status", "==", SERVICE_STATUS[0])
            // orderBy("serviceType")
        )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        //return boolean 
        //that is whether data exists or not
        successCallback(_data.length > 0)
    }catch (err) {
        errorCallback(err)
    }
});

export const getServiceTags = ()=>new Promise( async function(successCallback, errorCallback) {

    try {
        const myDocRef = collection(db, "ServiceTags/")
        const q = query(myDocRef, orderBy("tag"))
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(err) {
        errorCallback(err)
    }
});


export const getIndustries = ()=>new Promise( async function(successCallback, errorCallback) {

    // const db1 = getFirestore (app)
    
    //const searchRef = fQuery(fRef(db, 'Industries/'), orderByChild('industry'))
    
    const userRef = fRef(realTimeDB)
    get(child(userRef, 'Industries/'))
    // onValue(userRef, (snapshot) => {
        .then((snapshot) => {
        
        if(snapshot.exists()) {
            
            const data = snapshot.val()
            
            const newData = []
            Object.keys(data).forEach(function(key) {
                newData.push(data[key])
            })
            successCallback(newData)
            //goOffline(db)
        }else {
            successCallback([])
            //goOffline(db)
        }
    }).catch(errorCallback)


    // onValue(fRef(db, 'Industries/'), (snapshot) => {
    //     if(snapshot.exists()) {
            
    //         const data = snapshot.val()
    //         
    //         const newData = []
    //         Object.keys(data).forEach(function(key) {
    //             newData.push(data[key])
    //         })
    //         successCallback(newData)
    //         //goOffline(db)
    //     }else {
    //         successCallback([])
    //         //goOffline(db)
    //     }
    // }, (err)=>{
    //     //goOffline(db)
    //     errorCallback(err)
    // });


    // const myDocRef = collection(db, "Industries/")
    // const q = query(myDocRef, orderBy("industry"))
    // const docSnap = await getDocs(q)
    // const _data = []

    // docSnap.forEach((doc) => {
    //     _data.push(doc.data())        
    // });
    // successCallback(_data)
});

export const updateService = (data, firebaseUserID)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = doc(db, "UserServices", firebaseUserID, "Services", data['serviceId'])
    updateDoc(myDocRef, data).then(successCallback).catch(errorCallback)
})


export const deleteService = (firebaseUserID,serviceId)=>new Promise(function(successCallback, errorCallback){

    //TODO: check if service has booking

    //delete booking
    const myDocRef = doc(db, "UserServices", firebaseUserID, "Services",serviceId)
    deleteDoc(myDocRef).then(successCallback).catch(errorCallback)
})

export const deleteServiceProfile = ( firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    const storage = getStorage();

    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        const imgRef = storageRef(storage, firebaseUrl);
        deleteObject(imgRef).then(()=>{
            successCallBack("deleted")
        }).catch(errorCallback)
    }
})

export const updateServiceProfile = (file, firebaseID, firebaseUrl = "", isCompany = false)=>new Promise(function(successCallBack, errorCallback){
    
    const storage = getStorage()
    deleteServiceProfile(firebaseUrl)

    const ref_ = isCompany?"Companies/" + firebaseID+"/services/"+(new Date().valueOf()) + '.jpg'
        :"Users/" + firebaseID+"/services/"+(new Date().valueOf()) + '.jpg'
        
    const imgRef = storageRef(storage, ref_);
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                
                successCallBack(url)
            })
            .catch(errorCallback);
    }).catch(errorCallback)

})


export const saveCategory = (data, firebaseUserID, isCompany = false, subscribedUser)=>new Promise(function(successCallback, errorCallback){

      
    getSubscriptionSettings(subscribedUser).then((subscription)=>{

        
        const type = (subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) > new Date())
        ?subscription.subscriptionType:"Free"


        
        //get plan
        getSubscriptionPlanInfo(type).then((plans)=>{
            if(plans.length > 0) {
                run(parseFloat(plans[0].Categories))
            }else {
                errorCallback("Invalid subscription plan, try again later.")
            }
        }).catch(errorCallback)

    }).catch((err)=>{
        errorCallback(err)
    })

    function run(limit_) {
        if(limit_ && !isNaN(limit_)){
            getCategoriesDataFromDb(firebaseUserID, isCompany).then((categories)=>{

                if(categories.length >= limit_) {

                    
                    errorCallback("Sorry, you can only add upto "+limit_+" categories. Upgrade your plan to add more categories.")
        
                }else {
                    // checkIfCatTitleExists(data.catTitle, firebaseUserID, isCompany).then((cat)=>{
        
                        if(categories.filter((cat)=>(cat.catTitle === data.catTitle)).length > 0) {
                            errorCallback("Sorry, category title is already taken.")
                        } else {
                            const txt = isCompany?"CompanyServiceCategories":"UserServiceCategories"
                            const myDocRef = doc(db, txt, firebaseUserID, "Categories", data.catId)
                            setDoc(myDocRef, data).then(successCallback).catch(errorCallback)
                        }
        
                    // })
                    // .catch(errorCallback)
                }
            })
        }else {
            errorCallback("Could not get your subscriptions details, check your internet or try again later.")
        }
    }
})



export const updateCategory = (data, catId, firebaseUserID, isCompany = false)=>new Promise(function(successCallback, errorCallback){

    const txt = isCompany?"CompanyServiceCategories":"UserServiceCategories"
    const myDocRef = doc(db, txt, firebaseUserID, "Categories", catId)
    updateDoc(myDocRef, data).then(successCallback).catch(errorCallback)
})

export const getCategory = (firebaseUserID, catId, isCompany = false) => new Promise( async function(successCallback, errorCallback) {

    try {
        const txt = isCompany?"CompanyServiceCategories":"UserServiceCategories"
        const myDocRef = doc(db, txt, firebaseUserID, "Categories", catId)
        const docSnap = await getDoc(myDocRef)
        if(docSnap.exists()) {
            successCallback(docSnap.data())
        }else {
            successCallback({})            
        }
    }catch(err) {
        errorCallback(err)
    }
});


export const checkIfCatTitleExists =  (title, firebaseUserID, isCompany = false) => new Promise( async function(successCallback, errorCallback) {

    try{
        
        const txt = isCompany?"CompanyServiceCategories":"UserServiceCategories"
        const myDocRef = collection(db, txt+"/"+firebaseUserID+"/Categories/")
        const q = query(
                myDocRef, 
                where("catTitle", "==", title),
                limit(1)
            )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data.length > 0?_data[0]:{})
    }catch(err) {
        errorCallback(err)
    }
});


export const getCategoriesDataFromDb =  (firebaseUserID, isCompany = false, totalDataLimit) => new Promise( async function(successCallback, errorCallback) {

    try{
        
        const txt = isCompany?"CompanyServiceCategories":"UserServiceCategories"
        const myDocRef = collection(db, txt+"/"+firebaseUserID+"/Categories/")
        const q = query(
                myDocRef, 
                limit(totalDataLimit),
                orderBy("catTitle")
            )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(err) {
        errorCallback(err)
    }
});



export const deleteCategory = (catId, firebaseUserID, isCompany = false) => new Promise(function(successCallback, errorCallback){

    const txt = isCompany?"CompanyServiceCategories":"UserServiceCategories"
    const myDocRef = doc(db, txt, firebaseUserID, "Categories", catId)
    deleteDoc(myDocRef).then(successCallback).catch(errorCallback)
})


export const deleteCategoryProfile = (firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    const storage = getStorage();

    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        
        const imgRef = storageRef(storage, firebaseUrl);
        deleteObject(imgRef).then(()=>{
            successCallBack("deleted")
        }).catch(errorCallback)
    }

})


export const updateCategoryProfile = (file, firebaseID, firebaseUrl = "", isCompany = false)=>new Promise(function(successCallBack, errorCallback){
    
    const storage = getStorage();

    deleteCategoryProfile(firebaseUrl)
    const ref_ = isCompany?"Companies/" + firebaseID+"/Category/"+(new Date().valueOf()) + '.jpg'
        :"Users/" + firebaseID+"/ServiceCategories/"+(new Date().valueOf()) + '.jpg'
        
    const imgRef = storageRef(storage, ref_);
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                
                successCallBack(url)
            })
            .catch(errorCallback);
    }).catch(errorCallback)

})



export const getCategoryServices = (catId, firebaseUserID, isCompany = false, teamMemberId = null)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = collection(db, "UserServices/"+firebaseUserID+"/Services/")
    
    const q = isCompany?teamMemberId?
        query(myDocRef, 
            where("companyID", "==", parseInt(firebaseUserID)),
            where("serviceProviderId."+teamMemberId, "==", true), 
            where("serviceCategory", "==", catId)
        )
        :query(myDocRef, 
            where("companyID", "==", parseInt(firebaseUserID)),
            where("serviceCategory", "==", catId)
        ):
        query(myDocRef, 
            where("serviceCategory", "==", catId)
        )
    try {
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)

    } catch (err) {
        errorCallback(err)
    }
});