import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { firebaseConfig } from "../../AppSettings";

const app = initializeApp(firebaseConfig);
const db = getFirestore (app)
const realTimeDb = getDatabase()


export const savePayment = (payment)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = (payment.companyId)?doc(db, "CompanyPayments/"+payment.companyId+"/payments/"+(payment.paymentId))
        :doc(db, "UsersPayments/"+payment.providerId+"/payments/"+(payment.paymentId))
    setDoc(myDocRef, payment).then(successCallback).catch(errorCallback)
})




export const updatePayment = (payment, paymentId,  providerId, isCompany = false)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = (isCompany)?doc(db, "CompanyPayments/"+providerId+"/payments/"+paymentId)
        :doc(db, "UsersPayments/"+providerId+"/payments/"+(paymentId))
    updateDoc(myDocRef, payment).then(successCallback).catch(errorCallback)
})



export const deletePayment = (paymentId, providerId, isCompany = false)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = (isCompany)?doc(db, "CompanyPayments/"+providerId+"/payments/"+paymentId)
        :doc(db, "UsersPayments/"+providerId+"/payments/"+(paymentId))
    deleteDoc(myDocRef).then(()=>{
        successCallback("success")
    }).catch(errorCallback)
})
 
export const getAllPayments = (providerId, updatedAt, pageLimit = 10, isCompany = false, staffUserId = null)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const myDocRef = (isCompany)?collection(db, "CompanyPayments/"+providerId+"/payments/")
        :collection(db, "UsersPayments/"+providerId+"/payments/")
            
        const q = staffUserId?
            query(myDocRef,
                where("updatedAt", "<", parseFloat(updatedAt)),
                where("userId", "==", staffUserId),
                orderBy("updatedAt", "desc"),
                limit(pageLimit)
            )
        :
            query(myDocRef,
                where("updatedAt", "<", parseFloat(updatedAt)),
                orderBy("updatedAt", "desc"),
                limit(pageLimit)
            )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});


export const getPayment = (paymentId, providerId, isCompany = false)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const myDocRef = (isCompany)?doc(db, "CompanyPayments/"+providerId+"/payments/"+paymentId)
        :doc(db, "UsersPayments/"+providerId+"/payments/"+(paymentId))
        
        const docSnap = await getDoc(myDocRef)
        if(docSnap.exists()){
            successCallback(docSnap.data())
        } else {
            successCallback({})
        }
    }catch(error) {
        errorCallback(error)
    }
});



export const getPaymentByBookingId = (providerId, bookingId, isCompany = false)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const myDocRef = (isCompany)?collection(db, "CompanyPayments/"+providerId+"/payments/")
        :collection(db, "UsersPayments/"+providerId+"/payments/")
            
        const q = query(myDocRef,
            where("bookingId", "==", bookingId.toString()),
            limit(1)
        )
        
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});


export const getPaymentByInvoice = (providerId, invoiceNo, isCompany = false)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const myDocRef = (isCompany)?collection(db, "CompanyPayments/"+providerId+"/payments/")
        :collection(db, "UsersPayments/"+providerId+"/payments/")
            
        const q = query(myDocRef,
            where("invNo", "==", invoiceNo),
            limit(1)
        )
        
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});

//coupon firebase functions


export const saveCoupon = (coupon)=>new Promise(function(successCallback, errorCallback){

    getCouponByCode(coupon.couponCode, (coupon.companyId || coupon.providerId),  coupon.companyId != null)
        .then((data)=>{
            if(data.length > 0) {
                successCallback("exists")
            }else {
                const myDocRef = (coupon.companyId)?doc(db, "CompanyPayments/"+coupon.companyId+"/coupons/"+(coupon.couponId))
                    :doc(db, "UsersPayments/"+coupon.providerId+"/coupons/"+(coupon.couponId))
                setDoc(myDocRef, coupon).then(successCallback).catch(errorCallback)
            }
        })
        .catch(errorCallback)
})




export const updateCoupon = (coupon, couponId,  providerId, isCompany = false)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = (isCompany)?doc(db, "CompanyPayments/"+providerId+"/coupons/"+couponId)
        :doc(db, "UsersPayments/"+providerId+"/coupons/"+(couponId))
    updateDoc(myDocRef, coupon).then(successCallback).catch(errorCallback)
})



export const deleteCoupon = (couponId, providerId, isCompany = false)=>new Promise(function(successCallback, errorCallback){

    const myDocRef = (isCompany)?doc(db, "CompanyPayments/"+providerId+"/coupons/"+couponId)
        :doc(db, "UsersPayments/"+providerId+"/coupons/"+(couponId))
    deleteDoc(myDocRef).then(()=>{
        successCallback("success")
    }).catch(errorCallback)
})
 
export const getAllCoupons = (providerId, updatedAt, pageLimit = 10, isCompany = false, staffUserId = null)=>new Promise( async function(successCallback, errorCallback) {

    
    try {


        const myDocRef = (isCompany)?collection(db, "CompanyPayments/"+providerId+"/coupons/")
        :collection(db, "UsersPayments/"+providerId+"/coupons/")
            
        const q = 
        // staffUserId?
        //     query(myDocRef,
        //         where("updatedAt", "<", parseFloat(updatedAt)),
        //         where("userId", "==", staffUserId),
        //         orderBy("updatedAt", "desc"),
        //         limit(pageLimit)
        //     )
        // :
            query(myDocRef,
                where("updatedAt", "<", parseFloat(updatedAt)),
                orderBy("updatedAt", "desc"),
                limit(pageLimit)
            )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});


export const getCoupon = (couponId, providerId, isCompany = false)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const myDocRef = (isCompany)?doc(db, "CompanyPayments/"+providerId+"/coupons/"+couponId)
        :doc(db, "UsersPayments/"+providerId+"/coupons/"+(couponId))
        
        const docSnap = await getDoc(myDocRef)
        if(docSnap.exists()){
            successCallback(docSnap.data())
        } else {
            successCallback({})
        }
    }catch(error) {
        errorCallback(error)
    }
});


export const getCouponByCode = (couponCode, providerId, isCompany = false)=>new Promise( async function(successCallback, errorCallback) {

    try {


        const myDocRef = (isCompany)?collection(db, "CompanyPayments/"+providerId+"/coupons/")
            :collection(db, "UsersPayments/"+providerId+"/coupons/")
        const q =
            query(myDocRef,
                where("couponCode", "==", couponCode.toUpperCase()),
                limit(1)
            )
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});


export const getSubscriptionPlans = ()=>new Promise( async function(successCallback, errorCallback) {


    const types = ["Free", "Premium", "Professional"]

    try {

        const _data = []
        types.forEach(async type => {
            
            const myDocRef = collection(db, "PlanTypes/ay8NfC27dPfTm1E6lW9n/" + type)
            const q =
                query(myDocRef)
            const docSnap = await getDocs(q)
            docSnap.forEach((doc) => {
                _data.push(doc.data())        
            });
            if(_data.length === types.length) {
                
                successCallback(_data)
            }
        });
    }catch(error) {
        errorCallback(error)
    }
});

export const getSubscriptionPlanInfo = (plan)=>new Promise( async function(successCallback, errorCallback) {



    try {

        const _data = []
        const myDocRef = collection(db, "PlanTypes/ay8NfC27dPfTm1E6lW9n/" + plan)
        const q =
            query(myDocRef)
        const docSnap = await getDocs(q)
        docSnap.forEach((doc) => {
            _data.push(doc.data())
        });
        successCallback(_data)
    }catch(error) {
        errorCallback(error)
    }
});