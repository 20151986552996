import { CameraAlt, Cancel, CheckCircle, Close, Domain } from '@mui/icons-material'
import { Autocomplete, Avatar, Badge, Button, FormControl, Grid, IconButton, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import DivCenterContents from 'components/DivCenterContents'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SyncLoader } from 'react-spinners'
import { saveCompany, saveStaffMember, searchCompanyProfileLink, updateCompany, updateCompanyProfile, updateStaffMember } from '../../FirebaseController/Company'
import { saveLocation } from '../../FirebaseController/Locations'
import { getIndustries } from '../../FirebaseController/Services/services'
import { getUserDataFromDb } from '../../FirebaseController/User/user'
import { searchLocation } from '../../LocationController'
import { updateCompanyConfigData } from '../../Store/Reducers/appConfigsController/config.actions'
import { refreshCompanies } from '../../Store/Reducers/Company/company.actions'
import { refreshLocations } from '../../Store/Reducers/Location/locations.actions'
import { LOCATION_STATUS, MAX_LOGO_FILE_SIZE, STAFF_STATUS, STAFF_TYPE } from '../../Utils/constants'
import { yearRange } from '../../Utils/functions'
import { inputStyles } from '../../Utils/style'
// import '../../Utils/css/input_file.css'


export default function CompanyForm(props) {

    const { showTitle, editCompanyData, isEdit, configs } = props
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null
    
    const _teamMemberData = localStorage.getItem("teamMemberData")
    const teamMemberData = _teamMemberData?JSON.parse(_teamMemberData)[0]:null

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [companyData, setCompanyData] = useState({"founded": new Date().getFullYear()})
    const [isLoading, setIsLoading] = React.useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
    const [isLocLoading, setIsLocLoading] = React.useState(false)
    const [isIndLoading, setIsIndLoading] = React.useState(false)
    const [Industry, setIndustry] = React.useState([])
    const [locOptions, setLocOptions] = React.useState([])
    const [locData, setLocData] = React.useState([])
    const [locValue, setLocValue] = React.useState(locOptions.length > 0?locOptions[0]:'')
    const [locInputValue, setLocInputValue] = React.useState('')
    const [inputLogoFile, setInputLogoFile] = React.useState('')
    const [isSearchingProfileTitle, setIsSearchingProfileTitle] = React.useState(false)
    const [isNameAvailable, setIsNameAvailable] = React.useState(null)
    const dispatch = useDispatch()
    //const appConfigReducer = useSelector((state) => state.appConfigReducer)
    // const [appConfigReducer, setAppConfigReducer] = useOutletContext()
    const appConfigReducer = configs
    
    const formInputs = [
        {
            name: "shortCompanyDesc",
            required: true,
            holder: 'Profile title',
        }, 
        {
            name: "longCompanyDesc",
            required: true,
            holder: 'Description',
        }, 
        {
            name: "founded",
            required: true,
            holder: "Founded",
            type: 'number'
        }, 
        // {
        //     name: "addLine1",
        //     required: false,
        //     holder: 'Address'
        // }, 
        // {
        //     name: "city",
        //     required: true,
        //     holder: 'City'
        // }, 
        // {
        //     name: "country",
        //     required: true,
        //     holder: 'Country'
        // },
    ]
    
    const handleIndustryChange = (value) => {
        setCompanyData({
            ...companyData,
            'companyIndustry': getCompanyIndId(value)
        })
    };

    const handleFormInputChange = (name, value) => {
        if(value.length > 300 && name === "longCompanyDesc") {
            showSnackBar("Hey, you have added too many characters, only 300 characters are required.", "error")
            return

        }
        
        if(value.length > 100 && name === "shortCompanyDesc") {
            showSnackBar("Hey, you have added too many characters, only 100 characters are required.", "error")
            return

        }
        if(name === "longCompanyDesc" && (("longCompanyDesc" in companyData) && (companyData.longCompanyDesc.length > 300)) ) {
            showSnackBar("Hey, you have added too many characters, only 300 characters are required.", "error")
        }else if(name === "shortCompanyDesc" && (("shortCompanyDesc" in companyData) && (companyData.shortCompanyDesc.length > 100) || value.length > 300) ) {
            showSnackBar("Hey, you have added too many characters, only 100 characters are required.", "error")
        }
        setCompanyData({
            ...companyData,
            [name]: value
        })
        
        if(!isSearchingProfileTitle && name === "profileURLLink"){
            setIsSearchingProfileTitle(true)
            searchCompanyProfileLink((value)).then((result)=>{
                setIsSearchingProfileTitle(false)
                setIsNameAvailable(!(result.length > 0))
            }).catch((err)=>{
                setIsSearchingProfileTitle(false)
                
                setIsNameAvailable(false)
            })
        }
    }

    const handleLocValueChange = (value)=>{
        const locVal = locValue
        setLocValue(value)
        setLocData(locData.filter(item=>(item.title === locVal || ("address" in item && item.address.label === locVal))))
    }
  
    const handleLocInputChange = (event, value) => {
        setLocInputValue(value)
        setLocValue(value)
        //only load data after previous request is complete
        
        if(isLocLoading === false) {
            if(value.length >= 2) {
                    
                if (window.navigator.geolocation) {
                    window.navigator.geolocation.getCurrentPosition((position)=>{
                        
                        const lat = position.coords.latitude
                        const long = position.coords.longitude
                        setIsLocLoading(true)
                        //
                        searchLocation(value, lat, long).then((data)=>{
                           // 
                            // 
                            setLocOptions(
                                data.data.items.map(item=>("address" in item?item.address.label:item.title))
                            )
                            setLocData(data.data.items)
                            setIsLocLoading(false)
                        }).catch((err)=>{
                            
                            setIsLocLoading(false)
                            
                        })
                    })
                } else {
                    showSnackBar("Geolocation is not supported by this browser.", "error")
                }
            }else {
              //  
            }
        }else {
            //
        }
    }

    
    function getCompanyIndustry(indId) {

        const val =  Industry.map(item=>item.indId)
        return (val.includes(indId))?Industry[val.indexOf(indId)].industry:indId
    }
    
    function getCompanyIndId(industry) {

        const val =  Industry.map(item=>item.industry)
        return (val.includes(industry))?Industry[val.indexOf(industry)].indId:industry
    }


    const handleAddCompany = ()=>{
        if(!("companyName" in companyData) || !("shortCompanyDesc" in companyData) ||
            !("companyIndustry" in companyData) ||  !locValue
        ) {
            showSnackBar("All fields are required.", "error")
        }else if((isEdit === 1 && teamMemberData && teamMemberData.memberRole === STAFF_TYPE[0])) {
            showSnackBar("Sorry, only an admin can edit the marketplace's details.", "error")
        }else if(!("profileURLLink" in companyData)) {
            showSnackBar("Marketplace profile link field is required.", "error")
        }else {
            setIsLoading(true)
            const companyId = isEdit === 1?companyData.companyID:+ new Date()
            companyData["updatedAt"] = + new Date()
            companyData["lat"] =  locData.length > 0?locData[0].position.lat:isEdit === 1?companyData.lat:null
            companyData["long"] = locData.length > 0?locData[0].position.lng:isEdit === 1?companyData.long:null
            companyData["addLine1"] = locValue

            if(isEdit === 0){
                companyData['companyID'] = companyId
                companyData["creatorID"] = user.uid
                companyData["createdAt"] = + new Date()
                saveCompany(companyData,companyId, user.uid).then(()=>{
                    showSnackBar("Marketplace details added successfully.", "success")
                    setIsLoading(false)
                    //save logo if any
                    if(inputLogoFile) {
                        
                        saveCompanyLogo(companyId)
                    }

                    //save company location as default location
                    addDefaultLocation(companyData)
                    addCreatorAsDefaultStaff(companyData)

                }).catch((err)=>{
                    
                    setIsLoading(false)
                    if(err === "company limit") {
                        showSnackBar("You already reached maximum number of marketplaces to be registered. Upgrade your plan to add more.","error")
                    }
                })
            }else if(isEdit === 1) {
                
                updateCompany(companyData,companyId).then(()=>{
                    showSnackBar("Marketplace details updated successfully", "success")
                    setIsLoading(false)
                    //update redux configs data
                    dispatch(updateCompanyConfigData(companyData))
                    dispatch(refreshCompanies())
                }).catch((err)=>{
                    
                    setIsLoading(false)
                })
            }
        }
    }

    function addDefaultLocation(companyData) {
        const LocationID = Math.random().toString(10).slice(2)
        const locationData = {
            "LocationID": LocationID,
            "locationName": companyData["addLine1"],
            "lat": companyData["lat"],
            "long": companyData["long"],
            "creatorID": companyData["creatorID"],
            "companyID": companyData["companyID"],
            "createdAt": + new Date(),
            "updatedAt": + new Date(),
            "managerID": companyData["creatorID"],
            "locType": "Offline",
            "city": companyData["addLine1"],
            "status": LOCATION_STATUS[0]
        }
        saveLocation(locationData, LocationID).then(()=>{
            
            dispatch(refreshLocations())
        }).catch((err)=>{
            
            setIsLoading(false)
        })
    }

    function addCreatorAsDefaultStaff(companyData ) {
        
        const staffData = {}
        staffData["staffId"] = + new Date()
        staffData["userId"] = companyData["creatorID"]
        staffData["companyId"] = companyData["companyID"]
        staffData["memberRole"] = STAFF_TYPE[2]
        staffData["status"] = STAFF_STATUS[1]
        staffData["createdAt"] =  + new Date() 
        staffData["updateAt"] =  + new Date()
        saveStaffMember(staffData).then(()=>{
            // showSnackBar("Team member added successfully", "success")
            
            dispatch(refreshCompanies())//refreshing app configs
            getUserDataFromDb(staffData["userId"]).then((data)=>{
                if(Object.keys(data).length > 0) {
                    delete data["uid"]
                    data['cname'] = companyData['companyName']
                    //delete data["primaryEmail"]
                    updateStaffMember(staffData["staffId"], data).then(()=>{
                        
                    })
                }
            })
        }).catch((err)=>{
            
        })
    }
    
    const handleFileChange = (e) => {

        if(e.target.files.length > 0){
            if(e.target.files[0].size/ (1024) > MAX_LOGO_FILE_SIZE){//greater than 300 kb
                showSnackBar("File is too big, only 300kb are allowed.", "error")
            }else {
                setInputLogoFile(e.target.files[0])
            }
        }
    }
    

    const saveCompanyLogo = (companyId) => {
        

        showSnackBar("Uploading logo, please wait..", "success")
        updateCompanyProfile(inputLogoFile, companyId, ("logoUrl" in companyData)?companyData.logoUrl:"").then(
            (url) => {//success callback function
                
                showSnackBar("Upload successful. click save to update", "success")

                updateCompany({
                    ...companyData,
                    "logoUrl": url
                }, companyId).then(()=>{
                    setCompanyData({
                        ...companyData,
                        "logoUrl": url
                    })
                })
            }           

        ).catch(
            (err) => {
                showSnackBar("Could not upload image, please try again later.", "error")
                
                
            }
        )
    }


    const editLink = (url) => url.split("://")[1]

    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    React.useEffect(() => {
      
        
        setIsIndLoading(true)
        getIndustries().then((data)=>{
            setIsIndLoading(false)
            setIndustry(data)
        }).catch((err)=>{
            
            setIsIndLoading(false)
        })
    
      return () => setIndustry({})
    }, [])


    React.useEffect(() => {
        if(editCompanyData) setCompanyData( editCompanyData )
    
      return () => {
        setCompanyData({"founded": new Date().getFullYear()})
      }
    }, [editCompanyData])

    useEffect(() => {
      if(isEdit === 1 && inputLogoFile && !(typeof inputLogoFile === 'string' || inputLogoFile instanceof String)) {
        saveCompanyLogo(companyData.companyID)
      }
    
      return () => null
    }, [inputLogoFile])
    
    

    useEffect(() => {
      
        if("addLine1" in companyData && companyData.addLine1) {
            setLocValue(companyData.addLine1)
            setLocInputValue(companyData.addLine1)
        }
    
      return () => null
    }, [companyData])

    

    

    return (
        <Grid container spacing={1} className='px-4 pb-4 mb-3 mt-1'>


            <Grid item xs={12}>
                <div>
                    <DivCenterContents _size={6}>
                        
                            <div className="position-relative">
                                <input 
                                    accept="image/*" id="icon-button-file" 
                                    onChange={handleFileChange}
                                    type="file" style={{display: 'none'}} />
                                <label htmlFor="icon-button-file">
                                    <Badge
                                        overlap="circular"
                                        badgeContent={
                                            <Avatar sx={{bgcolor: "#63C3CF"}}><CameraAlt /></Avatar>
                                        }
                                    
                                    >
                                        {
                                            (("logoUrl" in companyData && companyData.logoUrl) || inputLogoFile)?
                                                <Avatar 
                                                    variant="square"
                                                    // src={profileLink && Object.keys(profileLink).length !== 0?profileLink:profile}
                                                    src={companyData.logoUrl || inputLogoFile}
                                                    sx={{width: '110px', height: '110px', border: '1px solid #d3d3d3'}} />

                                            :
                                            
                                            <Avatar 
                                                variant="square"
                                                sx={{width: '110px', height: '110px', border: '1px solid #d3d3d3'}} 
                                            >

                                                <Domain style={{fontSize: '80px'}} />
                                            </Avatar>

                                        }
                                    </Badge>
                                </label><   br />
                                <Typography variant='body2' sx={{fontSize: '11px', float: 'right', paddingLeft: '5px',paddingTop: '0'}} className="">
                                    Max size: {MAX_LOGO_FILE_SIZE} kb
                                </Typography>
                            </div>
                    </DivCenterContents>
                </div>

            </Grid>
            <Grid item xs={12}>
                <div className='border p-3' style={{borderColor: "#B5EBF2", background: '#ECFDFF', borderRadius: '5px'}}>
                    <label htmlFor='companyName'>
                       {"Name"} 
                    </label><br />
                    <textarea id="companyName" style={{
                            borderRadius: '5px',
                            background: 'transparent',
                            border: 'none',
                            padding: '5px',
                            width: '100%',
                            resize: 'none'
                        }} 
                        type="text" 
                        name="companyName" 
                        placeholder='Enter text here'
                        rows="1"
                        cols="50"
                        disabled={isEdit === 2}
                        onChange={(e)=>handleFormInputChange(e.target.name, e.target.value)}
                        value={
                            companyData && "companyName" in companyData?
                            companyData["companyName"]
                            :""
                        }
                    ></textarea>

                </div>
            </Grid>
            {
                isEdit === 1?"":
                <Grid item md={12} xs={12} style={{marginTop: '0',}}>
                    
                    <label>
                        Custom link
                    </label>
                    <div className={"input-group mb-3"} 
                            style={{background: "#F4F5FA", borderColor: "#F4F5FA",borderRadius: '5px', }}>
                        <span className="input-group-text" 
                            style={{color: '#000',fontSize: '14px', background: "#d9d9d9", borderColor: "#d9d9d9",borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px'}}
                            id="basic-addon3">{
                            editLink(window.location.origin) + "/co/"
                        }</span>
                        <input type="text" className="form-control px-2" id="basic-url" 
                            placeholder='marketplace-xyz'
                            onChange={(e)=>handleFormInputChange("profileURLLink", e.target.value)}
                            value={("profileURLLink" in companyData)?(companyData.profileURLLink):""}
                            style={{fontSize: '14px',background: "#F4F5FA",color: '#000', borderColor: "#F4F5FA",borderTopRightRadius: '15px', borderBottomRightRadius: "15px"}}
                            aria-describedby="basic-addon3" />
                        <Box sx={{mt: 0.8, mr: 1}}>
                            {
                            isSearchingProfileTitle?
                            <SyncLoader
                                loading={isSearchingProfileTitle}
                                size={9}
                                color="#000"
                            />
                            :isNameAvailable === true?
                                <IconButton color="primary"><CheckCircle /></IconButton>
                            :isNameAvailable === false?
                                <IconButton color="danger"><Cancel /></IconButton>
                            :""
                            }
                        </Box>
                    </div>
                </Grid>

            }
            

            {
                formInputs.map((item, idx)=>
                    <Grid item md={12} xs={12} key={item.name} style={{marginTop: '0',}}>
                        <label htmlFor={item.name}>
                            {item.holder}
                        </label>

                        {
                            item.name === "longCompanyDesc" || item.name === "shortCompanyDesc"?
                            <>
                                <textarea 
                                    // contentEditable={true}
                                    style={{
                                        minHeight: item.name === "shortCompanyDesc"?'80px':'150px',
                                        width: '100% !important',
                                        wordBreak: 'break-all',
                                        ...inputStyles,
                                    }}
                                    // fullWidth 
                                    id={item.name}
                                    name={item.name} 
                                    // placeholder={item.holder}
                                    required = {item.required}
                                    value={
                                        companyData && item.name in companyData?
                                        companyData[item.name]
                                        :""
                                    }
                                    onChange={(e)=>handleFormInputChange(e.target.name, e.target.value)}
                                >
                                </textarea>
                                <div className='text-right mb-0 pb-0' style={{fontSize: '14px',color: "#999" , fontWeight: 300}}>
                                    {
                                       companyData && item.name in companyData?
                                       companyData[item.name].length:0
                                    }
                                    {
                                        item.name === "longCompanyDesc"?"/300":"/100"
                                    }
                                </div>
                            </>

                            :(item.name === "founded")?
                            <select
                                style={inputStyles}
                                id={item.name}
                                name={item.name} 
                                // placeholder={item.holder}
                                value={
                                    companyData && item.name in companyData?
                                    companyData[item.name]
                                    :""
                                }
                                onChange={(e)=>handleFormInputChange(e.target.name, e.target.value)}
                            >
                                {
                                    yearRange(1900, new Date().getFullYear(), 1, true).map(item=>
                                        <option key={item} value={item}>
                                            {item}
                                        </option>)
                                }
                            </select>
                            :
                            <input
                                style={inputStyles}
                                id={item.name}
                                name={item.name} 
                                // placeholder={item.holder}
                                required = {item.required}
                                value={
                                    companyData && item.name in companyData?
                                    companyData[item.name]
                                    :""
                                }
                                onChange={(e)=>handleFormInputChange(e.target.name, e.target.value)}
                                type={"type" in item?item.type:"text"}
                            />
                        }
                    </Grid>
                )
            }

            <Grid item xs={12}>

                <div className='d-flex'>
                    <label htmlFor="location" className='py-0 my-0 mb-2'>
                        Address
                    </label>
                    <div style={{flex: 1}}></div>
                    <Button disabled={true} color="secondary"  className='py-0 my-0' style={{textTransform: 'none'}}>
                        {
                            isLocLoading?
                                <SyncLoader
                                loading={isLocLoading}
                                size={7}
                                color="#000"
                                />
                                :
                            ""
                        }
                        
                    </Button>

                </div>
                <Autocomplete
                    disablePortal
                    id="location"
                    options={locOptions}
                    color="grey"
                    sx={{
                        padding: 0,
                        margin: 0,
                        width: '100%', border: 'none',
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                      }}
                    value={locValue}
                    inputValue={locInputValue}
                    onChange={(event, newValue) => {
                        handleLocValueChange(newValue)
                    }} 
                    placeholder="Location name"                              
                    onInputChange={handleLocInputChange}
                    renderInput={(params) => 
                        <TextField {...params} variant="outlined" 
                            InputProps={{ ...params.InputProps, disableUnderline: true, style: {...inputStyles, borderRadius: 5} }} />}
                />
            </Grid>
            <Grid item xs={12}>
                    
                <FormControl fullWidth  sx={{mb: 2}}>
                                
                    {
                        isIndLoading?
                            <SyncLoader
                                loading={isIndLoading}
                                size={10}
                                color="#d3d3d3"
                            />
                        :
                        <>
                            <label id="Industry">Industry*
                                <div className='form-text pt-1' style={{fontSize: '12px', color: "#999" , fontWeight: 300}}>
                                    {"Please enter & choose the industry."}
                                </div>
                            </label>
                            
                            <Autocomplete
                                disablePortal
                                id="Industry"
                                options={
                                    Industry.map(item=>item.industry)
                                }
                                // item.serviceId
                                // value={serviceData && "serviceTag" in serviceData?getServiceTag(serviceData.serviceTag):""}
                                value={companyData && "companyIndustry" in companyData?getCompanyIndustry(companyData.companyIndustry):""}
                                inputValue={companyData && "companyIndustry" in companyData?getCompanyIndustry(companyData.companyIndustry):""}
                                sx={{ width: '100%', border: 'none' }}
                                disableClearable={true}
                                size="small"
                                onChange={(e, val)=>handleIndustryChange(val)}
                                onInputChange={(e, val)=>handleIndustryChange(val)}
                                renderInput={(params) => 
                                    <TextField 
                                        
                                        style={inputStyles}  variant="standard" {...params} 
                                        InputProps={{...params.InputProps,disableUnderline: true, style: { fontSize: 13,  } }} />
                                }
                            />

                        </>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <div style={{margin: '30px 0'}} className='d-flex'>
                    <div style={{flex: 1}}></div>
                    <Button variant='contained'
                            className="my-4 rounded border-0"
                            style={{color: "#fff", textTransform: 'none', background: '#F0973B',}}
                            disabled={isLoading} color="primary"
                            onClick={handleAddCompany}
                        >
                        {
                        isLoading?
                            <SyncLoader
                            loading={isLoading}
                            size={10}
                            color="#fff"
                            />
                            :
                        "Save"
                        }
                    </Button>
                </div>

            </Grid>

        </Grid>
    )


}