
import Close from '@mui/icons-material/Close';
import Domain from '@mui/icons-material/Domain';
import MiscellaneousServices from '@mui/icons-material/MiscellaneousServices';
import  Avatar from '@mui/material/Avatar';
import  IconButton from '@mui/material/IconButton';
import  Typography from '@mui/material/Typography'; 
import { acceptStaffMemberRequest, deleteStaffMember, getCompanyData, getStaffMember } from 'FirebaseController/Company';
import { sendFcmNotification } from 'FirebaseController/Notifications/fcmNotifications';
import { addNotif } from 'FirebaseController/Notifications/localNotifs';
import { getUserDataFromDb } from 'FirebaseController/User/user';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { Button } from 'reactstrap';
import { NOTIFICATION_STATUS } from 'Utils/constants';
import { formatDateToDateTimeStr, getUserRedirectData } from 'Utils/functions';
import profile from '../../Utils/images/logo.png';

export default function StaffNotificationCard(props) {

    const { teamMember, handleRefreshNotifs, configs } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isAcceptLoading, setIsAcceptLoading] = React.useState(false);
    const [isDeclineLoading, setIsDeclineLoading] = React.useState(false);
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj.user
    const [companyManager, setCompanyManager] = React.useState({})
    const [company, setCompany] = React.useState({})
    const staffReducer = useSelector((state) => state.userReducer)
    const navigate = useNavigate()
    const appConfigReducer = configs
    
    const handleAcceptRequest = () => {
        setIsAcceptLoading(true)
        acceptStaffMemberRequest(teamMember.staffId).then(()=>{
            getUserDataFromDb(user.uid).then((staffData)=>{
                
                let name = staffData.firstname + " " + staffData.surname
                run(name)
            })
            .catch((err)=>{

                let name = staffReducer.data.firstname + " " + staffReducer.data.surname
                name = name.trim()?name:user["displayName"]
                run(name)
            })
            function run(name) {
                setIsAcceptLoading(false)
                showSnackBar("Congratulations! you are now a "+teamMember.memberRole.toLowerCase()+" at "+company.companyName)
                handleRefreshNotifs()

                
                const notiText = name +
                    " has accepted the join request to your marketplace."
                const fcmData = {
                    "user": user.uid,
                    "icon": 0,
                    "body": notiText,
                    "title": "Join marketplace request",
                    "sented": teamMember.creatorId,

                }
                setNotif("Join marketplace request", notiText, user.uid, teamMember.creatorId)
                sendFcmNotification(fcmData, teamMember.creatorId)
                .then(()=>{
                    
                    window.location.href = ('/co/'+company.profileURLLink)
                })
                .catch((err)=>{
                    setIsAcceptLoading(false)
                    
                    
                    
                    
                })
            }
        })
        .catch((err)=>{
            setIsAcceptLoading(false)
        })
    }

    
    const handleDeclineRequest = () => {
        setIsDeclineLoading(true)
        deleteStaffMember(teamMember.staffId).then(()=>{

            getUserDataFromDb(user.uid).then((staffData)=>{
                
                let name = staffData.firstname + " " + staffData.surname
                run(name)
            })
            .catch((err)=>{

                let name = staffReducer.data.firstname + " " + staffReducer.data.surname
                name = name.trim()?name:user["displayName"]
                run(name)
            })

            function run(name) {
                
                setIsDeclineLoading(false)
                showSnackBar("You have declined request to join "+company.companyName)
                handleRefreshNotifs()
                const notiText = name+
                    " has declined request to join your marketplace team."
                const fcmData = {
                    "user": user.uid,
                    "icon": 0,
                    "body": notiText,
                    "title": "Join marketplace request",
                    "sented": teamMember.creatorId,

                }
                sendFcmNotification(fcmData, teamMember.creatorId)
                .catch((err)=>{
                    setIsDeclineLoading(false)
                    
                    
                    
                    
                })
                setNotif("Join marketplace request", notiText, user.uid, teamMember.creatorId)
            }
        })
        .catch((err)=>{
            setIsAcceptLoading(false)
        })
    }
    
    
    function setNotif(title, body, userId, sented){
        //set local notification
      //we are not going to set all notifs since some are handled by app logic. 
        //only notifs that dont need user action are stored in local

        const notif = {
            title: title,
            body: body,
            sender: userId,
            receiver: sented,
            date: formatDateToDateTimeStr(new Date()),
            notifId: + new Date(),
            userId: sented,
            status: NOTIFICATION_STATUS[1]
        }
        addNotif(sented, notif).then(()=>{
            
        })
    }

    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    useEffect(() => {
        getStaffMember(teamMember.creatorId, parseFloat(teamMember.companyId)).then((_data)=>{

            
            if(((!("firstname" in _data[0])  || _data[0].firstname === "")
                && 
                (!("surname" in _data[0]) || _data[0].surname  === ""))
                )
            {
                getUserDataFromDb(teamMember.creatorId).then((data)=>{
                    const newData = {
                        ... _data[0],
                        ...data
                    }
                    setCompanyManager(newData)
                })
            }else {
                setCompanyManager( _data[0])
            }
        })
        getCompanyData(teamMember.companyId).then(data=>setCompany(data))
    
      return () => null
    }, [teamMember])
    

    
    React.useEffect(()=>{
        // if(booking && "companyId" in booking && booking.companyId) {
        //     getCompanyManagers((booking.companyId)).then((data)=>{
        //         setCompanyManagers(data)
        //     })
        // }
    }, [])

    return (

        <div className="alert text-dark d-flex m-0" fade={false} style={{background: "#F4F5FA", margin: 0, borderBottom: "1px solid #d3d3d3"}}>
          <span className="alert-inner--icon">
            <a style={{color: "#000"}} href={window.location.origin + "/profile/?user=" + getUserRedirectData(teamMember.creatorId)}>
                <Avatar
                    src={"profile" in companyManager?companyManager.profile:profile}
                    sx={{ height: '45px', width: '45px', marginTop: '27%', background: 'inherit'}} 
                    aria-label="profile"
                    color="#fff"
                />
            </a>
          </span>
          <span className="alert-inner--text ml-1 mt-2">
            <strong style={{fontSize: '16px'}}>
                <p style={{fontSize: '14px', padding: '0', margin: 0}}>
                    <a style={{color: "#000"}} href={window.location.origin + "/profile/?user=" + getUserRedirectData(teamMember.creatorId)}>
                        <strong className="text-capitalize">
                            {companyManager.firstname + " " +companyManager.surname + " "}
                        </strong>
                    </a>
                    has invited you to join their marketplace team
                </p>
            </strong> 
            <Typography variant="body2" style={{fontSize: '13px', padding: 5, color: '#828282'}}>
                <span className='pr-1'>
                    <MiscellaneousServices style={{fontSize: '22px',}} color="primary" />
                </span>
                Join marketplace request
            </Typography>
            <Typography variant="body2" style={{fontSize: '13px', padding: 5, color: '#828282'}}>
                <span className='pr-1'>
                    <Domain style={{fontSize: '22px',}} color="primary" />
                </span>
                {company.companyName}
            </Typography>
            <div className='d-flex mt-1'>
                <Button
                    size="sm"
                    color="warning"
                    onClick={handleAcceptRequest}
                    className='rounded-pill btn-light border-light px-4'
                    style={{background: 'linear-gradient(270.3deg, #63C3CF 5.25%, #A68AB4 42.7%, #F1785C 102.13%)',}}
                >
                    {
                        isAcceptLoading?
                            <SyncLoader
                            loading={isAcceptLoading}
                            size={10}
                            color="#fff"
                            />
                            :
                        "Accept"
                    }
                    
                </Button>
                <Button
                    size="sm"
                    onClick={handleDeclineRequest}
                    className='rounded-pill btn-light px-4'
                    style={{background: "#F0973B", borderColor: "#F0973B"}}
                >
                    {
                        isDeclineLoading?
                            <SyncLoader
                            loading={isDeclineLoading}
                            size={10}
                            color="#fff"
                            />
                            :
                        "Decline"
                    }
                    
                </Button>
            </div>
          </span>
        </div>
       

        
    )


}