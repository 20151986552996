
import { Close } from '@mui/icons-material';
import { Button as MuiBtn, DialogActions, FormControl, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { SyncLoader } from 'react-spinners';
import { Button } from 'reactstrap';
import { getUserDataFromDb, updateUserInfo } from '../../../FirebaseController/User/user';
import { validateEmail } from '../../../Utils/functions';
import { inputStyles } from '../../../Utils/style';




export default function EditNameSettingsModal(props) {

    const { openProps } = props

    const [open, setOpen] = React.useState(openProps)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { companyName } = useParams()
    const [userData, setUserData] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(false)
    const [isServiceProviderMode, setIsServiceProviderMode] = React.useState(false)
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj ? userObj.user : null

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleModalClose = () => {

        if (!"firstname" in userData || !userData.firstname || !"surname" in userData || !userData.surname) {
            showSnackBar("Please enter above details to continue", "error")
        } else {
            handleClose()
        }
    }
    const handleSaveBtnClick = () => {

        if (!user) {
            showSnackBar('Unauthorised action, try again later', "error")
        } else if (!'firstname' in userData || !userData.firstname ||
            !'surname' in userData || !userData.surname ||
            !'primaryEmail' in userData || !userData.primaryEmail
        ) {
            showSnackBar('All fields are required', "error")
        } else if (!validateEmail(userData.primaryEmail)) {
            showSnackBar("Invalid email address", "error")
        } else {
            setIsLoading(true)

            const userObj_ = JSON.parse(localStorage.getItem("user"))
            const user_ = userObj_ ? userObj_.user : null
            if (user_ != null) {
                userData['uid'] = user_['uid']
            }
            updateUserInfo(userData).then(() => {
                showSnackBar("Details updated successfully", "success")
                setIsLoading(false)

                const newObj = {
                    ...userObj
                }
                newObj.user['displayName'] = userData.firstname + " " + userData.surname
                localStorage.setItem('user', JSON.stringify(newObj))

                if (localStorage.getItem("isFirstLogin") && localStorage.getItem("isFirstLogin") === "true") {
                    setIsServiceProviderMode(true)
                    localStorage.removeItem("isFirstLogin")
                } else {


                    const redirectBookingUrl = localStorage.getItem("redirectBookingUrl")


                    if (typeof redirectBookingUrl === 'string') {
                        localStorage.removeItem("redirectBookingUrl")
                        window.location.href = redirectBookingUrl


                    } else {
                        //redirect to settings
                        handleClose()
                    }
                }

            }).catch((err) => {
                showSnackBar("Could not update details, try again later", "danger")
                setIsLoading(false)

            })
        }
    }


    const handleFormInputChange = (key, e) => {
        const val = e.target.value

        setUserData({
            ...userData,
            [key]: val
        })
    }

    const handleEditProfileDetails = () => {
        handleClose()
        navigate('/auth/edit')
        window.scrollTo(0, 0)
    }

    function showSnackBar(msg, variant = 'success') {
        enqueueSnackbar(msg, {
            variant: variant,
            action: (key) => (
                <IconButton style={{ color: '#000' }} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
        })
    }


    React.useEffect(() => {
        if (user) {

            getUserDataFromDb(user.uid).then((data) => {

                if (Object.keys(data).length > 0) {
                    setUserData({
                        ...data,
                        "primaryEmail": (!data.primaryEmail) ? (user && "email" in user && user.email) ? user.email : "" : data.primaryEmail,
                        "firstname": (!data.firstname) ? (user && "displayName" in user && user.displayName) ? user.displayName.split(" ")[0] : "" : data.firstname,
                        "surname": (!data.surname) ? (user && "displayName" in user && user.displayName) ? user.displayName.split(" ")[1] : "" : data.surname,
                    })
                } else {

                    setUserData({
                        ...userData,
                        'uid': user.uid,
                        phoneNo: user.phoneNumber,
                        "primaryEmail": (user && "email" in user && user.email) ? user.email : "",
                        firstname: (user && "displayName" in user && user.displayName) ? user.displayName.split(" ")[0] : "",
                        surname: (user && "displayName" in user && user.displayName) ? user.displayName.split(" ")[1] : "",
                    })
                }
            })
        }
    }, [openProps])

    React.useEffect(() => {
        setUserData({

        })
        setOpen(openProps)
    }, [openProps])

    React.useEffect(()=>{
        if(localStorage.getItem("askForServiceProviderDet") === "true" && localStorage.getItem("isFirstLogin") === "true") {
            setIsServiceProviderMode(true)
            localStorage.removeItem("isFirstLogin")
            localStorage.removeItem("askForServiceProviderDet")
          }
    }, [openProps])


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleModalClose}
                fullWidth={!isServiceProviderMode}
                maxWidth={'xs'}
            >

                {
                    isServiceProviderMode ? "" :
                        <div className="d-flex pt-2 px-2">
                            <DialogTitle sx={{ fontSize: '16px', p: 1, pl: 2, mb: 0 }}>
                                Please confirm your name to continue

                            </DialogTitle>
                            <div style={{ flex: 1 }}></div>

                            {
                                // isServiceProviderMode?
                                //     <IconButton onClick={handleClose}>
                                //         <Close />
                                //     </IconButton>
                                // :""
                            }
                        </div>
                }
                <DialogContent sx={{ pt: 1, mt: 0, mb: isServiceProviderMode ? 0 : 1 }}>
                    {
                        isServiceProviderMode ?
                            <Typography sx={
                                {
                                    mt: 2,
                                    fontSize: '18px',
                                    width: '250px',
                                
                                }
                            }
                            >
                                Would you like to create services and get bookings?
                            </Typography>
                            :
                            <>

                                <div className='d-flex justify-content-center'>
                                    <Box sx={{ width: { md: '98%', xs: '100%' } }}>
                                        {
                                            [
                                                {
                                                    name: "firstname",
                                                    required: true,
                                                    holder: "First name",
                                                    disabled: false
                                                },
                                                {
                                                    name: "surname",
                                                    required: true,
                                                    holder: 'Surname',
                                                    disabled: false
                                                },
                                                {
                                                    name: "primaryEmail",
                                                    required: true,
                                                    holder: 'Email',
                                                    disabled: false
                                                },
                                            ].map((item) => (

                                                <div key={item.name}>

                                                    <FormControl variant="standard" fullWidth className="py-1 mb-1 mt-2" >
                                                        {/* <div className='d-flex mb-2'>
                                                <label htmlFor="input-with-icon-adornment41" className='py-0 my-0'>
                                                    
                                                </label>
                                            </div>
                                            */}
                                                        <input
                                                            style={{ ...inputStyles, display: (item.name === "primaryEmail" && user && "email" in user && user.email) ? "none" : "block" }}
                                                            id={item.name}
                                                            label={item.holder}
                                                            name={item.name}
                                                            required={item.required}
                                                            value={(item.name in userData) ? userData[item.name] : ""}
                                                            onChange={(e) => handleFormInputChange(item.name, e)}
                                                            placeholder={item.holder}

                                                        />
                                                    </FormControl>
                                                </div>
                                            ))
                                        }
                                    </Box>
                                </div>

                                <div className='text-center justify-content-center'>
                                    <Button variant='contained'
                                        className="my-4 rounded border-light"
                                        style={{ color: "#fff", background: '#F0973B', textTransform: 'none', }}
                                        color="primary" onClick={handleSaveBtnClick}
                                    >

                                        {
                                            isLoading ?
                                                <SyncLoader
                                                    loading={isLoading}
                                                    size={10}
                                                    color="#fff"
                                                />
                                                : "Save"
                                        }
                                    </Button>
                                </div>
                            </>
                    }
                </DialogContent>
                {
                    isServiceProviderMode ?

                        <DialogActions sx={{ m: 0, pt: 0, }} >
                            <MuiBtn color="primary" onClick={handleClose}>
                                No
                            </MuiBtn>
                            <MuiBtn color="secondary" onClick={handleEditProfileDetails}>
                                Yes
                            </MuiBtn>
                        </DialogActions>

                        : ""
                }
            </Dialog>
        </div>
    );
}
