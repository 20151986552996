


import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Campaign from '@mui/icons-material/Campaign';
import Category from '@mui/icons-material/Category';
import Contacts from '@mui/icons-material/Contacts';
import Discount from '@mui/icons-material/Discount';
import Domain from '@mui/icons-material/Domain';
import DomainAdd from '@mui/icons-material/DomainAdd';
import Explicit from '@mui/icons-material/Explicit';
import GroupAdd from '@mui/icons-material/GroupAdd';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Logout from '@mui/icons-material/Logout';
import MailIcon from '@mui/icons-material/Mail';
import MiscellaneousServices from '@mui/icons-material/MiscellaneousServices';
import MyLocation from '@mui/icons-material/MyLocation';
import PlayForWork from '@mui/icons-material/PlayForWork';
import Sell from '@mui/icons-material/Sell';
import Settings from '@mui/icons-material/Settings';
import SupportAgent from '@mui/icons-material/SupportAgent';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import React, { Fragment, useState } from 'react';
import { AppBar, Drawer } from './style';

import NotificationsIcon from '@mui/icons-material/Notifications';
import DivCenterContents from 'components/DivCenterContents';
import MainDrawer from 'components/MainDrawer';
import DemoNavbar from 'components/Navbars/DemoNavbar';
import useWindowDimensions from 'components/WindowListener';
import { GoogleLogout } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { APP_MANAGER_TYPES, navItems, STAFF_TYPE } from 'Utils/constants';
import { clientId, VERSION } from '../../AppSettings';
import { getStaffMember } from '../../FirebaseController/Company';
import { getUserDataFromDb, logout } from '../../FirebaseController/User/user';
import { setAppManagerConfig } from '../../Store/Reducers/appConfigsController/config.actions';
import '../../Utils/css/override.css';
import iconLogo from '../../Utils/images/latomcollogo.png';
import logo2 from '../../Utils/images/latomnewicon.png';
import profile from '../../Utils/images/logo.png';

export default function NavBar(props) {
  const { configs } = props
  const appConfigReducer = configs
  const showNavBar = true
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { companyName, userName } = useParams()

  const { height, width } = useWindowDimensions();
  const titleReducer = useSelector((state) => state.titleReducer)
  const userReducer = useSelector((state) => state.userReducer)
  // const appConfigReducer = useSelector((state) => state.appConfigReducer)
  
  
  
  
  const userObj = JSON.parse(localStorage.getItem("user"))
  const user = userObj?userObj.user:null

  const [localUserMmeberData, setLocalUserMmeberData] = React.useState(companyName?localStorage.getItem("teamMemberData")?JSON.parse(localStorage.getItem("teamMemberData"))[0]:null:null);

  // 

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actionMenuAnchor, setActionMenuAnchor] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notifInvisible, setNotifInvisible] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [actionMenuOpen, setActionMenuOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isLogoutLoading, setIsLogoutLoading] = React.useState(false);
  const [mainDrawerOpen, setMainDrawerOpen] = React.useState(false);
  const [responsiveDrawerOpen, setResponsiveMainDrawerOpen] = React.useState(false);

  const [pageType, setPageType] = useState({type: 'event', isEdit: 0, open: false})
  const profileURLLink = ("profileURLLink"  in appConfigReducer.data && appConfigReducer.data.profileURLLink)
  ?appConfigReducer.data.profileURLLink:(user && "profileURLLink"  in user && user.profileURLLink)?user.profileURLLink:""

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpenClose = () => {
    setOpen(!open)
  }

  const toggleMainDrawer = (open) => {
    setMainDrawerOpen(open)
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true)
  };

  
  const handleActionMenuOpen = (event) => {
    setActionMenuAnchor(event.currentTarget);
    setActionMenuOpen(true)
  };

  
  const handleActionMenuClose = () => {
    setActionMenuAnchor(null);
    setActionMenuOpen(false)
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false)
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleNavClick = (value, isSwitchUserMode = false, isClient = false) => {

    


    let url = (companyName?"/co/"+companyName:"")
    url += value
    if(isSwitchUserMode) {
      
      localStorage.setItem("manager_type", APP_MANAGER_TYPES[0].toString())//SAVE MANAGER TYPE TO USER
      localStorage.removeItem("company")//remove COMPANY DATA
      dispatch(setAppManagerConfig(user, APP_MANAGER_TYPES[0] ))//SET REDUX MANAGER TO USER
      navigate(url)
    }else{
      if(isClient && value === '/') {
        navigate("/co/"+companyName+"/home")
      }else if(isClient && value === '/client') {
        navigate("/co/"+companyName)
      }else if(companyName && value === '/') {
        navigate("/co/"+companyName+"/home")
      }else {
        navigate(url)
      }
    }
  }
  const handleLogout = () => {

    setIsLogoutLoading(true)
    if(appConfigReducer.type === APP_MANAGER_TYPES[0]) {
      getUserDataFromDb().then((data)=>{
        
        logout().then(()=>{
          handleMenuClose()
          setIsLogoutLoading(false)
          if(data.profileURLLink){
            window.location.href = "/" + data.profileURLLink
            localStorage.setItem("loggedOutRedirectLink", "/" + data.profileURLLink)
            
          }else {
            
            window.location.href = "/auth/login"
          }
        }).catch((err)=>{
          
          setIsLogoutLoading(false)
        })
        
      }).catch((err)=>{
        
        setIsLogoutLoading(false)
      })
    }else {

      logout().then(()=>{
        handleMenuClose()
      
        if(companyName){
          window.location.href = "/co/"+companyName
        }
        setIsLogoutLoading(false)
      }).catch((err)=>{
        
        setIsLogoutLoading(false)
      })
    }
    
    // navigate('/auth/login')
  }
  const handleFailure = (error) => {
    
    // handleLogout(  )
  }
  const handlePageType = (type, isEdit) => {
    setPageType({type: type, isEdit: isEdit, open: true, configs:appConfigReducer})
  }

  const handleResponsiveDrawerOpen = () => {
    setResponsiveMainDrawerOpen(!responsiveDrawerOpen)
  }

  React.useEffect(() => {
    toggleMainDrawer(pageType.open)
  }, [pageType])


  React.useEffect(() => {
    setOpen(width > 500)
  }, [width])
  

  React.useEffect(() => {

    if(user) {
      getStaffMember(user.uid, appConfigReducer.data.companyID).then((_data)=>{
        if((_data.length > 0)) {
          
          setLocalUserMmeberData(_data[0])
          localStorage.setItem("teamMemberData", JSON.stringify(_data[0]))  
        } 
      }).catch((err)=>{
          
      })
    }else {        
    }
  }, [])
  const actionMenuItems = [
    {
      id: "company",
      text: "Marketplace",
      icon: <DomainAdd fontSize="small" color="primary" />,
      action: ()=>{handlePageType("company", 0)}
    },
    {
      id: "booking",
      text: "Booking",
      icon: <CalendarToday fontSize="small" style={{color: '#F0973B'}} />,
      action: ()=>{navigate(companyName?'/co/'+companyName+'/services/self-booking':'/services/self-booking')}
    },
    {
      id: "service",
      text: "Service",
      icon: <MiscellaneousServices fontSize="small" color="primary" />,
      action: ()=>{handlePageType("service", 0)}
    },
    {
      id: "contact",
      text: "Contact",
      icon: <Contacts fontSize="small" color="primary" />,
      action: ()=>{handlePageType("contact", 0)}
    },
    {
      id: "event",
      text: "Event",
      icon: <Campaign fontSize="small" style={{color: '#F0973B'}} />,
      action: ()=>{handlePageType("event", 0)},
    },
    {
      id: "category",
      text: "Category",
      icon: <Category fontSize="small" color="primary" />,
      action: ()=>{handlePageType("category", 0)}
    },
    
    {
      id: "location",
      text: "Location",
      icon: <MyLocation fontSize="small" color="primary" />,
      action: ()=>{handlePageType("location", 0)}
    },
    {
      id: "staff",
      text: "Team",
      icon: <GroupAdd fontSize="small" color="primary" />,
      action: ()=>{handlePageType("staff", 0)}
    },
    {
      id: "invoice",
      text: "Invoice",
      icon: <InfoOutlined fontSize="small" style={{color: '#F0973B'}} />,
      action: ()=>{navigate(companyName?'/co/'+companyName+'/invoices/add/invoices' :'/invoices/add/invoices')}
    },
    {
      id: "estimate",
      text: "Estimate",
      icon: <Explicit fontSize="small" style={{color: '#F0973B'}} />,
      action: ()=>{navigate(companyName?'/co/'+companyName+'/invoices/add/estimates':'/invoices/add/estimates')}
    },
    {
      id: "billing_item",
      text: "Billing item",
      icon: <PlayForWork fontSize="small" style={{color: '#F0973B'}} />,
      action: ()=>{handlePageType("invoice_items", 3)}//add default billing item
    },
    {
      id: "coupon",
      text: "Coupon",
      icon: <Discount fontSize="small" style={{color: '#F0973B'}} />,
      action: ()=>{handlePageType("coupon", 0)}//add default billing item
    },
    // {
    //   text: "Add Task",
    //   icon: <AddTaskOutlined fontSize="small" style={{color: '#F0973B'}} />,
    //   action: ()=>{navigate('/services/self-booking')}
    // },
  ]

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
        anchorEl={anchorEl}
        id={menuId}
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: '180px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: width < 500?'4%':14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          (titleReducer.data.hideMenus)?""
          :
          <MenuItem onClick={()=>{
            
              window.location.href = (appConfigReducer.type === APP_MANAGER_TYPES[0]?profileURLLink?"/"+profileURLLink:"/profile":'/co/'+profileURLLink)
            }} >
            
              <ListItemIcon>
                <AccountCircle fontSize="small" style={{color: '#F0973B'}} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontSize: '15px'}}>
                {
                  appConfigReducer.type === APP_MANAGER_TYPES[0]?"My profile":"Profile"
                }
              </ListItemText>
              
          </MenuItem>
        }
      <MenuItem onClick={()=>navigate(appConfigReducer.type === APP_MANAGER_TYPES[0]?'/all-bookings':'/co/'+companyName+'/all-bookings')}>
        
          <ListItemIcon>
            <Sell fontSize="small" style={{color: '#F0973B', fontSize: '19px'}} />
            {/* <Avatar src={tag} style={{marginLeft: '1px' , width: '18px', height:'18px'}} size="small" /> */}
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{fontSize: '15px'}}>Orders</ListItemText>
          
      </MenuItem>
      <MenuItem onClick={()=>window.open('https://newaccount1628769790103.freshdesk.com/support/home','_blank')}>
        
          <ListItemIcon>
            <SupportAgent fontSize="small" style={{color: '#F0973B'}} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{fontSize: '15px'}}>Help</ListItemText>
          
      </MenuItem>
      {
        (titleReducer.data.hideMenus)?""
        :
        <MenuItem onClick={()=>navigate(appConfigReducer.type === APP_MANAGER_TYPES[0]?'/settings':'/co/'+companyName+'/settings')} >
          
            <ListItemIcon>
              <Settings fontSize="small" style={{color: '#F0973B'}} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{fontSize: '15px'}}>Settings</ListItemText>
            
        </MenuItem>
      }

      

      <GoogleLogout
        clientId={clientId}
        buttonText="Logout"
        onLogoutSuccess={handleLogout}
        onFailure={handleFailure}
        render={renderProps => (
          <>
          {
            isLogoutLoading?
              <SyncLoader
                  loading={isLogoutLoading}
                  size={10}
                  color="#000"
              />
            :
            <MenuItem 
              onClick={renderProps.onClick}
            >
              
                <ListItemIcon>
                  <Logout fontSize="small" style={{color: '#F0973B'}} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontSize: '15px'}}>Logout</ListItemText>
                
            </MenuItem>
          }
          </>
        )}
      >
      </GoogleLogout>
    </Menu>
  );

  
  const actionMenuId = 'primary-search-account-menu1234';
  const renderActionMenu = (
    <Menu
        anchorEl={actionMenuAnchor}
        id={actionMenuId}
        open={actionMenuOpen}
        onClose={handleActionMenuClose}
        onClick={handleActionMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '310px',
            padding: '10px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: width < 500?'28px':'28px',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <Grid container spacing={1}>
          {

            actionMenuItems.map((item, idx)=>(
              (item.id === "event".toLowerCase() && appConfigReducer.type === APP_MANAGER_TYPES[1])
              ||
              (item.id.toLowerCase() === "company".toLowerCase() && appConfigReducer.type === APP_MANAGER_TYPES[1])
              ||

              (item.id.toLowerCase() === "staff".toLowerCase() && appConfigReducer.type === APP_MANAGER_TYPES[0])

              ||

              
              (localUserMmeberData && localUserMmeberData.memberRole !== STAFF_TYPE[2] && //not admin and is add coupon
                item.id.toLowerCase() === "coupon".toLowerCase() && appConfigReducer.type === APP_MANAGER_TYPES[1])
              ||

              (localUserMmeberData && localUserMmeberData.memberRole === STAFF_TYPE[0] && 
                (
                  item.id.toLowerCase() === "staff".toLowerCase() || 
                  item.id.toLowerCase() === "service".toLowerCase() || 
                  item.id.toLowerCase() === "category".toLowerCase() || 
                  item.id.toLowerCase() === "billing_item".toLowerCase() ||                   
                  item.id.toLowerCase() === "location".toLowerCase()
                )
              ) 
              ||
              
              ((!localUserMmeberData || localUserMmeberData.memberRole === STAFF_TYPE[0]) && appConfigReducer.type === APP_MANAGER_TYPES[1] && item.id.toLowerCase() === "contact")
              ?
              ""
              :
              <Grid item xs={idx === 0?12:4} key={item.text}>
                <MenuItem  onClick={item.action} sx={
                    {
                      display: idx === 0?'flex':'block',
                      padding: idx === 0?'5px':'5px 3px',
                      background: '#F4F5FA',
                    }
                    
                  }
                  className="rounded"
                >
                  <div className={ idx === 0?'':'d-flex justify-content-center'}>
                    <span >
                      {item.icon}
                    </span>

                  </div>
                  <ListItemText primaryTypographyProps={{fontSize: '14px', textAlign: idx === 0?'left':'center', padding: idx === 0?'6px 0 0 8px':'0'}}>{item.text}</ListItemText>
                </MenuItem>
              </Grid>
            ))

          }
        </Grid>

      
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  

  return (
    <Fragment>
      <AppBar position="fixed" open={open} 
        style={{
            width: user?open && !titleReducer.data.hideDrawer?'calc(100% - 155px)':'100%':'100%' , 
            backgroundColor: '#F4F5FA',
            boxShadow: 'none',
            zIndex: '10',
        }} 
      >
        <Box 
          sx={{
              width: {xs: '100%', md: (!titleReducer.data.hideMenus || (titleReducer.data.hideMenus && user && !titleReducer.data.hideDrawer))?'94%':'97.5%'}, 
              margin: {md: "10px 1% 10px 5%",xs: 0}, 
              marginLeft: {md: (!titleReducer.data.hideMenus|| (titleReducer.data.hideMenus && user && !titleReducer.data.hideDrawer))?"4.8%":"1%"},
              boxShadow: 'none',
              border: '1px solid #fff',
              borderRadius: {md: '5px', xs: 0},
              backgroundColor: '#fff',
              padding: '7px 0',
          }} 
        >
        
          {
            showNavBar? 
              <DemoNavbar 
                data={titleReducer.data} 
                handleProfileMenuOpen={handleProfileMenuOpen} 
                handleActionMenuOpen={handleActionMenuOpen} 
                handleDrawerOpen={handleResponsiveDrawerOpen}
                configs={appConfigReducer}
                profileLink={
                  appConfigReducer.type === APP_MANAGER_TYPES[0]?

                    ("data" in appConfigReducer)? 
                      (appConfigReducer.data.profile || appConfigReducer.data.photoURL):profile
                  :
                  titleReducer.data.hideMenus && user && !titleReducer.data.hideDrawer?(user.photoURL || profile)
                  :
                    ("data" in userReducer && "profile" in userReducer.data)? 
                      userReducer.data.profile:profile
 

                  // ((appConfigReducer.type === APP_MANAGER_TYPES[0]))?
                  //     ("data" in userReducer && "profile" in userReducer.data)? 
                  //       userReducer.data.profile:profile
                  //     :
                  //     "logoUrl" in appConfigReducer.data && appConfigReducer.data.logoUrl?appConfigReducer.data.logoUrl:"company"
                      
                  
                }
              />:""
          }
        </Box>
      </AppBar>
      {
        (!titleReducer.data.hideMenus || (titleReducer.data.hideMenus && user && !titleReducer.data.hideDrawer))?
       
          <Drawer  
            variant="permanent" 
            sx={{ display: {xs: responsiveDrawerOpen?'block':'none', md: 'block', position: 'relative'}}} 
            open={open}
            PaperProps={{
              sx: {
                backgroundColor: "#fff",
                // backgroundColor: open?"#63C3CF":"#fff",
                // color: open?"#fff":"#6e7390",
                color: "#000",
                px: {md: 1, xs: 0},
                py: {md: 2, xs: 0}
              }
            }}
          >
            {/* <DrawerHeader>
              <IconButton onClick={handleDrawerOpenClose}>
                {open === false ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider /> */}
            <List sx={{pt: {md: 0, xs: 1}}} >
              <Tooltip title={"Close"} placement="right" className="d-block d-md-none">
                <ListItemButton
                  
                  sx={{
                    minHeight: 40,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  onClick={handleResponsiveDrawerOpen}
                >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: "#212121",
                      }}
                      style={{
                        fontSize: '33px'
                      }}
                    >
                      <ArrowBackIos style={{marginLeft: '4px'}} />
                    </ListItemIcon>
                    {/* <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} /> */}
                </ListItemButton>
              </Tooltip>
              {
                
              <Box
                  
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    px: {md: 2.5, xs: 1},
                    mt: 1.7,
                    mb: 3.7
                    //marginBottom: '17px !important'
                  }}
                  
                  // onClick={handleResponsiveDrawerOpen}
                >
                  <DivCenterContents>
                      <Avatar
                        style={{
                          backgroundColor: 'inherit',
                           width:'33px', 
                           height: '33px',
                          justifyContent: 'center',
                          color: "#000"
                        }} 
                        className="d-flex d-md-none justify-content-center"
                        src={appConfigReducer.type === APP_MANAGER_TYPES[0]?iconLogo:appConfigReducer.data.logoUrl}  >
                        {
                          appConfigReducer.type === APP_MANAGER_TYPES[0]?"":                      
                          <Domain sx={{fontSize: "21px", color: "#000"}} />
                        }
                        </Avatar>
                        </DivCenterContents>

                      <DivCenterContents>

                        {
                          appConfigReducer.type === APP_MANAGER_TYPES[0]?

                          <Avatar 
                            src={logo2} 
                            sx={{backgroundColor: 'inherit', width: '85px', height: '55px', objectFit: 'cover' }} 
                            className="d-none d-md-flex justify-content-center rounded-circle"
                          />


                          :appConfigReducer.data.logoUrl?
                          <Avatar 
                            src={appConfigReducer.data.logoUrl} 
                            sx={{backgroundColor: 'inherit', width: '55px', height: '55px',  objectFit: 'cover', }} 
                            className="d-none d-md-flex justify-content-center  rounded-circle"
                          >
                          {
                            appConfigReducer.type === APP_MANAGER_TYPES[0]?"":                      
                            <Domain sx={{fontSize: "55px", color: "#000"}} />
                          }
                          </Avatar>
                          :

                          <Avatar 
                            sx={{backgroundColor: 'inherit', width: '55px', height: '55px', mb: 2, mt:1,  objectFit: 'cover', }} 
                            className="d-none d-md-flex justify-content-center rounded-circle"
                          >
                          {
                            appConfigReducer.type === APP_MANAGER_TYPES[0]?"":                      
                            <Domain sx={{fontSize: "55px"}} color="black" />
                          }
                          </Avatar>
                        }
                      </DivCenterContents>
                </Box>
              }
              {navItems.map((item) => ( 
                  ((appConfigReducer.type === APP_MANAGER_TYPES[1] && item.id.toLowerCase() === "Marketplaces".toLowerCase() && !titleReducer.data.hideMenus)
                  ||
                  (appConfigReducer.type === APP_MANAGER_TYPES[0] && item.id.toLowerCase() === "Team".toLowerCase())
                  || 
                  (appConfigReducer.type === APP_MANAGER_TYPES[0] && item.id.toLowerCase() === "Analytics".toLowerCase())
                  ||
                  ((!localUserMmeberData || localUserMmeberData.memberRole === STAFF_TYPE[0]) && item.id.toLowerCase() === "Analytics".toLowerCase())
                  ||
                  ((!localUserMmeberData || localUserMmeberData.memberRole === STAFF_TYPE[0]) && appConfigReducer.type === APP_MANAGER_TYPES[1] && item.id.toLowerCase() === "Contacts".toLowerCase())
                  ||
                  ((!localUserMmeberData || localUserMmeberData.memberRole === STAFF_TYPE[0]) && appConfigReducer.type === APP_MANAGER_TYPES[1] && item.id.toLowerCase() === "Invoicing".toLowerCase())
                  ||  
                  ((!localUserMmeberData) && appConfigReducer.type === APP_MANAGER_TYPES[1] && item.id.toLowerCase() === "Payments".toLowerCase())
                  || 
                  (titleReducer.data.hideMenus && user && ((item.id.toLowerCase() !== "Dashboard".toLowerCase())) && (item.id.toLowerCase() !== "Home".toLowerCase()))
                  
                  ||

                  (!titleReducer.data.hideMenus && (item.id.toLowerCase() === "Home".toLowerCase()))
                  )
                  ?
                  ""
                  :
                  <Tooltip title={item.name} key={item.id} placement="right">
                    <ListItemButton
                      
                      sx={{
                        minHeight: 40,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2,
                        py: 0,
                        borderRadius: {md: '10px', xs: 0},
                        
                        '&& .Mui-selected, && .Mui-selected:hover': {
                          bgcolor: 'red',
                        },
                        
                      }}
                      selected={(item.id.toLowerCase() === (titleReducer.data.selectedNav || "dashboard").toLowerCase())}
                      onClick={()=>handleNavClick(item.url, (item.id.toLowerCase() === "Switch to user mode".toLowerCase()), (titleReducer.data.hideMenus && user) )}
                    >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open?1.5:'auto',
                            justifyContent: 'center',
                            color: "#212121",
                          }}
                          style={{
                            fontSize: '33px'
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText 
                          primary={item.name} 
                          sx={{ display: {xs: 'none', md: 'inherit'}}} 
                          primaryTypographyProps={{fontSize: '14px'}} 
                        />
                    </ListItemButton>
                  </Tooltip>
              ))}
            </List>

            <div className="text-center" style={{position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%'}}>
              <Divider sx={{background: '#63C3CF'}} /> 
              <div className="d-flex justify-content-center align-items-center" style={{height: '40px'}}>
                <small>Ver. No: {VERSION}</small>
              </div>
            </div>
          </Drawer>

        :""

      }

      <MainDrawer open={mainDrawerOpen} toggleDrawer={toggleMainDrawer} pageType={pageType} data={null} />
      
      {/* {renderMobileMenu} */}
      {renderMenu}
      {renderActionMenu}
    </Fragment>
  );
}
