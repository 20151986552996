
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref, remove, set, update } from "firebase/database";
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, query, setDoc, updateDoc, where } from "firebase/firestore";
import { deleteObject, getBlob, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { getCompanyProfileVisitsCount } from "FirebaseController/Analytics";
import { getSubscriptionSettings } from "FirebaseController/Settings";
import { getSubscriptionPlanInfo } from "FirebaseController/StripePayments";
import { STAFF_STATUS, STAFF_TYPE } from "Utils/constants";
import { baseFirebaseApiUrl } from 'AppSettings'
import { firebaseConfig } from "../../AppSettings";

const app = initializeApp(firebaseConfig);
const db = getFirestore (app)
const realTimeDb = getDatabase()

export const saveCompany = (data, companyId, firebaseUserID)=>new Promise(function(successCallback, errorCallback){

    //check if company name exists
    //check where user is company admin

    //const myDocRef = collection(db, "Companies/")
    //const q = query(myDocRef, where("creatorID", "==", firebaseUserID), limit(1))

    /*
            const docSnap = await getDocs(q)
            const _data = []
            docSnap.forEach((_doc) => {
                _data.push(_doc.data())        
            });

    */
    getMyCompaniesFromDb(firebaseUserID).then((_data)=>{

        
        getSubscriptionSettings(firebaseUserID).then((subscription)=>{

            
            const type = (subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) > new Date())
            ?subscription.subscriptionType:"Free"

            
            //get plan
            getSubscriptionPlanInfo(type).then((plans)=>{
                if(plans.length > 0) {
                    run(parseFloat(plans[0].Companies))
                }else {
                    errorCallback("Invalid subscription plan, try again later.")
                }
            }).catch(errorCallback)

        }).catch((err)=>{
            errorCallback(err)
        })


        function run(limit) {
            if(limit && !isNaN(limit)) {
                if(_data.length >= limit) {//total companies exceed subscription limit
                    errorCallback("company limit")
                }else {

                    const companyRef = ref(realTimeDb, 'Companies/' + companyId.toString()+"/")
                    set(companyRef, data).then(successCallback).catch(errorCallback)
                    
                    // const _myDocRef = doc(db, "Companies/"+companyId)             
                    // setDoc(_myDocRef, data).then(successCallback).catch(errorCallback)
                }
            }else {
                errorCallback("Could not get your subscriptions details, check your internet or try again later.")
            }
        }
    }).catch((err)=>{
        
        errorCallback(err)
    })

})


export const updateCompany = (data, companyId)=>new Promise(function(successCallback, errorCallback){

    //check if company name exists

    
    const companyRef = ref(realTimeDb, 'Companies/' + companyId)
    update(companyRef, data).then(successCallback).catch(errorCallback)

    // const _myDocRef = doc(db, "Companies/"+companyId)            
    // updateDoc(_myDocRef, data).then(successCallback).catch(errorCallback)
})


export const saveUserSignedInFromCompany =(data, companyId, subscribedUser)=>new Promise(async function(successCallback, errorCallback){
    try {


        
        getSubscriptionSettings(subscribedUser).then((subscription)=>{

            
            const type = (subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) > new Date())
            ?subscription.subscriptionType:"Free"

            
            //get plan
            getSubscriptionPlanInfo(type).then((plans)=>{
                if(plans.length > 0) {
                    run(parseFloat(plans[0].Contacts))
                }else {
                    errorCallback("Invalid subscription plan, try again later.")
                }
            }).catch(errorCallback)

        }).catch((err)=>{
            errorCallback(err)
        })

        function run (limit_) { 
            if(limit_ && !isNaN(limit_)) {
                getCompanyProfileVisitsCount(companyId)
                    .then(async (companyAnalytics)=>{
                        const totalContacts = "totalGuests" in companyAnalytics && companyAnalytics.totalGuests &&
                        "totalClients" in companyAnalytics && companyAnalytics.totalClients?
                        parseFloat(companyAnalytics.totalGuests) + parseFloat(companyAnalytics.totalClients)
                        :"totalClients" in companyAnalytics && companyAnalytics.totalClients?companyAnalytics.totalClients
                        :"totalGuests" in companyAnalytics && companyAnalytics.totalGuests?companyAnalytics.totalGuests
                        // :sortedContactData.length > 0?sortedContactData.length
                        :0

                        
                        if(totalContacts >= limit_) {

                            
                            errorCallback("Sorry, you can only add upto "+limit_+" contacts. Upgrade your plan to add more contacts.")

                        }else {
                            const myDocRef = collection(db, "CompanyUserBase/"+ companyId.toString() + "/Users/")
                            let q = 
                                query(
                                    myDocRef,     
                                    where('userId', '==', data.userId),
                                    limit(1)
                                )
                            let docSnap = await getDocs(q)
                            const _data = []
                            docSnap.forEach((doc) => {
                                _data.push(doc.data())        
                            });
                            if(_data.length > 0) {
                                errorCallback("User already exists")
                            }else {
                                const myDocRef = doc(db, "CompanyUserBase/"+ companyId + "/Users/" +data['contactId'])
                                setDoc(myDocRef, data).then(successCallback).catch(errorCallback)         
                                successCallback("yeey")
                            }
                        }
                    })
                    .catch((err)=>{
                        
                    })
            }else {
                errorCallback("Could not get your subscriptions details, check your internet or try again later.")
            }
            
        }
    }catch(err) {
        
        errorCallback(err)
    }
})


export const updateUserSignedInFromCompany =(data, companyId)=>new Promise(async function(successCallback, errorCallback){
    try {
        const myDocRef = collection(db, "CompanyUserBase/"+ companyId + "/Users/")
        const q = query(myDocRef, where("contactId", "==", data.contactId))
        const docSnap = await getDocs(q)
        if(!docSnap.empty) {
            
            updateDoc(docSnap.docs[0].ref, data).then(successCallback).catch(errorCallback) 
        }else {
            errorCallback("Could not find contact")
        }
    }catch(err) {
        
        errorCallback(err)
    }
})


export const saveUserSignedInFromCompanyAddr =(data, companyId)=>new Promise(async function(successCallback, errorCallback){
    try {
        const myDocRef = doc(db, "CompanyUserBase/"+ companyId + "/UsersAddress/"+data.contactId)
        setDoc(myDocRef, data).then(successCallback).catch(errorCallback)  
        
    }catch(err) {
        
        errorCallback(err)
    }
})


export const updateCompanyContactProfile = (file, companyId, firebaseID, firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    
    const storage = getStorage();

    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        const imgRef = storageRef(storage, firebaseUrl);
        deleteObject(imgRef).then(()=>{
            
        })
    }
    const imgRef = storageRef(storage, "Companies/contacts/" + companyId+"/"+firebaseID+"/"+(new Date().valueOf()) + '.jpg');
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                
                successCallBack(url)
            })
            .catch(errorCallback);
    }).catch(errorCallback)

})


export const updateUserSignedInFromCompanyAddr =(data, companyId)=>new Promise(async function(successCallback, errorCallback){
    try {
        const myDocRef = doc(db, "CompanyUserBase/"+ companyId + "/UsersAddress/"+data.contactId)
        updateDoc(myDocRef, data).then(successCallback).catch(errorCallback) 
    }catch(err) {
        
        errorCallback(err)
    }
})


export const checkIfUserIsSignedInViaCompany = (email, companyId) => new Promise(async function(successCallback, errorCallback){
  
    try {
        const myDocRef = collection(db, "CompanyUserBase/"+ companyId + "/Users/")
        const q = query(myDocRef, where('email', '==',email))
        
        const docSnap = await getDocs(q)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        successCallback(_data)
    }catch(err) {
        
        
        
        errorCallback(err)
    }
})




export const getUserSignedInViaCompany =(companyId, clientId)=>new Promise(async function(successCallback, errorCallback){
    try {
        const myDocRef = collection(db, "CompanyUserBase/"+ companyId + "/Users/")
        const qry = query(myDocRef, where('contactId', '==', clientId))
        const docSnap = await getDocs(qry)
        const _data = []
        docSnap.forEach((doc) => {
            _data.push(doc.data())        
        });
        if(_data.length > 0) {
            successCallback(_data[0])
        }else {
            successCallback({})
        }
    }catch(err) {
        errorCallback(err)
    }
})


export const getUserSignedInViaCompanyAddr =(companyId, clientId)=>new Promise(async function(successCallback, errorCallback){
    try {
        const myDocRef = doc(db, "CompanyUserBase/"+ companyId + "/UsersAddress/"+clientId)
        const docSnap = await getDoc(myDocRef)
        if(docSnap.exists()) {
            successCallback(docSnap.data())
        }else {
            successCallback({})
        }
    }catch(err) {
        errorCallback(err)
    }
})

export const searchCompanyProfileLink = (profileURLLink)=>new Promise( async function(successCallback, errorCallback) {

    const headers = {
        "Content-Type": "application/json",
    }
    axios.get(`${baseFirebaseApiUrl}/searchCompanyByProfileURLLink/?profileURLLink=${profileURLLink}`, {headers:headers})
        .then((data)=>{
            console.log("searchCompanyProfileLink", data)
            successCallback(data.data)
        })
        .catch(errorCallback)

    
    // const searchRef = query(
    //     ref(realTimeDb, 'Companies/'), 
    //     orderByChild('profileURLLink'), 
    //     equalTo(profileLink.toString())
    // )
    

    // get(searchRef).then((snapshot) => {
    //     if(snapshot.exists()) {

    //         console.log("snapshot.val()", snapshot.val())
    //         var key = Object.keys(snapshot.val())[0]
    //         successCallback([snapshot.val()[key]])
    //     }else {
    //         successCallback([])
    //     }
    // })
    // .catch(errorCallback)
    
    // const myDocRef = collection(db, "Companies/")
    // const q = query(myDocRef, where("profileURLLink", "==", profileLink))
    // const docSnap = await getDocs(q)
    // const _data = []
    // docSnap.forEach((doc) => {
    //     _data.push(doc.data())        
    // });
    // successCallback(_data)
});

 
export const updateCompanyProfile = (file, firebaseCompanyID, firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    
    const storage = getStorage();

    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        const imgRef = storageRef(storage, firebaseUrl);
        deleteObject(imgRef).then(()=>{
            
        })
    }
    const imgRef = storageRef(storage, "Companies/profile/"+firebaseCompanyID+"/"+(new Date().valueOf()) + '.jpg');
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                

                //update company
                
                updateCompany({
                    "logoUrl": url.toString(),
                }, firebaseCompanyID)
                .then(()=>{
                    successCallBack(url)
                    
                })
                
            })
            .catch(errorCallback);
    }).catch(errorCallback)

})

export const updateCompanyBanner = (file, firebaseCompanyID, firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    
    const storage = getStorage();

    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        const imgRef = storageRef(storage, firebaseUrl);
        deleteObject(imgRef).then(()=>{
            
        })
    }
    const imgRef = storageRef(storage, "Companies/banners/"+firebaseCompanyID+"/"+(new Date().valueOf()) + '.jpg');
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                

                //update company
                
                updateCompany({
                    "bannerImg": url.toString(),
                }, firebaseCompanyID)
                .then(()=>{
                    successCallBack(url)
                    
                })
                
            })
            .catch(errorCallback);
    }).catch(errorCallback)

})



export const getMyCompaniesFromDb = (firebaseUserID, pageLimit)=>new Promise( async function(successCallback, errorCallback) {

    
    
    const myDocRef = collection(db, "StaffMembers/")
    const q = query(
        myDocRef, 
        where("userId", "==", firebaseUserID), 
        where("status", "==", STAFF_STATUS[1]),
        where("memberRole", "==", STAFF_TYPE[2]),
        limit(pageLimit)
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallback(_data)
});


export const getAddedToCompaniesFromDb = (firebaseUserID)=>new Promise( async function(successCallback, errorCallback) {

    
    
    const myDocRef = collection(db, "StaffMembers/")
    const q = query(
        myDocRef, 
        where("userId", "==", firebaseUserID), 
        where("status", "==", STAFF_STATUS[1]),
        where("memberRole", "!=", STAFF_TYPE[2])
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallback(_data)
});

export const getMyCompanies = (firebaseUserID)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = collection(db, "StaffMembers/")
    const q = query(myDocRef, where("userId", "==", firebaseUserID), where("status", "==", STAFF_STATUS[1]))
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallback(_data)
});

export const getCompanyData = (companyID)=>new Promise( async function(successCallback, errorCallback) {

    const companyRef = ref(realTimeDb, 'Companies/' + companyID)
    
    onValue(companyRef, (snapshot) => {
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
            errorCallback("no company data")
        }
    }, errorCallback);
    
    // try {


    //     const myDocRef = doc(db, "Companies/"+companyID ) 
    //     //const q = query(myDocRef, where("companyID", "==", companyID))
    //     const docSnap = await getDoc(myDocRef)
    //     if(docSnap.exists()) {
    //         successCallback(docSnap.data())
    //     }else {
    //         errorCallback("no company data")
    //     }
    // }catch (err) {
    //     errorCallback(err)
    // }
});


export const saveStaffMember = ( data, subscribedUser )=>new Promise(function(successCallback, errorCallback){

    //get existing staff members

    
    getSubscriptionSettings(subscribedUser).then((subscription)=>{

         
        const type = (subscription.subscriptionType && new Date(parseFloat(subscription.currentTermEnd)) > new Date())
        ?subscription.subscriptionType:"Free"

        console.log(type)
        //get plan
        getSubscriptionPlanInfo(type).then((plans)=>{
            console.log(plans)
            if(plans.length > 0) {
                run(parseFloat(plans[0].Staff))
            }else {
                
        console.log("Invalid subscription plan, try again later")
                errorCallback("Invalid subscription plan, try again later.")
            }
        }).catch(errorCallback)

    }).catch((err)=>{
        errorCallback(err)
    })

    function run(limit_) {
        if(limit_ && !isNaN(limit_)) {
            const companyId = data["companyId"]
            getCompanyStaffMembers(companyId, false, 1000).then((_data)=>{

                const db = getFirestore (app)
                if(_data.length >= limit_) {
                    errorCallback("Sorry, you can only add upto "+limit_+" staff members per company. Please update your subscription.", "error")
                }else if(_data.length > 0) {
                    if(_data.filter(item=>item.memberRole === STAFF_TYPE[1]).length >= 2 && data.memberRole === STAFF_TYPE[1]) {
                         errorCallback("You have reached maximum number of company managers, only 2 are allowed", "error")
                    }else if(_data.filter(item=>item.userId === data['userId']).length > 0) {
                        errorCallback("Staff member already exists", "error")
                    }else {
                        
                    
                        const _myDocRef = doc(db, "StaffMembers/"+data["staffId"])         
                        setDoc(_myDocRef, data).then(()=>{
                            const staffRef = ref(realTimeDb, "StaffMembers/"+data["staffId"])
                            set(staffRef, data).then(successCallback).catch(errorCallback)
                        }).catch(errorCallback)
                    }
                }else {
                    

                    const _myDocRef = doc(db, "StaffMembers/"+data["staffId"])         
                    setDoc(_myDocRef, data).then(()=>{                
                        const staffRef = ref(realTimeDb, "StaffMembers/"+data["staffId"])
                        set(staffRef, data).then(successCallback).catch(errorCallback)
                    }).catch(errorCallback)
                }
            })
            .catch(errorCallback)
        }else {
            errorCallback("Could not get your subscriptions details, check your internet or try again later.")
        }
    }
})



export const updateStaffMember = (staffId, data, companyId = null)=>new Promise( function(successCallback, errorCallback) {

    
    // const db = getFirestore(app)
    // const myDocRef = doc(db, "StaffMembers/"+staffId)
    // updateDoc(myDocRef, data).then((resp)=>{
    //     
    //     successCallback(resp)
    // }).catch((err)=>{
    //     
    //     errorCallback(err)
    // })

        updateDb()

        function updateDb() {
            const staffRef = ref(realTimeDb, "StaffMembers/"+staffId)
            update(staffRef, data).then((resp)=>{
                // 

                const firestoreData = {}
                if(data.fName || data.firstname) {
                    firestoreData.firstname = data.fName || data.firstname
                }
                if(data.sName || data.surname) {
                    firestoreData.surname = data.sName || data.surname
                }
                if(data.profile) {
                    firestoreData.profile = data.profile
                }
                if(data.memberRole) {
                    firestoreData.memberRole = data.memberRole
                }

                if(Object.keys(firestoreData).length > 0) {
                    updateFirestoreStaffMember(staffId, firestoreData).then(()=>successCallback(resp))
                    .catch(errorCallback)
                } else {
                    successCallback()
                }

                
            }).catch((err)=>{
                
                errorCallback(err)
            })
        }
    
});

export const updateFirestoreStaffMember = (staffId, data)=>new Promise( function(successCallback, errorCallback) {

    
    const db = getFirestore(app)
    const myDocRef = doc(db, "StaffMembers/"+staffId)
    updateDoc(myDocRef, data).then((resp)=>{
        
        successCallback(resp)
    }).catch((err)=>{
        
        errorCallback(err)
    })

       
});

export const getCompanyStaffMembers = (companyId, removePendingMembers = false, limit_ = 100, removeDisabledStaff = true)=>new Promise( async function(successCallback, errorCallback) {


    const headers = {
        "Content-Type": "application/json",
    }
    axios.get(`${baseFirebaseApiUrl}/getCompanyStaffMembers/?companyId=${companyId}&limit=${limit_}`, {headers:headers})
        .then((data)=>{
            console.log("searchCompanyProfileLink", data)
            let _data = data.data
            if(removePendingMembers) {
                _data = _data.filter(item=>item.status === STAFF_STATUS[1])
            }
            const newData = (removeDisabledStaff)?_data.filter(item=>!item.disabled):_data

            successCallback(newData)

        })
        .catch(errorCallback)
    
    // const searchRef = query(ref(realTimeDb, 'StaffMembers/'), orderByChild('companyId'), equalTo(companyId), limitToFirst(limit_))
    
    // onValue(searchRef, (snapshot) => {
    //     if(snapshot.exists()) {

    //         var result = snapshot.val()
    //         var keys = Object.keys(result)
    //         const _data = []
    //         keys.forEach(key => {
                
    //             if(!removePendingMembers || (removePendingMembers && result[key].status === STAFF_STATUS[1])) {
    //                 _data.push(result[key]) 
    //             }

    //         })

    //         const newData = (removeDisabledStaff)?_data.filter(item=>!item.disabled):_data

    //         successCallback(newData)
    //     }else {
        
    //         successCallback({})
    //     }
    // }, errorCallback);


    // const myDocRef = collection(db, "StaffMembers/")
    // const q = removePendingMembers? query(myDocRef, where("companyId", "==", companyId), where("status", "==", STAFF_STATUS[1]))
    //     :query(myDocRef, where("companyId", "==", companyId))
    // const docSnap = await getDocs(q)
    // const _data = []
    // docSnap.forEach((doc) => {
    //     _data.push(doc.data())        
    // });
    // successCallback(_data)
});

export const getCompanyAdmin = (companyId)=>new Promise( async function(successCallback, errorCallback) {

    
    
    const myDocRef = collection(db, "StaffMembers/")
    const q = query(
        myDocRef, 
        where("companyId", "==", companyId), 
        where("status", "==", STAFF_STATUS[1]),
        where("memberRole", "==", STAFF_TYPE[2])
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallback(_data)
});

export const getCompanyManagers = (companyId)=>new Promise( async function(successCallback, errorCallback) {

    
    getCompanyStaffMembers(parseFloat(companyId), false, 500).then((data)=>{
        
        const newData = data.filter((staff)=> (staff.status === STAFF_STATUS[1] && staff.memberRole !==  STAFF_TYPE[0]))
        successCallback(newData)

    }).catch(errorCallback)

    // const myDocRef = collection(db, "StaffMembers/")
    // const q = query(myDocRef, 
    //     where("companyId", "==", parseFloat(companyId)),
    //     where("status", "==", STAFF_STATUS[1]),  
    //     where("memberRole", "!=", STAFF_TYPE[0]),

    // )
    // const docSnap = await getDocs(q)
    // const _data = []
    // docSnap.forEach((doc) => {
    //     _data.push(doc.data())        
    // });
    // successCallback(_data)
});

export const getStaffMember = (userId, companyId) => new Promise( async function(successCallback, errorCallback) {
    
     
    
    try{
        const myDocRef = collection(db, "StaffMembers/")
        const q = query(myDocRef,
            where("companyId", "==", parseFloat(companyId)), 
            where("userId", "==", userId),
            where("status", "==", STAFF_STATUS[1]),
        )
        const docSnap = await getDocs(q)
        let staffData = null
        docSnap.forEach((doc_) => {
            staffData = doc_.data()    
        });

        if(staffData) {
            const staffRef = ref(realTimeDb, "StaffMembers/"+staffData["staffId"])
            onValue(staffRef, (snapshot) => {
                if(snapshot.exists()) {    
                    

                    successCallback([snapshot.val()])
                }else {
                
                    successCallback([staffData])
                }
            }, errorCallback);
        }else {
            successCallback([])
        }
    }catch(err){
        
        errorCallback(err)
    }

})


export const getPendingStaffMemberNotif = (userId, companyId = null)=>new Promise( async function(successCallback, errorCallback) {

    const myDocRef = collection(db, "StaffMembers/")
    const q = companyId?
        query(myDocRef, where("status", "==", STAFF_STATUS[0]), where("companyId", "==", companyId), where("userId", "==", userId))
        :
        query(myDocRef, where("userId", "==", userId), where("status", "==", STAFF_STATUS[0]))
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    

    successCallback(_data)
});

export const acceptStaffMemberRequest = (staffId)=>new Promise( async function(successCallback, errorCallback) {

    
    const myDocRef = doc(db, "StaffMembers/"+staffId)
    const data = {"status": STAFF_STATUS[1]}
    updateDoc(myDocRef, data).then(()=>{
        updateStaffMember(staffId, data).then(()=>{
            successCallback()
        }).catch((err)=>{
            errorCallback(err)
        })
    }).catch(errorCallback)
});

export const deleteStaffMember = (staffId) => new Promise( async function(successCallback, errorCallback) {
    
    const myDocRef = doc(db, "StaffMembers/" + staffId)
    deleteDoc(myDocRef).then(()=>{
        const staffRef = ref(realTimeDb, "StaffMembers/"+staffId)
        remove(staffRef).then(successCallback).catch(errorCallback)
    }).catch(errorCallback)
})

export const updateStaffProfile = (file, staffId, firebaseUrl = "")=>new Promise(function(successCallBack, errorCallback){
    
    const storage = getStorage();

    //delete old file from firebase
    if(firebaseUrl !== "" && firebaseUrl) {
        try{ 
            const imgRef = storageRef(storage, firebaseUrl);
            deleteObject(imgRef).then(()=>{
                
            })
        }catch(err) {
            
        }
    }
    const imgRef = storageRef(storage, "Companies/Staff/Profile/"+staffId+"/"+(new Date().valueOf()) + '.jpg');
    const uploadTask = uploadBytes(imgRef, file)


    uploadTask.then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref)
            .then((url)=>{
                
                updateStaffMember(staffId, {profile: url.toString()}).then(()=>{
                    successCallBack(url)
                }).catch(errorCallback)

            })
            .catch((err)=>{
                errorCallback(err)
            });
    }).catch((err)=>{
        errorCallback(err)
    })

})


export const deleteImgs = (firebaseUrl) =>new Promise(function(successCallBack, errorCallback){
    if(firebaseUrl) {
        const storage = getStorage();
        const imgRef = storageRef(storage, firebaseUrl);
        deleteObject(imgRef).then(successCallBack).catch(errorCallback)
    }else {
        errorCallback("Invalid image link.")
    }
})

export const getImageBlob = (firebaseUrl) =>new Promise(function(successCallBack, errorCallback){
    const storage = getStorage();
    const imgRef = storageRef(storage, firebaseUrl);
    getBlob(imgRef)
        .then(successCallBack)
        .catch(errorCallback)
})




export const makeFakeCallToGetFirestoreStartedUp =()=>new Promise(async function(successCallback, errorCallback){
    
    const myDocRef = doc(db, "InitializeFirestore/Latom12345")
    const docSnap = await getDoc(myDocRef)
    if(docSnap.exists()){
        successCallback(docSnap.data())
    }else {
        errorCallback("No data")
    }
})
    