
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// reactstrap components
import MenuIcon from '@mui/icons-material/Menu';
import Whatshot from '@mui/icons-material/Whatshot';
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Box from "@mui/system/Box";
import NotificationsDrawer from "components/NotificationsDrawer";
import { getCompanypProfileSetingsData } from "FirebaseController/Settings";
import { getUserDirectoryDataFromDb, logout } from "FirebaseController/User/user";
import {
  Nav, Navbar,
  NavItem,
  NavLink
} from "reactstrap";
import { APP_MANAGER_TYPES } from "Utils/constants";
import BookingStepper from "./BookingStepper";
import ShareBtn from "./share";

class DemoNavbar extends React.Component {
  componentDidMount() {
    // let headroom = new Headroom(document.getElementById("navbar-main"));
    // // initialise
    // headroom.init();
    

    
      // saveUserToken()
      const user = this.getUser()
      if(user){
        getUserDirectoryDataFromDb(user.uid).then((data)=>{
            if("profileLink" in data && data.profileLink){
                //setProfileLink(data.profileLink)

                //profile image
            }
        })
      }
      if(this.props.configs.type === APP_MANAGER_TYPES[1]) {
        getCompanypProfileSetingsData(this.props.configs.data.companyID).then((data)=>{
          
          if(typeof data === 'object' && Object.keys(data).length > 0){
            this.setState({
              ...this.state,
              hideShareLink: data.hideShareLink,
            })
          }else {
            this.setState({
              ...this.state,
              hideShareLink: false,
            })
          }
        }, (err)=>{
            
        })

      }
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
    hideShareLink: false,
  };

  getUser = () => {
    const userObj = JSON.parse(localStorage.getItem("user"))
    return userObj?userObj.user:null
  }

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  handleClientLogout = ()=>{
    logout()
    
    window.location.href = (this.props.configs.type === APP_MANAGER_TYPES[0]?'/auth/login':'/co/'+this.props.configs.data.profileURLLink+'/auth/login')
  }

  handleLogout = () => {
    // e.preventDefault()
    logout()
    window.location.href = ('/auth/login')
  }

  handleFailure = (error) => {
    
    // this.handleLogout(  )
  }

  render() {
    return (
      <>
        <div className="header-global w-100" >
          <Navbar
            className="w-100 py-0 my-0 "
            expand="lg"
            id="navbar-main"
            style={{
              zIndex: 30, 
              backgroundColor: "#fff",
              color: "#000", 
              border: '1px solid #fff',
              borderRadius: '10px',
            }}
          >
            <div className="d-flex w-100">

              <Box  sx={{width: {xs: 25, md: 45 }, pt: 0.2, display: {md: 'none', xs: 'block'}  }}>
                <NavLink
                  className="nav-link-icon ml-0 pl-0  "
                  href="#"
                  id="tooltip184698703235"
                  onClick={this.props.handleDrawerOpen  }
                >
                  <Avatar style={{backgroundColor: "#fff", width: '23px', height: '23px'}}>
                    <MenuIcon style={{color: "#000"}} />
                  </Avatar>
                </NavLink>
              </Box>
              <Box className="d-flex ml-4 ml-md-0" to="/" tag={Link} sx={{width: {md: '35%', xs: '50%'},marginTop: '4px',fontSize: '17px',color: "#000", fontWeight: 600, textTransform: 'uppercase', letterSpacing: '0.05px'}}>
                <div className="mr-2 ml-2 d-md-block d-none" 
                  style={{height: '25px',width: '3px', background: '#63C3CF', borderWidth: '3px' }}

                ></div>
                <span className="text-truncate">
                  {
                    this.props.data.title
                  }
                </span>
              </Box>
              <div style={{flex: 1}} >
                {
                  this.props.data.isBooking?
                    <div className="w-50 d-md-flex d-none" style={{marginTop: '7px'}}>
                      <BookingStepper navData={this.props.data} />
                    </div>

                  :""
                }

              </div>

              {
                this.getUser()?
                  <div
                    toggler="#navbar_global"
                    navbar="true"
                  >
                    <Nav className="d-flex align-items-lg-center ml-lg-auto"  >
                      {
                        this.state.hideShareLink?"":
                          <NavItem className="d-none d-md-block ml-lg-4">
                            <ShareBtn  configs={this.props.configs} />
                          </NavItem>
                      }
                      {
                        // this.props.data.hideMenus?
                        
                        //   <Box sx={{width: {xs: 35, md: 45 }, pt: 0.2  }} >
                        //     <NavLink
                        //       className="nav-link-icon"
                        //       href="#"
                        //       id="tooltip18469870323589798"
                        //       onClick={this.handleClientLogout}
                        //     >
                        //       {
                                
                        //         <Avatar style={{width: '25px', height: '25px', border: '1px solid #d3d3d3'}}>
                        //           <Domain sx={{fontSize: '20px'}} />
                        //         </Avatar>
                        //       }
                        //       {/* <AccountCircle style={{fontSize: '25px'}} color="purpleish" /> */}
                        //     </NavLink>
                        // </Box>
                    
                        // :
                        <>
                          <Box sx={{width: {xs: 35, md: 45 }}}>
                              <NotificationsDrawer configs={this.props.configs} />
                            
                          </Box>
                          {
                            this.props.data.hideMenus?"":
                            <>
                            
                              <Box  sx={{ pt: 0.2  }}>
                                <NavLink
                                  className="nav-link-icon"
                                  style={{margin: 0, padding: 0, marginRight: '12px'}}
                                  href="#"
                                  id="tooltip184698703235"
                                  onClick={this.props.handleActionMenuOpen  }
                                >
                                  <Chip 
                                    label={"ADD"}
                                    //onClick={statusMenuOpen?()=>null:handleStatusMenuClick}
                                    // onDelete={()=>null}
                                    size="small"
                                    //clickable
                                    sx={{backgroundColor: "#63C3CF", color: '#fff', cursor: 'pointer' }}
                                  />
                                </NavLink>
                              </Box>
                            
                            </>
                          }
                          
                          <Box sx={{width: {xs: 35, md: 45 }, pt: 0.2  }} >
                            <NavLink
                              className="nav-link-icon px-0 mx-0"
                              href="#"
                              id="tooltip184698703235"
                              onClick={this.props.handleProfileMenuOpen}
                            >
                              {
                                // this.props.profileLink === "company"?
                                // <Avatar style={{width: '25px', height: '25px', border: '1px solid #d3d3d3'}}>
                                //   <Domain sx={{fontSize: '20px'}} />
                                // </Avatar>
                                // :
                                <Avatar src={this.props.profileLink} style={{width: '25px', height: '25px', border: 'none', background: '#fff'}} >

                                  
                                  <Whatshot color="primary"  sx={{fontSize: '25px'}}/>
                                </Avatar>
                              }
                              {/* <AccountCircle style={{fontSize: '25px'}} color="purpleish" /> */}
                            </NavLink>
                          </Box>
                        
                        </>
                      }
    
                    </Nav>
                  </div>

                :
                        
                  <Box sx={{width: {xs: 35, md: 45 }, pt: 0.2  }} >
                    <NavLink
                      className="nav-link-icon"
                      href="#"
                      id="tooltip18469870323589798"
                      onClick={this.handleClientLogout}
                    >
                      {
                        
                        // <Avatar style={{width: '25px', height: '25px', border: '1px solid #d3d3d3'}}>
                        //   <Domain sx={{fontSize: '20px'}} />
                        // </Avatar>
                        <Avatar src={this.props.profileLink} style={{width: '25px', height: '25px', border: 'none', background: '#fff'}} >
                          <Whatshot color="primary"  sx={{fontSize: '25px'}}/>
                        </Avatar>
                      }
                      {/* <AccountCircle style={{fontSize: '25px'}} color="purpleish" /> */}
                    </NavLink>
                  </Box>
              
              }
              
            </div>
          </Navbar>
        </div>
        
      </>
    );
  }
}

export default DemoNavbar;
