
import { initializeApp } from "firebase/app";
import { get, getDatabase, ref, update } from "firebase/database";
import { collection, doc, getDocs, getFirestore, limit, orderBy, query, setDoc, where } from "firebase/firestore";
import { getCompanyProfileVisitsCount, getCompanyStaffVisitsCount, getProviderTotalLoggedUsers, getServiceTotalBookingsAnalytics } from "FirebaseController/Analytics";
import { firebaseConfig } from "../../AppSettings";

const app = initializeApp(firebaseConfig);
const db = getDatabase()
const fireDB = getFirestore (app)


export const saveServiceRatingAnalytics = (totalRatings = 0, totalRatersCount = 1, serviceId, providerId, isCompany = false)=>new Promise((successCallBack, errorCallBack)=>{

    if(serviceId) {
        const analyticRef = isCompany?ref(db, 'CompanyAnalytics/' + providerId+"/services/"+serviceId)
            :ref(db, 'ProviderClientBaseAnalytics/' + providerId+"/services/"+serviceId)

        getServiceTotalBookingsAnalytics(serviceId, providerId, isCompany).then((data)=>{

            const _data = Object.keys(data).length > 0?
            {
                ...data,
                totalRatings: ("totalRatings" in data && data.totalRatings)?parseFloat(data.totalRatings) + totalRatings:totalRatings,
                totalRaters: ("totalRaters" in data && data.totalRaters)?parseFloat(data.totalRaters) + totalRatersCount:totalRatersCount,
                //totalBookings: count //override test
            }
            :
            {
                totalRatings: totalRatings,
                totalRaters: totalRatersCount,
            }

            if(Object.keys(data).length <= 0) {
                if(isCompany) {
                    _data["companyId"] = providerId
                }else {
                    _data["userId"] = providerId
                }
            }

            update(analyticRef, _data).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }).catch(errorCallBack)
    }

})

export const saveProviderTotalRatings  = (providerId, totalRatersCount = 1, _totalRatings = 0)=>new Promise((successCallBack, errorCallBack)=>{

    getProviderTotalLoggedUsers(providerId).then((data)=>{

        const analyticRef = ref(db, 'ProviderClientBaseAnalytics/' + providerId+ '/analytics/')
        const totalRatings = (Object.keys(data).length > 0 && "totalRatings" in data && data.totalRatings)?data.totalRatings:null
        const totalRaters = (Object.keys(data).length > 0 && "totalRaters" in data && data.totalRaters)?data.totalRaters:null
        const newData = {
            totalRatings: totalRatings?totalRatings + _totalRatings:_totalRatings,
            totalRaters: totalRatings?totalRaters + totalRatersCount:totalRatersCount,

        }
        
        update(analyticRef, newData).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        });
    }).catch(errorCallBack)

})
export const saveCompanyProviderTotalRatings = (companyId, staffUserId, totalRatersCount = 1, totalRatings = 0)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/staff/"+staffUserId)
    getCompanyStaffVisitsCount(companyId, staffUserId).then((data)=>{

        const _data = Object.keys(data).length > 0?
        {
            ...data,
            totalRatings: ("totalRatings" in data && data.totalRatings)?parseFloat(data.totalRatings) + totalRatings:totalRatings,
            totalRaters: ("totalRaters" in data && data.totalRaters)?parseFloat(data.totalRaters) + totalRatersCount:totalRatersCount,
            //totalBookings: count //override test
        }
        :
        {
            totalRatings: totalRatings,
            totalRaters: totalRatersCount,
        }

        update(analyticRef, _data).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        });
    }).catch(errorCallBack)

})
export const saveCompanyTotalRatings = (companyId, totalRatings = 0,totalRatersCount = 1 )=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/Analytics")
    getCompanyProfileVisitsCount(companyId).then((data)=>{
        const _data = Object.keys(data).length > 0?
        {
            ...data,
            totalRatings: ("totalRatings" in data && data.totalRatings)?parseFloat(data.totalRatings) + totalRatings:totalRatings,
            totalRaters: ("totalRaters" in data && data.totalRaters)?parseFloat(data.totalRaters) + totalRatersCount:totalRatersCount,
            //totalBookings: count //override test
        }
        :
        {
            totalRatings: totalRatings,
            totalRaters: totalRatersCount,
        }
        update(analyticRef, _data).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        });
        /*
        if(Object.keys(data).length > 0 ){
            update(analyticRef, {totalVisits: parseFloat(totalVisits) + 1}).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }else{
            set(analyticRef, {totalVisits: parseFloat(totalVisits) + 1}).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }*/
    }).catch(errorCallBack)

})

export const saveRatingReview = (providerId, review, companyId = null)=>new Promise((successCallBack, errorCallBack)=>{

    const refId = companyId?companyId:providerId
    const myDocRef = doc(fireDB, 'ReviewAndRatings/' + refId+ '/Reviews/'+review.reviewId)

    setDoc(myDocRef, review).then(successCallBack).catch(errorCallBack)

})


export const getRatingAndReview = (bookingId, providerId = null, isCompany = false)=>new Promise(async(successCallBack, errorCallBack)=>{

    
    const myDocRef = collection(fireDB, 'ReviewAndRatings/' + providerId+ '/Reviews/')

    const q = query(myDocRef, 
        where("bookingId", "==", bookingId.toString()),
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallBack(_data)

    // const searchRef = isCompany?
    //     query(
    //         ref(db, 'ReviewAndRatings/Companies/' + providerId+ '/Reviews/'), 
    //         orderByChild('bookingId'), 
    //         equalTo(bookingId)
    //     )
    //     :
    //     query(
    //         ref(db, 'ReviewAndRatings/Users/' + providerId+ '/Reviews/'), 
    //         orderByChild('bookingId'), 
    //         equalTo(bookingId)
    //     )
        
    // onValue(searchRef, (snapshot) => {
    //     if(snapshot.exists()) {
            
    //         successCallBack(Object.values(snapshot.val()))
    //     }else {
    //         successCallBack({})
    //     }
    // }).catch((err)=>{
    //     errorCallBack(err)
    // });

})

export const getAllRatingAndReviews = (providerId, companyId = null, startDate, pageLimit = 6)=>new Promise(async(successCallBack, errorCallBack)=>{


    
    
    const myDocRef = companyId?collection(fireDB, 'ReviewAndRatings/' + companyId+ '/Reviews/')
        :collection(fireDB, 'ReviewAndRatings/' + providerId+ '/Reviews/')

    const q = query(myDocRef, 
        where("updatedAt", "<", startDate.toString()),
        limit(pageLimit),
        orderBy("updatedAt", "desc")
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallBack(_data)

    // 
    // 
    // const analyticRef = companyId?
    //     ref(db, 'ReviewAndRatings/Companies/' + companyId+ '/Reviews/')
    //     :
    //     ref(db, 'ReviewAndRatings/Users/' + providerId+ '/Reviews/')

        
    // const searchRef = query( analyticRef,orderByChild('updatedAt'), endBefore(startDate.toString()), limitToLast(pageLimit))
        
    // get(searchRef).then( (snapshot) => {
    //     if(snapshot.exists()) {
    //         
    //         
    //         successCallBack(Object.values(snapshot.val()))
    //     }else {
    //         successCallBack([])
    //     }
    // }).catch((err)=>{
    //     errorCallBack(err)
    // });

})
export const getServiceRatingAndReviews = (serviceId, providerId, companyId = null, startDate, pageLimit = 6)=>new Promise(async(successCallBack, errorCallBack)=>{


    
    
    const myDocRef = companyId?collection(fireDB, 'ReviewAndRatings/' + companyId+ '/Reviews/')
        :collection(fireDB, 'ReviewAndRatings/' + providerId+ '/Reviews/')

    const q = query(myDocRef, 
        where("updatedAt", "<", startDate.toString()),
        where("serviceId", "==", serviceId),
        limit(pageLimit),
        orderBy("updatedAt", "desc")
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallBack(_data)


})


export const getStaffAllRatingsAndReview = (providerId, companyId , startDate, pageLimit = 6)=>new Promise(async(successCallBack, errorCallBack)=>{

    
    
    
    const myDocRef = collection(fireDB, 'ReviewAndRatings/' + companyId.toString()+ '/Reviews/')

    const q = query(myDocRef, 
        where("updatedAt", "<", startDate.toString()),
        where("providerId", "==", providerId),
        limit(pageLimit),
        orderBy("updatedAt", "desc")
    )
    const docSnap = await getDocs(q)
    const _data = []
    docSnap.forEach((doc) => {
        _data.push(doc.data())        
    });
    successCallBack(_data)

})


export const saveStaffServiceRatingsAnalytics = (totalRatings = 0, totalRatersCount = 1, serviceId, companyId,staffUserId)=>new Promise((successCallBack, errorCallBack)=>{

    if(serviceId) {
        const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/staff_services/"+serviceId+"/"+staffUserId)

        getCompanyStaffServiceRatingsAnalytics(companyId, staffUserId, serviceId).then((data)=>{

            const _data = Object.keys(data).length > 0?
            {
                ...data,
                totalRatings: ("totalRatings" in data && data.totalRatings)?parseFloat(data.totalRatings) + totalRatings:totalRatings,
                totalRaters: ("totalRaters" in data && data.totalRaters)?parseFloat(data.totalRaters) + totalRatersCount:totalRatersCount,
                //totalBookings: count //override test
            }
            :
            {
                totalRatings: totalRatings,
                totalRaters: totalRatersCount,
            }

            update(analyticRef, _data).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }).catch(errorCallBack)
    }

})
export const getCompanyStaffServiceRatingsAnalytics = (companyId, staffUserId, serviceId)=>new Promise(function(successCallback, errorCallback) {

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/staff_services/"+serviceId+"/"+staffUserId)
    get(analyticRef).then((snapshot) => {
        let count = 0
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
            successCallback({})
        }
    }).catch((err)=>{
        errorCallback(err)
    });

})