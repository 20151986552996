import { Close, Done } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/system';
import * as React from 'react';


export default function AddAttendee(props) {
    const { existingAttendees } = props
//   const emails = ['username@gmail.com', 'user02@gmail.com'];    
    const [open, setOpen] = React.useState(false);
    const [emails, setEmails] = React.useState([])
    const [inputEmails, setInputEmails] = React.useState("")
    const [errors, setErrors] = React.useState("")
    const userObj = JSON.parse(localStorage.getItem("user"))
    const user = userObj?userObj.user:null


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = ()=>{
        if(emails.length > 0) {
            localStorage.setItem("attendees", emails.join(","))
        }
        handleClose()
    }

    const handleRemoveEmail = (index) => {
        emails.splice(index, 1)
        
        setEmails([
            ...emails
        ])
    }

    const handleAddEmail = () => {
        if(user && user.email === inputEmails) {
            setErrors("Invalid email")
        }else if(validateEmail(inputEmails)) {
            setEmails([
                ...emails,
                inputEmails
            ])
            setInputEmails("")
        }else {
            setErrors("Invalid email")
        }
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    };

    React.useEffect(() => {
        if(existingAttendees) {
            setEmails([...existingAttendees.split(",")])  
        }  
    }, [existingAttendees])
    

    React.useEffect(() => {
    
        if(errors) {
            setTimeout(
                () => setErrors(""), 
                8000
            )
        }
    
    }, [errors])
    

    return (
        <div>
        
            <Button color="secondary" onClick={handleClickOpen} className='py-0 my-0' style={{textTransform: 'none'}}>
                Add attendees
            </Button>
            <Dialog onClose={handleClose} open={open}
                fullWidth={true}
                maxWidth={"xs"}
            >

                <Box className="d-flex py-3 pr-2">
                    <Typography variant="body2" sx={{m: 0, pb: 1.5, pt: 1.5, pl: 2 , }} >
                        Add attendees
                        
                    </Typography>
                    <div style={{flex: 1}}></div>

                    <IconButton onClick={handleClose} type="submit" sx={{ p: '10px', mr: 0.3 }} aria-label="Add">
                        <Close />
                    </IconButton>
                    <IconButton onClick={handleDone} type="submit" sx={{ p: '10px', mr: 0.3 }} aria-label="Add">
                        <Done />
                    </IconButton>
                </Box>
                <Box className="px-3 pb-0 mb-0"  >
                    
                    <Box className="d-flex px-1" sx={{border: '1px solid #d3d3d3', borderRadius: '10px' }}>
                        <InputBase
                            type={"email"}
                            value={inputEmails}
                            onChange={(e)=>setInputEmails(e.target.value)}
                            sx={{ ml: 1, flex: 1,  }}
                            placeholder="Add attendees"
                            inputProps={{ 'aria-label': 'Add attendees' }}
                            error={errors?true:false }
                        />
                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="Add" onClick={handleAddEmail}>
                            <Add />
                        </IconButton>
                    </Box>
                    <small className='text-danger px-2 pt-1'  >
                        {errors}
                    </small>
                </Box>
                <List sx={{ pb: 2 }}>
                    {
                        emails.length > 0?
                        
                            emails.map((email, idx) => (
                                <ListItem button key={email} onClick={()=>handleRemoveEmail(idx)}>
                                    <ListItemAvatar sx={{ mr: 1,  }} >
                                        <Avatar sx={{ bgcolor: "#F4F5FA", color: "#F0973B" }}>
                                            {
                                                email.substring(0, 1).toUpperCase()
                                            }
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={
                                            <Typography variant="span" style={{width: {md: '100%', xs: '90%'} , fontSize: {xs: '14px', md: '17px'}, wordBreak: 'break-all' }}>
                                                {email}
                                            </Typography>
                                        }
                                    />
                                    <IconButton handleClick={()=>handleRemoveEmail(idx)} sx={{width: '25px', height: '25px', color: "#000" }}>
                                        <Close sx={{fontSize: '18px' }} />   
                                    </IconButton>
                                </ListItem>
                            ))
                        :
                        <p className='text-left p-0 pl-4'>0 emails added</p>
                    }

                </List>
            </Dialog>
        </div>
    );
}
