
export const inputStyles= { 
    backgroundColor: "#F4F5FA", 
    borderColor: "#F4F5FA",
    width: '100%',
    padding: '8px',
    border: '1px solid #F4F5FA',
    boxSizing: 'border-box',
    borderRadius: '5px',

}