import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import * as React from 'react';


export default function BookingStepper(props) {
    const { navData } = props
  const [steps, setSteps] = React.useState([]);
//   const [skipped, setSkipped] = React.useState(new Set());


//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

  React.useEffect(()=>{
    const _steps = []
    for (let i = 0; i < navData.totalSteps; i++) {
      _steps.push('')      
    }
    setSteps([..._steps])
  },[navData])



  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={navData.step}>
        {steps.map((label, index) => {
          return (
            <Step key={label} completed={(index < parseInt(navData.step) === true)}>
              <StepLabel                
                StepIconProps={{
                  sx: { stroke: '#fff !important' } 
                }}
              >{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
