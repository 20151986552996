import { Fragment, useEffect, useState } from 'react';

// reactstrap components
import { Close } from '@mui/icons-material';
import { FormControl, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
    Button,
    Card, CardBody, Form
} from "reactstrap";
import { inputStyles } from 'Utils/style';


export default function ClientDetailsForm(props) {
    const { booking,  handleUpdateBooking } = props
    const [bookings, setBookings] = useState({})
    const [data, setData] = useState({})
    const [open, setOpen] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    

    const toggleModal = ()=>{
        setOpen(!open)
    }
    const handleConfirmBooking = ()=> {
        if(!("date" in booking)) {
            showSnackBar("Please select a date to continue", "error")
        }else if(data.email.trim() === ""){
            showSnackBar("Please add email to continue", "error")
        }else if(data.name.trim() === ""){
            showSnackBar("Please add user name to continue", "error")            
        }else {

            bookings.client.email = data.email
            bookings.client.clientId = ""
            bookings.client.fName = data.name
            bookings.client.sName = ""
            bookings.client.clientImg = ""
            bookings["bookingDetails"] = "Booking Title: "+data.title+"\n"+
                "Booking Desc: "+data.desc+"\n"
            setBookings(bookings)
            handleUpdateBooking(bookings)
            toggleModal()            
        }
    }


    const handleFormInputChange = (key, e) => {
        setData({
            ...data,
            [key]: e.target.value
        })
    }

    

    const formInputs = [
        
        {
            name: "email",
            required: true,
            holder: 'client email',
            type: 'email'
        },
        {
            name: "name",
            required: true,
            holder: 'Client Name',
            type: 'text'
        },
        {
            name: "title",
            required: false,
            holder: "booking title",
            type: 'text'
        }, 
        {
            name: "desc",
            required: false,
            holder: 'booking description',
            type: 'text'
        }, 
    ]

    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    useEffect(() => {
       
        setBookings(booking)
    
      return () => {
        setBookings({})
      }
    }, [booking])
    

    return (
        
        <Card className="border-0">
            <CardBody className="px-3">
                <Form >

                    {
                        formInputs.map((item, idx)=>(


                            <FormControl fullWidth size="sm" key={idx} sx={{mb: 1}} >

                                <label id={item.name}>{item.holder}</label>
                                {

                                    item.name === 'desc'?
                                        <Fragment>
                                            <textarea 
                                                style={inputStyles}
                                                fullWidth 
                                                id={item.name}
                                                name={item.name} 
                                                placeholder={item.placeholder}
                                                required = {item.required}
                                                value={
                                                    data && item.name in data?
                                                        data[item.name]
                                                    :""
                                                }
                                                onChange={(e)=>handleFormInputChange(item.name, e)} 
                                                rows="4" 
                                                cols="50"></textarea>

                                                
                                            <div className='text-right p-1' style={{fontSize: '14px',color: "#999" , fontWeight: 300}}>
                                                {
                                                    data && item.name in data?
                                                        data[item.name].length
                                                    :0
                                                } /300
                                            </div>

                                        </Fragment>
                                    :

                                    <input
                                        className="mb-2"
                                        style={inputStyles}
                                        placeholder={item.placeholder}
                                        type={item.type}
                                        name={item.name}
                                        id={item.name}
                                        min={10}
                                        step={10}
                                        onChange={(e)=>handleFormInputChange(item.name, e)}
                                        value={
                                            data && item.name in data?
                                                data[item.name]
                                            :""
                                        }
                                    />

                                }
                            </FormControl>
                        ))

                        
                        
                    }


                    <div className="d-flex">
                        <div style={{flex: 1}}></div>
                        
                        <Button onClick={handleConfirmBooking} 
                            className="my-4 rounded border-0 my-1"
                            style={{color: "#fff", background: '#F0973B',}}
                            color="primary" type="button">
                            Save    
                            
                        </Button>
                    </div>
                </Form>
            </CardBody>
        </Card>
    )
}