import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

export default function AddMeetingModal(props) {

    const { _propsOpen, title, msg, sigIn, handleCancelPermission } = props
  const [open, setOpen] = React.useState(_propsOpen)

  const handleSignIn = () => {
    setOpen(false)
    sigIn()
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    handleCancelPermission()
    setOpen(false)
  };

  React.useEffect(() => {
    setOpen(_propsOpen)
  
    return () => null
  }, [_propsOpen])
  

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Close</Button>
          <Button onClick={handleSignIn} color={"primary"} autoFocus>
            Enable
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
