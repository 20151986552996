
import { initializeApp } from "firebase/app";
import { get, getDatabase, onValue, ref, set, update } from "firebase/database";
import { firebaseConfig } from "../../AppSettings";

const app = initializeApp(firebaseConfig);
const db = getDatabase()
export const saveCompanyProfileVisitsCount = (companyId)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/Analytics")
    getCompanyProfileVisitsCount(companyId).then((data)=>{

        const totalVisits = Object.keys(data).length > 0 && "totalVisits" in data && data.totalVisits?parseFloat(data.totalVisits):0

        update(analyticRef, {totalVisits: parseFloat(totalVisits) + 1}).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        });
        /*
        if(Object.keys(data).length > 0 ){
            update(analyticRef, {totalVisits: parseFloat(totalVisits) + 1}).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }else{
            set(analyticRef, {totalVisits: parseFloat(totalVisits) + 1}).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }*/
    }).catch(errorCallBack)

})
export const saveCompanyTotalGuests = (companyId, count = null)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/Analytics")
    // update(analyticRef, {totalGuests: (count || 0)}).then(()=>{
    //     successCallBack("Count updated successfully")
    // }).catch((err)=>{
    //     errorCallBack(err)
    // });
    getCompanyProfileVisitsCount(companyId).then((data)=>{

        const totalGuests = Object.keys(data).length > 0  && "totalGuests" in data && data.totalGuests?data.totalGuests:0
        update(analyticRef, {totalGuests: parseFloat(totalGuests) + (count || 1)}).then(()=>{
//        update(analyticRef, {totalGuests: (count || 0)}).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        })

        // if(Object.keys(data).length > 0 ){
        //     update(analyticRef, {totalGuests: parseFloat(totalGuests) + (count || 1)}).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     })
        // }else {
            // set(analyticRef, {totalGuests: parseFloat(totalGuests) + (count || 1)}).then(()=>{
            //     successCallBack("Count updated successfully")
            // }).catch((err)=>{
            //     errorCallBack(err)
            // });
        // }
    }).catch(errorCallBack)

})
export const saveCompanyTotalLoggedUsers = (companyId, count = null)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/Analytics")
    // update(analyticRef, {totalClients:  (count || 0)}).then(()=>{
    //     //update(analyticRef, {totalClients: (count || 0)}).then(()=>{
    //        successCallBack("Count updated successfully")
    //    }).catch((err)=>{
    //        errorCallBack(err)
    //    });
    getCompanyProfileVisitsCount(companyId).then((data)=>{

        const totalClients = (Object.keys(data).length > 0 && "totalClients" in data && data.totalClients)?data.totalClients:0
        
        update(analyticRef, {totalClients: parseFloat(totalClients) + (count || 1)}).then(()=>{
         //update(analyticRef, {totalClients: (count || 0)}).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        });

    }).catch(errorCallBack)

})


export const saveCompanyTotalBookings = (companyId, count = 1)=>new Promise((successCallBack, errorCallBack)=>{

    
    
    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/Analytics")
    getCompanyProfileVisitsCount(companyId).then((data)=>{

        
        const totalBookings = (Object.keys(data).length > 0 && "totalBookings" in data && data.totalBookings)?data.totalBookings:0
        
        if(!(count === -1 && totalBookings === 0)) {
            update(analyticRef, {totalBookings: parseFloat(totalBookings) + parseFloat(count)}).then(()=>{
            //update(analyticRef, {totalBookings: parseFloat(count)}).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }
        // if(Object.keys(data).length > 0){
            
        // }else{
        //     set(analyticRef, {totalBookings: parseFloat(totalBookings) + parseFloat(count)}).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     });
        // }
    }).catch(errorCallBack)

})
 
export const saveProviderTotalLoggedUsers = (providerId, _totalGuests = null, _totalClients = null)=>new Promise((successCallBack, errorCallBack)=>{
    // const analyticRef = ref(db, 'ProviderClientBaseAnalytics/' + providerId+ '/analytics/')
    // update(analyticRef, {
    //     totalGuests: (_totalGuests || 0),
    //     totalClients: (_totalClients || 0),
    // }).then(()=>{
    //     successCallBack("Count updated successfully")
    // }).catch((err)=>{
    //     errorCallBack(err)
    // });
    getProviderTotalLoggedUsers(providerId).then((data)=>{

        const analyticRef = ref(db, 'ProviderClientBaseAnalytics/' + providerId+ '/analytics/')
        const totalGuests = (Object.keys(data).length > 0 && "totalGuests" in data && data.totalGuests)?data.totalGuests:0
        const totalClients = (Object.keys(data).length > 0 && "totalClients" in data && data.totalClients)?data.totalClients:0
        const newData = {
            totalGuests: _totalGuests?totalGuests + _totalGuests:totalGuests,
            totalClients: _totalClients?totalClients + _totalClients:totalClients,

            //totalGuests: _totalGuests,
            //totalClients: _totalClients,
        }
        
        update(analyticRef, newData).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        });
        
        // if(Object.keys(data).length > 0) {
        //     update(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     });
        // }else{
        //     set(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     });
        // }
    }).catch(errorCallBack)

})


export const saveProviderTotalBookings = (providerId, count = null)=>new Promise((successCallBack, errorCallBack)=>{

    getProviderTotalLoggedUsers(providerId).then((data)=>{

        const analyticRef = ref(db, 'ProviderClientBaseAnalytics/' + providerId+ '/analytics/')
        const totalBookings = (Object.keys(data).length > 0 && "totalBookings" in data && data.totalBookings)?data.totalBookings:0
         //const totalBookings = 0

        if(!(count === -1 && totalBookings === 0)) {
            const newData = {
                totalBookings: totalBookings + (count || 0)
            }
            
            update(analyticRef, newData).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }
        // if(Object.keys(data).length > 0) {
        //     update(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     });
        // }else{
        //     set(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     });
        // }
    }).catch(errorCallBack)

})

export const getProviderTotalLoggedUsers = (providerId)=>new Promise(function(successCallback, errorCallback) {

    

    const analyticRef = ref(db, 'ProviderClientBaseAnalytics/' + providerId + '/analytics/')
    onValue(analyticRef, (snapshot) => {
        // 
        
        
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
            successCallback({})
        }
    }, (err)=>{
        errorCallback(err)
    });

})

export const saveAppTotalLoggedUsers = (companyId)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'LatomAnalytics/')
    getLatomAnalytics().then((data)=>{

        const totalLoggedUsers = (Object.keys(data).length > 0 && "totalLoggedUsers" in data && data.totalLoggedUsers)?data.totalLoggedUsers:0
        if(Object.keys(data).length > 0){

            update(analyticRef, {totalLoggedUsers: parseFloat(totalLoggedUsers) + 1}).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }else{
            set(analyticRef, {totalLoggedUsers: parseFloat(totalLoggedUsers) + 1}).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }
    }).catch(errorCallBack)

})


export const saveCompanyClientBookingAnalytics = (companyId, clientId, lastBookingDate, creationTime, count = 1)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/ClientBookingAnalytics/"+clientId)
    getCompanyClientBookingAnalytics(companyId, clientId).then((data)=>{

        const totalBookings = Object.keys(data).length > 0  && "totalBookings" in data && data.totalBookings?data.totalBookings:0
        const _creationTime = Object.keys(data).length > 0  && "creationTime" in data && data.creationTime?
            (parseFloat(creationTime) >= parseFloat(data.creationTime) )?creationTime:data.creationTime:creationTime
        const _lastBookingDate = Object.keys(data).length > 0  && "lastBookingDate" in data && data.creationTime?
            (parseFloat(creationTime) >= parseFloat(data.creationTime) )?lastBookingDate:data.lastBookingDate:lastBookingDate
        const newData = {
            totalBookings: parseFloat(totalBookings) + (count),
            lastBookingDate: _lastBookingDate,
            creationTime: _creationTime
        }
        
        update(analyticRef, newData).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        })
        // if(Object.keys(data).length > 0 ){
        //     update(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     })
        // }else {
        //     set(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     });
        // }
    }).catch(errorCallBack)

})


export const saveCompanyClientTotalBookings = (companyId, clientId, count = 1)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/ClientBookingAnalytics/"+clientId)
    getCompanyClientBookingAnalytics(companyId, clientId).then((data)=>{

        const totalBookings = Object.keys(data).length > 0  && "totalBookings" in data && data.totalBookings?data.totalBookings:0
        
        if(!(count === -1 && totalBookings === 0)) {
            const newData = {
                totalBookings: parseFloat(totalBookings) + (count),
            }
            
            update(analyticRef, newData).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            })
        }
        // if(Object.keys(data).length > 0 ){
        //     update(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     })
        // }else {
        //     set(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     });
        // }
    }).catch(errorCallBack)

})


export const getCompanyClientBookingAnalytics = (companyId, clientId)=>new Promise(function(successCallback, errorCallback) {


    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/ClientBookingAnalytics/"+clientId)
    onValue(analyticRef, (snapshot) => {
        let count = 0
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
            successCallback({}) 
        }
    }, (err)=>{
        errorCallback(err)
    });

})


export const saveProviderClientBookingAnalytics = (providerId, clientId, lastBookingDate, creationTime, count = 1)=>new Promise((successCallBack, errorCallBack)=>{

    
    const analyticRef = ref(db, 'ProviderClientBaseAnalytics/' + providerId+"/ClientBookingAnalytics/"+clientId)
    getProviderClientBookingAnalytics(providerId, clientId).then((data)=>{

        // 
        const totalBookings = Object.keys(data).length > 0  && "totalBookings" in data && data.totalBookings?data.totalBookings:0
        const _creationTime = Object.keys(data).length > 0  && "creationTime" in data && data.creationTime?
            (parseFloat(creationTime) >= parseFloat(data.creationTime) )?(creationTime || new Date().valueOf()):data.creationTime:(creationTime || new Date().valueOf())
        const _lastBookingDate = Object.keys(data).length > 0  && "lastBookingDate" in data && data.creationTime?
            (parseFloat(creationTime) >= parseFloat(data.creationTime) )?lastBookingDate:data.lastBookingDate:lastBookingDate
        const newData = {
            totalBookings: parseFloat(totalBookings) + count,
            lastBookingDate: _lastBookingDate,
            creationTime: (_creationTime || new Date().valueOf())
        }
        
        // 
        update(analyticRef, newData).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        })
        // if(Object.keys(data).length > 0 ){
        //     // 
        //     update(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     })
        // }else {
        //     // 
        //     set(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     });
        // }
    }).catch(errorCallBack)

})


export const saveProviderClientTotalBooking = (providerId, clientId, count = 1)=>new Promise((successCallBack, errorCallBack)=>{

    
    const analyticRef = ref(db, 'ProviderClientBaseAnalytics/' + providerId+"/ClientBookingAnalytics/"+clientId)
    getProviderClientBookingAnalytics(providerId, clientId).then((data)=>{

        // 
        const totalBookings = Object.keys(data).length > 0  && "totalBookings" in data && data.totalBookings?data.totalBookings:0
        
        // 
        if(!(count === -1 && totalBookings === 0)) {
            const newData = {
                totalBookings: parseFloat(totalBookings) + count
            }
            update(analyticRef, newData).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            })
        }
        // if(Object.keys(data).length > 0 ){
        //     // 
        //     update(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     })
        // }else {
        //     // 
        //     set(analyticRef, newData).then(()=>{
        //         successCallBack("Count updated successfully")
        //     }).catch((err)=>{
        //         errorCallBack(err)
        //     });
        // }
    }).catch(errorCallBack)

})


export const getProviderClientBookingAnalytics = (providerId, clientId)=>new Promise(function(successCallback, errorCallback) {


    const analyticRef = ref(db, 'ProviderClientBaseAnalytics/' + providerId+"/ClientBookingAnalytics/"+clientId)
    onValue(analyticRef, (snapshot) => {
        //let count = 0
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
            successCallback({}) 
        }
    }, (err)=>{
        errorCallback(err)
    });

})

export const getCompanyProfileVisitsCount = (companyId)=>new Promise(function(successCallback, errorCallback) {


    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/Analytics")
    onValue(analyticRef, (snapshot) => {
        let count = 0
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
            successCallback({}) 
        }
    }, (err)=>{
        errorCallback(err)
    });

})


export const getCompanyAndStaffProfileVisitsCount = (companyId)=>new Promise(function(successCallback, errorCallback) {


    getCompanyProfileVisitsCount(companyId).then((data)=>{

        const analyticRef = ref(db, 'CompanyAnalytics/' + companyId + '/staff')//get all staff analytics
        onValue(analyticRef, (snapshot) => {
            let staffAnalytics = []
            if(snapshot.exists()) {

                staffAnalytics = snapshot.val()

            }
            const newData = {
                ...data,
                "staff": staffAnalytics
            }
            successCallback(newData)

        }, (err)=>{
            errorCallback(err)
        });

    }).catch(errorCallback)

    

})


export const getLatomAnalytics = ()=>new Promise(function(successCallback, errorCallback) {


    const analyticRef = ref(db, 'LatomAnalytics/')
    onValue(analyticRef, (snapshot) => {
        let count = 0
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
            successCallback({})
        }
    }, (err)=>{
        errorCallback(err)
    });

})


export const saveCompanyStaffVisitsCount = (companyId, staffUserId)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/staff/"+staffUserId)
    getCompanyStaffVisitsCount(companyId, staffUserId).then((data)=>{

        const _data = Object.keys(data).length > 0?
        {
            ...data,
            totalVisits: ("totalVisits" in data && data.totalVisits)?parseFloat(data.totalVisits) + 1:1
        }
        :
        {
            totalBookings: 0,
            initiatedBookings: 0,
            totalVisits: 1
        }

        update(analyticRef, _data).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        });
    }).catch(errorCallBack)

})


export const saveCompanyStaffTotalBookings= (companyId, staffUserId)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/staff/"+staffUserId)
    getCompanyStaffVisitsCount(companyId, staffUserId).then((data)=>{

        const _data = Object.keys(data).length > 0?
        {
            ...data,
            totalBookings: ("totalBookings" in data && data.totalBookings)?parseFloat(data.totalBookings) + 1:1
        }
        :
        {
            totalBookings: 1,
            initiatedBookings: 0,
            totalVisits: 0
        }

        update(analyticRef, _data).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        });
    }).catch(errorCallBack)

})

export const saveCompanyStaffInitiatedBookings = (companyId, staffUserId)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/staff/"+staffUserId)
    getCompanyStaffVisitsCount(companyId, staffUserId).then((data)=>{

        const _data = Object.keys(data).length > 0?
        {
            ...data,
            initiatedBookings: ("initiatedBookings" in data && data.initiatedBookings)?parseFloat(data.initiatedBookings) + 1:1
        }
        :
        {
            totalBookings: 0,
            initiatedBookings: 1,
            totalVisits: 0
        }

        update(analyticRef, _data).then(()=>{
            successCallBack("Count updated successfully")
        }).catch((err)=>{
            errorCallBack(err)
        });
    }).catch(errorCallBack)

})

export const getServiceTotalBookingsAnalytics = (serviceId, providerId, isCompany = false)=>new Promise(function(successCallback, errorCallback) {

    const analyticRef = isCompany?ref(db, 'CompanyAnalytics/' + providerId+"/services/"+serviceId)
        :ref(db, 'ProviderClientBaseAnalytics/' + providerId+"/services/"+serviceId)
    onValue(analyticRef, (snapshot) => {
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
            successCallback({})
        }
    }, (err)=>{
        errorCallback(err)
    });

})



export const saveServiceTotalBookingsAnalytics = (count = 1, serviceId, providerId, isCompany = false)=>new Promise((successCallBack, errorCallBack)=>{

    if(serviceId) {
        const analyticRef = isCompany?ref(db, 'CompanyAnalytics/' + providerId+"/services/"+serviceId)
            :ref(db, 'ProviderClientBaseAnalytics/' + providerId+"/services/"+serviceId)

        getServiceTotalBookingsAnalytics(serviceId, providerId, isCompany).then((data)=>{

            const _data = Object.keys(data).length > 0?
            {
                ...data,
                totalBookings: ("totalBookings" in data && data.totalBookings)?parseFloat(data.totalBookings) + count:count
                //totalBookings: count //override test
            }
            :
            {
                totalBookings: count,
            }

            if(Object.keys(data).length <= 0) {
                if(isCompany) {
                    _data["companyId"] = providerId
                }else {
                    _data["userId"] = providerId
                }
            }

            update(analyticRef, _data).then(()=>{
                successCallBack("Count updated successfully")
            }).catch((err)=>{
                errorCallBack(err)
            });
        }).catch(errorCallBack)
    }

})

export const getCompanyStaffVisitsCount = (companyId, staffUserId)=>new Promise(function(successCallback, errorCallback) {
 
    const analyticRef = ref(db, 'CompanyAnalytics/' + companyId+"/staff/"+staffUserId)
    get(analyticRef).then((snapshot) => {
        let count = 0
        if(snapshot.exists()) {
            successCallback(snapshot.val())
        }else {
            successCallback({})
        }
    }).catch((err)=>{
        errorCallback(err)
    });

})



export const saveSurveyResponses = (userId, responses)=>new Promise((successCallBack, errorCallBack)=>{

    const analyticRef = ref(db, 'SurveyResponses/' + userId+ '/')
    set(analyticRef, responses).then(()=>{
        successCallBack("Count updated successfully")
    }).catch((err)=>{
        errorCallBack(err)
    });

})