import { __hereMapsApiKey } from "AppSettings";
import axios from "axios";

export const searchLocation_ = (query)=>new Promise(function(successCallBack, errorCallback){
    const url = "https://geocode.search.hereapi.com/v1/geocode?q="+query+"&apiKey="+__hereMapsApiKey
    // const url = "https://geocoder.ls.hereapi.com/6.2/geocode.json?searchtext="+query+"&gen="+9+"&apiKey="+hereMapsApiKey
    axios.get(url)
    .then(successCallBack)
    .catch(errorCallback);
})

export const searchLocation = (query, lat, long)=>new Promise(function(successCallBack, errorCallback){
    
    // const headers = {
    //     "Content-Type": "application/json",
    //     "Authorization": "Bearer "+ __hereMapsApiKey
    // }
    const url = "https://autosuggest.search.hereapi.com/v1/autosuggest?at="+
        lat.toString()+","+long.toString()+"&limit=5&lang=en&q="+query+
        "&apiKey="+__hereMapsApiKey
    
    // const url = "https://geocoder.ls.hereapi.com/6.2/geocode.json?searchtext="+query+"&gen="+9+"&apiKey="+hereMapsApiKey
    // axios.get(url, {headers: headers})
    axios.get(url)
    .then(successCallBack)
    .catch(errorCallback);
})


export const reverseGeocodeLoc = ( lat, long)=>new Promise(function(successCallBack, errorCallback){
    
    // const headers = {
    //     "Content-Type": "application/json",
    //     "Authorization": "Bearer "+ __hereMapsApiKey
    // }
    const url = "https://revgeocode.search.hereapi.com/v1/revgeocode?lang=en-US&at="+
        lat.toString()+","+long.toString()+"&apiKey="+__hereMapsApiKey
    
    // const url = "https://geocoder.ls.hereapi.com/6.2/geocode.json?searchtext="+query+"&gen="+9+"&apiKey="+hereMapsApiKey
    // axios.get(url, {headers: headers})
    axios.get(url)
    .then(successCallBack)
    .catch(errorCallback);
})